
import {createElement, Component} from 'rmlibrary/comp';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';
const KEYDOWN = 'keydown';

export class FocusDetect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastEvent: null,
            pointerFocus: false
        };

        this.domEl = null;

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleRef = this.handleRef.bind(this);
    }

    handleMouseDown() {
        this.setState({
            lastEvent: MOUSEDOWN
        });
    }

    handleTouchStart() {
        this.setState({
            lastEvent: TOUCHSTART
        });
    }

    handleKeyDown() {
        this.setState({
            lastEvent: KEYDOWN
        });
    }

    handleFocus() {
        this.setState({
            pointerFocus: this.state.lastEvent === MOUSEDOWN || this.state.lastEvent === TOUCHSTART
        });
    }

    handleRef(el) {
        if (el) {
            el.addEventListener('keydown', this.handleKeyDown, true);
        } else {
            try {
                this.domEl.removeEventListener('keydown', this.handleKeyDown, true);
            } catch(e) {
                // Do nothing. DOM element already destroyed
            }
        }
        this.domEl = el;
    }

    render() {
        return (<div
            ref={this.handleRef}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}

            onFocus={this.handleFocus}
            className={ this.state.pointerFocus ? 'pointer-focus' : '' }
        >
            {this.props.children}
        </div>)
    }
}
