<style lang="scss">
    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: $yellow;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only, $md-only} {
            font-size: 36px;
        }
    }

    h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only, $md-only} {
            font-size: 17px;
            padding-bottom: 0px;
        }

        &.reccomend-to-colleague {
            padding-bottom: 20px;
        }
    }

    .results-container {
        position: relative;
        padding: 40px 20px;
        height: 100%;
        h1 {
            @media #{$lg-up} {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .rating-btns {
        display: flex;
        justify-content: center;

        .btn {
            background-color: $yellow;
            color: $black90;
            font-size: 22px;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: 0.5s ease;
            margin: 0 30px;
        }
    }

    .thumbs {
        width: 29px;
        padding-top: 7px;
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:active {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    .btn:focus {
        background-color: #fff;
        outline: none;
        color: #1a1a1a;
        border: 1px solid $yellow;
    }

    .btn.selected {
        background: $white;
        color: #1a1a1a;
        outline: none;
    }

    .action-btn-wrapper {
        @media #{$xs-only, $sm-only, $md-only} {
            @media screen and (max-height: 800px) {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
                padding-bottom: 0;
            }
        }
    }

    .main-container {
        overflow-x: hidden;
        position: relative;
    }

    .hide {
        display: none;
    }
</style>

<template name="SurveyResults" extends="SurveyResultsController">
    <main class="main-container" id="resultsPage" class.yellow-banner="{this.props.theme === 'yellow'}">
        <div class="text-container">
            <h1 tabIndex="-1" style="outline: none" ref="{this.focusElement}">{this.props.title}</h1>

            <div className="{this.props.question ? '' : 'hide'}">
                <h2 class="reccomend-to-colleague">{this.props.question}</h2>
                <div class="rating-btns">
                    <button
                        className="btn"
                        onClick="{() => {this.props.onSubmit({name: this.props.questionName, value: 'email'})}}"
                        ref="{this.buttonRef}"
                    >
                        <img class="thumbs" src="./site_assets/images/survey/thumbs-up.svg" alt="thumbs up icon" />
                    </button>
                </div>
            </div>
        </div>
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">
                    Back
                </button>
            </div>
        </div>
    </main>
</template>

<script>
    import "@rmlibrary/fetch";

    class SurveyResultsController extends Component {
        constructor(props) {
            super(props);
            this.buttonRef = React.createRef();
            this.focusElement = React.createRef();
        }

        // sets focus to h1 and scrolls to bottom of nav bar
        // adds eventlistener to hide "not now button" popup once active element moves off the button
        componentDidMount() {
            window.scrollTo(0, 0);
            // this.buttonRef.current.setAttribute("disabled", "true");
            this.postResults(this.props.engagementId);
        }

        componentDidUpdate() {
            this.postResults(this.props.engagementId);
        }

        postResults(engagementId) {
            let resultsString = "";

            this.props.answers.forEach((ans) => {
                if (ans.value.email) {
                    resultsString += `${ans.name}: [firstName: ${ans.value.firstName}, lastName: ${ans.value.lastName}, email: ${ans.value.email}], `;
                } else if (typeof ans.value === "object") {
                    if (typeof ans.value.emailSent === "boolean") {
                        resultsString += `${ans.name}: false, `;
                    } else {
                        resultsString += `${ans.name}: [${ans.value}], `;
                    }
                } else if (typeof ans.value === "number") {
                    resultsString += `${ans.name}: ${Math.round(ans.value)}, `;
                } else {
                    resultsString += `${ans.name}: ${ans.value}, `;
                }
            });

            resultsString = resultsString.slice(0, resultsString.length - 2);

            const timestamp = Date.now();

            // Building the query string
            const vid = this.props.vid;
            const firstName = this.props.firstName;
            const lastName = this.props.lastName;
            const email = this.props.accountMgrEmail;
            const company = this.props.company;

            let searchParams = `contactId=${vid}&timestamp=${timestamp}&notes=${resultsString}&firstName=${firstName}&lastName=${lastName}&email=${email}&company=${company}`;

            if (engagementId) {
                searchParams += `&engagementId=${engagementId}`;
            }

            if (this.props.notes === resultsString) {
                // this.buttonRef.current.removeAttribute("disabled");
            } else {
                const response = fetch("https://www.richmedia.com/api/surveyCompletedNote.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: searchParams,
                });

                response
                    .then((data) => {
                        return data.json();
                    })
                    .then((jsonResponse) => {
                        // Successful submission of survey notes
                        // this.buttonRef.current.removeAttribute("disabled", "true");
                        const res = JSON.parse(jsonResponse);
                        if (res.associationCreateFailures && res.associationCreateFailures.length === 0) {
                            // console.log("Success");
                            this.props.updateEngagementId(res.engagement.id, resultsString);
                        } else {
                            // console.log(res);
                            // console.log("updated");
                            this.props.updateNotes(resultsString);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }

    SurveyResultsController.defaultProps = {
        title: "Thank you for your feedback.",
        section: "results",
    };
</script>
