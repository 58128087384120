<style lang="scss">
    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #1a1a1a;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #1a1a1a;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 40px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    .landing-container {
        min-height: calc(100vh - 409px);        
        background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap:wrap;
        
        @media #{$lg-only} {
            min-height: calc(100vh - 411px);        
        }
        
        @media #{$md-only, $sm-only} {
            height:auto; 
        }
    }

    .text-container {
        text-align: center;
        padding: 20px 20px;
        width:100%;
        @media #{$xs-only} {
            padding: 20px 10px;
        }
        @media #{$md-only} {
            padding-bottom:100px;   
        }
        .mobile {
            height:107px;
        }
    }

    .rating-btns {
        display: flex;
        justify-content: center;
        
        .rating-btns-mobile {
            display: flex;
        }

        @media #{$xs-only, $sm-only} {
            /* flex-direction: column-reverse;
            align-items: center;*/
            flex-wrap: wrap;
        }
    }

    .rating-desc {
        padding-top: 15px;
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {  
            background: $white;
            color: #1a1a1a;
            outline: none;
        }
    }

    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:active { 
            background: white;
            color: black;
        }
    }

    .black-banner {
        background: $black90;
        h1, p {
            color: $yellow;
        }
        h2 {
            color:white;
        }
        button {
            background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);
            transition: 0.25s ease;
            & p {
                color: $black90;
            }
        }
        
        .back-button {
            background: url("images/icons/back-button-yellow.svg");
            background-size:cover;
            &:focus {
                border: 1px solid $yellow;
                border-radius: 100%;
            }
        }
        .next-button {
            background: url("images/icons/back-button-yellow.svg");
            background-size:cover;
            &:focus {
                border: 1px solid $yellow;
                border-radius: 100%;
            }
            
        }       
        .btn:focus {
            background-color: $white;
            outline: none;
            border: 1px solid $yellow;
        }   
        
        @media (-ms-high-contrast: none), (pointer: fine) {
            .back-button:hover, .next-button:hover {
                background: url("images/icons/back-button-white.svg");
                outline: none;
                background-size: cover;
            }
            .back-button:hover + .back-text, .next-button:hover + .next-text {
                opacity: 1;
            }
        }
        @media (-ms-high-contrast: none), (pointer: coarse) {
            .back-button:active, .next-button:active { 
                background: url("images/icons/back-button-white.svg");
                outline: none;
                background-size: cover;
            }
        }
    
        .back-text {
            color: #fff;
        }
        .rating-desc {
            color:white;
        }
    }
    
</style>

<template name="SurveyRating" extends="SurveyRatingController">
    <main class="landing-container" class.black-banner="{this.props.theme === 'black'}">
        <div class="text-container">
            <h1 tabIndex="-1" style="outline: none;" ref="{this.focusElement}">{this.props.title}</h1>

            <div class="desktop">
                <h2>{this.props.question}</h2>
                <p class="sr-only">select a rating between 1 and 6, 1 being strongly disagree, 6 being strongly agree</span>
            </div>  

            <div class="mobile">
                <h2>{this.props.question}</h2>
                <p>(1 being strongly disagree, 6 being strongly agree)</p>
            </div>
            
            <div class="rating-btns">
                <div>
                    <button class="{'rating-btn btn ' + (this.props.answer === 1 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(1)}">
                        <p>1</p>
                    </button>
                    <p class="rating-desc desktop">strongly disagree</p>
                </div>
        
                <button class="{'rating-btn btn ' + (this.props.answer === 2 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(2)}">
                    <p>2</p>
                </button>
        
                <button class="{'rating-btn btn ' + (this.props.answer === 3 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(3)}">
                    <p>3</p>
                </button>
                
                <div class="rating-btns-mobile">
                    <button class="{'rating-btn btn ' + (this.props.answer === 4 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(4)}">
                        <p>4</p>
                    </button>
                
                    <button class="{'rating-btn btn ' + (this.props.answer === 5 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(5)}">
                        <p>5</p>
                    </button>
        
                    <div>
                        <button class="{'rating-btn btn ' + (this.props.answer === 6 ? 'selected' : '')}" onClick="{() => this.props.onSubmit(6)}">
                            <p>6</p>
                        </button>
                        <p class="rating-desc desktop">strongly agree</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button" onClick="{() => this.props.onSubmit('back')}"></button>
                <div class="back-text"><p>Back</p></div>
            </div>
            <div class="next-button-container" style="{{display: this.props.answer > 0 ? 'block' : 'none'}}">
                <button aria-label="next" class="next-button" onClick="{() => this.props.onSubmit(this.props.answer)}"></button>
                <div class="next-text"><p>Next</p></div>
            </div>
        </div>    
        <!-- </SurveyAnimation> -->
    </main>
</template>
<script>
    class SurveyRatingController extends Component {
        constructor(props) {
            super(props);

            this.focusElement = React.createRef();
        }

        /* sets focus on h1 and scrolls to bottom of nav */
        componentDidMount() {
            this.focusElement.current.focus();  
            let nav = document.getElementsByClassName('page-header')[0];
            let x = nav.offsetWidth / 2;
            let y = nav.offsetHeight;
            window.scrollTo(x,y);
        }
        
        componentDidUpdate() {
            this.focusElement.current.focus();  
            let nav = document.getElementsByClassName('page-header')[0];
            let x = nav.offsetWidth / 2;
            let y = nav.offsetHeight;
            window.scrollTo(x,y);
        }
    }

    SurveyRatingController.defaultProps = {
        title: "Customer Care",
        question: "We are attentive to your needs and proactively communicating with you.",
        onSubmit: () => console.log("submit")
    }
</script>