/*globals window, document */
import {Component, createElement} from "rmlibrary/comp";

const requestAnimationFrame = window.requestAnimationFrame || function (cb) { return window.setTimeout(cb(new Date().getTime()), 16); };
const cancelAnimationFrame = window.cancelAnimationFrame || function (id) { window.clearTimeout(id); };

function myCreateRef(cb) {
    const fn = (ref) => {
        fn.current = ref;
        if (typeof cb === 'function') cb();
    }
    fn.current = null;
    return fn;
}

const VERTEX_SHADER = `
    attribute vec4 aVertexPosition;
    attribute vec2 aTextureCoord;

    varying highp vec2 vTextureCoord;

    void main() {
      gl_Position = aVertexPosition;
      vTextureCoord = aTextureCoord;
    }
`

const IS_IOS_10_1_OR_2 = (/ipod|ipad|iphone/i).test(window.navigator.userAgent) && (/CPU.*OS 10_[12]/i).test(window.navigator.userAgent);
const IS_IOS_10_3 = true;//(/ipod|ipad|iphone/i).test(window.navigator.userAgent) && (/CPU.*OS 10_3/i).test(window.navigator.userAgent);

const FRAGMENT_SHADER = `
    precision lowp float;
    varying highp vec2 vTextureCoord;

    uniform sampler2D uSampler;

    void main() {
        float alpha = texture2D(uSampler, vec2(vTextureCoord.s, vTextureCoord.t * 0.5 + 0.5)).r;
        vec4 inColor = texture2D(uSampler, vec2(vTextureCoord.s, vTextureCoord.t * 0.5));
        gl_FragColor = inColor${
            IS_IOS_10_1_OR_2 ? '.bgra' : '' // Fix iOS 10 color issue.
        } * alpha;
    }
`;

function loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.log(gl.getShaderInfoLog(shader)); // eslint-disable-line no-console
        gl.deleteShader(shader);
        throw new Error("Couldn't compile shader");
    }
    return shader;
}
function createShaderProgram(gl) {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, VERTEX_SHADER);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, FRAGMENT_SHADER);

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.log(gl.getProgramInfoLog(shaderProgram)); // eslint-disable-line no-console
        throw new Error('Unable to initialize the shader program.');
    }
    return {
        program: shaderProgram,
        attribLocations: {
            vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
            textureCoord: gl.getAttribLocation(shaderProgram, 'aTextureCoord'),
        },
        uniformLocations: {
            uSampler: gl.getUniformLocation(shaderProgram, 'uSampler'),
        },
    };
}
function initBuffers(gl) {
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
        1.0,    1.0,
        -1.0,   1.0,
        1.0,    -1.0,
        -1.0,   -1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER,
        new Float32Array(positions),
        gl.STATIC_DRAW);

    const textureCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

    const textureCoordinates = [
        1.0,  0.0,
        0.0,  0.0,
        1.0,  1.0,
        0.0,  1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates),
        gl.STATIC_DRAW);

    return {
        position: positionBuffer,
        textureCoord: textureCoordBuffer,
    };
}
function drawScene(gl, programInfo, buffers, texture) {
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clearDepth(1.0);
    gl.enable(gl.DEPTH_TEST);
    gl.depthFunc(gl.LEQUAL);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    {
        const numComponents = 2; // pull out 2 values per iteration
        const type = gl.FLOAT; // the data in the buffer is 32bit floats
        const normalize = false; // don't normalize
        const stride = 0; // how many bytes to get from one set of values to the next
        // 0 = use type and numComponents above
        const offset = 0; // how many bytes inside the buffer to start from
        gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
        gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, numComponents, type, normalize, stride, offset);
        gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }
    {
        const num = 2; // every coordinate composed of 2 values
        const type = gl.FLOAT; // the data in the buffer is 32 bit float
        const normalize = false; // don't normalize
        const stride = 0; // how many bytes to get from one set to the next
        const offset = 0; // how many bytes inside the buffer to start from
        gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
        gl.vertexAttribPointer(programInfo.attribLocations.textureCoord, num, type, normalize, stride, offset);
        gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);
    }

    // Tell WebGL to use our program when drawing
    gl.useProgram(programInfo.program);

    // Tell WebGL we want to affect texture unit 0
    gl.activeTexture(gl.TEXTURE0);

    // Bind the texture to texture unit 0
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Tell the shader we bound the texture to texture unit 0
    gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

    {
        const offset = 0;
        const vertexCount = 4;
        gl.drawArrays(gl.TRIANGLE_STRIP, offset, vertexCount);
    }
}

function initTexture(gl) {
    const texture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, texture);

    const level = 0;
    const internalFormat = gl.RGBA;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;

    const width = 1;
    const height = 1;
    const border = 0;
    const pixel = new Uint8Array([0, 0, 0, 0]);
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
        width, height, border, srcFormat, srcType,
        pixel);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    return texture;
}

function updateTexture(gl, texture, src) {
    gl.bindTexture(gl.TEXTURE_2D, texture);

    const level = 0;
    const internalFormat = gl.RGB;
    const srcFormat = gl.RGB;
    const srcType = gl.UNSIGNED_BYTE;

    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
        srcFormat, srcType, src);
}

export class TransparentVideo3D extends Component {
    constructor(props) {
        super(props);
        // this.canvas = createRef();
        this.canvas = myCreateRef(() => this.initGL());
        this.video = {};

        this.state = {
            videoWidth: 0,
            videoHeight: 0
        }

        this.hasPlayed = false;

        this.sizingEvents = {
            onLoadedMetadata: this.handleSizingEvent.bind(this, 'onLoadedMetadata'),
            onDurationChange: this.handleSizingEvent.bind(this, 'onDurationChange'),
        }

        this.handlePlaying = this.handlePlaying.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.processFrame = this.processFrame.bind(this);
        this.checkPositioning = this.checkPositioning.bind(this);
        this.videoRef = this.videoRef.bind(this);

        if (IS_IOS_10_3) {
            this.bkpCanvas = document.createElement("canvas");
        }
    }

    initGL() {
        if (!this.canvas.current) return;
        const gl = this.canvas.current.getContext("webgl") || this.canvas.current.getContext("experimental-webgl");
        if (!gl) return;
        if (!this.state.videoWidth) return;

        gl.clearColor(0.0, 0.0, 0.0, 0.0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        this.glParts = {
            gl: gl,
            programInfo: createShaderProgram(gl),
            buffers: initBuffers(gl),
            texture: initTexture(gl)
        };

        drawScene(gl, this.glParts.programInfo, this.glParts.buffers, this.glParts.texture);
    }

    checkPositioning() {
        if (!this.canvas.current) return;
        const canvas = this.canvas.current
        const video = this.video.current
        const rect = canvas.getBoundingClientRect();
        const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const isOffScreen = (rect.top + rect.height) < 0 || rect.top > windowHeight;
        if (isOffScreen) {
            if (!video.paused) video.pause();
            video.autoplay = false;
        } else {
            if (video.paused && (this.props.autoPlay || this.hasPlayed)) video.play();
            if (this.props.autoPlay) video.autoplay = true;
        }
    }

    processFrame() {
        if (this.glParts) {
            if (this.bkpCanvas) {
                this.bkpCanvas.width = this.video.current.videoWidth;
                this.bkpCanvas.height = this.video.current.videoHeight;
                const ctx = this.bkpCanvas.getContext('2d');
                ctx.drawImage(this.video.current, 0, 0);
                updateTexture(this.glParts.gl, this.glParts.texture, this.bkpCanvas);
            } else {
                updateTexture(this.glParts.gl, this.glParts.texture, this.video.current);
            }
            drawScene(this.glParts.gl, this.glParts.programInfo, this.glParts.buffers, this.glParts.texture);
        }

        this.rafId = requestAnimationFrame(this.processFrame);
    }

    handlePlaying(...args) {
        this.hasPlayed = true;
        if (!this.rafId) this.processFrame();
        if (typeof (this.props.onPlaying) === 'function') this.props.onPlaying(...args);
    }
    handlePause(...args) {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
        if (typeof (this.props.onPause) === 'function') this.props.onPause(...args);
    }

    componentDidMount() {
        if (this.props.pauseWhenOffscreen) {
            window.addEventListener('scroll', this.checkPositioning);
            window.addEventListener('resize', this.checkPositioning);
            setTimeout(this.checkPositioning);
        }
    }

    componentWillUnmount() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
        window.removeEventListener('scroll', this.checkPositioning);
        window.removeEventListener('resize', this.checkPositioning);
    }



    handleSizingEvent(propName, ...args) {
        if (!this.video.current.videoWidth) return;
        this.setState({
            videoWidth: this.video.current.videoWidth,
            videoHeight: this.video.current.videoHeight / 2,
            fullVideoHeight: this.video.current.videoHeight
        });
        if (!this.glParts) setTimeout(() => this.initGL());
        if (typeof (this.props.onLoadedMetadata) === 'function') this.props[propName](...args);
    }



    videoRef(video) {
        if (video) {
            this.video = {current: video};
            if (this.props.pauseWhenOffscreen) this.checkPositioning();
        } else {
            this.video = {};
        }
    }

    render() {
        const {pauseWhenOffscreen, className, style, width, height, 'rm-comp': rmComp, ...props} = this.props; // eslint-disable-line no-unused-vars

        return (<div style={{position:'relative', display:'inline-block', ...style}}
            rm-comp={this.props['rm-comp']}
            className={className}
        >
            <video ref={this.videoRef}
                {...props}
                style={{position: 'absolute', top:0, left: 0, opacity: 0,pointerEvents:'none', width:'1px', height:'1px'}}
                width={this.state.videoWidth}
                height={this.state.fullVideoHeight}
                {...this.sizingEvents}
                onPlaying={this.handlePlaying}
                onPause={this.handlePause}
            ></video>
            <canvas ref={this.canvas}
                width={width || this.state.videoWidth}
                height={height || this.state.videoHeight}
                style={{width: '100%'}}
            ></canvas>
            {this.props.children}
        </div>);
    }
}
