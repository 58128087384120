<import name="PersonalizationQuiz" from="./personalization.html" />
<import name="VideoPlayer, testAutoplayAudio" from="rmlibrary/video-player" />
<import name="AnimateVideoPlayer" from="rmlibrary/video-player/animate" />
<import name="RMModal" from="rmlibrary/react-modal" />
<import name="Loader" from="../../common/loader" />
<import name="PlayButton" from="../../common/play-btn" />
<import name="PauseButton" from="../../common/pause-btn" />
<import name="FloatingCard" from="./floating-card.html" />

<!-- <import name="MainBannerAnimation" from= /> -->

<style lang="scss">
    @import 'sass/variables';

    .video {
        position:relative;
        background-color: black;
        @media #{$xl-up} {
            overflow:hidden;
        }
        .video-bg {
            position: relative;
            /* background-image: url(images/video-placeholder.jpg); */
            background-repeat:no-repeat;
            background-size:cover;
            background-position:center;
            width:100%;
            height:100%;
            height: 56.25vw;

            @media #{$xl-up} {
                height:calc(100vh - 219px);
                max-height: 55.25vw;
            }

            > * {
                width: 100%;

                @media #{$xl-up} {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }

                > video {
                    width:100%;
                }
            }
        }

        .preloader {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            color:white;

            > * {
                margin: auto;
            }
        }

        .play-btn {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }

    .controls {
        opacity: 1;
        pointer-events: auto;

        transition: opacity 0.5s ease;
        &.hideControls {
            opacity: 0;
            pointer-events: none;
        }
    }
    
    .transcript {
        width: 30px;
        position: absolute;
        bottom: 36px;
        left: 190px;
        @media #{$sm-only} {
            bottom:8px;
            left:115px;
            transform: scale(0.75);
            transition: 0.5s ease-in-out;
        }
        @media #{$xs-only} {
            bottom: 4px;
            left: 80px;
            transform: scale(0.55);
            transition: 0.5s ease-in-out;
        }

        > * {
            vertical-align: top;
        }
    }

    .volume {
        background-image: url(images/volume.svg);
        /* width:40px; */
        background-repeat: no-repeat;
        background-size: 40px 32px;
        height:31px;
        position:absolute;
        bottom:40px;
        left:40px;
        color:white;
        font-size:20px;
        padding-top:5px;
        -webkit-animation:pop-in 0.5s;
        -moz-animation:pop-in 0.5s;
        -ms-animation:pop-in 0.5s;
        transition: 0.5s ease-in-out;

        @media #{$lg-up} {
            display: block;
        }
        @media #{$sm-only} {
            bottom:10px;
            left:5px;
            transform: scale(0.75);
            transition: 0.5s ease-in-out;
        }
        @media #{$xs-only} {
            bottom: 5px;
            left: -10px;
            transform: scale(0.55);
            transition: 0.5s ease-in-out;
        }
        .on-off.selected {
            color: $yellow;
        }
        &.volume-on {
            background-image: url(images/volume-y.svg);
        }
        .on-off {
            @media #{$xs-only, $sm-only} {
                font-size:16px;
            }
        }
        span.on {
            margin-left:50px;
        }
        img {
            display:inline-block;
            width:40px;
            margin-right:10px;
            @media #{$xs-only, $sm-only} {
                width:30px;
                vertical-align:middle;
                margin-top:-10px;
            }
        }
    }

    .social-media {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        bottom:40px;
        right:2%;
        -webkit-animation:pop-in 0.5s;
        -moz-animation:pop-in 0.5s;
        -ms-animation:pop-in 0.5s;
        @media #{$sm-up} {
            display: flex;
        }
        @media #{$xs-only, $sm-only} {
            bottom:5px;
/*            bottom:10px;*/
        }
            img {
                max-width: 30px;
                margin: 10px;
            }
            .bg-img {
                width:27px;
                height:25px;
                background-size:cover;
                margin:10px;
/*                margin:15px;*/
                transition:background-image 0.5s ease-in-out;
                @media #{$xs-only, $sm-only} {
                    width:20px;
                    height:20px;
                    margin:5px;
                }
            }
                .instagram {
                    background-image: url(images/icons/SVG/instagram-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/instagram-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .twitter {
                    background-image: url(images/icons/SVG/twitter-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/twitter-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .facebook {
                    background-image: url(images/icons/SVG/facebook-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/facebook-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .linkedin {
                    background-image: url(images/icons/SVG/linkedin-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/linkedin-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
            }



    .video-placeholder {
        background-image: url(images/video-placeholder.jpg);
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        width:100%;
        height:518px;
    }

    /* Repositioning the screen reader button, because I think it's causing the contents of this banner to 'jump' up the screen */

    .sr-playPauseButton {
        top: 50%;
    }

    
    .arrow-down {
        font-size:30px;
        display:block;
        margin:0 auto;
        height:40px;
        width:40px;
        position: absolute;
        z-index: 5;
        bottom: 4%;
        right: 50%;
        margin-right: -20px;
        border-radius: 50%;
        cursor:pointer;
        
        /* @media #{$lg-up} {
            display:block;
        } */
        
        .chevron {
            color:white;
            top: 6px;
            left: 11px;
            animation: arrow-scroll 1.5s ease-in infinite;
        }

    .chevron-video {
            color:white;
            top: 6px;
            left: 11px;
            animation: arrow-scroll 1.5s ease-in infinite;
        }
}
    
    .video-outer {
        position: relative;
    }

</style>
    
<template name="MainVideoBanner__" extends="MainVideoBannerController">
    <div class="video-outer">
        <div class="video" onTouchStart={this.showControlsTap} onMouseMove="{this.showControls}">
            <div ref={this.vidBg} class="video-bg" onClick={this.handleVideoClick}>
                <AnimateVideoPlayer
                    animation={MainBannerAnimation}
                    ref={this.vidPlayer}
                    src="{this.state.displayVideo}"
                    vars="{this.state.vars}"
                    getRect="{this.getVideoRect}"
                    preload="true" autoPlay loop playsInline webkit-playsinline="true"
                    muted="{this.state.volume == 0}" volume={this.state.volume}
                    onPlaying={this.handlePlaying}
                    onWaiting={this.handleWaiting}
                    onCanPlayThrough={this.handleCanPlayThrough}
                    playing="{this.state.videoPlaying && !this.props.personalizationOpen}"
                    pauseWhenOffscreen={true}
                    pauseWhenNotVisible={true}
                />
            </div>
            <Loader class="preloader" size="50" show="{this.state.videoLoading}" />
            <PlayButton class="play-btn" size="200" show="{(!this.state.videoLoading && this.state.videoPaused)}" onClick={this.handlePlayClick} />
            <PauseButton class="play-btn" size="200" show="{(!this.state.videoLoading && !this.state.videoPaused && !this.state.hidePause || !this.state.videoPaused && this.state.pausePlayButtonFocused)}" onTouchStart={this.handlePauseTS} onClick={this.handlePause} />
            <div class="sr-only sr-playPauseButton"aria-label="{this.state.videoPlaying ? 'pause button' : 'play button'}">
                <button onTouchStart={this.handlePauseTS} onClick={this.handleTogglePlay} onFocus="{this.focusPausePlayButton}" onBlur="{this.focusPausePlayButton}">{this.state.videoPlaying ? 'pause button' : 'play button'}</button>
            </div>
    
            <button class="volume controls" aria-label="volume controls" class.hideControls="{this.state.hideControls && this.state.volume > 0}" class.volume-on="{this.state.volume == 1}" onClick={this.toggleAudio} onFocus={this.showControls}>
                <span class="on-off on" class.selected="{this.state.volume == 1}">ON</span>/<span class="on-off" class.selected="{this.state.volume == 0}">OFF</span>
            </button>
            
            <If c="{!IS_PRODUCTION}">
                <button
                    class="transcript controls sr-only-with-focus"
                    aria-label="Video description"
                    class.hideControls="{this.state.hideControls}"
                    onClick="{this.showTranscriptDialog}"
                ><img src="./site_assets/images/transcript/white-transcript.svg"/></button>
            </If>
    
            <div class="social-media controls" class.hideControls="{this.state.hideControls}">
                <a href="https://www.instagram.com/richmedia.social/" target="_blank" title="Rich Media on Instagram" onFocus={this.showControls}>
                    <div class="instagram bg-img"></div>
                </a>
                <a href="https://x.com/RichMediaSocial/" target="_blank" title="Rich Media on X" onFocus={this.showControls}>
                    <div class="twitter bg-img"></div>
                </a>
                <a href="https://www.facebook.com/Rich-Media-251114581758676/" target="_blank" title="Rich Media on Facebook" onFocus={this.showControls}>
                    <div class="facebook bg-img"></div>
                </a>
                <a href="https://www.linkedin.com/company/rich-media" target="_blank" title="Rich Media on LinkedIn" onFocus={this.showControls}>
                    <div class="linkedin bg-img"></div>
                </a>
            </div>
    
            <a class="customize">
            </a>
    
            <div class="arrow-down" onClick={this.scrollToProduct}>
                <div class="chevron-video"></div>
            </div>
    
        </div>
        <PersonalizationQuiz /></PersonalizationQuiz>
        <RMModal content="{Dialog}" contentProps="{{video: this.state.transcriptVideo, industry: this.state.vars.industry}}" ref="{(ref) => (this.dialog = ref)}" />
    </div>
</template>
<template name="Dialog" extends="DialogController">
    <div class="transcript-dialog">
        <h1 tabIndex="-1" ref="{ref => ref && ref.focus()}" style.outline="0">Video Description
        </h1>
        <div class="body">
            <If c="{this.isDefault()}">
                <p>The screen reads “Awesome creative digital marketing”{ this.getIndustryText() }, while a woman smiles, looking at her phone.</p>
            </If>

            <If c="{this.props.video === 'halloween'}">
                <p>The screen reads “Awesome creative digital marketing”{ this.getIndustryText() }, while a woman smiles, looking at a
                tablet underneath her bedsheets with a ghost-like glow.</p>
            </If>
            
            <If c="{this.props.video === 'xmas'}">
                <p>The screen reads “Awesome creative digital marketing”{ this.getIndustryText() }, while a woman smiles, looking
                at her phone outdoors in front of Christmas lights under the snow.</p>
            </If>
            
            <If c="{this.props.video === 'new-year'}">
                <p>The screen reads “Awesome creative digital marketing”{ this.getIndustryText() }, as red and white fireworks burst in
                the sky with people holding phones and recording below.</p>
            </If>

            <If c="{this.props.video === 'pharma'}">
                <p>The screen reads “Awesome creative digital marketing”{ this.getIndustryText() }, as a doctor interacts with a tablet.</p>
            </If>
            
            <p>Another woman scrolls through the Rich Ideas website, while sitting down at her desk, as the screen reads “Websites,
            animation, mobile, social, and more”. A father is seen scrolling on his phone while carrying his child.</p>
            
            <p>A black screen reads, “A brand is more than just colours and a logo”.</p>
            
            <p>A grandmother is seen with her granddaughter, laughing while looking at a tablet, as the screen reads, “It’s the moments
            you provide”.</p>
            
            <p>The screen switches to a concert scene, with many hands with phones waving.</p>
            
            <p>“Personalized video brings their story to life”.</p>
            
            <p>Two people are looking at a tablet, watching a personalized financial video about their savings. Two people are looking
            at a phone together at a café.</p>
            
            <p>“Interactive tools let them see the whole picture.”</p>
            
            <p>A person looks at a workout app while biking in a gym.</p>
            
            <p>A group of friends gather around a café table outdoors, while looking at a phone together.</p>
            
            <p>“We’ve helped clients of all sizes”</p>
            
            <p>A team meeting is happening while people are sharing ideas, as the screen reads, “We listen, we guide, we make it easy”.
            Three people are at a dinner table, talking and laughing while looking at a phone.</p>
            
            <p>The screen flashes back, showing all the previous scenes.<br />
                Rich Experience<br />
                Rich Beginnings<br />
                Rich Imagination<br />
                Rich Moments<br />
                Rich Media
            </p>
        </div>
        <button class="btn-close" aria-label="Close" onClick="{() => this.props.closeModal()}">&times;</button>
    </div>
</template>

<script>
    import {createRef} from 'rmlibrary/comp';
    import {scrollIntoView} from "rmlibrary/react-scroll-into-view";

    import MainBannerAnimation from "./main-banner-animation";

    const IS_MOBILE = (/Android|iOS|ipad|iphone|ipod|mobile/i).test(window.navigator.userAgent)

    import {CustomizationConsumer} from "../../common/customization";

    export function MainVideoBanner(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <MainVideoBanner__ 
                {...props}
                personalizationOpen={customizationState.personalizationOpen}
                specialIndustry={customizationState.specialIndustry} 
                theme={customizationState.theme}    
                industry={customizationState.industry}
                lastChangedOption={customizationState.lastChangedOption}
            /> }
        </CustomizationConsumer>)
    }

    const INDUSTRY_TEXT = {
        financial: "Financial",
        consumer_electronics: "Consumer Electronics",
        retail: "Retail",
        travel: "Travel & Tourism",
        telecommunication: "Telecommunications",
        consumer_goods: "Consumer Packaged Goods",
        other: "Other",
        pharma: "Pharma and Health"
    }

    class DialogController extends Component {

        isDefault() {
            if (this.props.theme === 'default') return true;
            return (
                this.props.video !== 'xmas' &&
                this.props.video !== 'halloween' &&
                this.props.video !== 'new-year' &&
                this.props.video !== 'pharma'
            );
        }

        getIndustryText() {
            if (!this.props.industry) return "";
            if (!INDUSTRY_TEXT[this.props.industry]) return "";

            return ` with "${INDUSTRY_TEXT[this.props.industry]}" inserted between the words "creative" and "digital"`;
        }

    }

    MainVideoBanner.PASS_COLOUR = true;

    class MainVideoBannerController extends Component {
        constructor(props) {
            super(props);

            const isMobile = IS_MOBILE;
            const canAutoPlayAudio = !isMobile && VideoPlayer.canAutoPlayAudio;

            if (!isMobile && !canAutoPlayAudio) this.testVolumeOnPlay = true;

            this.vidPlayer = createRef();
            this.vidBg = createRef();

            this.state = {
                volume: canAutoPlayAudio ? 1 : 0,
                videoLoading: true,
                videoPaused: true,
                videoPlaying: true,
                canPause: true,
                pausePlayButtonFocused: false
            };

            if (!isMobile && !canAutoPlayAudio) testAutoplayAudio();

            this.toggleAudio = this.toggleAudio.bind(this);
            this.handleCanPlayThrough = this.handleCanPlayThrough.bind(this);
            this.handlePlaying = this.handlePlaying.bind(this);
            this.handlePause = this.handlePause.bind(this);
            this.handlePauseTS = this.handlePauseTS.bind(this);
            this.handleWaiting = this.handleWaiting.bind(this);
            this.handleVideoClick = this.handleVideoClick.bind(this);
            this.handlePlayClick = this.handlePlayClick.bind(this);
            this.showControlsTap = this.showControlsTap.bind(this);
            this.showControls = this.showControls.bind(this);
            this.showTranscriptDialog = this.showTranscriptDialog.bind(this);
            this.getVideoRect = this.getVideoRect.bind(this);
            this.handleTogglePlay = this.handleTogglePlay.bind(this);
            this.focusPausePlayButton = this.focusPausePlayButton.bind(this);
            this.scrollToProduct = this.scrollToProduct.bind(this);
        }

        static getDerivedStateFromProps(props, state) {
            let displayVideo = null;
            let transcriptVideo = null;

            if (props.industry === 'pharma') {
                displayVideo = 'pharma_no-text.mp4'
                transcriptVideo = 'pharma';
            } else {
                displayVideo = 'rm-video_no-text.mp4'
                transcriptVideo = 'default';
            }
            if (props.theme === 'halloween' && props.lastChangedOption === 'theme') {
                displayVideo = 'halloween_no-text.mp4';
                transcriptVideo = 'halloween';
            }
            if (props.theme === 'pride' && props.lastChangedOption === 'theme') {
                displayVideo = 'pride_no-text.mp4';
                transcriptVideo = 'pride';
            }
            if (props.theme === 'xmas' && props.lastChangedOption === 'theme') {
                displayVideo = 'christmas_no-text.mp4';
                transcriptVideo = 'xmas';
            }
            if (props.theme === 'new-year' && props.lastChangedOption === 'theme') {
                displayVideo = 'new-year_no-text.mp4'
                transcriptVideo = 'new-year';
            }
            if (props.theme === 'canadaday' && props.lastChangedOption === 'theme') {
                displayVideo = 'new-year_no-text.mp4'
                transcriptVideo = 'new-year';
            }
            if (props.theme === 'redacted' && props.lastChangedOption === 'theme' && !IS_PRODUCTION) {
                displayVideo = 'redacted_no-text.mp4'
                transcriptVideo = 'default';
            }
            if (props.industry === 'pharma' && props.lastChangedOption === 'industry') {
                displayVideo = 'pharma_no-text.mp4'
                transcriptVideo = 'pharma';
            }

            displayVideo = 'site_assets/videos/' + displayVideo

            return {
                displayVideo: displayVideo,
                transcriptVideo: transcriptVideo,
                vars: {
                    time: 'morning',
                    name: null,
                    industry: props.industry
                }
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.personalizationOpen && !prevProps.personalizationOpen) {
                if (this.vidPlayer.current) this.vidPlayer.current.seekTo(0);
                // this.handlePause();
            } else if (!this.props.personalizationOpen && prevProps.personalizationOpen) {
                // this.handlePlayClick();
            }
        }

        focusPausePlayButton(){
            this.setState({
                pausePlayButtonFocused: !this.state.pausePlayButtonFocused
            })
        }

        handleTogglePlay(){
            this.state.videoPlaying ? this.handlePause() : this.handlePlayClick();
        }

        getVideoRect() {
            const bgRect = this.vidBg.current.getBoundingClientRect();
            const headerRect = document.getElementsByTagName("header")[0].getBoundingClientRect();
            return {
                top: bgRect.top - headerRect.height,
                height: bgRect.height
            }
        }

        toggleAudio() {
            this.setState({
                volume: this.state.volume > 0 ? 0 : 1
            })
        }

        handleVideoClick() {
            if (this.preventClickLock || !this.state.hidePause) return;
            if (this.state.videoPlaying && !this.state.videoLoading && this.vidPlayer.current) {
                this.setState({
                    videoPaused: true,
                    videoPlaying: false
                });
            }
            this.showControls();
        }
        handlePlayClick() {
            if (this.state.videoPaused && !this.state.videoLoading && this.vidPlayer.current) this.vidPlayer.current.play();
            this.setState({
                volume: 1,
                videoPaused: false,
                videoPlaying: true
            });
            this.showControls();
        }

        showControls(e) {
            this.setState({ hideControls: false });
            this.setTimeoutForControls();
        }
        showControlsTap(e) {
            if (this.preventClickLock || !this.state.videoPlaying) {
                // Allow click to happen
                this.preventClickLock = false;
                return;
            }
            this.preventClickLock = true;
            setTimeout(() => {
                this.preventClickLock = false;
            }, 2000);
            this.setState({
                hidePause: false,
                canPause: false
            });
            this.showControls(e);
        }

        showTranscriptDialog() {
            if (this.dialog) this.dialog.open();
        }

        handlePlaying() {
            this.setState({
                videoLoading: false,
                videoPaused: false,
                videoPlaying: true,
                hidePause: true
            })

            if (this.testVolumeOnPlay) {
                this.testVolumeOnPlay = false;
                testAutoplayAudio().then((canPlay) => {
                    this.setState({
                        volume: canPlay ? 1 : 0
                    });
                });
            }

            this.setTimeoutForControls();
        }
        handlePause() {
            if (!this.state.canPause) return;
            this.setState({
                videoPaused: true,
                videoPlaying: false,
            });
        }
        handlePauseTS() {
            this.setState({
                canPause: true
            });
        }

        handleCanPlayThrough() {
            this.setState({
                videoLoading: false,
            });
        }
        handleWaiting() {
            this.setState({
                videoLoading: true,
            })
        }

        setTimeoutForControls() {
            if (this.timeout) clearTimeout(this.timeout);
            var pointer = document.querySelector(".pointer");
            if (pointer && !pointer.classList.contains("disappear") && !pointer.classList.contains("post-load")) {
                pointer.classList.add("disappear");
                setTimeout(() => {
                    pointer.classList.remove("disappear");
                    pointer.classList.add("post-load");
                }, 2500);
            }
            this.timeout = setTimeout(() => {
                this.setState({
                    hideControls: true,
                    hidePause: true
                });
                this.timeout = null;
            }, 2000);
        }

        scrollToProduct(){
            const viewportHeight = document.documentElement.clientHeight;
            const scrollElems = Array.from(document.querySelectorAll('.chevron-scroll-anchor'));
            const scrollElem = scrollElems.reduce((acc, el) => {
                if (acc) return acc;
                if (el.classList.contains("chevron-scroll-anchor-unless-visible")) {
                    const box = el.getBoundingClientRect();
                    return box.top >= viewportHeight ? el : null;
                } else {
                    return el.offsetParent ? el : null;
                }
            }, null);
            const offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(scrollElem, {
                block: 'start',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }

        componentWillUnmount() {
            if (this.timeout) clearTimeout(this.timeout);
        }
    }
</script>
