/*globals window */
import {Component, createElement, cloneElement, findDOMNode} from "rmlibrary/comp";


/*
* Props:
*   classScrolledPast, classInView: string - class to add
*
*   onEnter, onScrollPast, onUpdate: function - Callbacks
*   (onUpdate is called with one parameter, the percentage of the element that is in view as a value between 0 and 1)
*/
export class Scroll extends Component{
    constructor(props) {
        super(props);

        if (Array.isArray(props.children))
            throw new Error('Scroll only takes in one element.');
        if ((props.onEnter && typeof props.onEnter !== "function")
            || (props.onScrollPast && typeof props.onScrollPast !== "function")
            || (props.onUpdate && typeof props.onUpdate !== "function"))
                throw new Error('Make sure onEnter, onScrollPast, and/or onUpdate are functions.');

        this.state = {
            inView: false,
            scrolledPast: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleScroll);

        this.baseClasses = this.props.children.props.className;

        if (document.readyState === "complete") {
            this.handleScroll();
        } else {
            window.addEventListener('load', this.handleScroll);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleScroll);
    }

    handleScroll() {
        const viewTop = window.pageYOffset,
              viewBottom = viewTop + window.innerHeight;

        const bounds = this.childElement.getBoundingClientRect();
        const elemTop = bounds.top,
              elemBottom = bounds.bottom;

        // let parseThreshold = this.parseThreshold(this.props.viewThreshold);
        // console.log(parseThreshold);

        let inView = elemTop < viewBottom && elemBottom > 0;
        let scrolledPast = elemTop < 0 && elemBottom < 0;

        let pixelsInView = viewBottom - elemTop;
        let percentInView = pixelsInView / bounds.height;

        if (this.props.onUpdate && !this.state.inView && inView) this.props.onEnter();
        if (this.props.onUpdate && !this.state.scrolledPast && scrolledPast) this.props.onScrollPast();
        if (this.props.onUpdate) this.props.onUpdate(percentInView);

        this.setState({inView, scrolledPast});
    }

    /* Incomplete cooooodddeeeeee */
    parseThreshold(threshold) {
        if (!threshold) return 0;
        const regex = [new RegExp('px$'), new RegExp('%$')];
        for (let i = 0; i < regex.length; i++) {
            let currRegex = regex[i];
            if (currRegex.test(this.props.viewThreshold)) {
                let amount = Number(this.props.viewThreshold.replace(currRegex, ''))
                if (amount == 'NaN') throw new Error('Make sure the threshold is a valid number');

                if (i == 0) return amount; // pixel
                else { // percentage
                    const bounds = this.childElement.getBoundingClientRect();
                    return bounds.height * amount;
                }
            }
        }
    }

    joinClasses(...args) {
        return args.map((arr, str) => (str && typeof (str) === 'string' ? [...arr, ...str.split(/\s+/)] : arr), []).join(" ");
    }

    render() {
        let inViewClass = this.state.inView ? this.props.classInView : '';
        let scrolledPastClass = this.state.scrolledPast ? this.props.classScrolledPast : '';

        let cloneProps = {
            className: this.joinClasses(this.baseClasses, inViewClass, scrolledPastClass),
            ref: (e) => {this.childElement = findDOMNode(e)}
        };

        return cloneElement(this.props.children, cloneProps);
    }
}
