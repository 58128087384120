/*globals window */
import RMTween from "rmlibrary/tween";

var requestAnimationFrame = window.requestAnimationFrame || function (cb) { return window.setTimeout(cb(new Date().getTime()), 16); };
var cancelAnimationFrame = window.cancelAnimationFrame || function (id) { window.clearTimeout(id); };

export class DomTransform {
    constructor(element, initialValues) {
        this._element = element;

        this._x = 0;
        this._y = 0;
        this._scaleX = 1;
        this._scaleY = 1;
        this._rotate = 0;
        this._opacity = 1;

        if (typeof (initialValues) === 'object') {
            Object.keys(initialValues).forEach((k) => {
                this[k] = initialValues[k];
            });
        }

        this._nextAnimationFrame = null;
    }

    setTransform() {
        if (this._nextAnimationFrame) cancelAnimationFrame(this._nextAnimationFrame);
        this._nextAnimationFrame = requestAnimationFrame(() => {
            this._nextAnimationFrame = null;
            this._element.style.opacity = this._opacity;
            const transformStr = `translate(${this._x}px, ${this._y}px) scale(${this._scaleX}, ${this._scaleY}) rotate(${this._rotate}deg)`;
            this._element.style.transform = transformStr;
        })
    }

    get x() { return this._x; }
    get y() { return this._y; }
    get scaleX() { return this._scaleX; }
    get scaleY() { return this._scaleY; }
    get rotate() { return this._rotate; }
    get opacity() { return this._opacity; }

    set x(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: x must be a number");
        this._x = v;
        this.setTransform();
    }
    set y(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: y must be a number");
        this._y = v;
        this.setTransform();
    }
    set scaleX(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: scaleX must be a number");
        this._scaleX = v;
        this.setTransform();
    }
    set scaleY(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: scaleY must be a number");
        this._scaleY = v;
        this.setTransform();
    }
    set rotate(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: rotate must be a number");
        this._rotate = v;
        this.setTransform();
    }
    set opacity(v) {
        if (typeof (v) !== 'number') throw new Error("DomTween: opacity must be a number");
        this._opacity = v;
        this.setTransform();
    }
}

export function DomTween(element, initialValues) {
    return RMTween.create(new DomTransform(element, initialValues));
}
