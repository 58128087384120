<import name="FloatingCard" from="./banners/floating-card.html" />

<style lang="scss">
    @import 'sass/variables';

    .wrapper {
        background-color: $black90;
    }

    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
        padding-bottom: 100px;

        @media #{$xs-only, $sm-only} {
            padding-top: 20px;
        }

        .two-columns {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .column {
                width: 50%;

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                }

                h1 {
                    font-size: 42px;
                    font-weight: bold;
                    color: $yellow;
                    max-width: 360px;
                    margin-bottom: 20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;

                    @media #{$sm-only} {
                        font-size: 29px;
                    }

                    @media #{$md-only} {
                        font-size: 45px;
                    }

                    @media #{$xs-only, $sm-only} {
                        font-size: 35px;
                        max-width: none;
                    }
                }

                .job-details {
                    display: flex;
                    width: 100%;

                    .icon {
                        width: 20%;
                        animation-name: fadeIn;
                        animation-duration: 1s;

                        @media #{$xs-only, $sm-only} {
                            width: 12%;
                        }
                    }

                    .details {
                        width: 80%;

                        @media #{$xs-only, $sm-only, $md-only} {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .column1 {
                width: 40%;
                max-width: 400px;
                margin-right: 90px;

                @media #{$sm-only} {
                    margin-right: 20px;
                }

                @media #{$md-only} {
                    margin-right: 55px;
                }

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                    margin-right: 0;
                }
            }

            .column2 p {
                margin-bottom: 10px;
            }

            .column2 img {
                @media #{$sm-only, $xs-only} {
                    max-width: 50px;
                    width: 100%;
                }
            }
        }
    }

    .desktop {
        display: none;

        @media #{$md-up} {
            display: inline-block;
        }
    }

    h2 {
        color: $yellow;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    p {
        color: white;
        font-size: 16px;
        line-height: 21px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .yellow-button {
        margin-top: 30px;
        margin-left: 0;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .divider {
        height: 1px;
        background-color: $yellow;
        width: 90%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .apply-mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: contents;
        }
    }

    .mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: table;
        }
    }
</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
-->
<template name="DesignerCopy" extends="GraphicDesignerCopyPageController">
    <main>
        <div class="wrapper">
            <div class="content">
                <div class="two-columns">
                    <div class="column column1">
                        <h1>Graphic Designer &amp; Copy Writer</h1>
                        <h2>Position Description:</h2>
                        <p>{this.state.position}</p><br>
                        <p>This is a full-time in-office position.</p>
                        <div class="divider"></div>

                        <h2>Company Description:</h2>
                        <p>{this.state.company}</p>
                        <div class="divider"></div>

                        <h2 class="desktop">For interested applicants:</h2>
                        <p class="apply desktop">Please provide a copy of your resume, university or college transcript, a link to your online portfolio and samples of your writing (any style).</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying for Graphic Designer %26 Copy Writer&body=Please provide a copy of your resume, university or college transcript, a link to your online portfolio and samples of your writing (any style). %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button desktop">Apply<span class="chevron right"></span></a>
                    </div>
                    <div class="column column2">
                        <div class="job-details">
                            <div class="icon">
                                <img src="site_assets/images/icons/SVG/responsibilities-icon-yellow.svg" alt="Responsibilities" width="74" height="74" />
                            </div>
                            <div class="details">

                                <h2>The main responsibilities are as follows:</h2>
                                <For var="responsibility" of="{this.state.responsibilities}" index="index">
                                    <p key={index}>{responsibility}</p>
                                </For>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="job-details">
                            <div class="icon">
                                <img src="site_assets/images/icons/SVG/skills-icon-yellow.svg" alt="Desired Skills" width="74" height="74" />
                            </div>
                            <div class="details">
                                <h2>Desired Skills:</h2>
                                <For var="requirement" of="{this.state.requirements}" index="index">
                                    <p key={index}>{requirement}</p>
                                </For>
                            </div>
                        </div>
                    </div>

                    <div class="divider mobile"></div>

                    <div class="mobile apply-mobile">
                        <h2 class="mobile">For interested applicants:</h2>
                        <p class="apply mobile">Please provide a copy of your resume, university or college transcript, a link to your online portfolio and samples of your writing (any style).</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying for Graphic Designer %26 Copy Writer&body=Please provide a copy of your resume, university or college transcript, a link to your online portfolio and samples of your writing (any style). %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button mobile">Apply<span class="chevron right"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    class GraphicDesignerCopyPageController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                "position": "Rich Media is looking for an exceptional designer who loves to design as well as write copy. You have a passion for expressing ideas and telling a client's story through both writing and design. You strive to design engaging, interactive projects. You love technology and are especially good at designing across multiple mobile and social platforms. You're outgoing, you love to share ideas and be around creative people.",
                "company": "Rich Media is an award winning Toronto based digital agency producing many of North America's leading interactive projects. Our services include websites, apps, tools, social media, print, animation, and video. Some of our clients include Samsung, Scotiabank, CIBC, Manulife and Sun Life Financial. Rich Media has garnered a reputation for taking leading edge technology, combining it with strong creative talents and developing awesome digital projects.",
                "responsibilities": [
                    "Assist or take a lead role in the design, copy writing and rollout of dynamic digital projects for some of Canada's largest companies",
                    "Create engaging animation and interactive elements",
                    "Write internal and external copy for various digital media such as: video, animations, articles, banners, social media, and web",
                    "Participate enthusiastically as a team member in planning and implementation of projects",
                    "Attend client meetings where required"
                ],
                "requirements": [
                    "Proficient in: Illustrator, Photoshop, CSS, HTML5, After Effects and Word",
                    "Degree/Diploma in graphic design and a strong artistic background",
                    "Advertising/Marketing education or experience would be a plus",
                    "Strong verbal and written communication skills",
                    "Familiarity with financial concepts",
                    "Ability to have fun with our pool table, pinball machine, air hockey table, and regular celebration lunches"
                ]
            };
        }

        componentDidMount() {
            const { hostname } = location;
            const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

            let baseURL = "";
            switch (hostname) {
                case "localhost":
                case "127.0.0.1": {
                    break;
                }

                case "richmediawireless.com": {
                    baseURL = "www.richmediawireless.com/dev/rm/job-posting";
                    break;
                }

                default: {
                    baseURL = "www.richmedia.com";
                    break;
                }
            }

            if (!isLocalhost && hostname !== "richmediawireless.com") {
                fetch(`https://${baseURL}/api/jobpostings.php?position_title=designercopy`)
                .then(result => result.json())
                .then(result => this.setState({ ...result }));
            }
        }
    }
</script>