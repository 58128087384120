<style lang="scss">
    @import 'sass/variables'; 

    img {
         clear: both;
    }

    input {
        font-size: 16px;
        padding: 4px;
        float: left;
        background-color: #fff;
        border: 2px solid #bbb;
    }

    a {
        text-decoration: none;
    }

    .search-wrapper {
        padding: 20px 20px;
        max-width: 1024px;
        background-color: #FFF;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        z-index: 0;
        min-height: calc(100vh - 400px);
    }

    .search {
        display: inline-block;
    }


    .search-btn{
        margin-left: 10px; 
        padding: 4px; 
        cursor: pointer; 
        border: 2px solid #FFC425; 
        font-weight:bold; 
    }

    .searchResultsFound {
        font-size: 20px;
        color: #edaa00;
        margin-bottom: 18px;
    }

    .searchResultTitle {
        color: #333333;
        margin-bottom: 5px;
        font-size: 20px;
    }

    .searchResultDescription {
        color: #666666;
        font-size: 16px;
        overflow: hidden;
    }

    .searchResultsList hr {
        margin: 15px 0;
        color: #cccccc;
        background-color: #cccccc;
        border-color: #cccccc;
    }

    .searchResultIcon {
        float: left;
        margin-right: 12px;
        border: 1px solid #cccccc;
    }

    .searchResultsList hr {
        margin: 15px 0;
        color: #cccccc;
        background-color: #cccccc;
        border-color: #cccccc;
        height: 1px;
        border: 0;
    }

    .keywords{
        background-color:rgba(231, 228, 79, .8);
    }
    .searchResult {
        height: 100%;
        display: block;
    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="RichIdeasSearch_">
    <div>
        <div class="search-wrapper">
            <div class="content">
                <div class="searchErrorMsg" style="display:none; margin-top: 6px; display:block; color:red;"></div>
                <div class="searchResultsContainer">
                    <div class="searchResultsFound"><span class="numResults"></span>  {this.props.results.length} results found</div>

                    <div class="searchResultsList">
                        <For var="article" of="{this.props.results}">
                            <SearchResult key={article.id} article={article} keywords={this.props.keywords}/>
                        </For>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import {SearchContextConsumer} from "../../richideas/search-context.js";

export const RichIdeasSearch = (props) => (
    <SearchContextConsumer>
        {
            (state) => 
                (<RichIdeasSearch_ {...props} results={state.results} keywords={state.searchInput} loading={state.loading} />)   
        }
    </SearchContextConsumer>)
</script>


<template name="SearchResult" extends="SearchResultContent">
    <a class="searchResult" style="margin-bottom: 12px;" href="{`richideas/articles/${this.state.article.url}`}" target="_blank">
        <img class="searchResultIcon" src="{`richideas/img/${this.state.article.thumb}`}" alt={this.state.article.thumb_alt}>
        <div class="searchResultTitle">{this.wrapKeywords(this.state.article.title)}</div>
        <div class="searchResultDescription"> {this.wrapKeywords(this.state.article.description)} </div>
        <br style="clear: both;">
        <hr/>
    </a>
</template>

<script>

class SearchResultContent extends Component{
    constructor(props){
        super(props);
        this.wrapKeywords = this.wrapKeywords.bind(this)
    }

    static getDerivedStateFromProps(props, state){
        return {
            article: props.article,
            keywords: props.keywords.trim().split(' ')
        };
    }

    wrapKeywords(text){
        let renderText = [];
        text.split(" ").forEach(word => {
            let foundKeyWord = false;
            this.state.keywords.forEach(keyword => {
                if(word.toLowerCase().includes(keyword.toLowerCase())){
                    foundKeyWord = true;
                    let i = 0;
                    while(i < keyword.length){
                        if(word[i].toLowerCase() == keyword[i].toLowerCase()){
                            renderText.push(<span  class="keywords">{word[i]}</span>);
                        } else{
                            renderText.push(word[i]);
                        }
                        i++;
                    }
                    while(i < word.length){
                        renderText.push(word[i]);
                        i++;

                    }
                }
            })

            if(foundKeyWord == false){
                // debugger;
                renderText.push(word);
            }

            renderText.push(" ");
        })
        
        return(
            <div> 
                {renderText}
            </div>
        )
    }
}

</script>