/*globals window */

import { createElement } from "rmlibrary/comp";
import { VideoPlayer } from "./index";
import createjs from "createjs";

export class AnimateVideoPlayer extends VideoPlayer {
    constructor(props) {
        super(props);

        this.handleCanvasRef = this.handleCanvasRef.bind(this);
        this.tick = this.tick.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevProps.vars !== this.props.vars) {
            this.animation.vars = this.props.vars;
        }
    }

    componentDidMount() {
        super.componentDidMount();

        const animation = this.props.animation;
        if (animation && animation.lib && animation.exportRoot) {
            this.animation = animation;
            if (this.props.vars) this.animation.vars = this.props.vars;
            this.setupStage();
        }
    }

    handleRef(vid) {
        this.vid = vid;

        // TODO
    }

    setupStage() {
        if (!this.canvas || !this.animation) return;

        this.exportRoot = new this.animation.exportRoot();
        this.stage = new this.animation.lib.Stage(this.canvas);
        this.stage.addChild(this.exportRoot);
        createjs.Ticker.setFPS(this.animation.lib.properties.fps);
        this.exportRoot.framerate = createjs.Ticker.framerate;
        createjs.Ticker.addEventListener("tick", this.tick);
    }

    destroyStage() {
        this.exportRoot = null;
        this.stage = null;
        createjs.Ticker.removeEventListener("tick", this.tick);
    }

    setCanvasSize() {
        if (!this.stage || !this.animation || !this.canvas || !this.canvas.offsetParent) return false;

        const parent = this.canvas.parentElement;
        const pRatio = Math.min(2, window.devicePixelRatio || 1);
        const rect = parent.getBoundingClientRect();
        const libWidth = this.animation.lib.properties.width;
        const libHeight = this.animation.lib.properties.height;
        const wRatio = rect.width / libWidth;
        const hRatio = rect.height / libHeight;
        this.canvas.width = rect.width * pRatio;
        this.canvas.height = rect.height * pRatio;
        this.stage.scaleX = wRatio * pRatio;
        this.stage.scaleY = hRatio * pRatio;

        return true;
    }

    handleCanvasRef(canvas) {
        if (canvas) {
            this.canvas = canvas;
            this.setupStage();
        } else {
            this.destroyStage();
        }
    }

    tick(event) {
        if (!this.exportRoot || !this.vid) return;

        if (!this.setCanvasSize()) return;

        if (this.state.isOffScreen || !this.state.isPageVisible) return;

        var animTime = (this.exportRoot.currentFrame * 1000) / this.animation.lib.properties.fps;
        var videoTime = this.vid.currentTime * 1000;
        var delta = videoTime - animTime;
        var origDelta = event.delta;
        event.delta = origDelta + delta;
        if (event.delta < 0) {
            if (this.props.playing) {
                var newFrame = Math.round(
                    this.exportRoot.currentFrame + (event.delta * this.animation.lib.properties.fps) / 1000,
                );
                this.exportRoot.gotoAndPlay(newFrame);
            }
            this.stage.tickOnUpdate = false;
            this.stage.update();
            this.stage.tickOnUpdate = true;
        } else {
            this.stage.update(event);
        }
    }

    canvasStyle() {
        return {
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
        };
    }

    render() {
        return (
            <div className={this.props.className} rm-comp={this.props["rm-comp"]} style={{ position: "relative" }}>
                <canvas ref={this.handleCanvasRef} style={this.canvasStyle()}></canvas>
                {super.render()}
            </div>
        );
    }
}
