import {Component, createElement} from "rmlibrary/comp";
import RMTween from 'rmlibrary/tween'

class Proxy {
    constructor(cr) { this.controller = cr }

    get maxHeight() { return this.controller.state.maxHeight }
    set maxHeight(v) { this.controller.setState({maxHeight: v}) }
}

export class Collapsible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.open ? "block" : "none",
            maxHeight: "none"
        }

        this.tween = null;

        this.proxy = new Proxy(this)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            if (this.props.open) this.open();
            else this.close();
        }
    }

    close(){
        if (typeof (this.props.onClose) === 'function') this.props.onClose();
        const elHeight = !isNaN(this.state.maxHeight) ? this.state.maxHeight : this.elRef.scrollHeight;
        if (this.tween) this.tween.stop();
        this.tween = RMTween.create(this.proxy)
                .to({ maxHeight: elHeight })
                .to({ maxHeight: 0 }, this.props.duration, this.props.ease)
                .run(() => {
                    this.setState({display: "none"});
                    this.tween = null;
                    if (typeof (this.props.onClosed) === 'function') this.props.onClosed();
                });
    }

    open(){
        if (typeof (this.props.onOpen) === 'function') this.props.onOpen();
        this.elRef.style.display="block"
        this.setState({display: "block"});
        const startHeight = isNaN(this.state.maxHeight) ? 0 : this.state.maxHeight;
        const elHeight = this.elRef.scrollHeight;
        if (this.tween) this.tween.stop();
        this.tween = RMTween.create(this.proxy)
                .to({ maxHeight: startHeight })
                .to({ maxHeight: elHeight }, this.props.duration, this.props.ease)
                .run(() => {
                    this.setState({maxHeight: "none"});
                    this.tween = null;
                    if (typeof (this.props.onOpened) === 'function') this.props.onOpened();
                })
    }

    toggle(){
        this.props.open ? this.close() : this.open()
    }

    render() {
        const { style: propStyles, onOpen, onOpened, onClose, onClosed, ...otherProps } = this.props; // eslint-disable-line no-unused-vars
        const divStyles ={
            ...propStyles,
            maxHeight: this.state.maxHeight,
            display: this.state.display,
            padding: 0,
            overflow:'hidden'
        }

        return (
            <div ref={(elRef) => this.elRef = elRef} {...otherProps} style={divStyles}>
                {this.props.children}
            </div>
        );
    }
}
