<!-- Import other components used here -->
<import name="Fragment" from="rmlibrary/comp" />

<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .template {
        background-color: $black90;
        .content {
            @media #{$sm-only} {
                max-width: 428px;
            }
            .project {
                width: 612px;
                @media #{$xs-only} {
                    width: 100%;
                }
                @media #{$sm-only} {
                    max-width: 383px;
                }
                .project-description {
                    @media #{$lg-up} {
                        max-width: 460px;
                    }
                }
            }
        }
        .side-imgs {
            z-index: 1000;
            margin-top: 3%;
            .side {
                position: absolute;
                width: 70%;
                right: 235px;
                top: 93px;
                @media #{$lg-only} {
                    width: 70%;
                }
            }
            .side1 {
                z-index: 1000;
            }
            @media #{$sm-only, $xs-only, $md-only} {
                display: none;
            }
            img {
                width: 90%;
                @media #{$lg-only} {
                    width: 80%;
                }
            }
        }
        .project-imgs {
            overflow: hidden;
            .devices {

                width: 100%;
                @media #{$md-only} {
                    align-items: center;
                }
                @media #{$xl-only} {
                    margin-top: 15px;
                    margin-left: 470px;
                }
                @media #{$lg-only} {
                    margin-top: 42px;
                    margin-left: 440px;
                }
                .imac {
                    width: 63%;
                    position: relative;
                    margin-bottom: 18px;
                    @media #{$md-only} {
                        width: 536px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                    @media #{$sm-only} {
                        width: 350px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                    @media #{$xs-only} {
                        width: 300px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }

                    .desktop-img {
                        display: none;
                        border: 1px solid $dark-gray;
                        @media #{$md-up} {
                            display: block;
                            width: 100%;
                        }
                        @media #{$lg-up} {
                            width: 80.5%;
                        }

                        @media #{$xl-up} {
                            width: 88%;
                        }

                    }
                    .mobile-img {
                        display: block;
                        border: 1px solid $dark-gray;
                        width: 100%;
                        height: 100%;
                        @media #{$md-up} {
                            display: none;
                        }

                    }
                }
                .gif-box {
                    width: 55.5%;
                    display: flex;
                    justify-content: space-between;
                    @media #{$lg-only} {
                        width: 50.5%;
                    }
                    @media #{$md-only} {
                        width: 536px;
                        left: 18.5%;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$sm-only} {
                        width: 350px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$xs-only} {
                        width: 300px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    .gif {
                        width: 31%;
                        height: 31%;
                        border: 1px solid $dark-gray;
                        @media #{$xs-only} {
                            width: 49%;
                        }
                        &.long {
                            width: 100%;
                            height: 100%;
                        }
                    }

                }
            }
        }

        .yellow-button {
            @media #{$md-only, $sm-only, $xs-only} {
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }


        .dv {
            display: none;
            @media #{$lg-up} {
                display: block;
            }
        }

        .mv {
            display: block;
            @media #{$lg-up} {
                display: none;
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

</style>

<template name="PortfolioTemplate4">
    <main>

        <div class="template">
            <div class="content" style="{{position: 'relative', zIndex: '1'}}">
                <div class="project">
                    <h1 class="project-title">{ this.props.project.title }</h1>
                    <p class="project-description">{ this.props.project.description }</p>
                    <div class="side-imgs">
                            <If c="{!this.props.project.images.walmartsideOne}">
                                <Fragment>
                                    <img draggable="false" src="{this.props.project.images.sideOne}" alt="{ this.props.project.title }" class="side side1"/>
                                </Fragment>
                            </If>
                        </div>
                </div>

            <div class="project-imgs">
                    <div class="devices">
                        <div class="imac">
                            <img draggable="false" src="{this.props.project.images.desktopImg}" alt="{ this.props.project.title }" class="desktop-img"/>
                            <img draggable="false" src="{this.props.project.images.desktopImg2}" alt="{ this.props.project.title }" class="mobile-img"/> 
                        </div>
                        <div class="gif-box">
                            <img draggable="false" src="{this.props.project.images.gif1}" alt="{ this.props.project.title }" class="gif dv"/>
                            <img draggable="false" src="{this.props.project.images.gif2}" alt="{ this.props.project.title }" class="gif dv"/>
                            <img draggable="false" src="{this.props.project.images.gif3}" alt="{ this.props.project.title }" class="gif dv"/>
                            <img draggable="false" src="{this.props.project.images.gif4}" alt="{ this.props.project.title }" class="gif long mv"/>  
                        </div>
                    </div>
                </div>

            </div>

            <div class="gradient" style="{{position: 'absolute', bottom: '0', zIndex: '0'}}">
                <div class="content">
                    <div class="client-logo">
                        <img draggable="false" class="center" src="{this.props.project.images.clientLogo}" />
                        <img draggable="false" class="left" src="{this.props.project.images.clientLogoDesktop}" />
                    </div>
                </div>
            </div>
        </div>

    </main>

</template>
