<!-- Import other components used here -->


<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .template {
        background-color: $black90;
        .project-imgs {
            overflow: hidden;
            .portfolio-project {
                width: 100%;
                .devices {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    margin-left: 260px;
                    margin-top: -30px;
                    @media #{$md-only, $sm-only, $xs-only} {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$lg-only} {
                        width: 82%;
                        left: 5%;
                        margin-top: 0;
                    }
                    @media #{$xs-only} {
                        width: 165%;
                        max-width: 545px;
                        left: 50%;
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                    @media #{$sm-only} {
                        max-width: 630px;
                        margin-top: -10px;
                    }
                    @media #{$md-only} {
                        max-width: 680px;
                        left: 9%;
                    }
                    .device {
                        display: inline-block;
                        width: 50%;
                        .tablet {
                            position: relative;
                            display: inline-block;
                            width: 110%;
                            z-index: 8000;
                            bottom: 20px;
                            @media #{$sm-only, $xs-only} {
                                left: 31%
                            }
                        }
                        .tablet-img {
                            position: absolute;
                            top: 24%;
                            width: 40%;
                            left: 7%;
                            @media #{$sm-only, $xs-only} {
                                left: 22.5%
                            }
                        }
                        .phone {
                            position: relative;
                            display: inline-block;
                            width: 50%;
                            margin-top: 1%;
                            right: 20%;
                            z-index: 10000;
                            @media #{$sm-only, $xs-only} {
                                right: 12%;
                            }
                        }
                        .phone-img {
                            position: absolute;
                            width: 19.7%;
                            left: 55.2%;
                            top: 14.6%;
                            z-index: 9000;
                            @media #{$sm-only, $xs-only} {
                                left: 59.2%;
                            }
                        }
                    }
                }
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="PortfolioTemplate2">
    <main>

        <div class="template">
            <div class="content" style="{{position: 'relative', zIndex: '1'}}">

                <div class="project">
                        <h1 class="project-title">{ this.props.project.title }</h1>
                        <p class="project-description">{ this.props.project.description }</p>
                        <If c="{this.props.project.link}">
                        <a href="{ this.props.project.link }" class="yellow-button" target="_blank">View Live <span class="chevron right"></span></a>
                        </If>
                    </div>
             <div class="project-imgs">
                <div class="portfolio-project">
                    <div class="devices">
                        <div class="device">
                            <img draggable="false" src="{this.props.project.images.tablet}" alt="{ this.props.project.title }" class="tablet"/>
                            <img draggable="false" src="{this.props.project.images.tabletImg}" alt="{ this.props.project.title }" class="tablet-img"/>
                        </div>    
                        <div class="device">
                            <img draggable="false" src="{this.props.project.images.phone}" alt="{ this.props.project.title }" class="phone"/>
                            <img draggable="false" src="{this.props.project.images.phoneImg}" alt="{ this.props.project.title }" class="phone-img"/>
                        </div> 
                    </div>
                </div>
                </div>
            </div>

            <div class="gradient" style="{{position: 'absolute', bottom: '0', zIndex: '0'}}">
                <div class="content">
                    <div class="client-logo">
                        <img draggable="false" class="center" src="{this.props.project.images.clientLogo}" />
                        <img draggable="false" class="left" src="{this.props.project.images.clientLogoDesktop}" />
                    </div>
                </div>
            </div>
        </div>

    </main>

</template>
