
import React from "react";
import ReactDOM from "react-dom";

const CLASS_PROP_REGEX = /^class\.(.+)$/;
const STYLE_PROP_REGEX = /^style\.(.+)$/;

function parseStyleAttr(propValue) {
    if (typeof (propValue) === 'string') {
        return propValue.split(";")
            .reduce((obj, str) => {
                const parts = str.split(":").map((v) => v.trim());
                if (parts.length === 2) obj[parts[0]] = parts[1];
                return obj;
            }, {});
    }
    return propValue;
}

export const Fragment = React.Fragment;
export const createContext = React.createContext;
export const createRef = React.createRef;
export const forwardRef = React.forwardRef;
export const isValidElement = React.isValidElement;

export const createPortal = ReactDOM.createPortal;

function fixStyleKey(key) {
    return key.replace(/\-([a-z])/g, (match, p1) => p1.toUpperCase());
}

export function createElement(type, props, ...children) {
    let newProps = {};
    if (props) {
        let className = props.class || props.className;
        let classList = typeof (className) === 'string' ? className.split(/\s+/g) : [];
        const styles = (props.style && parseStyleAttr(props.style)) || {};
        Object.keys(props).forEach((propKey) => {
            const propValue = props[propKey];
            const classMatch = propKey.match(CLASS_PROP_REGEX);
            const styleMatch = propKey.match(STYLE_PROP_REGEX);

            if (classMatch) {
                if (propValue) classList.push(classMatch[1]);
            } else if (styleMatch) {
                styles[styleMatch[1]] = propValue;
            } else if (propKey !== 'className' && propKey !== 'class' && propKey !== 'style') {
                newProps[propKey] = propValue;
            }
        });
        if (Object.keys(styles).length) {
            newProps.style = Object.entries(styles).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [fixStyleKey(key)]: value,
                }),
                {},
            );
        }
        if (classList.length) newProps.className = classList.join(" ");
    }

    return React.createElement(type, newProps, ...children);
}

export function cloneElement(...args) {
    return React.cloneElement(...args);
}

export function elementFactory(globalProps) {
    return (type, props, ...children) => {
        const combinedProps = type === Fragment ? props : {...globalProps, ...props};
        return createElement(type, combinedProps, ...children);
    };
}

export function findDOMNode(component) {
    return ReactDOM.findDOMNode(component)  // eslint-disable-line react/no-find-dom-node
}

export const Component = React.Component;

export const Children = React.Children;

export function render(element, container) {
    ReactDOM.render(element, container);
}

export function joinClassNames(...args) {
    return args.map((arr, str) => (str && typeof (str) === 'string' ? [...arr, ...str.split(/\s+/)] : arr), []).join(" ");
}
