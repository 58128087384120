<style lang="scss">
    @import "sass/variables";
    @import "sass/survey-common";

    /* Before/after default styles */
    #before-after {
        display: block;
        position: relative;
        height: 550px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        top: 50%;
        /*        margin-top: 50px;*/
        @media #{$xs-only} {
            margin-top: 0px;
        }
        @media #{$md-only} {
            margin-top: 20px;
        }
        @media #{$xs-only, $sm-only, $md-only} {
            padding-bottom: 56.5%;
            height: auto;
        }
    }

    .rm-ba-left,
    .rm-ba-right {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        @media #{$xs-only} {
            width: 120%;
            height: 120%;
            left: -10%;
        }
    }

    .rm-ba-left *,
    .rm-ba-right * {
        display: block;
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media #{$lg-up} {
            top: 42%;
        }
    }

    .rm-ba-right * {
        z-index: 1;
    }

    .rm-ba-left * {
        z-index: 2;
    }

    .number {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .slider-outer {
        z-index: 4;
        position: inherit;
        @media #{$lg-up} {
            position: absolute;
        }
        @media (max-height: 400px) {
            margin-top: -80px;
        }
    }

    .main-container {
        overflow: hidden;
    }

    .hiddenVideo {
        position: absolute;
        opacity: 0;
        width: 100px;
        height: 100px;
    }

    @keyframes sliderAnim {
        from {
            left: 0;
        }

        75% {
            left: 100%;
        }

        to {
            left: 0;
        }
    }
</style>

<template name="SurveyRatingVidImgWebgl" extends="SurveyRatingVidImgWebglController">
    <main class="main-container" class.black-banner="{this.props.theme === 'black'}">
        <h1 tabIndex="-1" style="outline: none">{this.props.title}</h1>

        <video
            class="hiddenVideo"
            src="{this.props.videoSrc}"
            ref="{this.video}"
            autoPlay
            muted
            loop
            playsInline
            preload="auto"
        ></video>

        <!-- Before/After element -->
        <rm-before-after id="before-after" progress="{this.props.answer ? (this.props.answer.value - 1) * 20 : 0}">
            <div class="rm-ba-left">
                <canvas id="video" width="1920px" height="1080px" ref="{this.canvas}"></canvas>
            </div>
            <div class="rm-ba-right">
                <img src="{this.props.imageSrc}" alt="Test Image" />
            </div>
        </rm-before-after>

        <!-- Slider to control before/after -->
        <div class="slider-outer">
            <p>{this.props.desc}</p>
            <div class="slider-container">
                <div class="sign minus" role="button">&#8722;</div>
                <div class="sign plus" role="button">+</div>
                <div id="rm-slider"></div>
                <p class="number">{this.props.answer ? Math.round(this.props.answer.value) : 1}</p>
            </div>
        </div>

        <!-- Buttons back/next -->
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">
                    Back
                </button>
            </div>
            <div class="next-button-container">
                <button
                    aria-label="next"
                    class="next-button btn"
                    onClick="{() => this.props.onSubmit({name: this.props.questionName, value: (this.slider.value / 20) + 1})}"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- </SurveyAnimation> -->
    </main>
</template>

<script>
    import "@rmlibrary/before-after";
    import { Slider } from "@rmlibrary/slider";

    const requestAnimationFrame =
        window.requestAnimationFrame ||
        function (cb) {
            return window.setTimeout(cb(new Date().getTime()), 16);
        };

    const cancelAnimationFrame =
        window.cancelAnimationFrame ||
        function (id) {
            window.clearTimeout(id);
        };

    const VERTEX_SHADER = `
        attribute vec4 aVertexPosition;
        attribute vec2 aTextureCoord;

        varying highp vec2 vTextureCoord;

        void main() {
            gl_Position = aVertexPosition;
            vTextureCoord = aTextureCoord;
        }
    `;

    const IS_IOS_10_1_OR_2 =
        /ipod|ipad|iphone/i.test(window.navigator.userAgent) && /CPU.*OS 10_[12]/i.test(window.navigator.userAgent);

    const FRAGMENT_SHADER = `
        precision mediump float;
        varying highp vec2 vTextureCoord;

        uniform sampler2D uSampler;

        void main() {
            vec4 inColor = texture2D(uSampler, vec2(vTextureCoord.s, vTextureCoord.t));
            gl_FragColor = inColor;
        }
    `;

    function createShader(gl, type, src) {
        const shader = gl.createShader(type);
        gl.shaderSource(shader, src);
        gl.compileShader(shader);

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            console.log(gl.getShaderInfoLog(shader));
            gl.deleteShader(shader);
            return null;
        }

        return shader;
    }

    function createProgram(gl, vShader, fShader) {
        const shaderProgram = gl.createProgram();

        gl.attachShader(shaderProgram, vShader);
        gl.attachShader(shaderProgram, fShader);
        gl.linkProgram(shaderProgram);

        if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
            console.log(gl.getProgramInfoLog(shaderProgram));
            return null;
        }

        return {
            program: shaderProgram,
            attribLocations: {
                vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
                textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
            },
            uniformLocations: {
                uSampler: gl.getUniformLocation(shaderProgram, "uSampler"),
            },
        };
    }

    function initBuffers(gl) {
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        const positions = [1.0, 1.0, -1.0, 1.0, 1.0, -1.0, -1.0, -1.0];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

        const textureCoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
        const textureCoords = [1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoords), gl.STATIC_DRAW);

        return {
            position: positionBuffer,
            textureCoord: textureCoordBuffer,
        };
    }

    function initTexture(gl) {
        const texture = gl.createTexture();

        gl.bindTexture(gl.TEXTURE_2D, texture);

        const level = 0;
        const internalFormat = gl.RGBA;
        const srcFormat = gl.RGBA;
        const srcType = gl.UNSIGNED_BYTE;

        const width = 1;
        const height = 1;
        const border = 0;
        const pixel = new Uint8Array([0, 0, 0, 0]);

        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, pixel);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

        return texture;
    }

    function updateTexture(gl, texture, src) {
        gl.bindTexture(gl.TEXTURE_2D, texture);

        const level = 0;
        const internalFormat = gl.RGB;
        const srcFormat = gl.RGB;
        const srcType = gl.UNSIGNED_BYTE;

        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, src);
    }

    function drawScene(gl, programInfo, buffers, texture) {
        gl.clearColor(0.0, 0.0, 0.0, 0.0);
        gl.clearDepth(1.0);
        gl.enable(gl.DEPTH_TEST);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        {
            const num = 2;
            const type = gl.FLOAT;
            const normalize = false;
            const stride = 0;
            const offset = 0;

            gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
            gl.vertexAttribPointer(
                programInfo.attribLocations.vertexPosition,
                num,
                type,
                normalize,
                stride,
                offset,
            );
            gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
        }

        {
            const num = 2;
            const type = gl.FLOAT;
            const normalize = false;
            const stride = 0;
            const offset = 0;

            gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
            gl.vertexAttribPointer(
                programInfo.attribLocations.textureCoord,
                num,
                type,
                normalize,
                stride,
                offset,
            );
            gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);
        }

        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        gl.useProgram(programInfo.program);

        gl.activeTexture(gl.TEXTURE0);

        gl.bindTexture(gl.TEXTURE_2D, texture);

        gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

        {
            const offset = 0;
            const vertexCount = 4;
            gl.drawArrays(gl.TRIANGLE_STRIP, offset, vertexCount);
        }
    }

    class SurveyRatingVidImgWebglController extends Component {
        constructor(props) {
            super(props);
            
            this.canvas = React.createRef();
            this.video = React.createRef();
        }

        componentDidMount() {
            const sliderEl = document.getElementById("rm-slider");

            this.slider = new Slider(sliderEl, {
                min: 0,
                max: 100,
                step: 1,
                value: 0,
            });

            this.beforeAfterElement = document.querySelector("#before-after");

            this.sliderNumber = document.querySelector(".number");
            this.sliderHandle = document.querySelector(".rm-slider-handle");
            this.sliderHandle.appendChild(this.sliderNumber);

            this.processFrame = this.processFrame.bind(this);
            this.initGL = this.initGL.bind(this);
            this.drawScene = drawScene.bind(this);

            this.slider.on("change", (value) => {
                this.beforeAfterElement.progress = value.toString();
                this.sliderNumber.innerText = Math.round(value / 20) + 1;
                this.sliderValue = value / 20 + 1;
            });

            this.nextBtn = document.querySelector(".next-button");
            this.backBtn = document.querySelector(".back-button");
            this.plus = document.querySelector(".plus");
            this.minus = document.querySelector(".minus");

            this.plus.addEventListener("click", (evt) => {
                let num = Number(this.sliderNumber.innerText);
                if (num < 6) {
                    num++;
                    this.sliderNumber.innerText = num;
                    this.beforeAfterElement.progress = (num - 1) * 20;
                    this.slider.value = (num - 1) * 20;
                }
            });
            this.minus.addEventListener("click", (evt) => {
                let num = Number(this.sliderNumber.innerText);
                if (num > 1) {
                    num--;
                    this.sliderNumber.innerText = num;
                    this.beforeAfterElement.progress = (num - 1) * 20;
                    this.slider.value = (num - 1) * 20;
                }
            });

            if (!this.props.answer) {
                this.nextBtn.setAttribute("disabled", "true");
                this.backBtn.setAttribute("disabled", "true");
            }  else {
                this.nextBtn.removeAttribute("disabled");
                this.backBtn.removeAttribute("disabled");
            }

            const videoLoadedHandler = () => {
                if (this.isIE() || navigator.userAgent.indexOf("Chrome") !== -1) {
                    this.bkpCanvas = document.createElement("canvas");
                }
                this.initGL();
                this.updateValues();
                if (!this.props.answer) {
                    this.animateSliderHandle(this.slider)
                }
                this.video.current.removeEventListener("loadeddata", videoLoadedHandler);
            };

            this.video.current.addEventListener("loadeddata", videoLoadedHandler);

            if (this.video.current.readyState === 4) {
                videoLoadedHandler();
            }
            
            window.scrollTo(0, 0);
        }

        componentDidUpdate() {
            this.updateValues(false);
            
            if (!this.props.answer) {
                this.nextBtn.setAttribute("disabled", "true");
                this.backBtn.setAttribute("disabled", "true");
            }  else {
                this.nextBtn.removeAttribute("disabled");
                this.backBtn.removeAttribute("disabled");
            }

            if (document.activeElement) {
                document.activeElement.blur();
            }

            cancelAnimationFrame(this.rafId);
            this.rafId = null;

            const videoLoadedHandler = () => {
                this.processFrame();
                this.updateValues();
                if (!this.props.answer) {
                    this.animateSliderHandle(this.slider)
                }

                this.video.current.removeEventListener("loadeddata", videoLoadedHandler);
            };
            
            if (this.video.current.readyState === 4 && !this.sliderRafID && this.sliderValue) {
                this.nextBtn.removeAttribute("disabled");
                this.backBtn.removeAttribute("disabled");
                const sliderVal = this.props.answer ? this.props.answer.value : this.sliderValue; 
                this.slider.value = (sliderVal - 1) / 5 * 100;
                this.beforeAfterElement.progress = (sliderVal - 1) / 5 * 100;
                this.sliderNumber.innerText = Math.round(sliderVal);
                this.processFrame();
            } else {
                this.video.current.addEventListener("loadeddata", videoLoadedHandler);
            }
            
            window.scrollTo(0, 0);
        }

        componentWillUnmount() {
            cancelAnimationFrame(this.rafId);
        }

        updateValues(firstMount) {
            const sliderNumber = this.props.answer ? this.props.answer.value : 1;
            const sliderValue = this.props.answer ? (this.props.answer.value - 1) * 20 : 0;

            if (!firstMount) {
                this.beforeAfterElement.progress = `${sliderValue}`;
            }
            this.slider.value = sliderValue;
            this.sliderNumber.innerText = Math.round(sliderNumber);
        }

        isIE() {
            const ua = navigator.userAgent;
            const msie = ua.indexOf("MSIE ");
            const trident = ua.indexOf("Trident/");

            return msie > 0 || trident > 0;
        }

        initGL() {
            if (!this.canvas.current) return;
            const gl = this.canvas.current.getContext("webgl") || this.canvas.current.getContext("experimental-webgl");

            if (!gl) return;

            const vShader = createShader(gl, gl.VERTEX_SHADER, VERTEX_SHADER);

            // AFTER part of the video
            const fShader = createShader(gl, gl.FRAGMENT_SHADER, FRAGMENT_SHADER);

            gl.clearColor(0.0, 0.0, 0.0, 0.0);
            gl.clear(gl.COLOR_BUFFER_BIT);

            this.glParts = {
                gl: gl,
                programInfo: createProgram(gl, vShader, fShader),
                buffers: initBuffers(gl),
                texture: initTexture(gl),
            };

            this.drawScene(this.glParts.gl, this.glParts.programInfo, this.glParts.buffers, this.glParts.texture);

            if (!this.rafId) {
                this.processFrame();
            }
        }

        animateSliderHandle() {
            let start;

            /* const easeInOut = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t); */
            const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);

            let animateSlider = (timestamp) => {
                if (start === undefined) start = timestamp;
                const elapsed = timestamp - start;
                let value =
                    elapsed <= 1500
                        ? Math.min(easeInOutCubic(elapsed * 0.00075), 1) * 100
                        : Math.max(1 - easeInOutCubic(Math.abs(elapsed - 1500) * 0.001), 0) * 50 + 50;

                if (elapsed <= 2000 || value > 50) {
                    this.slider.value = value;
                    this.beforeAfterElement.progress = value;
                    this.sliderNumber.innerText = Math.round(value / 20) + 1;
                    requestAnimationFrame(animateSlider);
                } else {
                    cancelAnimationFrame(this.sliderRafID);
                    this.sliderValue = 3.5;
                    this.sliderRafID = null;
                    this.nextBtn.removeAttribute("disabled");
                    this.backBtn.removeAttribute("disabled");
                }
            };

            this.sliderRafID = requestAnimationFrame(animateSlider);
        }

        processFrame() {
            if (this.glParts) {
                if (this.bkpCanvas) {
                    this.bkpCanvas.width = this.video.current.videoWidth;
                    this.bkpCanvas.height = this.video.current.videoHeight;
                    const ctx = this.bkpCanvas.getContext("2d");
                    ctx.drawImage(this.video.current, 0, 0);
                    updateTexture(this.glParts.gl, this.glParts.texture, this.bkpCanvas);
                } else {
                    updateTexture(this.glParts.gl, this.glParts.texture, this.video.current);
                }
                this.drawScene(this.glParts.gl, this.glParts.programInfo, this.glParts.buffers, this.glParts.texture);
            }

            this.rafId = requestAnimationFrame(this.processFrame);
        }
    }

    SurveyRatingVidImgWebglController.defaultProps = {
        title: "Customer Care",
        question: "We are attentive to your needs and proactively communicating with you.",
        onSubmit: () => console.log("submit"),
    };
</script>
