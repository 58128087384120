<style lang="scss">
/* styles go here */
.search-icon-container {
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    display:flex;
    align-items: center;

    button {
        background-image:url(images/icons/SVG/search-icon.svg);
        width: 40px;
        height: 40px;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.search-bar-outer {
    position: relative;
    height: 0px;

    @media only screen and (max-width: 768px) {
        right: 0px;
        top:67px;
        bottom:0;
        position: fixed;
        transition: right 250ms ease-in-out;
        height:100vh;
        margin-top:0;
        z-index: 99999;
    }

    &.open {
        height: 60px;

        @media only screen and (max-width: 768px) {
            height: 100vh;
        }
    }
}
.search-bar {
    display: none;
    position: fixed;
    top:30px;
    left:0;
    right:0;
    background: #424143;
    height: 60px;
    color:white;
    z-index: 1000;

    @media only screen and (max-width: 768px) {
        margin-right: -230px;
        width:230px;
        position: relative;
        height:100%;
        top:0;
        padding-top:20px;
    }
    &.open {
        display: block;
        top:90px;

        @media only screen and (max-width: 768px) {
            top:0;
            margin-right:0;
        }

    }
    .bar-content {
        max-width: 1070px;
        margin: 0 auto;
        justify-content:space-between;
        width:100%;
        padding-left: 20px;
        padding-right: 20px;
        align-items:center;
        padding-top:12.5px;
        display:flex;
        @media only screen and (max-width: 768px) {
            flex-wrap:wrap;
            padding:0;
        }
        @media screen and (max-width: 900px) and (min-width: 769px) {
            padding-right:5px;
            padding-left:5px;
        }
        .search-icon {
            width:auto;
            padding-top:5px;
            margin-right:5px;
            margin-left:5px;
            @media only screen and (max-width: 768px) {
                width:100%;
                margin-bottom: 10px;
                padding-top:10px;
                border-top: 1px solid #1d1d1d;
                margin-right:0;
                margin-left:0;
            }
            button {
                display:flex;
                align-items:center;
                @media only screen and (max-width: 768px) {
                    margin-left:20px;
                }
            }
            button:hover p {
                text-decoration:underline;
            }
            img {
                width:25px;
                margin-right:5px;
                @media screen and (max-width: 900px) and (min-width: 769px) {
                    width:20px;
                }
            }
            p {
                color:white;
                @media only screen and (max-width: 950px) {
                    font-size:13px;
                }
            }
        }
        button.close-btn {
            background-image:url(images/icons/SVG/search-close.svg);
            width:20px;
            height:20px;
            background-size:contain;
            @media only screen and (max-width: 768px) {
                display:none;
            }
        }
        .search-input {
            position:relative;
            padding-top:0;
            @media screen and (max-width: 900px) and (min-width: 769px) {
                width:140px;
            }
            @media only screen and (max-width: 768px) {
                order:1;
                margin-bottom:10px;
                margin-right:20px;
                margin-left:20px;
            }
        }
        .social {
            @media only screen and (max-width: 768px) {
                order:2;
            }
        }
        .search-icon:nth-child(2) {
            @media only screen and (max-width: 768px) {
                order:3;
            }
        }
        .search-icon:nth-child(3) {
            @media only screen and (max-width: 768px) {
                order:4;
            }
        }
        .search-icon:nth-child(4) {
            @media only screen and (max-width: 768px) {
                order:5;
            }
        }
        .search-icon:nth-child(5) {
            @media only screen and (max-width: 768px) {
                order:6;
            }
        }
        .search-icon:nth-child(6) {
            @media only screen and (max-width: 768px) {
                order:7;
            }
        }
        .search-icon:nth-child(8) {
            @media only screen and (max-width: 768px) {
                display:none;
            }
        }
    }
}
    
input[type=search] { 	
	padding: 5px;    
    border-radius:4px;
    color:#1a1a1a;
    @media screen and (max-width: 900px) and (min-width: 769px) {
        width:140px;
    }
}        
    
.search-btn {
    position:absolute;
    background-image:url(images/icons/SVG/search-icon.svg);
    width:20px;
    height:20px;
    background-size:contain;
    right:10px;
    bottom:10px;
}    
    
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}        
    
    
.mobile-header {
    .search-container {
        .search-icon-container {
            margin-right:20px;
        }
    }
}    
    
    p.active {
        font-weight:bold;
    }    
</style>
<template name="RichIdeasSearchIcon">
    <div class="search-icon-container">
        <button onClick="{this.props.onClick}">&nbsp;</button>
    </div>
</template>
<template name="RichIdeasSearch_" extends="RichIdeasSearchController">
    <div>
        <div class="search-bar-outer" ref={this.outerRef}>
            <div class="search-bar" ref={this.barRef}>
                <div class="bar-content">
                    <div class="search-icon social">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-social.svg" alt="Social"/>
                            <p>Social</p>
                        </button>    
                    </div>
                    
                    <div class="search-icon">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-research.svg" alt="Research"/>
                            <p>Research</p>
                        </button>
                    </div> 
                    
                    <div class="search-icon">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-mobile.svg" alt="Research"/>
                            <p>Mobile</p>
                        </button>
                    </div> 
                    
                    <div class="search-icon">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-design.svg" alt="Research"/>
                            <p>Design</p>
                        </button>
                    </div> 
                    
                    <div class="search-icon">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-interactive.svg" alt="Research"/>
                            <p>Interactive</p>
                        </button>
                    </div> 
                    
                    <div class="search-icon">
                        <button>
                            <img src="site_assets/images/icons/SVG/search-content.svg" alt="Research"/>
                            <p>Content Marketing</p>
                        </button>
                    </div> 
                    <div class="search-icon search-input desktop">
                        <form onSubmit={this.handleSubmit}>
                            <input type="search" id="site-search" name="q"
                                   aria-label="Search through site content" placeholder="Search"
                                   value={this.state.searchInput} onChange={this.handleInputChange}>
                            <button class="search-btn">&nbsp;</button>
                        </form>
                    </div>   
                    
                    <div class="search-icon">
                        <button class="close-btn" onClick={this.props.onClose}>&nbsp;</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {createRef} from "rmlibrary/comp";
import RMTween from "rmlibrary/tween";
import {SearchContextConsumer} from "../../richideas/search-context.js";

export const RichIdeasSearch = (props) => (<SearchContextConsumer>{
    (state) => (<RichIdeasSearch_ {...props} searchInput={state.searchInput} onSubmit={state.setSearchInput} />)
}</SearchContextConsumer>)

class StyleProxy {
    constructor(ref, property) {
        this.ref = ref;
        this.property = property;
    }

    fn(v) {
        return v + "px";
    }

    get value() {
        try {
            return parseInt(this.ref.current.style[this.property]);
        } catch(e) {
            return null;
        }
    }

    set value(v) {
        this.ref.current.style[this.property] = this.fn(v);
    }

    clear() {
        this.ref.current.style[this.property] = "";
    }
}

class TransformXProxy extends StyleProxy {

    constructor(ref) {
        super(ref, "transform");
    }

    fn(v) {
        return `translateX(${v}px)`;
    }

    get value() {
        try {
            return Number(this.ref.current.style.transform.match(/translateX\(([+-]?\d+(?:\.\d+)?)/)[1]);
        } catch(e) {
            return null;
        }
    }
    set value(v) {
        this.ref.current.style[this.property] = this.fn(v);
    }
}

class RichIdeasSearchController extends Component {
    

    static getDerivedStateFromProps(props, state) {
        if (props.searchInput !== state.initialSearch) {
            return {
                initialSearch: props.searchInput,
                searchInput: props.searchInput
            }
        }
        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            initialSearch: "",
            searchInput: ""
        }

        this.outerRef = createRef();
        this.barRef = createRef();

        this.outerProxy = new StyleProxy(this.outerRef, "height");
        this.outerProxyMobile = new TransformXProxy(this.outerRef);
        this.barProxy = new StyleProxy(this.barRef, "top");
        this.tween = null;

        this.mobileMedia = window.matchMedia("(max-width: 768px)");

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    animateOpenMobile() {
        this.tween = RMTween.create(this.outerProxyMobile)
            .to({value: this.outerProxyMobile.value || 230}, 0)
            .to({value: 0}, 250, 'easeIn'),
        this.outerRef.current.classList.add("open");
        this.barRef.current.classList.add("open");
        this.tween.run(() => {
            this.tween = null;
            this.outerProxyMobile.clear();
        })
    }

    animateOpen() {
        if (this.mobileMedia.matches) return this.animateOpenMobile();
        
        this.tween = RMTween.parallel(
            RMTween.create(this.outerProxy)
                .to({value: this.outerProxy.value || 0}, 0)
                .to({value: 60}, 250, 'easeIn'),
            RMTween.create(this.barProxy)
                .to({ value: this.outerProxy.value || 30 }, 0)
                .to({ value: 90 }, 250, 'easeIn'),
        );
        this.outerRef.current.classList.add("open");
        this.barRef.current.classList.add("open");
        this.tween.run(() => {
            this.tween = null;
            this.outerProxy.clear();
            this.barProxy.clear();
        })
    }

    animateClosedMobile() {
        this.tween = RMTween.create(this.outerProxyMobile)
            .to({value: this.outerProxyMobile.value || 0}, 0)
            .to({value: 230}, 250, 'easeOut'),
        this.tween.run(() => {
            this.tween = null;
            this.outerRef.current.classList.remove("open");
            this.barRef.current.classList.remove("open");
            this.outerProxyMobile.clear();
        })
    }

    animateClosed() {
        if (this.mobileMedia.matches) return this.animateClosedMobile();

        this.tween = RMTween.parallel(
            RMTween.create(this.outerProxy)
                .to({ value: this.outerProxy.value || 60 }, 0)
                .to({ value: 0 }, 250, 'easeOut'),
            RMTween.create(this.barProxy)
                .to({ value: this.outerProxy.value || 90 }, 0)
                .to({ value: 30 }, 250, 'easeOut'),
        );
        this.tween.run(() => {
            this.tween = null;
            this.outerRef.current.classList.remove("open");
            this.barRef.current.classList.remove("open");
            this.outerProxy.clear();
            this.barProxy.clear();
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            console.log("DO IT", prevProps.show, this.props.show)
            if (!this.outerRef.current || !this.barRef.current) return;

            if (this.tween) this.tween.stop();

            if (this.props.show) this.animateOpen();
            else this.animateClosed();
        }
    }

    handleInputChange(e) {
        this.setState({
            searchInput: e.target.value,
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onSubmit(this.state.searchInput);
    }
}
</script>