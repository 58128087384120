
const TODAY = new Date()
// For `date`, use TODAY.getFullYear() for the `year` part of `new Date(year, month, day)`.
const EVENT_LIST_DAY = [
    {
        name: 'Groundhog Day',
        date: new Date(TODAY.getFullYear(), 1, 2),
        imageSrc: 'site_assets/images/gallery/GroundhogDay.jpg',
        imageLabel: 'Happy Groundhog Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/BtWY4JUlfPo/',
        imageClass: 'article img3'
    },
    {
        name: 'Valentine\'s Day',
        date: new Date(TODAY.getFullYear(), 1, 14),
        imageSrc: 'site_assets/images/gallery/ValentinesDay.jpg',
        imageLabel: 'Happy Valentine\'s Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/Bt4AhtWlitG/',
        imageClass: 'article img3'
    },
    {
        name: 'Family Day',
        date: new Date(TODAY.getFullYear(), 1, 17),
        imageSrc: 'site_assets/images/gallery/FamilyDay.jpg',
        imageLabel: 'Happy Family Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/Bt6iAuBlu2i/',
        imageClass: 'article img3'
    },
    {
        name: 'Pi Day',
        date: new Date(TODAY.getFullYear(), 2, 14),
        imageSrc: 'site_assets/images/gallery/PiDay.jpg',
        imageLabel: 'Happy Pi Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/Bu_Z-dYFQgV/',
        imageClass: 'article img3'
    },
    {
        name: 'St. Patrick\'s Day',
        date: new Date(TODAY.getFullYear(), 2, 17),
        imageSrc: 'site_assets/images/gallery/St.PatricksDay_2019_Final.jpg',
        imageLabel: 'Happy St. Patrick\'s Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/BvB-ePoFwLs/',
        imageClass: 'article img3'
    },
    {
        name: 'April Fool\'s',
        date: new Date(TODAY.getFullYear(), 3, 1),
        imageSrc: 'site_assets/images/gallery/AprilFools_1a.jpg',
        imageLabel: 'Happy April Fool\'s from Rich Media',
        imageURL: 'https://www.instagram.com/p/BvtuJCkFL7c/',
        imageClass: 'article img3'
    },
    {
        name: 'May The 4th',
        date: new Date(TODAY.getFullYear(), 4, 4),
        imageSrc: 'site_assets/images/gallery/MayThe4th.jpg',
        imageLabel: 'May The 4th be with you from Rich Media',
        imageURL: 'https://www.instagram.com/p/BxArIWUliRK/',
        imageClass: 'article img3'
    },
    {
        name: 'Mother\'s Day',
        date: new Date(TODAY.getFullYear(), 4, 10),
        imageSrc: 'site_assets/images/gallery/MothersDay.jpg',
        imageLabel: 'Happy Mother\'s Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/BxSvqoAl37x/',
        imageClass: 'article img3'
    },
    {
        name: 'Pride Month',
        date: new Date(TODAY.getFullYear(), 5, 1),
        imageSrc: 'site_assets/images/gallery/PrideMonth.jpg',
        imageLabel: 'Happy Pride Month from Rich Media',
        imageURL: 'https://www.instagram.com/p/ByQEEf5Bzn9/',
        imageClass: 'article img3'
    },
    {
        name: 'Canada Day',
        date: new Date(TODAY.getFullYear(), 6, 1),
        imageSrc: 'site_assets/images/gallery/Canada-Day--01_cropped.jpg',
        imageLabel: 'Happy Canada Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/BknhYC2A7Gr/',
        imageClass: 'article img3'
    },
    {
        name: 'Labour Day',
        date: new Date(TODAY.getFullYear(), 8, 7),
        imageSrc: 'site_assets/images/gallery/LabourDayWeekend.jpg',
        imageLabel: 'Happy Labour Day Weekend from Rich Media',
        imageURL: 'https://www.instagram.com/p/B1zDw-7nB-j/',
        imageClass: 'article img3'
    },
    {
        name: 'Thanksgiving',
        date: new Date(TODAY.getFullYear(), 9, 12),
        imageSrc: 'site_assets/images/gallery/Thanksgiving.jpg',
        imageLabel: 'Happy Thanksgiving from Rich Media',
        imageURL: 'https://www.instagram.com/p/Boj-_UiAbJ7/',
        imageClass: 'article img3'
    },
    {
        name: 'Halloween',
        date: new Date(TODAY.getFullYear(), 9, 15),
        imageSrc: 'site_assets/images/gallery/ideas_halloween_banner.jpg',
        imageLabel: 'Happy Halloween from Rich Media',
        imageURL: './richideas/articles/7-halloween-facts-for-digital-marketers-infographic',
        imageClass: 'article img3'
    },
    {
        name: 'Remembrance Day',
        date: new Date(TODAY.getFullYear(), 10, 11),
        imageSrc: 'site_assets/images/gallery/RemembranceDay.jpg',
        imageLabel: 'Remembrance Day from Rich Media',
        imageURL: 'https://www.instagram.com/p/Bp-TAqrlQU6/',
        imageClass: 'article img3'
    }
];
const EVENT_LIST_SEASON = [
  {
    name: "Spring",
    date: new Date(TODAY.getFullYear(), 2, 20),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_01-Spring.jpg",
    imageLabel: "Hello Spring",
    imageURL: "https://www.instagram.com/p/BvO1uYFFWR5/",
    imageClass: "article img6"
  },
  {
    name: "Summer",
    date: new Date(TODAY.getFullYear(), 5, 21),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_02-Summer.jpg",
    imageLabel: "Hello Summer",
    imageURL: "https://www.instagram.com/p/By-Z8xqFK72/",
    imageClass: "article img6"
  },
  {
    name: "Fall-1",
    date: new Date(TODAY.getFullYear(), 8, 23),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_03-Fall.jpg",
    imageLabel: "Hello Fall",
    imageURL: "https://www.instagram.com/p/B2wV8b9pQsU/",
    imageClass: "article img6"
  },
  {
    name: "Halloween",
    date: new Date(TODAY.getFullYear(), 9, 15),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_Halloween.jpg",
    imageLabel: "Halloween",
    imageURL: "https://www.instagram.com/p/Bpm2oNml1JW/",
    imageClass: "article img6"
  },
  {
    name: "Fall-2",
    date: new Date(TODAY.getFullYear(), 10, 1),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_03-Fall.jpg",
    imageLabel: "Hello Fall",
    imageURL: "https://www.instagram.com/p/B2wV8b9pQsU/",
    imageClass: "article img6"
  },
  {
    name: "Winter",
    date: new Date(TODAY.getFullYear(), 11, 21),
    imageSrc: "site_assets/images/gallery/Seasonal_Banners_04-Winter.jpg",
    imageLabel: "Hello Winter",
    imageURL: "https://www.instagram.com/p/BrqM7A1liRG/",
    imageClass: "article img6"
  }
];

function getDateString(date){
    return `${date.getMonth()}-${date.getDate()}`;
}

export function getCurrentEventData(eventType){
    let recentEvents = [];
    let eventList = [];
    if( eventType == "holiday" ) eventList = EVENT_LIST_DAY;
    else eventList = EVENT_LIST_SEASON;

    recentEvents = eventList.filter((day) => getDateString(TODAY) === getDateString(day.date));
    if(recentEvents.length === 0){
        recentEvents = eventList.reduce((prevDays, day) => {
            if(day.date < TODAY) {
                prevDays.push(day);
            }
            return prevDays;
        },
        []);
        recentEvents.sort((x, y) => y.date - x.date);
    }
    return recentEvents.length > 0 ? recentEvents[0] : eventList[eventList.length-1];
}

export function getSpecificEventData(eventType, monthNum, dayNum){
    let eventList = [];
    if( eventType == "holiday" ) eventList = EVENT_LIST_DAY;
    else eventList = EVENT_LIST_SEASON;

    return eventList.filter((day) => {
        return ( day.date.getMonth() == monthNum && day.date.getDate() == dayNum )
    })[0];
}