<!-- Import other components used here -->
<import name="Fragment" from="rmlibrary/comp" />

<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .template {
        background-color: $black90;
        .content {
            @media #{$sm-only} {
                max-width: 428px;
            }
            .project {
                width: 612px;
                @media #{$xs-only} {
                    width: 100%;
                }
                @media #{$sm-only} {
                    max-width: 383px;
                }
                .project-description {
                    @media #{$lg-up} {
                        max-width: 460px;
                    }
                }
            }
        }
        .side-imgs {
            z-index: 1000;
            margin-top: 3%;
            .side {
                position: absolute;
                width: 45%;
                @media #{$lg-only} {
                    width: 45%;
                }
            }
            .side1 {
                z-index: 1000;
            }
            .side2 {
                left: 30%;
                top: 135%;
                z-index: 2000;
            }
            .walmart-side {
                top: 65%;
                width: 43%;
                border: 1px solid #ccc;
            }
            .walmart-side2 {
                top: 100%;
                width: 43%;
                border: 1px solid #ccc;
            }
            @media #{$sm-only, $xs-only, $md-only} {
                display: none;
            }
            img {
                width: 90%;
                @media #{$lg-only} {
                    width: 80%;
                }
            }
        }
        .project-imgs {
            overflow: hidden;
            .devices {
                /*display:flex;*/
                width: 100%;
                /*justify-content:flex-start;*/
                @media #{$md-only} {
                    align-items: center;
                }
                @media #{$xl-only} {
                    margin-top: 15px;
                    margin-left: 470px;
                }
                @media #{$lg-only} {
                    margin-top: 15px;
                    margin-left: 440px;
                }
                .imac {
                    width: 63%;
                    text-align: center;
                    position: relative;
                    margin-top: 40px;
                    @media #{$lg-only} {
                        margin-top: 55px;
                        width: 55%;
                    }
                    @media #{$md-only, $sm-only, $xs-only} {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin: 0 auto;
                    }
                    .device {
                        position: relative;
                        z-index: 10000;
                        width: 100%;
                        @media #{$md-only, $sm-only, $xs-only} {
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                        }
                        @media #{$md-only} {
                            width: 500px;
                        }
                        @media #{$sm-only} {
                            width: 435px;
                        }
                        @media #{$xs-only} {
                            width: 130%;
                            max-width: 400px;
                        }
                    }
                    .desktop-img {
                        position: absolute;
                        width: 70%;
                        top: 8%;
                        left: 15%;
                        @media #{$md-only, $sm-only, $xs-only} {
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            top: 0;
                        }
                        @media #{$md-only} {
                            width: 350px;
                            top: 8%;
                        }
                        @media #{$sm-only} {
                            width: 302px;
                            margin-top:23px;
                        }
                        @media #{$xs-only} {
                            width: 90%;
                            max-width: 276px;
                            top: 8%;
                        }
                    }
                    .mobile {
                        display: none;
                    }
                    .desktop {
                        display: none;
                        @media #{$xs-up} {
                            display: block;
                        }
                    }
                }
            }
        }

        .yellow-button {
            @media #{$md-only, $sm-only, $xs-only} {
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

</style>

<template name="PortfolioTemplate3">
    <main>

        <div class="template">
            <div class="content" style="{{position: 'relative', zIndex: '1'}}">
                <div class="project">
                    <h1 class="project-title">{ this.props.project.title }</h1>
                    <p class="project-description">{ this.props.project.description }</p>
                    <If c="{this.props.project.link}">
                        <a href="{ this.props.project.link }" class="yellow-button" target="_blank">View Live <span class="chevron right"></span></a>
                    </If>
                    <div class="side-imgs">
                            <If c="{!this.props.project.images.walmartsideOne}">
                                <Fragment>
                                    <img draggable="false" src="{this.props.project.images.sideOne}" alt="{ this.props.project.title }" class="side side1"/>
                                    <img draggable="false" src="{this.props.project.images.sideTwo}" alt="{ this.props.project.title }" class="side side2"/>
                                </Fragment>
                            </If>
                            <If c="{this.props.project.images.walmartsideOne}">
                                <Fragment>
                                    <img draggable="false" src="{this.props.project.images.walmartsideOne}" alt="{ this.props.project.title }" class="side side1 walmart-side"/>
                                    <img draggable="false" src="{this.props.project.images.walmartsideTwo}" alt="{ this.props.project.title }" class="side side2 walmart-side2"/>
                                </Fragment>
                            </If>
                        </div>
                </div>

            <div class="project-imgs">
                    <div class="devices">
                        <div class="imac">
                            <img draggable="false" src="{this.props.project.images.desktop}" alt="{ this.props.project.title }" class="desktop device"/>
                            <img draggable="false" src="{this.props.project.images.desktopImg}" alt="{ this.props.project.title }" class="desktop-img"/>
                            <img draggable="false" src="{this.props.project.images.mobileVertical}" alt="{ this.props.project.title }" class="mobile"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="gradient" style="{{position: 'absolute', bottom: '0', zIndex: '0'}}">
                <div class="content">
                    <div class="client-logo">
                        <img draggable="false" class="center" src="{this.props.project.images.clientLogo}" />
                        <img draggable="false" class="left" src="{this.props.project.images.clientLogoDesktop}" />
                    </div>
                </div>
            </div>
        </div>

    </main>

</template>
