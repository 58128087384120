
<import name="PortfolioTemplate1" from="./portfolio-template1.html" />
<import name="PortfolioTemplate2" from="./portfolio-template2.html" />
<import name="PortfolioTemplate3" from="./portfolio-template3.html" />
<import name="PortfolioTemplate4" from="./portfolio-template4.html" />
<import name="TEMPLATE_1,TEMPLATE_2,TEMPLATE_3,TEMPLATE_4" from="../../portfolio-data" />

<style lang="scss">
    @import 'sass/variables';

    .projects-carousel {
        position: relative;
    }

    .controls {
        background: white;
        width: 100%;
        padding: 10px 20px;
        padding-bottom:25px;
        display: block;
        text-align: center;
        z-index:-1;
        @media #{$xs-only} {
            padding: 50px 15px;
            padding-top:10px;
            padding-bottom:20px;
        }
        @media #{$md-only} {
            padding-top:20px;
        }

        .dots {
            width:14px;
            height:14px;
            border:1px solid $black;
            border-radius:100%;
            display:inline-block;
            vertical-align:middle;
            margin:5px;
            border:1px solid $white;
            background-color:$black;
            @media #{$sm-only, $xs-only} {
                width:10px;
                height:10px;
                margin:4px;
            }
        }

        .selected-dot {
            background-color:$white;
            border:1px solid $black;
        }
    }
    
    .hidden-slider[hidden] {
        display: block !important;
    }
</style>

<template name="PortfolioProject">
    <div class="project">
        <If c="{this.props.project.template === TEMPLATE_1}">
            <PortfolioTemplate1 project="{this.props.project}" />
        </If>
        <If c="{this.props.project.template === TEMPLATE_2}">
            <PortfolioTemplate2 project="{this.props.project}" />
        </If>
        <If c="{this.props.project.template === TEMPLATE_3}">
            <PortfolioTemplate3 project="{this.props.project}" />
        </If>
        <If c="{this.props.project.template === TEMPLATE_4}">
            <PortfolioTemplate4 project="{this.props.project}" />
        </If>
    </div>
</template>

<template name="PortfolioCarousel" extends="PortfolioCarouselController">
    <div class="projects-carousel">

        <If c="{this.state.scrollDir != 'right'}">
            <div class="carousel-left">
                <a href="javascript:void(0);" onClick="{this.prevCard}" class="arrow arrow-left" aria-label="last slide"></a>
            </div>
        </If>

        <Carousel draggable allowScroll="{this.state.scrollDir}" width="100%" callback="{this.refreshDeck}" callbefore="{this.callbefore}" ref="{carousel => this.carousel = carousel}">
            <div aria-hidden className="hidden-slider"> <PortfolioProject project="{this.state.deck[0]}" /> </div> 
            <div> <PortfolioProject project="{this.state.deck[1]}" /> </div>
            <div aria-hidden className="hidden-slider"> <PortfolioProject project="{this.state.deck[2]}" /> </div>
        </Carousel>
        
        <If c="{this.state.scrollDir != 'left'}">
            <div class="carousel-right">
                <a href="javascript:void(0);" onClick="{this.assignAM}" class="arrow arrow-right" aria-label="next slide"></a>
            </div>
        </If>
        
        <div class="controls">
            <For var="project" of="{this.props.filteredProjects}">
                <button key="{project.slug}" onClick="{() => {this.indicatorsRedirectProject(project, index)}}"
                    class="dots" class.selected-dot="{index == this.state.projectIndex}"
                    ref="{btn => index == this.state.projectIndex ? this.selectedBtn = btn : ''}" aria-label="{'Slide ' + (index + 1)}"></button>
            </For>
        </div>
    </div>
</template>

<script>
    import { Carousel } from "rmlibrary/carousel";

    class PortfolioCarouselController extends Component {
        constructor(props) {
            super(props);

            const deckMeta = this.getDeckMetaBySlug(props.project);
            this.state = {
                deck: deckMeta.deck,
                projectIndex: deckMeta.projectIndex,
                scrollDir: this.getScrollDir(deckMeta.projectIndex),
            };

            this.overrideIndicator = false;

            this.refreshDeck = this.refreshDeck.bind(this);
            this.callbefore = this.callbefore.bind(this);
            this.prevCard = this.prevCard.bind(this);
            this.assignAM = this.assignAM.bind(this);
        }

        componentWillReceiveProps(nextProps) {
            const deckMeta = this.getDeckMetaBySlug(nextProps.project);
            if (deckMeta) this.setState({
                deck: deckMeta.deck,
                projectIndex: deckMeta.projectIndex,
                scrollDir: this.getScrollDir(deckMeta.projectIndex)
            });
        }

        refreshDeck(increment) {
            this.overrideIndicator = false;

            if (increment == -1) this.redirectProject(this.state.deck[0].slug);
            else if (increment == 1) this.redirectProject(this.state.deck[2].slug);
        }

        redirectProject(slug) {
            this.props.redirect(['/portfolio/' + slug]);
        }

        getScrollDir(projectIndex) {
            if (projectIndex == 0) return "right";
            else if (projectIndex == this.props.filteredProjects.length - 1) return "left";
            else return "both";
        }

        indicatorsRedirectProject(project, index) {            
            if (!this.carousel.isBusy()){
                if (index != this.state.projectIndex) {
                    const replIndex = index < this.state.projectIndex ? 0 : 2;
                    let deck = this.state.deck.slice();
                    deck[replIndex] = project;

                    this.setState({ projectIndex: index, deck });
                    this.overrideIndicator = true;

                    if (replIndex == 0) this.prevCard();
                    if (replIndex == 2) this.assignAM();
                }
            }
            else this.selectedBtn.focus();
        }

        callbefore(increment) {
            if (!this.overrideIndicator) this.setState({
                projectIndex: this.state.projectIndex + increment
            });
        }

        prevCard() {
            if (this.carousel) this.carousel.prev();
        }

        assignAM() {
            if (this.carousel) this.carousel.next();
        }

        getDeckMetaBySlug(slug) {
            const projects = this.props.filteredProjects;
            for (let i = 0; i < projects.length; i++) {
                if (projects[i].slug === slug) {
                    return {
                        deck: [
                            projects[(i - 1 + projects.length) % projects.length],
                            projects[i],
                            projects[(i + 1) % projects.length]
                        ],
                        projectIndex: i
                    };
                }
            }
        }
    }
</script>
