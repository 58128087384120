/* globals window, document */

import "rmlibrary/fetch";

import { createElement as el, render } from "rmlibrary/comp";

import { PageTemplate } from "./components/common/page-template.html";
import { URL_PARAMS } from "rmlibrary/util";

export function renderPage(page, container) {
    render(el(PageTemplate, {}), container);
}

// http://richmedia.com/?utm_source=linkedin&utm_campaign=fin_banner
function addLinkedInTracking() {
    const _linkedin_partner_id = (window._linkedin_partner_id = "497457");
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
}

/**
 * NOTE: The structured datas should be kept updated with the page content. It
 * enables special search result features and enhancements in Google Search.
 * Refer to https://developers.google.com/search/docs/data-types/job-posting#definitions
 * for the structured data type definition.
 */
function addGoogleStructuredData() {
    function toggleStructuredData() {
        const pathname = window.location.pathname;
        const pagename = pathname.substring(pathname.lastIndexOf("/") + 1);
        const scriptID = pagename + "-structured-data";
        const structuredDataLoaded = document.getElementById(scriptID) ? true : false;
        let shouldLoadStructuredData = false;

        switch (pagename) {
            case "accountmanager":
            case "designercopy":
            case "graphicdesigner":
            case "internship":
            case "programmer":
            case "qaanalyst": {
                shouldLoadStructuredData = !structuredDataLoaded && true;
                break;
            }

            default:
                break;
        }

        const careersStructuredData = document.getElementsByClassName("careers-structured-data");
        for (let i = 0; i < careersStructuredData.length; i++) {
            const structuredData = careersStructuredData[i];
            if (structuredData.id !== scriptID || !structuredDataLoaded) {
                structuredData.parentNode.removeChild(structuredData);
            }
        }

        if (shouldLoadStructuredData) {
            const hostname = location.hostname;
            const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

            let baseURL = "";
            switch (hostname) {
                case "localhost":
                case "127.0.0.1": {
                    break;
                }
                case "richmediawireless.com": {
                    baseURL = "www.richmediawireless.com/dev/rm/job-posting";
                    break;
                }

                default: {
                    baseURL = "www.richmedia.com";
                    break;
                }
            }

            if (!isLocalhost && hostname !== "richmediawireless.com") {
                fetch(`https://${baseURL}/api/data/structured-data/${pagename}.json`)
                    .then((result) => result.text())
                    .then((result) => {
                        const head = document.getElementsByTagName("head")[0];
                        const script = document.createElement("script");
                        script.id = pagename + "-structured-data";
                        script.classList.add("careers-structured-data");
                        script.type = "application/ld+json";
                        script.innerHTML = result;

                        head.appendChild(script);
                    });
            }
        }
    }

    window.setInterval(toggleStructuredData, 250);
}

addGoogleStructuredData();

if (URL_PARAMS.utm_source && URL_PARAMS.utm_source === "linkedin") {
    addLinkedInTracking();
}
