/*globals sessionStorage, XMLHttpRequest */

import { Component, createContext, createElement } from "rmlibrary/comp";
import { getPersonalization, trackPage } from "./hs-track";

import { AM_BY_EMAIL } from "../../client-list-AM";
import { URL_PARAMS } from "rmlibrary/util";

const CustomizationContext = createContext({ industry: null });

const HS_INDUSTRY_MAP = {
    "Finance & Insurance": "financial",
    Pharmaceutical: "pharma",
};

function isHoliday() {
    const date = new Date();
    const month = date.getMonth();
    const day = date.getDate();

    if (sessionStorage.getItem("theme") == "default") return "default";
    if (sessionStorage.getItem("theme") == "halloween") return "halloween";
    if (sessionStorage.getItem("theme") == "new-year") return "new-year";
    if (sessionStorage.getItem("theme") == "xmas") return "xmas";
    if (sessionStorage.getItem("theme") == "redacted") return "redacted";
    if (sessionStorage.getItem("theme") == "canadaday") return "canadaday";

    if (month === 9 && day > 14) return "halloween";

    if ((month === 11 && day === 31) || (month === 0 && day <= 4)) return "new-year";
    if (month === 11) return "xmas";
    // if ((month === 5 && day >= 28) || (month === 6 && day <= 2)) return "canadaday";
    if (month === 6 && day === 1) return "canadaday";
    if (month === 5 && day >= 1) return "pride";

    return "default";
}

function getUrlIndustry() {
    let industry;
    if (URL_PARAMS.i && URL_PARAMS.i.toLowerCase() === "f") industry = "financial";
    if (URL_PARAMS.i && URL_PARAMS.i.toLowerCase() === "p") industry = "pharma";
    if (industry) {
        sessionStorage.setItem("industry", industry);
        sessionStorage.setItem("specialIndustry", "true");
    }
}

export class CustomizationProvider extends Component {
    constructor(props) {
        super(props);

        getUrlIndustry();

        this.trackReady = false;
        this.trackQueue = [];

        this.state = {
            name: sessionStorage.getItem("name"),
            whatBrings: sessionStorage.getItem("whatBrings"),
            specialIndustry: JSON.parse(sessionStorage.getItem("specialIndustry")),
            industry: sessionStorage.getItem("industry"),
            company: sessionStorage.getItem("company"),
            interest: sessionStorage.getItem("interest"),
            theme: isHoliday(),
            personalizationOpen: false,
            lastChangedOption: sessionStorage.getItem("lastChangedOption") || "theme",

            accountmanager: null,
            designer: null,
            designercopy: null,
            developer: null,
            internship: null,
            qaanalyst: null,
            openings: null,

            AMByVid: false,
            AMfirstName: undefined,
            AMlastName: undefined,
            AMemail: undefined,
            AMext: undefined,

            updateName: this.updateName.bind(this),
            updateWhatBrings: this.updateWhatBrings.bind(this),
            updateIndustry: this.updateIndustry.bind(this),
            updateCompany: this.updateCompany.bind(this),
            updateInterest: this.updateInterest.bind(this),
            updateTheme: this.updateTheme.bind(this),
            setRedacted: this.setRedacted.bind(this),
            updateCardExpanded: this.updateCardExpanded.bind(this),
            updateCompanyAutofill: this.updateCompanyAutofill.bind(this),
            setPersonalizationOpen: this.setPersonalizationOpen.bind(this),

            updateAMContact: this.updateAMContact.bind(this),
            updateCurrentPage: this.updateCurrentPage.bind(this),

            trackPage: this.trackPage.bind(this),
            currentPage: null,
            currentPath: null,
            cardExpanded: false,
            getOpenCareers: this.getOpenCareers.bind(this),
        };
    }

    componentDidMount() {
        this.getOpenCareers(this);

        getPersonalization()
            .then((results) => {
                this.trackReady = true;
                this.trackQueue.forEach((pageName) => trackPage(pageName));

                if (results && results.industry) {
                    const industry = HS_INDUSTRY_MAP[results.industry] || "";
                    this.updateIndustry(industry, false);
                }

                if (results && typeof results === "object" && results.owner) {
                    if (AM_BY_EMAIL[results.owner.email]) {
                        this.updateAMContact(AM_BY_EMAIL[results.owner.email]);
                    } else {
                        this.updateAMContact(results.owner);
                    }
                } else {
                    throw new Error("Bad response");
                }
            })
            .catch(() => {
                this.trackReady = true;
                this.trackQueue.forEach((pageName) => trackPage(pageName));

                let amInfo;
                try {
                    amInfo = JSON.parse(sessionStorage.getItem("AMInfo"));
                } catch (e) {
                    amInfo = {};
                }
                this.updateAMContact(amInfo);
            });
    }

    setPersonalizationOpen(isOpen) {
        this.setState({
            personalizationOpen: isOpen,
        });
    }

    updateCurrentPage(trackingTitle, path) {
        this.setState({
            currentPage: trackingTitle,
            currentPath: path,
        });
    }

    updateAMContact(amInfo) {
        if (this.state.AMByVid) return;

        sessionStorage.setItem("AMInfo", JSON.stringify(amInfo));
        if (amInfo) {
            this.setState({
                AMfirstName: amInfo.firstName,
                AMlastName: amInfo.lastName,
                AMemail: amInfo.email,
                AMext: amInfo.ext,
            });
        } else {
            this.setState({
                AMfirstName: undefined,
                AMlastName: undefined,
                AMemail: undefined,
                AMext: undefined,
            });
        }
    }

    updateName(e) {
        this.setState({
            name: e.target.value,
        });
    }
    updateWhatBrings(e) {
        this.setState({
            whatBrings: e.target.value,
        });
    }
    updateIndustry(e, updateLastChanged = true) {
        const v = typeof e === "string" ? e : e.target.value;

        this.setState({
            industry: v,
        });
        sessionStorage.setItem("industry", v);
        if (v == "financial" || v == "pharma") {
            this.setState({
                specialIndustry: true,
            });
            sessionStorage.setItem("specialIndustry", true);
        } else {
            this.setState({ specialIndustry: false });
            sessionStorage.setItem("specialIndustry", false);
        }
        if (updateLastChanged) {
            this.setState({
                lastChangedOption: "industry",
            });
            sessionStorage.setItem("lastChangedOption", "industry");
        }
    }

    updateCompany(e) {
        this.setState({
            company: e.target.value,
        });
    }

    updateCompanyAutofill(e) {
        this.setState({
            company: e,
        });
    }

    updateInterest(e) {
        this.setState({
            interest: e.target.value,
        });
    }

    updateTheme(e) {
        this.setState({
            theme: e.target.value,
            lastChangedOption: "theme",
        });
        sessionStorage.setItem("lastChangedOption", "theme");
    }

    updateCardExpanded(e) {
        if (e) {
            this.setState({
                cardExpanded: true,
            });
        } else {
            this.setState({
                cardExpanded: false,
            });
        }
    }

    setRedacted(lastTheme) {
        if (lastTheme === "redacted") {
            this.setState({
                theme: "default",
            });
        } else if (lastTheme) {
            this.setState({
                theme: lastTheme,
            });
        } else {
            this.setState({
                theme: "redacted",
            });
        }
    }

    trackPage(pageName) {
        if (!this.trackReady) {
            this.trackQueue = [...this.trackQueue, pageName];
        } else {
            trackPage(pageName);
        }
    }

    getOpenCareers(that) {
        var json = new XMLHttpRequest();
        json.open("GET", "./site_assets/data/open-careers.json");
        json.onload = function () {
            var data = JSON.parse(json.responseText);
            var openings = 0;
            for (var career in data) {
                if (data[career]) {
                    openings++;
                }
            }
            that.setState({
                accountmanager: data["accountmanager"],
                designer: data["designer"],
                designercopy: data["designercopy"],
                developer: data["developer"],
                internship: data["internship"],
                qaanalyst: data["qaanalyst"],
                openings: openings,
            });
        };
        json.send();
    }

    render() {
        return (
            <div>
                <CustomizationContext.Provider value={this.state}>{this.props.children}</CustomizationContext.Provider>
            </div>
        );
    }
}

export const CustomizationConsumer = CustomizationContext.Consumer;
