
import {Component, createElement, cloneElement, findDOMNode, createRef, Fragment, Children} from "rmlibrary/comp";
import RMTween from "rmlibrary/tween";
import {DomTween} from "rmlibrary/dom-tween";

const ANIMATE_IN = "ANIMATE_IN";
const ANIMATE_MOVE = "ANIMATE_MOVE";

const TRANSFORM_EASE = RMTween.cubicBezier(0.4, 0, 0.2, 1);

export class GridAnimation extends Component {

    constructor(props) {
        super(props);

        this.refMap = {};
        this.lastKeys = [];

        this.performAnimations = this.performAnimations.bind(this);
    }

    getRect(domRef) {
        if (!domRef || !domRef.current) return null;
        const domNode = findDOMNode(domRef.current); //eslint-disable-line react/no-find-dom-node
        const rect = domNode.getBoundingClientRect();
        return {
            top: rect.top,
            left: rect.left
        };
    }

    performAnimations(animations) {
        RMTween.parallel(
            ...animations.map((a) => {
                const domRef = this.refMap[a.key].current;
                if (!domRef) return;
                const domNode = findDOMNode(domRef); //eslint-disable-line react/no-find-dom-node
                if (a.animation === ANIMATE_IN) {
                    return DomTween(domNode, {opacity: 0, scaleX: 0.001, scaleY: 0.001}).to({opacity: 1, scaleX: 1, scaleY: 1}, 250, TRANSFORM_EASE);
                }
                if (a.animation === ANIMATE_MOVE) {
                    const newRect = domNode.getBoundingClientRect();
                    const x = a.rect.left - newRect.left;
                    const y = a.rect.top - newRect.top;
                    return DomTween(domNode, {x: x, y: y}).to({x: 0, y: 0}, 250, TRANSFORM_EASE);
                }
            }).filter((v) => v)
        ).run();
    }

    getSnapshotBeforeUpdate() {
        return this.props.children.map((c) => this.getRect(this.refMap[c.key]));
    }

    getAnimations(rects) {
        const oldKeys = this.lastKeys;
        const children = this.props.children;

        const animations = children.map((c, i) => {
            if (!rects || !rects[i]) {
                return {
                    key: c.key,
                    animation: ANIMATE_IN
                }
            } else if (oldKeys[i] !== c.key) {
                return {
                    key: c.key,
                    animation: ANIMATE_MOVE,
                    rect: rects[i]
                }
            }
        }).filter(c => c);

        this.lastKeys = children.map((c) => c.key);

        return animations;
    }

    componentDidMount() {
        this.performAnimations(this.getAnimations())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.performAnimations(this.getAnimations(snapshot))
    }

    render() {
        return (<Fragment>
            { Children.map(this.props.children, (c) => {
                const ref = this.refMap[c.key] = this.refMap[c.key] || createRef();
                return cloneElement(c, {ref: ref })
            }) }
        </Fragment>)
    }
}
