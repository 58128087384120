<import name="Collapsible" from="rmlibrary/collapsible" />
<import name="LearnMoreButton" from="../../common/learn-more-button.html" />

<style lang="scss">
    @import 'sass/variables';

    @mixin banner-img($url) {
        background-image: url($url);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .img-1 {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-1.jpg');
        @media #{$xs-only, $sm-only} {
            @include banner-img('images/banner-bgs/future-tech-banner-bg-1-mobile.jpg');
            background-position: 100% 75%;
        }
        &-halloween {
            @include banner-img('images/banner-bgs/halloween-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/halloween-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-pride {
            @include banner-img('images/banner-bgs/pride-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/pride-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-xmas {
            @include banner-img('images/banner-bgs/xmas-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/xmas-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-canadaday {
            @include banner-img('images/banner-bgs/canada-future-tech-banner-bg-1.png');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/canada-future-tech-banner-bg-1-mobile.png');
                background-position: 100% 75%;
            }
        }
    }

    .img-2 {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-2.jpg');
        @media #{$xs-only, $sm-only} {
            @include banner-img('images/banner-bgs/future-tech-banner-bg-2-mobile.jpg');
        }
    }

    .img-smart-watch {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-3.jpg');
    }

    .future {
        overflow: hidden;
    }

    .smart-watch-view {
        width: 300px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4%;
        overflow: hidden;
        @media #{$xl-only} {
            left: 7%;
        }
        @media #{$lg-only} {
            left: 2%;
        }
        @media #{$md-only} {
            left: -7%;
        }
        @media #{$xs-only, $sm-only} {
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            top: auto;
        }
        .smart-watch {
            width: 100%;
            transform: scale(1.5) translateY(-100%);
            transition: transform 1s ease;

            @media #{$xs-only, $sm-only} {
                transform: scale(1.5) rotate(-55deg) translate(9%, -70%);
                width: 300px;
            }
        }

        &.smart-watch-shown .smart-watch {
            transform: scale(1.5) translateY(-8%);

            @media #{$xs-only, $sm-only} {
                transform: scale(1.5) rotate(-55deg) translate(9%, 6%);
            }
        }
    }

    .gallery {
        width: 100%;
        height: 625px;
        @media #{$md-only} {
            height: 550px;
        }
    }

    .banner-future {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        max-width: 1100px;

        .text {
            color: $black;
            text-align: center;
            max-width: 520px;
            margin: 0 auto;
            display: block;
            @media #{$xs-only} {
                padding-top: 40px;
            }
            .copy {
                color: $black;
                margin-bottom: 30px;
                margin-top: 20px;
            }
            .title {
                padding-top: 50px;
                @media #{$sm-only} {
                    padding-top: 30px;
                }
                @media #{$lg-up} {
                    padding-top: 130px;
                }
            }
        }
        .device {
            text-align: center;
            padding-bottom: 35px;
            img {
                width: 100%;
            }
        }
        .buttons {
            padding-bottom: 258px;
            text-align: center;
            .yellow-button {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
            }
            .selected {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
            }
        }
    }

    .content {
        @media #{$lg-up} {
            max-width: none;
            width: 1100px;
        }
    }

    .controls {
        width: 100%;
        padding: 0 20px 30px;
        display: block;
        text-align: center;
        position: absolute;
        bottom: 0;
        .dots {
            width: 14px;
            height: 14px;
            border: 1px solid $black;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            border: 1px solid $white;
            background-color: $black;
            @media #{$sm-only, $xs-only} {
                width: 10px;
                height: 10px;
                margin: 4px;
            }
        }

        .selected-dot {
            background-color: $white;
            border: 1px solid $black;
        }
    }

    .learn-more {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            .item {
                width: 22%;
                text-align: center;
                @media #{$md-only, $sm-only, $xs-only} {
                    width: 50%;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                }
            }
            p {
                -webkit-animation: fadeIn 1s 0.2s both;
                -moz-animation: fadeIn 1s 0.2s both;
                -ms-animation: fadeIn 1s 0.2s both;
                animation-delay: 0.8s;
            }
            .item-top {
                @media #{$md-only, $sm-only, $xs-only} {
                    padding-bottom: 20px;
                }
            }
            .item-bottom {
                @media #{$md-only, $sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$md-only, $sm-only, $xs-only} {
                    display: none;
                }
            }
            .line-mobile {
                @media #{$xs-only, $sm-only, $md-only} {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

    .learn-more-pharma {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        @media #{$sm-only, $xs-only} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .learn-more-content-pharma {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            @media #{$sm-only, $xs-only} {
                align-items: inherit;
            }
            .item {
                width: 31%;
                text-align: center;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 50%;
                    font-size: 14px;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        width: 60px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    border-bottom: 1px solid $black90;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $black90;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .second-row {
                padding-top: 50px;
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
            .line2 {
                margin-top: 50px;
            }
            .no-line {
                @media #{$sm-only, $xs-only} {
                    border: none;
                }
            }
            .lines {
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
                .line-horizontal {
                    width: 33%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 50px;
                    max-width: 280px;
                    margin-right: 30px;
                    margin-left: 30px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content-pharma {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

</style>

<template name="FutureTech__" extends="FutureTechController">
    <div role="group" aria-labelledby="lbl-future-tech" class="chevron-scroll-anchor">
    <div class="banner future" style="position: relative">

        <div class="gallery">
            <GalleryCarousel draggable autoScroll="6000" callbefore="{this.galleryCallbefore}" ref="{carousel => this.gCarousel = carousel}">
                <div class="img-1"
                    class.img-1-halloween="{this.props.siteTheme === 'halloween'}"
                    class.img-1-pride="{this.props.siteTheme === 'pride'}"
                    class.img-1-canadaday="{this.props.siteTheme === 'canadaday'}"
                    class.img-1-xmas="{this.props.siteTheme === 'xmas' || this.props.siteTheme === 'new-year'}"
                ></div>
                <div class="img-smart-watch"></div>
                <div class="img-2"></div>
            </GalleryCarousel>
        </div>
        <div class="{'smart-watch-view ' + this.state.smartWatchClasses }" style.pointerEvents="none">
            <img class="smart-watch" draggable="false" src="site_assets/images/banner-bgs/future-tech-device-3.png" alt="smart watch" aria-label="smart watch">
        </div>
        <div class="content banner-future" style.pointerEvents="none">
            <div class="text">
                <If condition="{!this.props.specialIndustry}">
                    <div>
                        <h2 id="lbl-future-tech" class="title">Future Tech</h2>
                        <p class="copy"><span class="redact2">Get in on the ground floor of</span> the next big thing <span class="redact2">with cutting-edge technology and interfaces.</span></p>
                    </div>
                </If>
                <If condition="{this.props.specialIndustry}">
                    <div>
                        <If condition="{this.props.industry == 'financial'}">
                            <div>
                                <h2 id="lbl-future-tech" class="title">Fintech</h2>
                                <p class="copy"><span class="redact2">Keep ahead of Fintech with</span> cutting-edge technology and interfaces.</p>
                            </div>
                        </If>
                        <If condition="{this.props.industry == 'pharma'}">
                            <div>
                                <h2 id="lbl-future-tech" class="title">Future Tech</h2>
                                <p class="copy"><span class="redact2">Go beyond the ordinary with </span> wearables, voice interfaces, and more cutting edge technology.</p>
                            </div>
                        </If>
                    </div>
                </If>
                <div class="buttons">
                    <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                </div>
            </div>
        </div>

        <div class="controls">
            <For var="card" of="{this.state.galleryImages}">
                <button key="{index}" onClick="{() => {this.indicatorsRedirect(card, index)}}"
                        class="dots" class.selected-dot="{index == this.state.index}"
                        ref="{btn => index == this.state.index ? this.selectedBtn = btn : ''}" aria-label="{'Slide ' + (index + 1)}"></button>
            </For>
        </div>
    </div>
    <Collapsible open={this.state.isOpen} duration="250" ease="easeInOut" onOpened={this.accordionOpened}>
        <If condition="{this.props.industry != 'pharma'}">
            <div class="learn-more" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
                <div class="learn-more-content">
                    <div class="item item-top">
                        <img src="{`site_assets/images/icons/SVG/voice-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-voice-icon"/>
                        <p id="future-voice-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Voice assistants</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item item-top">
                        <img src="{`site_assets/images/icons/SVG/wearables-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-wearables-icon"/>
                        <p id="future-wearables-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Wearables</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="line-mobile"></div>
                    <div class="item item-bottom">
                        <img src="{`site_assets/images/icons/SVG/IOT-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-iot-icon"/>
                        <p id="future-iot-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Internet of Things (IoT)</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item item-bottom">
                        <img src="{`site_assets/images/icons/SVG/kiosk-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-kiosk-icon"/>
                        <p id="future-kiosk-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Interactive kiosk displays</span></p>
                    </div>
                </div>
            </div>
        </If>
        <If condition="{this.props.industry == 'pharma'}">
            <div class="learn-more-pharma" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
                <div class="learn-more-content-pharma">
                    <div class="item item-line">
                        <img src="{`site_assets/images/icons/SVG/voice-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-voice-icon"/>
                        <p id="future-voice-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Voice assistants</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item item-line">
                        <img src="{`site_assets/images/icons/SVG/wearables-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-wearables-icon"/>
                        <p id="future-wearables-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Wearables</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item item3">
                        <img src="{`site_assets/images/icons/SVG/IOT-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-iot-icon"/>
                        <p id="future-iot-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Internet of Things (IoT)</span></p>
                    </div>

                    <div class="lines">
                        <div class="line-horizontal"></div>
                        <div class="line-horizontal"></div>
                        <div class="line-horizontal"></div>
                    </div>

                    <div class="item item-line second-row">
                        <img src="{`site_assets/images/icons/SVG/3danimation-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-kiosk-icon"/>
                        <p id="future-kiosk-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">3D Animation</span></p>
                    </div>
                    <div class="line line2"></div>
                    <div class="item item-line second-row no-line">
                        <img src="{`site_assets/images/icons/SVG/VR-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-kiosk-icon"/>
                        <p id="future-kiosk-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Augmented Reality &amp; VR</span></p>
                    </div>
                    <div class="line line2"></div>
                    <div class="item item3 second-row no-line">
                        <img src="{`site_assets/images/icons/SVG/kiosk-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="future-kiosk-icon"/>
                        <p id="future-kiosk-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Interactive kiosk displays</span></p>
                    </div>
                </div>
            </div>
        </If>
    </Collapsible>
    </div>
</template>

<script>
    import {
        GalleryCarousel
    } from 'rmlibrary/carousel/gallery-carousel';
    import {
        findDOMNode,
        createRef
    } from "rmlibrary/comp";
    import {
        scrollIntoView
    } from "rmlibrary/react-scroll-into-view";

    import {CustomizationConsumer} from '../../common/customization';
    import {trackLearnMore, trackPortfolio} from '../../../tracking';

    export function FutureTech(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <FutureTech__ {...props}
                siteTheme={customizationState.theme}
                specialIndustry={customizationState.specialIndustry}
                industry={customizationState.industry}
            /> }
        </CustomizationConsumer>)
    }
    FutureTech.PASS_COLOUR = true;

    class FutureTechController extends Component {
        constructor(props) {
            super(props);

            this.scrollRef = createRef();

            this.state = {
                smartWatchClasses: "",
                index: 0,
                galleryImages: [],
                isOpen: false
            }

            this.jumpingToCard = false;

            this.galleryCallbefore = this.galleryCallbefore.bind(this);
            this.toggleAccordion = this.toggleAccordion.bind(this);
            this.accordionOpened = this.accordionOpened.bind(this);
        }

        componentDidMount() {
            this.setState({
                galleryImages: this.gCarousel.props.children
            });
        }

        indicatorsRedirect(card, index) {
            if (!this.gCarousel.isBusy()) {
                this.setState({
                    index
                });
                this.jumpingToCard = true;
                this.gCarousel.jumpToCard(card, index);
            } else this.selectedBtn.focus();
        }

        galleryCallbefore(increment) {
            if (increment != 0) {
                let scrollIndex = (this.state.index + increment + this.state.galleryImages.length) % this.state.galleryImages.length;

                let nextIndex = this.jumpingToCard ? this.gCarousel.getIndex() : scrollIndex;

                let nextImg = this.gCarousel.getImageByIndex(nextIndex);

                if (nextImg.props.className === "img-smart-watch") this.setState({
                    smartWatchClasses: "smart-watch-shown"
                });
                else this.setState({
                    smartWatchClasses: ""
                });

                this.setState({
                    index: nextIndex
                });
                this.jumpingToCard = false;
            }
        }

        toggleAccordion() {
            if (!this.state.isOpen) {
                trackLearnMore("future-tech", "Future Tech");
            }
            this.setState({
                isOpen: !this.state.isOpen
            });
        }

        accordionOpened() {
            if (!this.scrollRef.current) return;
            let offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(this.scrollRef.current, {
                block: 'center',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }
    }

</script>
