<template name="BannerSelect">
    <div style="margin: 1em 0;">
        <select data-index="{this.props.index}" onChange={this.props.onChange} style="border: 1px solid black;" value="{this.props.value}">
            <option value="-1">Select a banner</option>
            <For var="option" of="{BANNER_OPTIONS}"><option value="{option.value}">{option.text}</option></For>
        </select>
        <If c={!!this.props.onDelete}>
            <button data-index="{this.props.index}" onClick={this.props.onDelete} style="border:1px solid black;padding: 1px 5px;margin-left:5px">X</button>
        </If>
    </div>
</template>
<template name="DebugSiteOrder" extends="DebugSiteOrderController">
    <div style="display:flex;">
        <div style="padding:2em;">
            <h2>Order Banners:</h2>
            <div>
                <For var="item" of="{this.state.order}" index="i">
                    <BannerSelect key={i} index={i} onChange={this.selectChanged} value="{item}" onDelete={this.handleDelete} />
                </For>
                <BannerSelect index={this.state.order.length} onChange={this.selectChanged} value="-1" />
                <div style="margin: 10px 0;">
                    <button onClick={this.resetOrder} style="border:1px solid black;padding: 1px 5px;margin-left:5px">Reset Order</button>
                </div>
            </div>
        </div>
        <div style="padding:2em;">
            <h2>Other properties:</h2>
            <div style="margin: 10px 0;">
                <div><label for="prefix">Site URL:</label></div>
                <div><input id="prefix" type="text" value="{this.state.base_url}" onChange="{this.base_urlChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
            </div>
            <div style="margin: 10px 0;">
                <div><label for="hsid">HubSpot ID:</label></div>
                <div><input id="hsid" type="text" value="{this.state.hsid}" onChange="{this.hsidChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
            </div>
            <div style="margin: 10px 0;">
                <div><input name="r-industry" id="i-none" type="radio" checked="{!this.state.industry}" onChange="{this.industryChange}" value="{''}" /> <label for="i-none">none</label></div>
                <div><input name="r-industry" id="i-financial" type="radio" checked="{this.state.industry === 'f'}" onChange="{this.industryChange}" value="f" /> <label for="i-financial">Financial</label></div>
                <div><input name="r-industry" id="i-pharma" type="radio" checked="{this.state.industry === 'p'}" onChange="{this.industryChange}" value="p" /> <label for="i-pharma">Pharma</label></div>
                <div style="font-size:0.7em;">NOTE: this is only used if the HubSpot ID is not present.</div>
            </div>
            <h2>Google analytics:</h2>
            <div style="margin: 10px 0;">
                <div><label for="ga_source">Campaign Source (required for google):</label></div>
                <div><input id="ga_source" type="text" value="{this.state.ga_source}" onChange="{this.ga_sourceChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
                <div style="font-size:0.7em;">The referrer: (e.g. google, newsletter)</div>
            </div>
            <div style="margin: 10px 0;">
                <div><label for="ga_medium">Campaign Medium:</label></div>
                <div><input id="ga_medium" type="text" value="{this.state.ga_medium}" onChange="{this.ga_mediumChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
                <div style="font-size:0.7em;">Marketing medium: (e.g. cpc, banner, email)</div>
            </div>
            <div style="margin: 10px 0;">
                <div><label for="ga_name">Campaign Name:</label></div>
                <div><input id="ga_name" type="text" value="{this.state.ga_name}" onChange="{this.ga_nameChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
                <div style="font-size:0.7em;">Product, promo code, or slogan (e.g. spring_sale)</div>
            </div>
            <div style="margin: 10px 0;">
                <div><label for="ga_term">Campaign Term:</label></div>
                <div><input id="ga_term" type="text" value="{this.state.ga_term}" onChange="{this.ga_termChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
                <div style="font-size:0.7em;">Identify the paid keywords</div>
            </div>
            <div style="margin: 10px 0;">
                <div><label for="ga_content">Campaign Content:</label></div>
                <div><input id="ga_content" type="text" value="{this.state.ga_content}" onChange="{this.ga_contentChange}" style="border:1px solid black;padding: 1px 5px;width: 500px;" /></div>
                <div style="font-size:0.7em;">Use to differentiate ads</div>
            </div>
        </div>
        <div style="padding:2em;">
            <h2>Result:</h2>
            <div style="margin: 10px 0;">
                <div><input type="text" style="border:1px solid black;padding: 1px 5px;width: 800px;" value="{this.state.link}" onFocus="{this.focusHandler}" readonly /></div>
                <a href="{this.state.link}" target="_blank">Link</a>
            </div>
        </div>
    </div>
</template>
<script>

import {BANNER_OPTIONS,getBannerIndexes, encodeParam} from '../common/banner-order';

const originalOrder = getBannerIndexes();

function arraysEqual(a, b) {
    if (a.length !== b.length) return false;
    return a.every((v1, i) => v1 === b[i]);
}

class DebugSiteOrderController extends Component {
    constructor() {
        this.state = {
            order: originalOrder.slice(),
            base_url: "http://www.richmedia.com/",
            hsid: "",
            industry: false,
            ga_source: "",
            ga_medium: "",
            ga_name: "",
            ga_term: "",
            ga_content: "",
        };

        this.state.link = this.getLink(this.state);

        this.selectChanged = this.selectChanged.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.base_urlChange = this.base_urlChange.bind(this);
        this.hsidChange = this.hsidChange.bind(this);
        this.industryChange = this.industryChange.bind(this);
        this.ga_sourceChange = this.ga_sourceChange.bind(this);
        this.ga_mediumChange = this.ga_mediumChange.bind(this);
        this.ga_nameChange = this.ga_nameChange.bind(this);
        this.ga_termChange = this.ga_termChange.bind(this);
        this.ga_contentChange = this.ga_contentChange.bind(this);

        this.focusHandler = this.focusHandler.bind(this);
        this.resetOrder = this.resetOrder.bind(this);
    }

    focusHandler(e) {
        e.target.select();
    }

    selectChanged(e) {
        const index = Number(e.target.dataset.index);
        const value = Number(e.target.value);
        const order = this.state.order
        if (index >= order.length) {
            this.setState({
                order: [...order, value]
            }, () => {
                this.setState({ link: this.getLink(this.state) });
            });
        } else {
            this.setState({
                order: order.map((v, i) => i === index ? value : v)
            }, () => {
                this.setState({ link: this.getLink(this.state) });
            });
        }
    }
    handleDelete(e) {
        const index = Number(e.target.dataset.index);
        const order = this.state.order
        this.setState({
            order: order.filter((v, i) => i !== index)
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        });
    }

    base_urlChange(e) {
        this.setState({
            base_url: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    hsidChange(e) {
        this.setState({
            hsid: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    industryChange(e) {
        this.setState({
            industry: e.target.value || false
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    ga_sourceChange(e) {
        this.setState({
            ga_source: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    ga_mediumChange(e) {
        this.setState({
            ga_medium: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    ga_nameChange(e) {
        this.setState({
            ga_name: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    ga_termChange(e) {
        this.setState({
            ga_term: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }
    ga_contentChange(e) {
        this.setState({
            ga_content: e.target.value
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        })
    }

    resetOrder() {
        this.setState({
            order: originalOrder.slice()
        }, () => {
            this.setState({ link: this.getLink(this.state) });
        });
    }

    getLink(state) {
        let params = [];
        if (!arraysEqual(originalOrder, state.order)) {
            params = [...params, `b=${encodeParam(state.order)}`];
        }
        if (state.hsid) {
            params = [...params, `vid2=${encodeURIComponent(state.hsid)}`]
        } else {
            if (state.industry) {
                params = [...params, `i=${state.industry}`]
            }
        }
        if (state.ga_source) {
            params = [...params, `utm_source=${encodeURIComponent(state.ga_source)}`]
        }
        if (state.ga_medium) {
            params = [...params, `utm_medium=${encodeURIComponent(state.ga_medium)}`]
        }
        if (state.ga_name) {
            params = [...params, `utm_campaign=${encodeURIComponent(state.ga_name)}`]
        }
        if (state.ga_term) {
            params = [...params, `utm_term=${encodeURIComponent(state.ga_term)}`]
        }
        if (state.ga_content) {
            params = [...params, `utm_content=${encodeURIComponent(state.ga_content)}`]
        }

        if (!params.length) return state.base_url;

        return `${state.base_url}?${params.join("&")}`;
    }
}
</script>
