<import name="FloatingCard" from="./banners/floating-card.html" />

<style lang="scss">
    @import 'sass/variables';

    .wrapper {
        background-color:$black90;
        .content {
            max-width: 1100px;
            display: block;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            padding-top:60px;
            padding-bottom:100px;
            h1 {
                font-size:30px;
                font-weight:bold;
                color:$yellow;
                margin-bottom:20px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            p {
                color:white;
                font-size:16px;
                line-height:22px;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            h2 {
                color:$yellow;
                font-size:20px;
                font-weight:bold;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            h3 {
                color:white;
                font-size:18px;
                font-weight:bold;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: 0.5s;
            }
            a {
                color:$yellow;
                text-decoration:none;
                transition:0.5s ease-in-out;
                &:hover {
                    color:white;
                }
            }
            .policy {
                margin-bottom:20px;
            }
            ul {
                margin-bottom:15px;
                margin-left:20px;
                li {
                    color:white;
                }
            }
            .contact {
                p {
                    margin:0;
                }
                a {
                    display:block;
                    margin-top:10px;
                }
            }
        }
    }

</style>

<template name="Privacy">
    <main>
        <div class="wrapper">
            <div class="content">
                <div class="policy">
                    <h1>Privacy Policy</h1>
                    <p>Rich Media respects individuals’ right to privacy. This policy summarizes what personally identifiable information it may collect, and how it might use this information. Rich Media’s policy is based on the privacy principles developed by the Canadian Standards Association. </p>
                </div>

                <div class="policy">
                    <h2>Rich Media General Policy on Privacy</h2>
                    <p>Protecting the privacy and confidentiality of our customers’ and employees’ personal information is an important aspect of Rich Media’s operations.</p>
                    <p>All Rich Media employees are responsible for following our Privacy Policy when collecting and handling the personal information of Rich Media customers or employees. Our employees are informed about the importance of privacy and receive ongoing updates on privacy related policies. In accordance with these policies, client information and any customer information provided by our client is kept confidential.</p>
                    <p>“Personal information” is defined as any piece of information, either factual or subjective, about an identifiable individual. Personal information does not include the name, title, business address or telephone number of an employee of an organization. Business contact information is maintained for business contact via phone, meeting and email.</p>
                    <p>It is Rich Media’s policy to obtain an individual’s or business’ consent when a Rich Media employee or website collects, uses or discloses personal information. Rich Media will also inform its customers and employees of the purpose for the collection, use and disclosure of personal information. Rich Media takes care to safeguard all personal information in its possession against loss, theft and unauthorized use or disclosure. </p>
                    <p>Rich Media takes reasonable efforts to ensure that such recorded information is accurate and up to date. While we do our best to ensure this, we do rely on clients to inform us of any relevant changes in their personal information. If any information requires changes, please contact us. </p>
                </div>

                <div class="policy">
                    <h2>Rich Media Internet Privacy Policy</h2>
                    <p>Rich Media respects clients and employees rights to privacy and is committed to the protection of personal information. This policy summarizes what personally identifiable information we may collect through our website, app, and email correspondence, how we might use it, and to whom we may disclose it. </p>
                </div>

                <div class="policy">
                    <h3>Information Collection</h3>
                    <p>Rich Media will only collect personally identifiable information (such as name, address, telephone number, or e-mail address) that is voluntarily provided through our web site, app, or e-mail correspondence. Rich Media will collect general information (such as the type of browser used, the files requested, and the domain name and country from which it was requested) to improve our Web site and better meet clients’ needs.</p>
                </div>

                <div class="policy">
                    <h3>Use and Disclosure of Information</h3>
                    <p>Before any personally identifiable information is collected, Rich Media will identify the purpose for its collection, use and disclosure. For instance, the information supplied can be used to respond to inquiries, supply requested information on Rich Media products, services, and employment opportunities. Rich Media does not collect personal information indiscriminately. Rather, we limit our collection of personal information to only what is necessary for the purpose we have identified. We employ fair and lawful means in the collection of personal information. </p>
                    <p>Rich Media will seek consent to use personal information at the time of collection or in advance. Consent may be expressed in writing, or implied, and in some cases may be provided verbally or electronically. With consent, authorized third parties may be utilized by Rich Media to collect, track and process such information. Personal information can be collected and used for research purposes – in these cases, responses are taken in aggregate so that individual responses remain anonymous. </p>
                    <p>If a user has subscribed to electronic newsletters and wishes to unsubscribe, the user may click the unsubscribe link that appears at the bottom of every email. If a user has submitted personal information to Rich Media electronically and would like to have it removed or modified, the user can contact Rich Media.</p>
                    <p>Please note that should a requestor choose not to provide Rich Media with the necessary personal information, Rich Media may be unable to service the request for Rich Media products or information. We will use all reasonable efforts to comply with the request.</p>
                </div>

                <div class="policy">
                    <h3>Rich Media App Permissions</h3>
                    <p>The Rich Media app requires access to the user’s identity and contacts as a means of sending notifications to the user. Only the bare minimum, impersonalized information about user identities is taken, and is stored securely, in keeping with our policies. We don’t allow unauthorized publishing or disclosure of people’s non-public contacts.</p>
                </div>

                <div class="policy">
                    <h3>Other Important Information </h3>
                    <p>The Rich Media web site and app may contain links to other sites. We do not endorse or otherwise accept responsibility for the content or privacy policies of those sites. Additionally, some of our web pages may contain “cookies,” which are data that may be sent to users’ web browser and stored on computers. This allows our server to “recognize” users for future website visits. Most web browsers can be configured to not accept cookies, or to notify users if a cookie is sent. </p>
                </div>

                <div class="policy">
                    <h2>Rich Media Privacy and Data Security Code</h2>
                    <p>The Rich Media Privacy and Data Security Code is a formal statement of the principles and guidelines concerning the requirements for the protection of information and data provided to Rich Media by its customers and employees. Rich Media strives to provide its customers with the best service possible. This means handling personal information and data fairly, honestly, and securely.</p>
                    <p>The purpose of the Rich Media Privacy and Data Security Code is to provide responsible and transparent practices in its management of personal information in keeping with applicable legislation. It outlines how we protect the privacy and the confidentiality of personal information.</p>
                </div>

                <div class="policy">
                    <h3>Client-Provided Information</h3>
                    <p>From time to time Rich Media will receive data from clients that include types of business data on the client’s employees or their customers. Rich Media adheres to its privacy code as well as the processes requested by clients in handling this data, including encrypting the data if necessary. If private data has been sent in error, Rich Media deletes it. </p>
                    <p>When we hire other organizations to provide support services, we will require them to conform to our privacy standards. In the case of marketing research projects, personal information and contact details may be retained in order to follow up with participants. We will retain personal information only for as long as required to fulfil the identified purpose. Once personal information is no longer required, it is destroyed or made anonymous. </p>
                </div>

                <div class="policy">
                    <h2>Data Security</h2>
                    <p>Rich Media employs appropriate safeguards to make certain that personal information and data are protected from loss, theft, and unauthorized access or disclosure. </p>
                    <p>Information and data hosted by Rich Media is protected using the same high standards that govern Rich Media’s information handling practices. The nature of the safeguards and level of protection will depend on the sensitivity of the information in question. These safeguards include physical security measures (secure file cabinets and server rooms), organizational controls (security cameras building security, including check-in for all visitors), and technological tools (computers and servers are password-protected, sensitive data is encrypted, and database access is restricted and secured).</p>
                    <p>In some cases, Rich Media employs other companies to provide services such as web hosting or cloud-hosted software. When this occurs, Rich Media ensures that these suppliers meet our own security policies.</p>
                </div>

                <div class="policy">
                    <h3>Providing Access</h3>
                    <p>Rich Media will provide access to the information it retains about clients upon receipt of a written request and satisfactory identification. We may charge a nominal fee and, if so, give notice in advance of costs for processing the request. Upon written request and satisfactory identification, we will also correct or amend any personal information if its accuracy and completeness is found to be deficient. There are some instances where Rich Media will not be able to provide an individual with the requested information. Some of these instances include where the information:</p>
                    <ul>
                        <li>consists of references to other persons that cannot be otherwise severed;</li>
                        <li>contains confidential Rich Media proprietary information;</li>
                        <li>has already been destroyed due to legal requirements, (limited time of use).</li>
                    </ul>
                    <p>If Rich Media is unable to provide the information requested, the reason for the refusal will be explained. </p>
                </div>

                <div class="policy">
                    <h2>Changes to Privacy Policy</h2>
                    <p>Rich Media reserves the right to modify this privacy policy at any time.</p>
                </div>

                <div class="policy">
                    <h2>Providing Recourse</h2>
                    <p>At any time a formal privacy inquiry can be initiated by contacting Rich Media Management. Any information provided will be used for the purpose of resolving questions or concerns. Any questions or concerns about the privacy of personal information or our Privacy Code, please contact Rich Media management at 416-406-6545.</p>
                </div>

                <div class="policy contact">
                    <p>Email or write to:</p>
                    <a href="mailto:info@richmedia.com">info@richmedia.com</a>
                    <p>Rich Media</p>
                    <p>Suite 501</p>
                    <p>1090 Don Mills Rd.</p>
                    <p>Toronto, ON M3C 3R6</p>
                </div>
            </div>
        </div>

    </main>

</template>
