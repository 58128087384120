<!-- Import other components used here -->


<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .wrapper {
        background-color: $black90;
    }

    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
        padding-bottom: 100px;

        @media #{$xs-only, $sm-only} {
            padding-top: 20px;
        }

        .two-columns {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .column {
                width: 50%;

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                }

                h1 {
                    font-size: 60px;
                    font-weight: bold;
                    color: $yellow;
                    max-width: 200px;
                    margin-bottom: 20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;

                    @media #{$sm-only} {
                        font-size: 29px;
                    }

                    @media #{$md-only} {
                        font-size: 45px;
                    }

                    @media #{$xs-only, $sm-only} {
                        font-size: 35px;
                        max-width: none;
                    }
                }

                .job-details {
                    display: flex;
                    width: 100%;

                    .icon {
                        width: 20%;
                        -webkit-animation: pop-in 0.5s;
                        -moz-animation: pop-in 0.5s;
                        -ms-animation: pop-in 0.5s;

                        @media #{$xs-only, $sm-only} {
                            width: 12%;
                        }
                    }

                    .details {
                        width: 80%;

                        @media #{$xs-only, $sm-only, $md-only} {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .column1 {
                width: 40%;
                max-width: 400px;
                margin-right: 90px;

                @media #{$sm-only} {
                    margin-right: 20px;
                }

                @media #{$md-only} {
                    margin-right: 55px;
                }

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                    margin-right: 0;
                }
            }

            .column2 p {
                margin-bottom: 10px;
            }

            .column2 img {
                @media #{$sm-only, $xs-only} {
                    max-width: 50px;
                    width: 100%;
                }
            }
        }
    }

    .desktop {
        display: none;

        @media #{$md-up} {
            display: inline-block;
        }
    }

    h2 {
        color: $yellow;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    p {
        color: white;
        font-size: 16px;
        line-height: 21px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .yellow-button {
        margin-top: 30px;
        margin-left: 0;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .divider {
        height: 1px;
        background-color: $yellow;
        width: 90%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .apply-mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: contents;
        }
    }

    .mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: table;
        }
    }
</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->

<template name="AccountManager" extends="AccountManagerPageController">
    <main>
        <div class="wrapper">
            <div class="content">
                <div class="two-columns">
                    <div class="column column1">
                        <h1>Digital Marketing Sales</h1>
                        <p>{this.state.position}</p><br>
                        <p>This is a full-time in-office position.</p>
                        <div class="divider"></div>
                        
                        <h2>Company Description:</h2>
                        <p>{this.state.company}</p>
                        <div class="divider"></div>

                        <h2 class="desktop">For interested applicants:</h2>
                        <p class="apply desktop">Please provide a copy of your resume and university or college transcript.</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying for Digital Marketing Sales&body=Please provide a copy of your resume and university or college transcript. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button desktop">Apply<span class="chevron right"></span></a>
                    </div>
                    <div class="column column2">
                        <div class="job-details">
                            <div class="icon">
                                <img src="site_assets/images/icons/SVG/responsibilities-icon-yellow.svg" alt="Responsibilities" width="72" height="72" />
                            </div>
                            <div class="details">
                                <h2>The main responsibilities are as follows:</h2>
                                <For var="responsibility" of="{this.state.responsibilities}" index="index">
                                    <p key={index}>{responsibility}</p>
                                </For>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="job-details">
                            <div class="icon">
                                <img src="site_assets/images/icons/SVG/skills-icon-yellow.svg" alt="Desired Skills" width="72" height="72" />
                            </div>
                            <div class="details">
                                <h2>Desired Skills:</h2>
                                <For var="requirement" of="{this.state.requirements}" index="index">
                                    <p key={index}>{requirement}</p>
                                </For>
                            </div>
                        </div>
                    </div>

                    <div class="divider mobile"></div>

                    <div class="mobile apply-mobile">
                        <h2 class="mobile">For interested applicants:</h2>
                        <p class="apply mobile">Please provide a copy of your resume and university or college transcript.</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying for Digital Marketing Sales&body=Please provide a copy of your resume and university or college transcript. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button mobile">Apply<span class="chevron right"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    class AccountManagerPageController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                "position": "Rich Media is looking for someone with exceptional communication skills who is passionate about sales. You're excited about working with financial clients to create digital videos, animation, websites, and other marketing initiatives for their online presence. You’re very outgoing, you have a good understanding of financial concepts, you love to share ideas and be around creative people, and you strive to provide the best possible service to your clients.",
                "company": "Rich Media is an award-winning Toronto based digital agency producing many of North America's leading digital projects. Our services include web sites, mobile, tools, demos, social marketing, animation, print, games, and video. Some of our clients include Samsung Electronics, CIBC, Manulife, Scotiabank, BMO Financial and Sun Life. Rich Media has garnered a reputation for taking leading edge technology, combining it with strong creative talents and developing digital firsts.",
                "responsibilities": [
                    "Communicate with confidence, and provide recommendations and support to clients, with a high level of responsiveness.",
                    "Actively seek new business through emails or phone with current and new clients",
                    "Work in conjunction with project managers, designers, and developers",
                    "Accurately convey the client vision to the rest of the project team"
                ],
                "requirements": [
                    "University/college degree in a related field",
                    "Previous experience in an account manager role",
                    "Strong knowledge of digital marketing",
                    "Outstanding English verbal and written communication skills",
                    "Strong knowledge of financial products (education or work experience)",
                    "Able to effectively sell a creative idea and a technical product",
                    "Energetic, motivated, positive attitude",
                    "Able to take initiative and seek out sales opportunities ",
                    "Ability to have fun with our pool table, pin ball machine, air hockey table, and regular celebration events"
                ]
                };
        }

        componentDidMount() {
            const { hostname } = location;
            const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

            let baseURL = "";
            switch (hostname) {
                case "localhost":
                case "127.0.0.1": {
                    break;
                }

                case "richmediawireless.com": {
                    baseURL = "www.richmediawireless.com/dev/rm/job-posting";
                    break;
                }

                default: {
                    baseURL = "www.richmedia.com";
                    break;
                }
            }

            if (!isLocalhost && hostname !== "richmediawireless.com") {
                fetch(`https://${baseURL}/api/jobpostings.php?position_title=accountmanager`)
                .then(result => result.json())
                .then(result => this.setState({ ...result }));
            }
        }
    }
</script>