import {createElement, joinClassNames} from "rmlibrary/comp";

export function PlayButton(props) {
    const {show, size, color, ...otherProps} = props;
    const _size = size || 50;
    const _color = color || "rgba(255,196,37, 0.3)";
    const randomID = `svg_${Math.random().toString(36).substring(7)}`;

    return (
        <div {...otherProps}
            className={joinClassNames("rm-play-btn", show ? 'show' : undefined, props.className)}
        >
            <svg width={_size} height={_size} viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" strokeWidth="7" stroke={_color} fill="none" />
                <path id={randomID} d="M33,25l40,25l-40,25z" strokeWidth="0" stroke="#000" fill={_color} />
            </svg>
        </div>
    );
}
