<style lang="scss">
    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: #ffc425;
        font-size: 100px;
        font-weight: 600;
        padding-bottom: 20px;
        text-align: center;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        padding-bottom: 20px;
        text-align: center;
        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    h3 {
        color: white;
        font-size: 30px;
        padding-bottom: 30px;
        font-weight: normal;
    }
    .h3-yellow {
        color: $yellow;
        font-weight: bold;
    }

    .btn:hover {
        background-color: white;
        color: #1a1a1a;
    }

    .main-container {
        overflow-x: hidden;
    }
</style>

<template name="SurveyLanding" extends="SurveyLandingController">
    <main class="main-container">
        <div class="text-container info-pulled">
            <h1 ref="{this.focusElement}">{this.props.title}</h1>

            <h2>{this.props.intro}</h2>
            <h3><span class="h3-yellow">{this.props.score}</span> {this.props.scoreRange}</h3>

            <button class="btn" onClick="{() => this.props.onSubmit()}">
                <p>{this.props.btnTxt}</p>
            </button>
        </div>
        <div class="text-container error-vid">
            <h1>We're sorry!</h1>
            <h2>Something went wrong!</h2>
        </div>
    </main>
</template>

<script>
    class SurveyLandingController extends Component {
        constructor(props) {
            super(props);

            this.showHideSections = this.showHideSections.bind(this);
            this.focusElement = React.createRef();
        }

        // sets the page focus on the h1 element and scroll to below the nav
        componentDidMount() {
            this.showHideSections();
            window.scrollTo(0, 0);
        }
        
        componentDidUpdate() {
            this.showHideSections();
            window.scrollTo(0, 0);
        }

        showHideSections() {
            if (this.props.error) {
                document.querySelector(".info-pulled").style.display = "none";
                document.querySelector(".error-vid").style.display = "block";
                // this.focusHeader.current.focus();
            } else {
                document.querySelector(".error-vid").style.display = "none";
                document.querySelector(".info-pulled").style.display = "block";
                this.focusElement.current.focus();
            }
        }
    }

    SurveyLandingController.defaultProps = {
        title: "Hi John!",
        intro: "By taking this survey, you're helping us improve the service we provide to you.",
        score: "Score:",
        scoreRange: "1 lowest, 6 highest",
        btnTxt: "START",
        error: false,
    };
</script>
