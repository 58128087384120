/*globals window, XMLHttpRequest, IS_PRODUCTION */

import { URL_PARAMS, urlParams } from "rmlibrary/util";
import { Promise } from "rmlibrary/promise";

const TRACKING_URL = "https://api.richmedia.com/track/"; // Change URL when new Tracker is up

export let HSID = getHSID();
let TEST_IP = URL_PARAMS.test_ip;
function getHSID() {
    let id = window.localStorage.getItem("com.richmedia.hsid");
    if (URL_PARAMS.hasOwnProperty("vid")) {
        id = URL_PARAMS.vid;
        window.localStorage.setItem("com.richmedia.hsid", id);
    }
    if (!id && URL_PARAMS.hasOwnProperty("vid2")) {
        id = URL_PARAMS.vid2;
        window.localStorage.setItem("com.richmedia.hsid", id);
    }

    return id || null;
}
function setHSID(hsid) {
    HSID = hsid;
    window.localStorage.setItem("com.richmedia.hsid", hsid);
}

function get(url, params) {
    return new Promise((resolve, reject) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    resolve(httpRequest.responseText);
                } else {
                    reject(httpRequest.statusText);
                }
            }
        };
        let paramString = Object.keys(params)
            .reduce((acc, key) => acc.concat(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`), [])
            .join("&");
        if (paramString) paramString = "?" + paramString;

        httpRequest.open("GET", url + paramString, true);
        httpRequest.withCredentials = true;
        httpRequest.send();
    });
}

function post(url, params) {
    return new Promise((resolve, reject) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    resolve(httpRequest.responseText);
                } else {
                    reject(httpRequest.statusText);
                }
            }
        };
        let paramString = Object.keys(params)
            .reduce((acc, key) => acc.concat(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`), [])
            .join("&");

        httpRequest.open("POST", url, true);
        httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        // httpRequest.withCredentials = true;
        httpRequest.send(paramString);
    }).then((resultsStr) => JSON.parse(resultsStr));
}

export function trackPage(pageName) {
    if (!HSID) {
        return;
    }
    if (IS_PRODUCTION) {
        get(TRACKING_URL, { hid: HSID, pageVisited: pageName }).catch((err) => {
            console.log("Tracking call failed!", err); // eslint-disable-line no-console
        });
    } else {
        console.log("DEBUG TRACKING", pageName); // eslint-disable-line no-console
    }
}
export function trackEvent(eventName) {
    if (!HSID) {
        return;
    }
    if (IS_PRODUCTION) {
        get(TRACKING_URL, { hid: HSID, eventName: eventName }).catch((err) => {
            console.log("Tracking call failed!", err); // eslint-disable-line no-console
        });
    } else {
        console.log("DEBUG TRACKING EVENT", eventName); // eslint-disable-line no-console
    }
}

// if url contains utm_source or utm_campaign params
// return source and campaign values
export function campaignURLCheck() {
    let URL_PARAMS = urlParams();
    if (URL_PARAMS.hasOwnProperty("utm_source") || URL_PARAMS.hasOwnProperty("utm_campaign")) {
        let source = URL_PARAMS.utm_source ? URL_PARAMS.utm_source : "N/A";
        let campaign = URL_PARAMS.utm_campaign ? URL_PARAMS.utm_campaign : "N/A";
        return { source, campaign };
    }
    return false;
}

//set user HSID to campaign HSID
export function campaignHSID() {
    if (!HSID) {
        setHSID("X0FR");
    }
}

//rich ideas articles have different tracking setup then richmedia.com
//richmedia.com campaign tracking found in page-template.js
export function richIdeasCampaignTrack(source, campaign) {
    //only run if current page is a rich ideas article
    if (window.RICH_IDEAS_ARTICLE) {
        if (source || campaign) {
            let trackingTitle = "Rich Ideas";
            trackingTitle = `${trackingTitle} - ${window.RICH_IDEAS_ARTICLE}`;
            trackPage(`Source - ${source} *** Campaign - ${campaign} *** ${trackingTitle}`);
        }
    }
}

let personalizationPromise = null;
export function getPersonalization() {
    if (personalizationPromise) return personalizationPromise;

    let isCampaign = campaignURLCheck();

    if (isCampaign) {
        campaignHSID();
        richIdeasCampaignTrack(isCampaign.source, isCampaign.campaign);
    }

    let params = {
        query: "getcontactdata",
    };

    if (HSID) {
        params = {
            query: "getcontactdata",
            param: HSID,
        };
    } else if (TEST_IP) {
        params = {
            query: "getcontactdata",
            p1: TEST_IP,
        };
    }

    personalizationPromise = post("https://www.richmedia.com/api/getcontact.php", params).then((results) => {
        if (results && results.vid) {
            setHSID(results.vid);
        }
        return results;
    });
    return personalizationPromise;
}
