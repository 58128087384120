import { createElement, createContext, Component } from "rmlibrary/comp";
import "@rmlibrary/fetch";

const SearchContext = createContext({
    searchInput: "",
    loading: false,
    results: [],
});

export class SearchContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchInput: "",
            loading: false,
            results: [],

            setSearchInput: this.setSearchInput.bind(this),
        };
    }

    setSearchInput(str) {
        this.setState({
            searchInput: str,
        });
        if (str.length > 0) {
            this.setState({
                loading: true,
                results: [],
            });
            fetch(`richideas/new_php/api/article/search.php?keywords=${str}`)
                .then(response => response.json())
                .then(json => {
                    let articles = [];
                    for (let data in json) {
                        articles.push(json[data]);
                    }
                    //gets rid of "filtered" element
                    articles.pop();
                    console.log(articles);
                    this.setState({
                        loading: false,
                        results: articles,
                    });
                })
                .catch(error => console.error(error));
        }
    }

    render() {
        return <SearchContext.Provider value={this.state}>{this.props.children}</SearchContext.Provider>;
    }
}

export const SearchContextConsumer = SearchContext.Consumer;
