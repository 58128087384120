<style lang="scss">
    @import 'sass/variables';
    @import 'sass/base';
    
    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        line-height:1.2;
    }

    a.btn:link{
        color: black;
        
        &:hover{
            text-decoration: underline;
        }
    }

    .AMIcon {
        transform: scale(0.9);
        margin-right:5px;
        
        @media #{$md-up} {
            transform: scale(1);
        }
    }
    
.floating {
    display:block;
    position:fixed;
    bottom:0px;
    right:0;
    text-align:left;
    width:100vw;
    height:60px;
    color:black;
    background-color:$yellow;
    cursor:pointer;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
    padding:0;
    transition: 0.5s ease-in-out;
    z-index: 10;
    @media #{$sm-up} {
        width:320px;
        text-align:left;
    }
    @media #{$md-up} {
        width:320px;
        text-align:left;
    }

    @media #{$lg-up} {
        width:295px;
        text-align:left;
        padding:0;
    }
    
    .card-container {
        width:100%;
        margin-left:10%;
        height:inherit;

        @media #{$sm-up} {
            margin-left:10%;
        }

        @media #{$md-up} {
            margin-left: 10%;
        }

        @media #{$lg-up} {
            margin-left:5%;
        }
    }

    .left {
        float:left;
        width:60%;
        @media #{$sm-up} {
            width: 60%;
        }
        @media #{$md-up} {
            width: 60%;
        }
        @media #{$lg-up} {
            width:71%
        }
    }

    .right {
        width:25%;  
        float:left;
        transition: 0.5s ease-in-out;

        @media #{$xs-only} {
            float: right;
            margin-right: 10%;
        }
        
        @media #{$md-up} {
            width:40%;
            transition: 0.5s ease-in-out;
        }

        @media #{$lg-up} {
            width:25%;
            transition: 0.5s ease-in-out;
        }

/*    ACCOUNT MANAGERS AVATAR */

        .card {
            position:absolute;
            width:65px;
            height:65px;
            top:-7px;
            right:10px;
            text-align:center;
            img {
                width:95%;
            }
            .shannon {
                display: none;
            }    
            .kelly {
                display: none;
            } 
        }
    }       
}

    .expand {
        display:block;
        position:fixed;
        bottom:0px;
        right:0;
        text-align:left;
        width:100vw;
        color:black;
        background-color:$yellow;
        cursor:pointer;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
        padding:0;
        transition: 0.5s ease-in-out;
        z-index: 20;
    
     @media #{$sm-up} {
        width:320px;
        text-align:left;
    }


    @media #{$md-up} {
        width:320px;
        text-align:left;
    }

    @media #{$lg-up} {
        width:312px;
        text-align:left;
        padding:0;
    }
    
    .card-container {
        width:100%;
        margin-left:10%;
        height:inherit;

        @media #{$sm-up} {
            margin-left:10%;
        }

        @media #{$md-up} {
            margin-left: 10%;
        }

        @media #{$lg-up} {
            margin-left:5%;
        }
    }

    .left {
        float:left;
        width:60%;
        @media #{$sm-up} {
            width: 60%;
        }
        @media #{$md-up} {
            width: 60%;
        }
        @media #{$lg-up} {
            width:71%
        }
    }

    .right {
        width:25%;  
        float:left;
        transition: 0.5s ease-in-out;

        @media #{$xs-only} {
            float: right;
            margin-right: 10%;
        }
        
        @media #{$md-up} {
            width:40%;
            transition: 0.5s ease-in-out;
        }

        @media #{$lg-up} {
            width:25%;
            transition: 0.5s ease-in-out;
        }

/*    ACCOUNT MANAGERS AVATAR */

        .card {
            img {
                width:95%;
            }
            .shannon {
                display: none;
            }    
             .kelly {
                display: none;
            }      
            position:absolute;
            width:65px;
            height:65px;
            top:-7px;
            right:10px;
            text-align:center;

        }
    }   
}
    
.text {
    color:black;
    padding: 10px 0 0 0px; 
    font-size:12px;
    @media #{$lg-up} {
        padding: 10px 0 0 5px; 
        font-size: 14px;
    }            
}

.left {
    /*    ACCOUNT MANAGERS NAME */
    h2 {
        font-size:20px;
        color:black;
        padding: 0px 0 5px 0px; 
        @media #{$lg-up} {
            padding: 0px 0 10px 5px; 
        }
    }  
}    
    
    .tele-contain {
        @media #{$xs-only} {
        width: 100%;
        margin-left: 10%;
        }
    }
    
    .tele {
        top: 65px;
        font-size: 14px;
        
        @media #{$xs-up} {
            width: 100%;
            margin:0;
        }
    }
    
    .email {
        top: 95px;
        font-size: 14px;
         
        @media #{$xs-up} {
            width: 100%;
            margin:0;
        }
        img {
            margin-top:5px;
        }
    }

    .contact {
        display: flex;
        align-items: center;
        &:hover {
            text-decoration: underline;
        }
        a {
            text-decoration: none;
            color: $dark-gray;
            cursor: pointer;
            @media #{$md-up} {
                font-size: 16px;
                }
            }
        }
    
</style>

<template name="FloatingCard__" extends="FloatingCardController">
    <div>
        <If c="{this.props.AMfirstName}">
            <div class="floating" id="floating-card" onClick={this.toggleAMCard}>
                <div class="card-container">
                    <div class="left">
                        <div class="text card">Contact your Account Manager</div>
                        <h2 class="AMCard">{this.props.AMfirstName} {this.props.AMlastName}</h2>
                    </div>
    
                    <div class="tele contact">
                        <div>
                            <img class="AMIcon" src="./site_assets/images/phone.svg" alt="Contact Number" width="30px" height="30px"/>
                        </div>
                        <div>
                            <a class="btn" href="{`tel:4164066545`}">416 406 6545 x{this.props.AMext}</a>
                        </div>    
                    </div>
                    
                    <div class="email contact">
                        <div>
                            <img class="AMIcon" src="./site_assets/images/email.svg" alt="Contact Email" width="30px" height="30px"/>
                        </div>
                        <div>
                            <a class="btn" href="{`mailto:${this.props.AMemail}`}">{this.props.AMemail}</a>
                        </div>    
                    </div>

                    <div class="right">
                        <If c="{this.getImage(this.props.AMemail)}">
                            <div class="card">
                                <img class="AMCard" src="{this.getImage(this.props.AMemail)}" alt="{`${this.props.AMfirstName} ${this.props.AMlastName}`}" />
                            </div>
                        </If>         
                    </div>
                    
                </div>
                <span id="black-fill-box" class="black-fill"></span>
            </div>
        </If>
    </div>
</template>



<script>    

    import {CustomizationConsumer} from "../../common/customization";

    export function FloatingCard(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <FloatingCard__ 
                {...props} 
                AMfirstName={customizationState.AMfirstName}
                AMlastName={customizationState.AMlastName}
                AMemail={customizationState.AMemail}
                AMext={customizationState.AMext}
                updateCardExpanded={customizationState.updateCardExpanded}
            /> }
        </CustomizationConsumer>)
    }

    let shannon;
    let kelly;
    let bridget;
    let teeyum;

    const IMAGE_MAP = {
        "shannonsloan@richmedia.com": "./site_assets/images/icons/shannon_dp.png",
        "kellystepien@richmedia.com": "./site_assets/images/icons/kelly_dp.png",
        "bridgetasser@richmedia.com": "./site_assets/images/icons/bridget_dp.png",
        "kimcruz@richmedia.com": "./site_assets/images/icons/kim_dp.png",
        "teeyumamin@richmedia.com": "./site_assets/images/icons/Teeyum_dp.png",
        "allisonthompson@richmedia.com": "./site_assets/images/icons/allison_dp.png",
        "ryleighcarson@richmedia.com": "./site_assets/images/icons/ryleigh_dp.png",
        "ridakhurram@richmedia.com": "./site_assets/images/icons/rida_dp.png",
        "javedhydaralli@richmedia.com": "./site_assets/images/icons/javed_dp.png",
    }
    
    class FloatingCardController extends Component {
        constructor(props){
            super(props);
            this.state = {
                cardExpanded: false,
            }

            this.toggleAMCard = this.toggleAMCard.bind(this);
            this.handleClickOutsideAM = this.handleClickOutsideAM.bind(this);
        }

        componentDidMount() {
            document.body.addEventListener("click", this.handleClickOutsideAM);
            document.body.addEventListener("touchstart", this.handleClickOutsideAM)
        }

        handleClickOutsideAM(e) {
            
            if (!this.checkParent(e.target) && this.state.cardExpanded) {
                this.toggleAMCard(e);
            }
        }

        checkParent(e) {
            if (e.parentElement == null) {
                return false;
            }
            else if (e.classList.contains("floating") || e.classList.contains("expand")) {
                return true;
            }
            else {
                return this.checkParent(e.parentElement);
            }
        }
        

        getImage(email) {
            return IMAGE_MAP[email];
        }

        toggleAMCard(e) {
            if (e.target.tagName != "A") {
                e.preventDefault();
                if (!this.state.cardExpanded) {
                    document.querySelector("#floating-card").className = "expand";
                    
                    this.setState ({
                        cardExpanded: true,
                    })
                    this.props.updateCardExpanded(true);
                } else {
                    document.querySelector("#floating-card").className = "floating";
                    
                    this.setState ({
                        cardExpanded: false,
                    })
                    this.props.updateCardExpanded(false);
                }
            }
        }
    }    
</script>