<style lang="scss">
    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #ffc425;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 50px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    p,
    label {
        color: #fff;
        font-size: 20px;
        display: block;
        padding-bottom: 30px;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }
    
    @media #{$xs-only, $sm-only} {
        label {
            padding-top: 50px;
        }
    }

    .landing-container {
        min-height: calc(100vh - 409px);        
        background-color: #1a1a1a;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height:auto;
        @media #{$lg-only} {
            min-height: calc(100vh - 411px);        
        }
        @media #{$md-only} {
            min-height:0;    
        }
        .text-container {
            text-align: center;
            padding: 20px 20px 100px 20px;
            width: 100%;
            @media #{$xs-only} {
                padding-bottom:120px;
            }
            @media #{$sm-only} {
                padding-bottom:150px;
            }
            @media #{$md-only} {
                padding-bottom:100px;   
            }
        }
    }
    
    .text-container-inner {
        max-width: 800px;
        display:block;
        margin:0 auto;
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    .feedback {
        animation: fadein 2s;
    }

    .slider {
        display: block;
        background-color: #ffc425;
        max-width: 722px;
        height: 4px;
        width: 100%;

        @media #{$xs-only, $sm-only} {
            transform: rotate(90deg);
            width: 100%;
            max-width: 350px;
            margin-top: 150px;
            margin-bottom: 150px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    #slider-values {
        display: flex;
        width: 100%;
        justify-content: space-between;
        & span {
            margin-top: 10px;
            color: $white;
        }
    }
    .slider-wrapper {
        margin-bottom:62px;
        @media #{$xs-only, $sm-only} {
            margin-bottom:0px;
        }
    }
    
    .service-text {
        border-radius: 10px;
        padding: 10px 20px;
        width: 100%;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }
    
    .back-button-container .back-button {
        background: url("images/icons/back-button-yellow.svg");
        background-size:cover;
    }
    
    .back-text {
        position: absolute;
        display: block;
        color: #fff;
        opacity: 0;
        transition: 0.25s ease;
        p {
            padding:0;
        }
    }
   
    .back-button-container .back-button:focus, .next-button-container .next-button:focus {
        background: url("images/icons/back-button-white.svg");
        outline: none;
        background-size: cover;
        border-radius:100%;
        border: 1px solid $yellow;
    }
    
    .next-button-container .next-button {
        background: url("images/icons/back-button-yellow.svg");
        background-size:cover;
    }
    .back-button-container, .next-button-container {
        @media #{$sm-only} {
            bottom:70px;
        }
    }
    @media (-ms-high-contrast: none), (pointer: fine) {
        .back-button:hover, .next-button:hover {
            background: url("images/icons/back-button-white.svg");
            outline: none;
            background-size: cover;
        }
        .back-button:hover + .back-text, .next-button:hover + .next-text {
            opacity: 1;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .back-button:active, .next-button:active {
            background: url("images/icons/back-button-white.svg");
            outline: none;
            background-size: cover;
        }
    }
    
    .yellow-banner {
        background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);

        h1, h2, label {
            color: $black90;
        }

        .back-button {
            background: url("images/icons/back-button.svg");
            background-size: cover;
        }
        
        .back-text {
            color: #fff;
        }
    }
    
        
</style>

<import name="Slider" from="rmlibrary/react-slider" />

<template name="SurveyServices" extends="SurveyServicesController">
    <main class="landing-container services-container" class.yellow-banner="{this.props.theme === 'yellow'}">
        <div class="text-container">
            <div class="text-container-inner">
                <h1 tabIndex="-1" style="outline: none;" ref="{this.headerElement}">{this.props.title}</h1>

                <div>
                    <h2>{this.props.question}</h2>
                    <p class="sr-only">select a rating between 0 and 10, 0 being strongly disagree, 10 being strongly agree</span>
                </div>

                <div class="slider-wrapper">
                    <Slider options="{this.state.sliderOptions}" aria-label="slider"  
                    onUp="{this.handleSliderChange}" ref="{this.handleSliderRef}" 
                    />
                </div>

                <div class="feedback" ref="{this.subQuestionElement}">
                    <label htmlFor="service">{this.props.subQuestion}</label>
                    <textarea ref="{this.textArea}" id="service" rows="4" cols="80" 
                        placeholder="Type Here" class="service-text" defaultValue="{this.props.feedback ? this.props.feedback : ''}"></textarea>
                </div>
            </div>
        </div>   
        
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button" onClick="{() => this.props.onSubmit('back')}"></button>
                <div class="back-text"><p>Back</p></div>
            </div>
            
            <div class="next-button-container">
                <button aria-label="next" class="next-button" onClick="{() => this.props.onSubmit(this.state.sliderValue, this.textArea.current.value)}"></button>
                <div class="next-text"><p>Next</p></div>
            </div>
        </div>    
    </main>
</template>

<script>
    import {
        createRef
    } from 'rmlibrary/comp';
    
    let SLIDER_OPTIONS = {
        min: 0,
        max: 10,
        step: [1],
        orientation: "",
        value: 5,
        snap: true
    }; 

    // used to determine orientation of slider
    let mql = window.matchMedia('(max-width : 767px)');

    class SurveyServicesController extends Component {
        
        constructor(props) {
            super(props);

            this.state = {
                sliderOptions: SLIDER_OPTIONS,
                sliderValue: SLIDER_OPTIONS.value,
            };
            this.detectScreenSize = this.detectScreenSize.bind(this);
            mql.addListener(this.detectScreenSize);
            this.handleSliderChange = this.handleSliderChange.bind(this);
            this.handleSliderRef = this.handleSliderRef.bind(this);
            this.textArea = createRef();
            this.headerElement = React.createRef();
            this.subQuestionElement = React.createRef();
        }

        componentDidMount() {
            this.detectScreenSize(mql);
            this.headerElement.current.focus();  
            window.scrollTo(0, 0);
        }

        componentWillUnmount() {
            mql.removeListener(this.detectScreenSize);
        }

        componentDidUpdate() {
            this.subQuestionElement.current.focus();
            window.scrollTo(0, 0);
        }

        // set orientation for slider by updating state slider options object 
        detectScreenSize(size) {
            if (size.matches) {
                // if viewport is 767 pixels wide or less
                this.setState({
                    sliderOptions: {
                        ...this.state.sliderOptions, 
                        orientation: 'vertical'
                    }
                });
            } else {
                this.setState({
                    sliderOptions: {
                        ...this.state.sliderOptions,
                        orientation: 'horizontal'
                    }
                });
            }
        }

        // dynamically sets the slider handle value according to the state value using css property content + attr
        addContentAttr() {
            if (this.sliderElement) {
                this.sliderElement.querySelector(".rm-slider-handle").setAttribute("slider-value", this.state.sliderValue);
                this.sliderElement.querySelector(".rm-slider-handle").setAttribute("aria-label", "slider handle");
            }
        }

        // setup dots along each step of the slider for visibility
        handleSliderRef(sliderElement) {
            this.sliderElement = sliderElement;
            this.addContentAttr();

            if (sliderElement) {
                const element = sliderElement.firstChild;
                let dotContainer = document.createElement('div');
                dotContainer.className = "dot-container";
                element.appendChild(dotContainer);

                for (let i = 0; i < SLIDER_OPTIONS.max + 1; i++) {
                    const dot = document.createElement('span');
                    dot.className = "dot";
                    dotContainer.appendChild(dot);
                }
            }
        }

        // updates state value for slider & determines subquestion
        handleSliderChange(value) {
            SLIDER_OPTIONS.value = value;
            this.setState({
                sliderValue: value
            }, () => {
                this.addContentAttr();
            });

        }
    }

    SurveyServicesController.defaultProps = {
        title: "Our Services",
        question: "How happy are you with the services provided by Rich Media?",
        subQuestion: "To help us improve the service we provide to you, what did we get right and what can we do better?",
        theme: "black",
        onSubmit: () => console.log("submit")
    };
</script>