/* eslint-disable @typescript-eslint/explicit-function-return-type */
const NativeHTMLElement = window.HTMLElement;

const upgradeQueue = [];
export const tagNameLookup = new Map();

export function queueElement(element) {
    upgradeQueue.push(element);
}

let watchAttributes = null;

export function setWatchAttributes(fn) {
    watchAttributes = fn;
}

function HTMLElement() {
    const C = this.constructor;
    const tagName = tagNameLookup.get(C);
    let element;

    if (!upgradeQueue.length) {
        if (!tagName) {
            throw new Error("Coulnd't find a tagname for this ");
        }
        element = document.createElement(tagName);
    } else {
        element = upgradeQueue.pop();
    }

    Object.setPrototypeOf(element, C.prototype);
    watchAttributes(element, C);

    return element;
}
HTMLElement.prototype = NativeHTMLElement.prototype;

window.HTMLElement = HTMLElement;
