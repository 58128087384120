/*globals document, window*/
import {TransparentVideo2D} from "./2d";
import {TransparentVideo3D} from "./webgl";

export {TransparentVideo2D} from "./2d";
export {TransparentVideo3D} from "./webgl";

const IS_IE_11 = (/Trident\/7\.|MSIE/i).test(window.navigator.userAgent)
// const IS_IOS_10_3 = (/ipod|ipad|iphone/i).test(window.navigator.userAgent) && (/CPU.*OS 10_3/i).test(window.navigator.userAgent);

const SUPPORTS_WEBGL = (() => {
    if (IS_IE_11) return false;
    const canvas = document.createElement("canvas");
    return !!(canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
})()

export const TransparentVideo = SUPPORTS_WEBGL ? TransparentVideo3D : TransparentVideo2D;
