/*globals navigator */

import createjs from "createjs";

import MainBannerAnimation from "../../../../animate/main-video/video-overlay-canvas";

/***********************************************
 * Fix chrome/FF issue
 ***********************************************/
var firefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
var chromeGt71 = false;
try {
  chromeGt71 = Number(navigator.userAgent.match(/Chrome\/(\d+)/i)[1]) >= 71;
} catch (e) {
  chromeGt71 = false;
}

if (firefox || chromeGt71) {
  // Object.keys(createjs.Text.V_OFFSETS).forEach((k) => {
  //     createjs.Text.V_OFFSETS[k] = createjs.Text.V_OFFSETS[k] + 0.3;
  // });
  var savedPrep = createjs.Text.prototype._prepContext;
  createjs.Text.prototype._prepContext = function(ctx) {
    ctx.translate(0, this.lineHeight * 0.2);
    return savedPrep.call(this, ctx);
  };
}

/***********************************************
 * Helpers
 ***********************************************/
var lib = MainBannerAnimation.lib;

function attachDrawSwitch(libraryItem, fn) {
  var oldDraw = libraryItem.prototype.draw;
  libraryItem.prototype = Object.create(libraryItem.prototype);
  libraryItem.prototype.draw = function(...args) {
    const shouldDraw = fn.call(this);
    if (shouldDraw) return oldDraw.apply(this, args);
    else return false;
  };
}

function syncClip(libraryItem) {
  var oldInitialize = libraryItem.prototype.initialize;
  libraryItem.prototype.initialize = function(...args) {
    const [mode, ...rest] = args; // eslint-disable-line no-unused-vars
    oldInitialize.apply(this, [createjs.MovieClip.SYNCHED, ...rest]);
  };
}

const not = fn => () => !fn();

/***********************************************
 * Scene 1
 ***********************************************/

const getIndustry = () => MainBannerAnimation.vars.industry;
const getGreeting = () => {
  if (!MainBannerAnimation.vars.name) return false;
  return `Good ${MainBannerAnimation.vars.time}, ${
    MainBannerAnimation.vars.name
  }`;
};

// Industry overlay
attachDrawSwitch(lib.overlayfinancial, function() {
  const industry = getIndustry();
  if (!industry) return false;
  this.gotoAndStop(industry);
  return true;
});

// Greeting
syncClip(lib.Name);
attachDrawSwitch(lib.userName, function() {
  const greeting = getGreeting();
  if (!greeting) return false;
  this.txt.text = greeting;
  return true;
});

/***********************************************
 * Scene 2 - Websites
 ***********************************************/

const specialIndustry = () =>
  MainBannerAnimation.vars.industry === "financial" ||
  MainBannerAnimation.vars.industry === "pharma";
const financialIndustry = () =>
  MainBannerAnimation.vars.industry === "financial";
const pharmaIndustry = () => MainBannerAnimation.vars.industry === "pharma";
const notSpecial = not(specialIndustry);
const notPharma = not(pharmaIndustry);

attachDrawSwitch(lib._02_websites_financial, financialIndustry);
attachDrawSwitch(lib._02_financial_tools, financialIndustry);
attachDrawSwitch(lib._02_animation_financial, financialIndustry);
attachDrawSwitch(lib._02_social_financial, financialIndustry);
attachDrawSwitch(lib._02_andmore_financial, financialIndustry);

attachDrawSwitch(lib._02_websites_pharma, pharmaIndustry);
attachDrawSwitch(lib._02_pharma_tools, pharmaIndustry);
attachDrawSwitch(lib._02_animation_pharma, pharmaIndustry);
attachDrawSwitch(lib._02_social_pharma, pharmaIndustry);
attachDrawSwitch(lib._02_andmore_pharma, pharmaIndustry);

attachDrawSwitch(lib._02_websites, notSpecial);
attachDrawSwitch(lib._02_social, notSpecial);
attachDrawSwitch(lib._02_mobile, notSpecial);
attachDrawSwitch(lib._02_animation, notSpecial);
attachDrawSwitch(lib._02_andmore, notSpecial);

/***********************************************
 * Scene 6 - Interactive
 ***********************************************/
attachDrawSwitch(lib._06_financial, financialIndustry);
attachDrawSwitch(lib._06_interactive, notSpecial);

attachDrawSwitch(lib._06_tools, notPharma);
attachDrawSwitch(lib._06_let, notPharma);
attachDrawSwitch(lib._06_them, notPharma);
attachDrawSwitch(lib._06_see, notPharma);
attachDrawSwitch(lib._06_the, notPharma);
attachDrawSwitch(lib._06_whole, notPharma);
attachDrawSwitch(lib._06_picture, notPharma);

attachDrawSwitch(lib._06_pharma_interactive, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_tools, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_can, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_explain, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_evaluate, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_and, pharmaIndustry);
attachDrawSwitch(lib._06_pharma_demonstrate, pharmaIndustry);

/***********************************************
 * Scene 7 - Client of all sizes
 ***********************************************/

syncClip(lib._07_sync_normal);
syncClip(lib._07_sync_financial);
syncClip(lib._07_sync_pharma);
attachDrawSwitch(lib._07_sync_normal, notSpecial);
attachDrawSwitch(lib._07_sync_financial, financialIndustry);
attachDrawSwitch(lib._07_sync_pharma, pharmaIndustry);

export default MainBannerAnimation;
