<import name="Slider" from="rmlibrary/react-slider" />
<template name="SliderTest" extends="SliderTestController">
    <div style="padding:50px;">

        <Slider options="{SLIDER_OPTIONS}" value="{this.state.sliderValue}" onChange="{this.handleSliderChange}" />

    </div>
</template>
<script>

const SLIDER_OPTIONS = {
    min: 0,
    max: 10,
    step: [1],
    value: 5
};

class SliderTestController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sliderValue: 5
        };

        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    handleSliderChange(v) {
        this.setState({
            sliderValue: v
        });
    }
}

</script>