<import name="PersonalizationQuizController" from="../personalization.html" />

<style lang="scss">

@import 'sass/variables';
@import 'sass/_personalization';

</style>

<template name="QuizOne">

    <form autoComplete="off" role="form" aria-labelledby="Personalization Quiz" class="{`one ${this.props.openCard === 1 ? 'show' : 'hide'}`}" onSubmit="{this.props.togglePersonalization}">
        <h2 class="hello" tabIndex="-1" ref="hello"> Personalize Visit
        </h2>

        
        
        
        <div class="dropdown">                
            <div class="material_div">
                <span id="whatBringsLabel" class="material_field">Reason for visit</span>
                <select ref="whatBrings" aria-labelledby="what brings selection" id="whatBringsInput" name="whatBrings" value="{this.props.state.whatBrings || ''}" onChange="{this.props.handleWhatBringsChange}">
                    <option value="">Reason for visit</option>
                    <option value='client'>I am a client</option>
                    <option value='looking-for-agency'>I am looking for an agency</option>
                    <option value='competitor'>I am a competitor</option>
                    <option value='job-seeker'>I am looking for a job</option>
                    <option value='other'>Other</option>
                </select>
            </div>
        </div>
        

        <div class="dropdown"> 
            <div class="material_div">
                <span id="industryLabel" class="material_field">Industry</span>
                <select aria-labelledby="industry selection" name="industry" id="industryInput" value="{this.props.state.industry || ''}" onChange="{this.props.handleIndustryChange}">
                    <option value="">Industry</option>
                    <option value='financial'>Finance & Insurance</option>
                    <option value='pharma'>Pharma and Health</option>
                    <option value='consumer_electronics'>Consumer Electronics</option>
                    <option value='retail'>Retailer</option>
                    <option value='travel'>Travel & Tourism</option>
                    <option value='telecommunication'>Telecommunications</option>
                    <option value='consumer_goods'>Consumer Packaged Goods</option>
                    <option value='other'>Other</option>
                </select>
            </div>
        </div>

            <div class="material_div">
            <span id="companyLabel" class="material_field">Company</span>
        <div class="suggestion-container">
            <input onBlur="{this.props.handleCompanyBlur}" id="companyInput" aria-labelledby="company name" placeholder="Company/Institution" ref="companyInput" type="text" class="type-in" name="companyName" value="{this.props.state.companyName || ''}" onChange="{this.props.handleCompanyChange}" aria-autocomplete="both">
            <div id="suggestions" class="suggestion-items" aria-label="clients" />
                
            </div>
        </div>


        <div class="dropdown"> 
            <div class="material_div">
                <span id="interestLabel" class="material_field">Interest</span>
            <!-- values for interest are designed to match the respective values for banner ordering -->
            <select aria-labelledby="interest selection" name="interest" id="interestInput" value="{this.props.state.interest || ''}" onChange="{this.props.handleInterestChange}">
                <option value="">Interest</option>
                <option value='websites'>Websites</option>
                <option value='interactive-tools'>Interactive Tools</option>
                <option value='personalized-video'>Personalized Video</option>
                <option value='animation'>Animation</option>
                <option value='social-media'>Social Media Campaigns</option>
                <option value='content-marketing'>Content marketing</option>
                <option value='apps'>Apps</option>
                <option value='future-tech'>Future technology</option>
            </select>
            </div>
        </div>
        
        <div class="dropdown"> 
            <div class="material_div">
                <span id="themeLabel" class="material_field">Theme</span>
            
            <select id="themeInput" aria-labelledby="theme selection" name="theme" value="{this.props.state.theme || 'default'}" onChange="{this.props.handleThemeChange}">
                <option value='default'>Classic</option>
                <option value='halloween'>Halloween</option>
               <!-- <option value='valentines'>Valentines Day</option>
                <option value='stpatricks'>St. Patrick's Day</option>
                <option value='fathersday'>Fathers Day</option>                
                <option value='familyday'>Family Day</option>
                <option value='canadaday'>Canada Day</option>                -->
                <option value='xmas'>Winter Holidays</option>
                <option value='new-year'>New Years</option>
                <option value='canadaday'>Canada Day</option>
                <option value='pride'>Pride</option>
                <!--DELETE THE IS_PRODUCTION CHECK TO RE-ENABLE REDACTED FUNCTIONALITY-->
                <If c="{!IS_PRODUCTION}">
                    <option value='redacted'>Top Secret</option>
                </If>
            </select>
            </div>
        </div>

        
            <!--CLICKING THIS WOULD PROCEED TO PERSONALIZED BANNERS!-->
        <button id="submit-button" 
                role="button" 
                aria-labelledby="submit button" 
                class="button" 
                type="submit" 
                ref="personalizationBtn">
            Personalize
<!--             <a href="javascript:void(0)">Personalize</a> -->
           <span class="chevron right"></span>
        </button>
        
        
<!--
        <p> Answer a couple of questions to customize your visit.</p>
        <button type="submit" class="button"> let's go
            <span class="chevron right"></span>
        </button>
-->
    </form>
</template>