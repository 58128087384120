import { Component, createElement } from "rmlibrary/comp";
import { Carousel } from 'rmlibrary/carousel';

/*
 ** This is a HOC that utilizes the Carousel component
 * The children of this component should simply be the divs, in order, to be used in the carousel

 * Example usage:

     <div style="width: 100%; height: 625px;">
         <GalleryCarousel draggable autoScroll="10000" callbefore="{this.galleryCallbefore}" ref="{carousel => this.gCarousel = carousel}">
             <div class="img-1"></div>
             <div class="img-2"></div>
             <div class="img-3"></div>
             <div class="img-4"></div>
             <div class="img-5"></div>
             <div class="img-6"></div>
         </GalleryCarousel>
     </div>

*/

export class GalleryCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            cards: this.getCardsAtIndex(0)
        };

        this.jumpingToCard = false;

        this.refreshDeck = this.refreshDeck.bind(this);
        this.callbefore = this.callbefore.bind(this);
        this.getCardsAtIndex = this.getCardsAtIndex.bind(this);
    }

    jumpToCard(card, index) {
        if (index != this.state.index && !this.carousel.isBusy()) {

            const replIndex = index < this.state.index ? 0 : 2;
            let cards = this.state.cards.slice();
            cards[replIndex] = this.getChildren()[index];

            this.setState({ index, cards });
            this.jumpingToCard = true;

            setTimeout(() => {
                if (replIndex == 0) this.carousel.prev();
                if (replIndex == 2) this.carousel.next();
            }, 0)
        }
    }

    getCardsAtIndex(index) {
        let children = this.getChildren();

        for (let i = 0; i < children.length; i++) {
            if (i == index) {
                return [
                    children[(i - 1 + children.length) % children.length],
                    children[i],
                    children[(i + 1) % children.length]
                ];
            }
        }
    }

    callbefore(increment) {
        if (this.props.callbefore) this.props.callbefore(increment);
    }

    refreshDeck(increment) {
        let children = this.getChildren();
        let index = (this.state.index + increment + children.length) % children.length;
        if (!this.jumpingToCard) this.setState({ index, cards: this.getCardsAtIndex(index) });
        else this.setState({ cards: this.getCardsAtIndex(this.state.index) });

        this.jumpingToCard = false;
    }

    getChildren() {
        if (Array.isArray(this.props.children)) return this.props.children;

        return [this.props.children];
    }

    /* API */

    getIndex() {
        return this.state.index;
    }

    getImageByIndex(index) {
        return this.props.children[index];
    }

    isBusy() {
        return this.carousel.isBusy();
    }

    /* Rendering */

    render() {
        return (
            <div style={{ height: '100%' }}>

                <Carousel draggable={ this.props.draggable }
                          reveal={ this.props.reveal }
                          autoScroll={ this.props.autoScroll }
                          animateDuration={ this.props.animateDuration }
                          width="100%"
                          callback={ this.refreshDeck }
                          callbefore={ this.callbefore }
                          ref={ carousel => this.carousel = carousel }>
                    { this.state.cards }
                </Carousel>

            </div>
        );
    }
}
