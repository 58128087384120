<import name="FooterDesktop" from="./footer-desktop.html" />
<import name="FooterMobile" from="./footer-mobile.html" />
<import name="FloatingCard" from="../pages/banners/floating-card.html" />

<template name="Footer">
    <footer id="contact">
        <FooterDesktop isRichIdeas={this.props.isRichIdeas} richIdeasBase={this.props.richIdeasBase} />
        <FooterMobile isRichIdeas={this.props.isRichIdeas} richIdeasBase={this.props.richIdeasBase} />
        <FloatingCard></FloatingCard>
    </footer>
</template>
