import RMDraggable from './draggable';
import {Component, createElement as el, cloneElement as clone} from "rmlibrary/comp";

export class Draggable extends Component {
    constructor(props) {
        super(props);

        if (Array.isArray(this.props.children)) throw new Error("Draggable should only have one child.");

        this.init = this.init.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.dx != "NaN") this.draggable.dx = nextProps.dx;
    }

    init(element) {
        if (element) this.draggable =
            new RMDraggable(element, {direction: 'x'})
                .on('rm-dragstart', () => {
                    this.draggable.errors = "none";
                    this.draggable.rangeX = this.props.dragRangeX;
                    if (this.props.onDragStart) this.props.onDragStart();
                })
                .on('rm-dragmove', () => {
                    if (this.props.tick) this.props.tick();
                })
                .on('rm-dragend', (e) => {
                    this.props.onDragEnd(e.dx, e.ddx);
                    this.draggable.errors = "x";
                })

    }

    getDx() {
        return this.draggable.dx;
    }

    render() {
        this.children = clone(this.props.children, {ref: this.init})

        return this.children
    }
}
