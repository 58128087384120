/*globals document */

var hidden, visibilityChangeEvent;

if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChangeEvent = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChangeEvent = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChangeEvent = "webkitvisibilitychange";
}

class VisibilityListener {
    constructor(fn) {
        this.fn = fn;
        document.addEventListener(visibilityChangeEvent, this, false);
    }

    isVisible() {
        return !document[hidden];
    }

    handleEvent() {
        const isHidden = !document[hidden]
        if (typeof(this.fn) === 'function') this.fn(isHidden);
    }

    destroy() {
        document.removeEventListener(visibilityChangeEvent, this, false);
    }
}

export function visibilityChange(fn) {
    return new VisibilityListener(fn);
}
