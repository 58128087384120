/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/camelcase */
if (navigator.appVersion.indexOf("Trident/") >= 0 && !window.__rm_ie11_innerhtml_fixed__) {
    function fixProp(propName) {
        var desc = Object.getOwnPropertyDescriptor(HTMLElement.prototype, propName);
        Object.defineProperty(HTMLElement.prototype, propName, {
            set: function(v) {
                while (this.lastChild) this.removeChild(this.lastChild);
                desc.set.call(this, v);
            },
        });
    }
    fixProp("innerHTML");
    fixProp("innerText");

    window.__rm_ie11_innerhtml_fixed__ = true;
}
