import {createElement, joinClassNames} from "rmlibrary/comp";

export function Loader(props) {
    const {show, size, color, ...otherProps} = props;
    const cssSize = `${size || 50}px`;
    const innerStyle = { width: cssSize, height:cssSize };

    if (color) innerStyle.borderTopColor = color;

    return (
        <div {...otherProps}
            className={joinClassNames("rm-loader", show ? 'show' : undefined, props.className)}
        >
            <div style={innerStyle}></div>
        </div>
    );
}
