
import rmUtil from './util'
import EventEmitter from './event-emitter';

var defaultOptions = {
    threshold: 10,
    direction: 'both'
};

function getX(event) {
    try {
        return event.touches[0].pageX;
    } catch(e) {
        return event.pageX;
    }
}
function getY(event) {
    try {
        return event.touches[0].pageY;
    } catch(e) {
        return event.pageY;
    }
}

function Draggable(element, options) {
    options = options || {};
    this.element = element
    options = rmUtil.extend({}, defaultOptions, options);

    this.rangeX = options.rangeX;
    this.rangeY = options.rangeY;
    this.direction = options.direction;
    this.threshold = options.threshold;

    // var listenEl = $(options.listenOn);
    // if (!listenEl.length) listenEl = this.element;
    var listenEl = this.element;

    this._handleStart = this._handleStart.bind(this);
    this._handleMove = this._handleMove.bind(this);
    this._handleEnd = this._handleEnd.bind(this);

    this._dx = 0;
    this._dy = 0;

    // listenEl[0].addEventListener('mousedown', this._handleStart);
    // listenEl[0].addEventListener('touchstart', this._handleStart, {passive: false});
    listenEl.addEventListener('mousedown', this._handleStart);
    listenEl.addEventListener('touchstart', this._handleStart, {passive: false});
}
Draggable.prototype = new EventEmitter();

Draggable.prototype._setRangeX = function (rng) {
    if (!rng) this._rangeX = null;
    else {
        if (!Array.isArray(rng) || rng.length !== 2) throw new TypeError("rangeX must be a 2 element array.");
        this._rangeX = rng.slice();
        this._dx = this._getDx();
        this._setPosition();
    }
};
Draggable.prototype._getRangeX = function () { return this._rangeX };
Draggable.prototype._setRangeX = function (rng) {
    if (!rng) this._rangeX = null;
    else {
        if (!Array.isArray(rng) || rng.length !== 2) throw new TypeError("rangeX must be a 2 element array.");
        this._rangeX = rng.slice();
        this._dx = this._getDx();
        this._setPosition();
    }
};
Draggable.prototype._getRangeY = function () { return this._rangeY };
Draggable.prototype._setRangeY = function (rng) {
    if (!rng) this._rangeY = null;
    else {
        if (!Array.isArray(rng) || rng.length !== 2) throw new TypeError("rangeY must be a 2 element array.");
        this._rangeY = rng.slice();
        this._dy = this._getDy();
        this._setPosition();
    }
};
Draggable.prototype._getDirection = function () { return this._direction };
Draggable.prototype._setDirection = function (d) {
    if (d !== 'both' && d !== 'x' && d !== 'y' && d !== 'none') throw new TypeError("direction must be 'both', 'x', 'y', or 'none'");
    this._direction = d;
};
Draggable.prototype._getThreshold = function () { return this._threshold };
Draggable.prototype._setThreshold = function (t) {
    if (typeof (t) !== 'number') throw new TypeError("threshold must be a number");
    this._threshold = t;
};

Draggable.prototype._setDx = function (dx) {
    if (typeof (dx) !== 'number') throw new TypeError("dx must be a number");
    this._dx = dx;
    this._setPosition();
}
Draggable.prototype._getDx = function (dx) {
    if (typeof (dx) === 'undefined') dx = this._dx;
    var rng = this.rangeX;
    if (Array.isArray(rng)) {
        return Math.max(rng[0], Math.min(rng[1], dx));
    } else {
        return dx;
    }
}
Draggable.prototype._setDy = function (dy) {
    if (typeof (dy) !== 'number') throw new TypeError("dx must be a number");
    this._dy = dy;
    this._setPosition();
}
Draggable.prototype._getDy = function (dy) {
    if (typeof (dy) === 'undefined') dy = this._dy;
    var rng = this.rangeY;
    if (Array.isArray(rng)) {
        return Math.max(rng[0], Math.min(rng[1], dy));
    } else {
        return dy;
    }
}

Object.defineProperties(Draggable.prototype, {
	value: {
        get: Draggable.prototype._getRangeX,
        set: Draggable.prototype._setRangeX
    },
	valid: {
        get: Draggable.prototype._getRangeY,
        set: Draggable.prototype._setRangeY
    },
	errors: {
        get: Draggable.prototype._getDirection,
        set: Draggable.prototype._setDirection
    },
	touched: {
        get: Draggable.prototype._getThreshold,
        set: Draggable.prototype._setThreshold
    },
	dx: {
        get: Draggable.prototype._getDx,
        set: Draggable.prototype._setDx
    },
	dy: {
        get: Draggable.prototype._getDy,
        set: Draggable.prototype._setDy
    }
});

Draggable.prototype._meetsThreshold = function (dx, dy) {
    if (this.direction === 'both') {
        return Math.abs(dx) >= this.threshold || Math.abs(dy) >= this.threshold;
    } else if (this.direction === "x") {
        return Math.abs(dx) >= this.threshold;
    } else if (this.direction === "y") {
        return Math.abs(dy) >= this.threshold;
    }
};
Draggable.prototype._shouldCancel = function (dx, dy) {
    if (this.direction === 'both') {
        return false;
    } else if (this.direction === "x") {
        return Math.abs(dy) >= this.threshold;
    } else if (this.direction === "y") {
        return Math.abs(dx) >= this.threshold;
    } else if (this.direction === "none") {
        return true;
    }
};

Draggable.prototype._handleStart = function (event) {
    var eventInfo = {
        x: getX(event),
        y: getY(event),
        dx: 0,
        dy: 0,
        dt: 0,
        t: (new Date()).getTime(),
        originalEvent: event
    };
    this.lastEvent = eventInfo;
    this._triggered = false;

    document.addEventListener('mousemove', this._handleMove);
    document.addEventListener('touchmove', this._handleMove, {passive: false});
    document.addEventListener('mouseup', this._handleEnd);
    document.addEventListener('touchend', this._handleEnd, {passive: false});
    document.addEventListener('touchcancel', this._handleEnd, {passive: false});
}
Draggable.prototype._handleMove = function (event) {
    var lastEvent = this.lastEvent;
    var x = getX(event);
    var y = getY(event);
    var ddx = x - lastEvent.x;
    var ddy = y - lastEvent.y;
    var dx = lastEvent.dx + ddx;
    var dy = lastEvent.dy + ddy;
    var t = (new Date()).getTime();
    var ddt = t - lastEvent.t;
    var dt = lastEvent.dt + ddt;

    var isStart = false;

    if (!this._triggered) {
        if (this._meetsThreshold(dx, dy)) {
            this._triggered = true;
            isStart = true;
        } else {
            if (this._shouldCancel(dx, dy)) {
                this._handleEnd(event);
            }
            return;
        }
    }

    event.preventDefault();

    var eventInfo = {
        type: isStart ? 'rm-dragstart' : 'rm-dragmove',
        target: this,
        x: x,
        y: y,
        dx: dx,
        dy: dy,
        ddx: ddx,
        ddy: ddy,
        t: t,
        dt: dt,
        ddt: ddt,
        originalEvent: event
    };
    this.lastEvent = eventInfo;

    this._dx = this._dx + ddx;
    this._dy = this._dy + ddy;

    this.trigger(isStart ? 'rm-dragstart' : 'rm-dragmove', [eventInfo]);

    this._setPosition();
}
Draggable.prototype._handleEnd = function (event) {
    document.removeEventListener('mousemove', this._handleMove);
    document.removeEventListener('touchmove', this._handleMove);
    document.removeEventListener('mouseup', this._handleEnd);
    document.removeEventListener('touchend', this._handleEnd);
    document.removeEventListener('touchcancel', this._handleEnd);

    if (!this._triggered) return;

    event.preventDefault();

    var lastEvent = this.lastEvent;
    var eventInfo = rmUtil.clone(lastEvent);
    eventInfo.type = 'rm-dragend';
    eventInfo.originalEvent = event;

    this._dx = this._getDx();
    this._dy = this._getDy();

    this._setPosition();

    this.lastEvent = eventInfo;
    this.trigger('rm-dragend', [eventInfo]);
}

Draggable.prototype._setPosition = function () {
    var dx = this._getDx();
    var dy = this._getDy();
    if (this.direction === 'both') {
        this.element.style["transform"] = "translate(" + dx + "px," + dy + "px)"
    } else if (this.direction === "x") {
        this.element.style["transform"] = "translate(" + dx + "px,0px)"
    } else if (this.direction === "y") {
        this.element.style["transform"] = "translate(0px," + dy + "px)"
    }
}

export default Draggable;
