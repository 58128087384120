<import name="RouterLink" from="rmlibrary/router" />

<style lang="scss">
    @import 'sass/variables';

    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
    }

    p {
        color: inherit;
    }

    h2 {
        color: black;
    }

    div.desktop {
        color: black;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        height: 280px;
        width: 100%;
        flex-wrap: wrap;
        background-color: $yellow;
        align-items: center;

        @media #{$xs-only, $sm-only} {
            display: none;
        }

        @media #{$height} {
            display: none;
        }

        .footer-yellow {
            background-color: $yellow;
            width: 30%;
            padding: 50px;
            padding-bottom: 0;

            @media #{$md-only} {
                padding-left: 20px;
                padding-right: 20px;
                width: 35%;
            }

            @media #{$lg-only} {
                padding: 28px;
            }

            .content {
                /* Fix rich ideas */
                min-height: 0;
                padding-bottom: 0;
                width: auto;
                /* End rich ideas fixes */

                max-width: 240px;
                display: block;
                margin: 0 auto;

                .contact {
                    display: flex;
                    align-items: center;

                    &:hover {
                        text-decoration: underline;
                    }

                    img {
                        max-width: 30px;
                        margin-right: 10px;
                        vertical-align: middle;
                        margin-top: -4px;
                    }

                    a {
                        text-decoration: none;
                        color: $dark-gray;
                        cursor: pointer;

                        @media #{$md-only} {
                            font-size: 14px;
                        }
                    }
                }

                h2 {
                    font-size: 28px;
                    margin-bottom: 10px;
                }
            }
        }


        .footer-black {
            background-color: black;
            width: 70%;
            display: flex;
            padding: 50px;
            padding-bottom: 0;
            box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.25);

            @media #{$xl-up} {
                padding-left: 160px;
            }

            @media #{$md-only} {
                width: 65%;
                padding-right: 5px;
                padding-left: 25px;
            }

            img {
                max-width: 30px;

                &:first-child {
                    margin-left: 15px;
                }
            }

            .right-column,
            .left-column {
                width: 40%;
                margin-top: 40px;
                margin-right: 20px;

                @media #{$md-only} {
                    margin-right: 15px;
                }
            }

            .footer-title {
                color: $yellow;
                font-weight: 600;
                font-size: 28px;
                display: flex;
                align-items: center;
            }

            p {
                color: $white;
                font-size: 16px;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;

                @media #{$md-only} {
                    font-size: 14px;
                }

                &.where {
                    max-width: 215px;
                    margin-bottom: 15px;
                }
            }

            .privacy-text { 
                padding-left: 25px;
                position: absolute;
                bottom: -40px;
                width: 160px;
                text-align: left;
                font-size: 12px;

                @media #{$md-only} {
                    width: 65%;
                }

                @media #{$lg-only} {
                    padding-left: 50px;
                }

                @media #{$xl-up} {
                    min-height: calc(100vh - 951px);
                    padding-left: 160px;
                }

                a {
                    text-decoration: none;
                    color: $white;
                    cursor: pointer;
                }

                p {
                    font-size: 12px;
                }
            }

            .yellow-button {
                padding: 5px 10px 5px 10px;
                font-size: 14px;
                background-color: $black;
                color: white;
                border: 1px solid white;

                &:hover {
                    background-color: $yellow;
                    color: $black;
                    border: 1px solid $yellow;
                }
            }

            .button1 {
                margin-right: 20px;
            }

            .directions {
                color: $yellow;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    color: $white;
                }
            }

            .social-media-wrapper {
                position: relative;
                width: 20%;

                @media #{$md-only} {
                    width: 10%;
                }

                a {
                    text-decoration: none;
                    color: $white;
                    cursor: pointer;
                }

                p {
                    font-size: 12px;
                    text-align: left;
                }


                .social-media {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;

                    img {
                        max-width: 30px;
                        margin: 10px;
                    }

                    .instagram {
                        background-image: url(./images/icons/SVG/instagram-y.svg);
                        width: 27px;
                        height: 25px;
                        background-size: cover;
                        margin: 10px;
                        transition: background-image 0.5s ease-in-out;

                        &:hover {
                            background-image: url(./images/icons/SVG/instagram-w.svg);
                            transition: background-image 0.5s ease-in-out;
                        }
                    }

                    .twitter {
                        background-image: url(./images/icons/SVG/twitter-y.svg);
                        width: 27px;
                        height: 25px;
                        background-size: cover;
                        margin: 10px;
                        transition: background-image 0.5s ease-in-out;

                        &:hover {
                            background-image: url(./images/icons/SVG/twitter-w.svg);
                            transition: background-image 0.5s ease-in-out;
                        }
                    }

                    .facebook {
                        background-image: url(./images/icons/SVG/facebook-y.svg);
                        width: 27px;
                        height: 25px;
                        background-size: cover;
                        margin: 10px;
                        transition: background-image 0.5s ease-in-out;

                        &:hover {
                            background-image: url(./images/icons/SVG/facebook-w.svg);
                            transition: background-image 0.5s ease-in-out;
                        }
                    }

                    .linkedin {
                        background-image: url(./images/icons/SVG/linkedin-y.svg);
                        width: 27px;
                        height: 25px;
                        background-size: cover;
                        margin: 10px;
                        transition: background-image 0.5s ease-in-out;

                        &:hover {
                            background-image: url(./images/icons/SVG/linkedin-w.svg);
                            transition: background-image 0.5s ease-in-out;
                        }
                    }
                }
            }
        }
    }

    .privacy-text {
        text-align: left;
        font-size: 12px;
        color: $white;
        padding-left: 25px;
        /*        padding-right: 60px;*/
        padding-top: 45px;
        padding-bottom: 30px;
        background-color: $black;
        width: 70%;

        @media #{$md-only} {
            width: 65%;
        }

        @media #{$lg-only} {
            padding-left: 50px;
        }

        @media #{$xl-up} {
            min-height: calc(100vh - 951px);
            padding-left: 160px;
        }

        a {
            text-decoration: none;
            color: $white;
            cursor: pointer;
        }

        p {
            font-size: 12px;
        }
    }

    .footer-yellow2 {
        padding: 57px;
        padding-bottom: 32px;
        background-color: #FFC425;
        width: 30%;

        @media #{$md-only} {
            padding-left: 20px;
            width: 35%;
        }

        @media #{$xl-up} {
            min-height: calc(100vh - 952px);
        }
    }

    .icon {
        width: 30px;
        height: 30px;
        background-size: cover;
        transition: background-image 0.5s ease-in-out;

        /* fix rich ideas bugs */
        position: relative;
        /* end - fix rich ideas bugs */
    }

    .location {
        background-image: url(./images/location.svg);

        &:hover {
            background-image: url(./images/location-w.svg);
            transition: background-image 0.5s ease-in-out;
        }
    }

    .android {
        background-image: url(./images/android.svg);

        &:hover {
            background-image: url(./images/android-w.svg);
            transition: background-image 0.5s ease-in-out;
        }
    }

    .apple {
        background-image: url(./images/apple.svg);

        &:hover {
            background-image: url(./images/apple-w.svg);
            transition: background-image 0.5s ease-in-out;
        }
    }

    div.desktop {
        @media #{$height} {
            display: flex;
        }
    }

</style>
<template name="FooterDesktop">
    <div class="desktop">
        <div class="footer-yellow">
            <div class="content">
                <p>Let's get started</p>
                <h2>Contact Us</h2>

                <div class="contact">
                    <a href="tel:14164066545"><img src="./site_assets/images/phone.svg" alt="Contact Number" width="30px" height="30px" />416 406 6545</a>
                </div>
                <div class="contact">
                    <a href="mailto:contactus@richmedia.com"><img src="./site_assets/images/email.svg" alt="Contact Email" width="30px" height="30px" />contactus@richmedia.com</a>
                </div>
            </div>
        </div>
        <div class="footer-black">
            <div class="left-column">
                <span class="footer-title">Where <a aria-label="location" href="https://goo.gl/maps/XRPtoj5Rim72" target="_blank">
                        <div class="icon location"></div>
                    </a></span>
                <p class="where">1090 Don Mills Rd. Suite 501 Toronto, ON M3C 3R6</p>
                <a class="directions" href="https://goo.gl/maps/XRPtoj5Rim72" target="_blank">Directions</a>
            </div>
            <div class="right-column">
                <span class="footer-title">App
                    <a aria-label="android" href="https://play.google.com/store/apps/details?id=com.richmedia.richideas&hl=en" target="_blank">
                        <div class="icon android"></div>
                    </a>
                    <a aria-label="apple" href="https://itunes.apple.com/us/app/rich-media-ideas/id948015528?mt=8" target="_blank">
                        <div class="icon apple"></div>
                    </a>
                </span>
                <p>Download the Rich Media Ideas App</p>
                <a href="https://play.google.com/store/apps/details?id=com.richmedia.richideas&hl=en" target="_blank" class="yellow-button black-button button1">Android</a>
                <a href="https://itunes.apple.com/us/app/rich-media-ideas/id948015528?mt=8" target="_blank" class="yellow-button black-button">Apple</a>
            </div>
            <div class="social-media-wrapper">
                <div class="social-media">
                    <a aria-label="rich media instagram" href="https://www.instagram.com/richmedia.social/" target="_blank">
                        <div class="instagram"></div>
                    </a>
                    <a aria-label="rich media x" href="https://x.com/RichMediaSocial/" target="_blank">
                        <div class="twitter"></div>
                    </a>
                    <a aria-label="rich media facebook" href="https://www.facebook.com/Rich-Media-251114581758676/" target="_blank">
                        <div class="facebook"></div>
                    </a>
                    <a aria-label="rich media linkedin" href="https://www.linkedin.com/company/rich-media" target="_blank">
                        <div class="linkedin"></div>
                    </a>
                </div>
            </div>
        </div>
        <div class="footer-yellow2">
        </div>
        <div class="privacy-text">
            <p>©2021 Rich Media |<span>&nbsp;</span>
                <If c="{!this.props.isRichIdeas}">
                    <RouterLink className="privacy" to="/privacy">Privacy</RouterLink>
                </If>
                <If c="{this.props.isRichIdeas}"><a className="privacy" href="{`${RICH_IDEAS_BASE}/privacy`}">Privacy</a></If>
            </p>
        </div>
    </div>
    </div>
</template>
