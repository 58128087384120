<!-- Import other components used here -->
<import name="RouterLink" from="rmlibrary/router" />

<import name="PortfolioGrid" from="./portfolio-grid.html" />
<import name="PortfolioCarousel" from="./portfolio-carousel.html" />
<!-- <import name="PortfolioTemplate1" from="./portfolio-template1.html" /> -->
<import name="PORTFOLIO,FEATURED,APPS_WEBSITE,INTERACTIVE_TOOLS,ANIMATION,LIVE_ACTION,SOCIAL_GAME" from="../../portfolio-data" />
<import name="FloatingCard" from="./banners/floating-card.html" />

<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .portfolio {
        background-color:$black90;

        .portfolio-nav {
            max-width:1060px;
            display:block;
            margin:0 auto;
            padding-top:15px;

            &:hover {
                fdfs:ddssd;
            }

            @media #{$xs-only} {
                display:none;
            }
            @media #{$md-only} {
                max-width:750px;
            }
            @media #{$sm-only} {
                max-width:556px;
            }

            a {
                color:$yellow;
                padding:20px;
                text-decoration:none;
                display:inline-block;
                position: relative;

                @media #{$md-only} {
                    padding:10px;
                }

                @media #{$sm-only} {
                    padding:5px;
                }
                &.selected {
                    /* &:after {
                        content: "";
                        display: block;
                        margin: 0 auto;
                        width: 30%;
                        padding-top: 10px;
                        border-bottom: 1px solid $yellow;
                        position: absolute;
                        top: 35px;
                        left: 35%;

                        @media #{$md-only} {
                            top: 25px;
                        }

                        @media #{$sm-only} {
                            top:20px;
                        }
                    } */
                }
            }
        }
        .mobile-nav {
            position: relative;
            display:block;
            max-width:320px;
            margin:0 auto;
            border-bottom:1px solid $yellow;
            @media #{$sm-up} {
                display:none;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: $black90;
                min-width: 320px;
                overflow: auto;
                z-index: 1;

                &.show {
                    display:block;
                }

                a {
                    color: $yellow;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    border-top:0.5px solid $yellow;
                }
            }
            .nav-button {
                background-color: $black90;
                color: $yellow;
                padding: 12px;
                font-size: 16px;
                border: none;
                cursor: pointer;
                text-transform: uppercase;
                min-width: 320px;
            }
        }
    }
    .show {display:block;}

    .mobile-portfolio-nav {
        position: relative;
        display: block;
        max-width: 320px;
        margin: 0 auto;
        border-bottom: 1px solid $yellow;
        @media #{$sm-up} {
            display: none;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: $black90;
            min-width: 320px;
            overflow: auto;
            z-index: 1006;

            &.show {
                display: block;
            }

            a {
                color: $yellow;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                border-top: 0.5px solid $yellow;
            }
        }
        .nav-button {
            background-color: $black90;
            color: $yellow;
            padding: 12px;
            padding-top:20px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            display: block;
            outline: none;
        }
    }

    .chevron-nav {
    	border-style: solid;
    	border-width: 0.1em 0.1em 0 0;
    	content: '';
    	display: inline-block;
    	height: 0.45em;
    	left: 0.15em;
    	position: relative;
    	top: 0.25em;
    	transform: rotate(-45deg);
    	vertical-align: top;
    	width: 0.45em;
        color:$yellow;
        &:hover {
            color:$yellow;
        }
    }

    .chevron-nav.bottom {
    	top: 0;
    	transform: rotate(135deg);
        margin-top:10px;
        text-align:right;
        float:right;
        margin-right:10px;
    }

    .mobile-portfolio-nav {
        .nav-button {
            width:100%;
            text-align:left;
        }
    }

    #nav-blocker {
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        z-index:1005;
        display:none;

        &.show {
            display:block;
        }
    }

</style>

<template name="PortfolioPage" extends="PortfolioPageController">
    <main>
        <div class="portfolio">
            <div class="portfolio-nav">
                <ItemSelector lookForClass="selected" ignorePadding options="{this.itemSelectorOptions}">
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === FEATURED }" query="{{ f: FEATURED }}"><span class="redact-yellow-header">Featured</span></RouterLink>
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === APPS_WEBSITE }" query="{{ f: APPS_WEBSITE }}"><span class="redact-yellow-header">Apps/Websites</span></RouterLink>
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === INTERACTIVE_TOOLS }" query="{{ f: INTERACTIVE_TOOLS }}"><span class="redact-yellow-header">Interactive Tools</span></RouterLink>
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === ANIMATION }" query="{{ f: ANIMATION }}"><span class="redact-yellow-header">Animation</span></RouterLink>
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === LIVE_ACTION }" query="{{ f: LIVE_ACTION }}"><span class="redact-yellow-header">Live Action</span></RouterLink>
                    <RouterLink to="/portfolio" class.selected="{ this.state.filter === SOCIAL_GAME }" query="{{ f: SOCIAL_GAME }}"><span class="redact-yellow-header">Social/Games</span></RouterLink>
                </ItemSelector>
            </div>

            <div class="mobile-portfolio-nav">
                <button class="nav-button" onClick="{this.toggleMobileMenu}">
                    Category
                    <span class="chevron-nav bottom"></span>
                </button>
                <div id="nav-blocker" class.show="{this.state.showMobileMenu}" onClick="{() => this.setState({ showMobileMenu: false })}"></div>
                <div id="nav" class="dropdown-content" class.show="{this.state.showMobileMenu}" onClick="{() => this.setState({ showMobileMenu: false })}">
                    <RouterLink to="/portfolio" query="{{ f: FEATURED }}">Featured</RouterLink>
                    <RouterLink to="/portfolio" query="{{ f: APPS_WEBSITE }}">Apps/Websites</RouterLink>
                    <RouterLink to="/portfolio" query="{{ f: INTERACTIVE_TOOLS }}">Interactive Tools</RouterLink>
                    <RouterLink to="/portfolio" query="{{ f: ANIMATION }}">Animation</RouterLink>
                    <RouterLink to="/portfolio" query="{{ f: LIVE_ACTION }}">live Action</RouterLink>
                    <RouterLink to="/portfolio" query="{{ f: SOCIAL_GAME }}">Social/Games</RouterLink>
                </div>
            </div>

            <If c="{!this.state.showProject}">
                <PortfolioGrid projects="{this.state.currentProjects}" filter="{this.state.filter}" />
            </If>

            <If c="{this.state.showProject}">
                <PortfolioCarousel filteredProjects="{this.state.currentProjects}"
                                   project="{this.props.variables.project}"
                                   redirect="{this.props.redirect}" />
            </If>


        </div>

    </main>

</template>
<script>
import {ItemSelector} from '../common/item-selector'

const ALL_FILTERS = [FEATURED,APPS_WEBSITE,INTERACTIVE_TOOLS,ANIMATION,LIVE_ACTION,SOCIAL_GAME];

function getFilter(props) {
    let filter = props.query && props.query.f;
    if (!filter || ALL_FILTERS.indexOf(filter) < 0) return;
    return filter;
}

function getFilteredProjects(filter) {
    return PORTFOLIO.filter((project) => project.categories.indexOf(filter) >= 0);
}

// Returns the first filter that contains the given slug
function matchFilter(slug) {
    for (let i = 0; i < ALL_FILTERS.length; i++) {
        let projects = getFilteredProjects(ALL_FILTERS[i]);
        for (let j = 0; j < projects.length; j++) {
            if (projects[j].slug === slug) return ALL_FILTERS[i];
        }
    }
    return null;
}

function findNewFilter(props, state) {
    if (props.query.f) {
        return getFilter(props) || ALL_FILTERS[0];
    } else if (props.variables.project) {
        if (state.filter) return state.filter;
        else return matchFilter(props.variables.project) || ALL_FILTERS[0];
    } else {
        return ALL_FILTERS[0];
    }
}

function projectExists(slug) {
    for (let i = 0; i < PORTFOLIO.length; i++)
        if (PORTFOLIO[i].slug === slug) return true;

    return false;
}

class PortfolioPageController extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        const newFilter = findNewFilter(nextProps, prevState);
        let newState = null;

        if (newFilter && newFilter !== prevState.filter) {
            newState = {
                filter: newFilter,
                currentProjects: getFilteredProjects(newFilter),
                showProject: false
            };
        }

        if (nextProps.variables.project && projectExists(nextProps.variables.project)) {
            newState = {
                ...newState,
                showProject: true
            };
        }
        if (prevState.showProject && !nextProps.variables.project) {
            newState = {
                ...newState,
                showProject: false
            };
        }

        return newState;
    }

    constructor(props) {
        super(props);

        const firstFilter = this.findFirstFilter(props);
        this.state = {
            showMobileMenu: false,
            filter: firstFilter,
            currentProjects: getFilteredProjects(firstFilter),
            showProject: props.variables.project ? projectExists(props.variables.project) : false
        };

        this.itemSelectorOptions = {
            color: '#FFC425',
            alignX: 'center',
            alignY: 'bottom',
            shiftY: 5,
            widthPercent: 0.4
        }

        // Redirect to '/portfolio' if project slug isn't valid
        if (props.variables.project && !projectExists(props.variables.project)) this.props.redirect('/portfolio', {replace: true});

        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    componentDidMount(){
        // Force portfolio tile images to load when component is mounted.
        PORTFOLIO.forEach((portfolio) => {
            const imgToLoad = new Image();
            imgToLoad.src = portfolio.images.tileBg;
        });
    }

    // Returns the appropriate filter that should be currently selected
    findFirstFilter(props) {
        if (props.query.f) {
            return getFilter(props) || ALL_FILTERS[0];
        } else if (props.variables.project) {
            return matchFilter(props.variables.project) || ALL_FILTERS[0];
        } else {
            return ALL_FILTERS[0];
        }
    }

    toggleMobileMenu() {
        this.setState({
            showMobileMenu: !this.state.showMobileMenu
        });
    }
}
</script>
