import { Component, createElement } from "rmlibrary/comp";

import { CustomizationConsumer } from "../common/customization";

export class CareersRedirectInner extends Component {
  componentDidMount() {
    setTimeout(() => this.props.redirect("/careers", { replace: true }), 10);
  }

  render() {
    return <div></div>;
  }
}

export function CareersRedirect(props) {
  return (
    <CustomizationConsumer>
      {() => <CareersRedirectInner {...props} />}
    </CustomizationConsumer>
  );
}
