
<import name="CLIENT_LOGOS" from="../../client-logos-data" />

<style lang="scss">
    @import 'sass/variables';

    .logo-bg {
        background-color: $black90;
    }
    
    .logo-carousel {
        background-color: $black90;
        animation-name: fadeIn2;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .logo-card {
        background-color: $black90;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        @media #{$xs-only, $sm-only} {
            padding: 30px 10px;
        }

    }

    .img-wrapper {
        padding: 0 20px;
        max-width: 260px;
        @media #{$xs-only, $sm-only} {
            padding: 0 5px;
        }
    }

    .client-logo {
        width: 100%;
        display: block;
    }



</style>
<template name="LogoCard">
    <React.Fragment>
        <For var="client" of="{this.props.children}">
            <div key="{client.client}" class="img-wrapper" style.width="{this.props.logoWidth}">
                <img class="client-logo" draggable="false" src="{client.clientLogo}" alt="{client.client}" title="{client.client}" width="{client.width}">
            </div>
        </For>
    </React.Fragment>
</template>

<template name="ClientsCarousel__" extends="ClientsCarouselController">
    <div class="logo-bg chevron-scroll-anchor chevron-scroll-anchor-unless-visible">
        <div class="logo-carousel">
            <Carousel draggable autoScroll="3000" width="100%" callback="{this.refreshDeck}">
                <div class="logo-card"> <LogoCard logoWidth="{this.state.logoWidth}">{this.state.deck[0]}</LogoCard> </div>
                <div class="logo-card"> <LogoCard logoWidth="{this.state.logoWidth}">{this.state.deck[1]}</LogoCard> </div>
                <div class="logo-card"> <LogoCard logoWidth="{this.state.logoWidth}">{this.state.deck[2]}</LogoCard> </div>
            </Carousel>
        </div>
    </div>    
</template>

<script>
    import { Carousel } from "rmlibrary/carousel";

    export function ClientsCarousel(props) {
        return (<ClientsCarousel__ {...props} />);
    }

    ClientsCarousel.PASS_COLOUR = true;

    class ClientsCarouselController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                deck: null,
                cardIndex: null,
                nLogos: null,
                logoWidth: null
            };

            this.refreshDeck = this.refreshDeck.bind(this);
            this.handleResize = this.handleResize.bind(this);
        }

        componentWillMount() {
            this.resetCarousel();
        }

        componentDidMount() {
            addEventListener("resize", this.handleResize);
        }

        componentWillUnmount() {
            removeEventListener("resize", this.handleResize);
        }

        handleResize() {
            if (this.howManyLogos() != this.state.nLogos)
                this.resetCarousel();
        }

        resetCarousel() {
            const nLogos = this.howManyLogos();
            this.deckPack = this.buildDeckPack(nLogos);

            const cardIndex = 0;
            const deck = this.getDeckByIndex(cardIndex);
            const logoWidth = `${100 / nLogos}%`;
            this.setState({ deck, cardIndex, nLogos, logoWidth });
        }

        howManyLogos() {
            if (window.innerWidth > 768) return 5;
            else return 3;
        }

        buildDeckPack(logosPerCard) {
            return CLIENT_LOGOS.reduce((deckPack, item, i) => {
                let deckIndex = Math.floor(i / logosPerCard);
                if (deckPack[deckIndex]) deckPack[deckIndex].push(item);
                else deckPack[deckIndex] = [item];

                return deckPack;
            }, []);
        }

        refreshDeck(increment) {
            let newIndex = (this.state.cardIndex + increment + this.deckPack.length) % this.deckPack.length;

            this.setState({
                cardIndex: newIndex,
                deck: this.getDeckByIndex(newIndex)
            });
        }

        getDeckByIndex(index) {
            for (let i = 0; i < this.deckPack.length; i++) {
                if (i == index) {
                    return [
                        this.deckPack[(i - 1 + this.deckPack.length) % this.deckPack.length],
                        this.deckPack[i],
                        this.deckPack[(i + 1) % this.deckPack.length]
                    ];
                }
            }
        }
    }
    
</script>
