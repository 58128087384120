var IDENTITY = "Identity",
    THROWER = "Thrower",
    STATE_PENDING = "pending",
    STATE_FULFILLED = "fulfilled",
    STATE_REJECTED = "rejected",
    undef = (function() {})();

if (!Array.isArray) {
    Array.isArray = function(arr) {
        return Object.prototype.toString.call(arr) === "[object Array]";
    };
}

function makeIterator(arr) {
    var i = 0;

    try {
        if (!Array.isArray(arr) && typeof arr.length !== "number")
            throw new TypeError("Not an Iterable");
    } catch (err) {
        throw new TypeError("Not an Iterable");
    }

    return {
        next: function() {
            if (i >= arr.length) return { done: true };
            else return { done: false, value: arr[i++] };
        }
    };
}

var enqueueJob = (function() {
    var queue = [],
        timer;

    function runQueue() {
        timer = undef;

        var item;
        while ((item = queue.shift())) {
            //jshint ignore:line
            item.func.apply(undef, item.args);
        }
    }

    return function(f, args) {
        queue.push({ func: f, args: args });
        if (!timer) {
            timer = setTimeout(runQueue, 0);
        }
    };
})();

function promiseReactionJob(reaction, argument) {
    var promiseCapability = reaction.capabilities,
        handler = reaction.handler,
        handlerResult,
        throws = false;

    if (handler === IDENTITY) {
        handlerResult = argument;
    } else if (handler === THROWER) {
        handlerResult = argument;
        throws = true;
    } else {
        try {
            handlerResult = handler(argument);
        } catch (err) {
            throws = true;
            handlerResult = err;
        }
    }

    if (throws) {
        promiseCapability.reject(handlerResult);
    } else {
        promiseCapability.resolve(handlerResult);
    }
}

function promiseResolveThenableJob(promiseToResolve, thenable, then) {
    var resolvingFunctions = createResolvingFunctions(promiseToResolve);

    try {
        then.call(
            thenable,
            resolvingFunctions.resolve,
            resolvingFunctions.reject
        );
    } catch (err) {
        resolvingFunctions.reject(err);
    }
}

function triggerPromiseReactions(reactions, argument) {
    for (var i = 0; i < reactions.length; i++) {
        enqueueJob(promiseReactionJob, [reactions[i], argument]);
    }
}

function rejectPromise(promise, reason) {
    if (promise["[[PromiseState]]"] !== STATE_PENDING) return;

    var reactions = promise["[[PromiseRejectReactions]]"];
    promise["[[PromiseResult]]"] = reason;
    promise["[[PromiseFulfillReactions]]"] = undef;
    promise["[[PromiseRejectReactions]]"] = undef;
    promise["[[PromiseState]]"] = STATE_REJECTED;

    return triggerPromiseReactions(reactions, reason);
}

function fulfillPromise(promise, value) {
    if (promise["[[PromiseState]]"] !== STATE_PENDING) return;

    var reactions = promise["[[PromiseFulfillReactions]]"];
    promise["[[PromiseResult]]"] = value;
    promise["[[PromiseFulfillReactions]]"] = undef;
    promise["[[PromiseRejectReactions]]"] = undef;
    promise["[[PromiseState]]"] = STATE_FULFILLED;

    return triggerPromiseReactions(reactions, value);
}

function createResolvingFunctions(promise) {
    var alreadyResolved = false;

    var resolve = function(resolution) {
        if (typeof promise !== "object") throw new TypeError(); // TODO: error message

        if (alreadyResolved) return;

        alreadyResolved = true;

        if (resolution === promise)
            return rejectPromise(
                promise,
                new TypeError("Promise resolution cannot be itself.")
            );

        if (
            (typeof resolution !== "object" || resolution === null) &&
            typeof resolution !== "function"
        )
            return fulfillPromise(promise, resolution);

        try {
            var then = resolution.then;
            if (typeof then !== "function")
                return fulfillPromise(promise, resolution);

            enqueueJob(promiseResolveThenableJob, [promise, resolution, then]);
        } catch (err) {
            return rejectPromise(promise, err);
        }
    };
    var reject = function(reason) {
        if (typeof promise !== "object") throw new TypeError(); // TODO: error message

        if (alreadyResolved) return;

        alreadyResolved = true;

        return rejectPromise(promise, reason);
    };

    return { resolve: resolve, reject: reject };
}

function newPromiseCapability(C) {
    var resolve,
        reject,
        promise = new C(function(res, rej) {
            if (typeof resolve !== "undefined" || typeof reject !== "undefined")
                throw new TypeError(); // TODO: error message

            resolve = res;
            reject = rej;
        });

    if (typeof resolve !== "function" || typeof reject !== "function")
        throw new TypeError(); // TODO: error message

    return {
        promise: promise,
        resolve: resolve,
        reject: reject
    };
}

function isPromise(p) {
    return typeof p === "object" && p["[[PromiseState]]"];
}

function PromiseReaction(capabilities, handler) {
    this.capabilities = capabilities;
    this.handler = handler;
}

export function Promise(executor) {
    if (!(this instanceof Promise)) throw new TypeError("Must call with new");
    if (typeof this["[[PromiseState]]"] !== "undefined")
        throw new TypeError("this is an already constructed Promise");
    if (typeof executor !== "function")
        throw new TypeError("executor must be a function");

    this["[[PromiseState]]"] = STATE_PENDING;
    this["[[PromiseFulfillReactions]]"] = [];
    this["[[PromiseRejectReactions]]"] = [];
    this["[[PromiseResult]]"] = undef;

    var resolvingFunctions = createResolvingFunctions(this);

    try {
        executor(resolvingFunctions.resolve, resolvingFunctions.reject);
    } catch (err) {
        resolvingFunctions.reject(err);
    }

    return this;
}

Promise.prototype.then = function(onFulfilled, onRejected) {
    if (!isPromise(this)) throw new TypeError("this must be a promise");

    var promise = this,
        C = this.constructor || Promise,
        resultCapability = newPromiseCapability(C);

    if (typeof onFulfilled !== "function") onFulfilled = IDENTITY;
    if (typeof onRejected !== "function") onRejected = THROWER;

    var fulfillReaction = new PromiseReaction(resultCapability, onFulfilled),
        rejectReaction = new PromiseReaction(resultCapability, onRejected);

    if (promise["[[PromiseState]]"] === STATE_PENDING) {
        promise["[[PromiseFulfillReactions]]"].push(fulfillReaction);
        promise["[[PromiseRejectReactions]]"].push(rejectReaction);
    } else if (promise["[[PromiseState]]"] === STATE_FULFILLED) {
        enqueueJob(promiseReactionJob, [
            fulfillReaction,
            promise["[[PromiseResult]]"]
        ]);
    } else if (promise["[[PromiseState]]"] === STATE_REJECTED) {
        enqueueJob(promiseReactionJob, [
            rejectReaction,
            promise["[[PromiseResult]]"]
        ]);
    }

    return resultCapability.promise;
};

Promise.prototype["catch"] = function(onRejected) {
    return this.then(undef, onRejected);
};

function reject(r) {
    var C = this;
    if (typeof C !== "function") throw TypeError(); // TODO: error message

    var promiseCapability = newPromiseCapability(C);
    promiseCapability.reject(r);

    return promiseCapability.promise;
}

function resolve(x) {
    var C = this;

    if (isPromise(x) && x.constructor === C) {
        return x;
    }

    if (typeof C !== "function") throw TypeError(); // TODO: error message

    var promiseCapability = newPromiseCapability(C);
    promiseCapability.resolve(x);

    return promiseCapability.promise;
}

function promiseAllResolveElement(
    index,
    values,
    capabilities,
    remainingElements
) {
    var alreadyCalled = false;

    return function(x) {
        if (alreadyCalled) return;
        alreadyCalled = true;

        values[index] = x;
        remainingElements.value -= 1;
        if (remainingElements.value === 0) {
            capabilities.resolve(values);
        }
    };
}

function performPromiseAll(iteratorRecord, constructor, resultCapability) {
    var values = [],
        remainingElementsCount = { value: 1 },
        index = 0;

    while (true) {
        var next;
        try {
            next = iteratorRecord.iterator.next();
        } catch (err) {
            iteratorRecord.done = true;
            throw err;
        }

        if (next.done) {
            iteratorRecord.done = true;
            remainingElementsCount.value -= 1;
            if (remainingElementsCount.value === 0) {
                resultCapability.resolve(values);
            }
            return resultCapability.promise;
        }

        values.push(undef);

        var nextPromise = constructor.resolve(next.value);
        var resolveElement = promiseAllResolveElement(
            index,
            values,
            resultCapability,
            remainingElementsCount
        );

        remainingElementsCount.value += 1;
        nextPromise.then(resolveElement, resultCapability.reject);
        index += 1;
    }
}

function all(iterable) {
    var C = this;
    if (typeof C !== "function") throw TypeError(); // TODO: error message

    var promiseCapability = newPromiseCapability(C);

    var iterator;
    try {
        iterator = makeIterator(iterable);
    } catch (err) {
        promiseCapability.reject(err);
        return promiseCapability.promise;
    }

    var iteratorRecord = { iterator: iterator, done: false };
    try {
        return performPromiseAll(iteratorRecord, C, promiseCapability);
    } catch (err) {
        promiseCapability.reject(err);
        return promiseCapability.promise;
    }
}

function performPromiseRace(iteratorRecord, promiseCapability, C) {
    while (true) {
        var next;
        try {
            next = iteratorRecord.iterator.next();
        } catch (err) {
            iteratorRecord.done = true;
            throw err;
        }

        if (next.done) {
            iteratorRecord.done = true;
            return promiseCapability.promise;
        }

        var nextPromise = C.resolve(next.value);
        nextPromise.then(promiseCapability.resolve, promiseCapability.reject);
    }
}

function race(iterable) {
    var C = this;
    if (typeof C !== "function") throw TypeError(); // TODO: error message

    var promiseCapability = newPromiseCapability(C);

    var iterator;
    try {
        iterator = makeIterator(iterable);
    } catch (err) {
        promiseCapability.reject(err);
        return promiseCapability.promise;
    }

    var iteratorRecord = { iterator: iterator, done: false };
    try {
        return performPromiseRace(iteratorRecord, promiseCapability, C);
    } catch (err) {
        promiseCapability.reject(err);
        return promiseCapability.promise;
    }
}

if (Object.defineProperties) {
    Object.defineProperties(Promise, {
        resolve: { value: resolve, writable: true },
        reject: { value: reject, writable: true },
        all: { value: all, writable: true },
        race: { value: race, writable: true }
    });
} else {
    Promise.resolve = resolve;
    Promise.reject = reject;
    Promise.all = all;
    Promise.race = race;
}

export default Promise;
