<import name="joinClassNames" from="rmlibrary/comp" />
<import name="RouterLink" from="rmlibrary/router" />
<import name="Collapsible" from="rmlibrary/collapsible" />
<import name="LearnMoreButton" from="../../common/learn-more-button.html" />

<style lang="scss">
    @import 'sass/variables';
    .banner {
        max-height:none;
    }
    .banner-video {
        .text {
            color: white;
            text-align: center;
            .title {
                padding-top: 60px;
                @media #{$xs-only} {
                    padding-top: 45px;
                }
                @media #{$md-only} {
                    padding-top: 30px;
                }
            }
            .copy {
                /*color: white;*/
                margin-bottom: 30px;
                margin-top: 20px;
                @media #{$xs-only} {
                    margin-bottom: 20px;
                }
            }
        }

        .device {
            text-align: center;
            padding-bottom: 35px;
            img {
                width: 100%;
            }
        }
        .buttons {
            padding-bottom: 50px;
            text-align: center;
            @media #{$sm-only, $xs-only} {
                padding-bottom: 40px;
            }
            @media #{$md-only} {
                padding-bottom: 30px;
            }
            .yellow-button {
                margin: 10px;
                @media #{$xs-only} {
                    margin: 3px;
                }
            }
            .yellow-button .selected {
                border: 1px solid $yellow;
                transition: 0.5s ease;
                margin: 10px;
                .chevron {
                    color: $black;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid white;
                    border-style: inset;
                    color: $white;
                    transition: background-color 0.5s ease;
                }
            }
            .black-button {
                background-color: $black90;
                color: white;
                border: 1px solid white;
                .chevron {
                    color: $white;
                }
            }
            .selected {
                background-color: $yellow;
                color: $black;
                .chevron {
                    color: $black;
                }
            }
        }
        &.yellow-banner {
            .black-button {
                background-color: $yellow;
                color: black;
                border: 1px solid black;
                .chevron {
                    color: black;
                }
            }
            .black-button:hover {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                .chevron {
                    color: $yellow;
                }
            }
        }
    }

    .gallery {
        display: flex;
        width: 100%;
        max-height: 250px;
        .column {
            .small {
                max-height: 125px;
            }
            .large {
                max-height: 250px;
            }
        }
    }

    .img-gallery {
        display: flex;
        margin-bottom: 45px;
        @media #{$xs-only, $sm-only} {
            flex-wrap: wrap;
        }
        .article {
            padding-top: 56.25%;
        }
        .img3 {
            min-width: 200px;
            @media #{$xs-only, $sm-only} {
                min-width: 0;
            }
            @media #{$md-only} {
                min-width: 222px;
            }

        }
        .column {
            width: 16.66vw;
            @media #{$xs-only, $sm-only} {
                width: 33.33%;
            }
            @media #{$md-only} {
                width: 33.33%;
            }
            @media #{$lg-only, $xl-only} {
                flex: 1;
            }
        }
        .column2 {
            @media #{$md-only} {
                display: none;
            }
        }
        .column1 {
            @media #{$xs-only, $sm-only} {
                width: 33.3%;
            }
            @media #{$lg-only, $xl-only} {
                flex: 1;
            }
        }
        .column2 {
            width: 33.332vw;
            @media #{$xs-only, $sm-only} {
                width: 66.66%;
            }
            @media only screen and (min-width : 992px) and (max-width : 1023px) {
                flex: 2;
            }
            @media only screen and (min-width : 1025px) and (max-width : 1224px) {
                flex: 2;
            }
            @media only screen and (min-width : 1226px) {
                flex: 2;
            }

        }
    }

    .photo-tile {
        overflow: hidden;
        position: relative;
        >* {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all 1s ease;
            &:hover {
                transform: scale(1.5) rotate(0.1deg);
            }
        }
    }


    .learn-more {
        background-color: $black90;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            .item {
                width: 24%;
                text-align: center;
                color: white;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    align-items: center;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                    width: 95%;
                    margin: 0 auto;
                    &.even {
                        width: 80%;
                    }
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                    height: 1px;
                    background-color: $white;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }

            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $white;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
        }

        &.yellow {
            background-color: $yellow;
            .learn-more-content {
                .line {
                    background-color: $black;
                }
                p {
                    color: $black;
                }

            }
        }

    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="PhotoTile">
    <div className="{joinClassNames(this.props.className, 'photo-tile')}">
        <div style.backgroundImage="{`url('${this.props.src}')`}"></div>
    </div>
</template>
<template name="SocialMedia__" extends="SocialMediaController">
<div role="group" aria-labelledby="lbl-social-media" class="chevron-scroll-anchor" >
    <div class="banner">
        <div class="banner-video" class.black-banner="{this.props.theme === 'black'}" class.yellow-banner="{this.props.theme === 'yellow'}">
            <div class="content">
                <If condition="{!this.props.specialIndustry}">
                    <div class="text">
                        <h2 id="lbl-social-media" class="title">Social Media &amp; Content Marketing</h2>
                        <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach out and</span> connect <span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> with your audience </span> with interesting, shareable, and fun content.</p>
                    </div>
                </If>
                <If condition="{this.props.specialIndustry}">
                    <div>
                        <If condition="{this.props.industry == 'financial'}">
                            <div class="text">
                                <h2 id="lbl-social-media" class="title">Social and Content Marketing</h2>
                                <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach out and </span> connect<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> to your audience</span> with content that reflects their financial needs and desires.</p>
                            </div>
                        </If>
                        <If condition="{this.props.industry == 'pharma'}">
                            <div class="text">
                                <h2 id="lbl-social-media" class="title">Social and Content Marketing</h2>
                                <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach out and </span> connect<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> with your audience</span> with interesting, shareable, and fun content that resonates with health care professionals.</p>
                            </div>
                        </If>
                    </div>
                </If>
            </div>

            <!--<div class="device">
                <img src="site_assets/images/banner-bgs/social-media.jpg"/>
            </div>-->

            <div class="img-gallery">
                <div class="column column1">
                    <a aria-label="The Internet of Things Infographic" href="./richideas/articles/the-internet-of-things-infographic" target="_blank"><PhotoTile class="article img1" src="site_assets/images/gallery/gallery1-2.jpg" /></a>
                    <a aria-label="Snapchat 101 And How To Use It In Digital Marketing" href="./richideas/articles/snapchat-101-and-how-to-use-it-in-digital-marketing" target="_blank"><PhotoTile class="article img2" src="site_assets/images/gallery/gallery2_phonechange.jpg"/></a>
                </div>
                <If condition="{this.props.rmTheme === `halloween`}">
                    <div class="column2">
                        <a aria-label={this.props.rmEventSpecific.imageLabel} href={this.props.rmEventSpecific.imageURL} target="_blank"><PhotoTile class="article img3" src={this.props.rmEventSpecific.imageSrc}/></a>
                    </div>
                </If>
                <If condition="{this.props.rmTheme !== `halloween`}">
                    <div class="column2">
                        <!-- TOCHECK -->
                        <a aria-label={this.props.rmEvent.imageLabel} href={this.props.rmEvent.imageURL} target="_blank"><PhotoTile class="article img3" src={this.props.rmEvent.imageSrc}/></a>
                    </div>
                </If>
                <div class="column">
                    <a aria-label="Getting The Most Out Of LinkedIn – The Do’s And Don’ts" href="./richideas/articles/getting-the-most-out-of-linkedIn-the-dos-and-donts" target="_blank"><PhotoTile class="article img5" src="site_assets/images/gallery/gallery10.jpg "/></a>
                    <a aria-label="Desktop, Tablet And Phone - Different Uses, Different Times" href="./richideas/articles/desktop-tablet-and-phone-different-uses-different-times" target="_blank"><PhotoTile class="article img4" src="site_assets/images/gallery/gallery4.jpg"/></a>
                </div>
                <If condition="{this.props.rmTheme === `halloween`}">
                    <div class="column">
                        <a aria-label={this.props.rmSeasonSpecific.imageLabel} href={this.props.rmSeasonSpecific.imageURL} target="_blank"><PhotoTile class="article img6" src={this.props.rmSeasonSpecific.imageSrc}/></a>
                        <a aria-label="Vivid Storytelling With Facebook Instant Articles" href="./richideas/articles/vivid-storytelling-with-facebook-instant-articles" target="_blank"><PhotoTile class="article img7" src="site_assets/images/gallery/gallery7-2.jpg"/></a>
                    </div>
                </If>
                <If condition="{this.props.rmTheme !== `halloween`}">
                    <div class="column">
                        <a aria-label={this.props.rmSeason.imageLabel} href={this.props.rmSeason.imageURL} target="_blank"><PhotoTile class="article img6" src={this.props.rmSeason.imageSrc}/></a>
                        <a aria-label="Vivid Storytelling With Facebook Instant Articles" href="./richideas/articles/vivid-storytelling-with-facebook-instant-articles" target="_blank"><PhotoTile class="article img7" src="site_assets/images/gallery/gallery7-2.jpg"/></a>
                    </div>
                </If>
                <div class="column">
                    <a aria-label="Pinterest 101" href="./richideas/articles/everything-you-need-to-know-about-pinterest" target="_blank"><PhotoTile class="article img8" src="site_assets/images/gallery/gallery8.jpg"/></a>
                    <a aria-label="6 Digital Marketing Trends For 2015" href="./richideas/articles/how-to-invest-your-2019-digital-marketing-budget-wisely" target="_blank"><PhotoTile class="article img9" src="site_assets/images/gallery/gallery9-3.jpg"/></a>
                </div>
            </div>

            <div class="content">
                <div class="buttons buttons-yellow">
                    <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                    <RouterLink
                        role="button"
                        to="/portfolio"
                        query="{{f: 'social-game'}}"
                        className="yellow-button black-button"
                        onClick="{this.portfolioClick}"
                    >Portfolio <span class="chevron right"></span></RouterLink>
                </div>
            </div>
        </div>
    </div>
    <Collapsible open={this.state.isOpen} duration="250" ease="easeInOut" onOpened={this.accordionOpened}>
        <div class="learn-more" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
            <If condition="{!this.props.specialIndustry}">
                <div class="learn-more-content">
                    <div class="item">
                        <img src="{`site_assets/images/icons/SVG/shareable-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Upload icon"/>
                        <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Shareable text, picture and video content that stands out on major social platforms</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item-line"></div>
                    <div class="item">
                        <img src="{`site_assets/images/icons/SVG/promo-content-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Lightbulb icon"/>
                        <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Content for promotion, education, lead generation, and more</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item-line"></div>
                    <div class="item item3">
                    <img src="{`site_assets/images/icons/SVG/engaging-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                        <p class="even"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Engaging content for public sites or private networks</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item-line"></div>
                    <div class="item">
                        <img src="{`site_assets/images/icons/SVG/social-video-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                        <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Short and sweet animation and video posts that light up any feed</span></p>
                    </div>
                </div>
            </If>
            <If condition="{this.props.specialIndustry}">
                <div>
                    <If condition="{this.props.industry == 'financial'}">
                        <div class="learn-more-content no-wrap">
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/shareable-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Upload icon"/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Informative and eye-catching text, picture and video content</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/promo-content-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Lightbulb icon"/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Stand out on all major social platforms</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/engaging-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                                <p class="even"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Engaging content for public sites or private networks</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/social-video-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Content for promotion, education, lead generation, and more</span></p>
                            </div>
                        </div>
                    </If>
                    <If condition="{this.props.industry == 'pharma'}">
                        <div class="learn-more-content no-wrap">
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/shareable-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Upload icon"/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Shareable and professional text, picture and video content</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/promo-content-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Lightbulb icon"/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Stand out on all major social platforms</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/engaging-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                                <p class="even"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Engaging content for public sites or private networks</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item-line"></div>
                            <div class="item">
                                <img src="{`site_assets/images/icons/SVG/social-video-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="Speaker icon "/>
                                <p><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Content for promotion, education, lead generation, and more</span></p>
                            </div>
                        </div>
                    </If>
                </div>
            </If>
        </div>
    </Collapsible>
</div>
</template>
<script>
    import {
        createRef
    } from 'rmlibrary/comp';
    import {
        scrollIntoView
    } from "rmlibrary/react-scroll-into-view";

    import {CustomizationConsumer} from "../../common/customization";
    import {trackLearnMore, trackPortfolio} from '../../../tracking';
    import {getCurrentEventData, getSpecificEventData} from "../../common/dynamic-social-event";

    export function SocialMedia(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <SocialMedia__ {...props} 
            specialIndustry={customizationState.specialIndustry}
            industry={customizationState.industry}
            rmEvent={getCurrentEventData("holiday")}
            rmEventSpecific={getSpecificEventData("holiday", 9, 15)}
            rmSeason={getCurrentEventData("season")}
            rmSeasonSpecific={getSpecificEventData("season", 9, 15)}
            rmTheme={customizationState.theme} /> }
        </CustomizationConsumer>)
    }

    class SocialMediaController extends Component {
        constructor(props) {
            super(props);

            this.scrollRef = createRef();

            this.state = {
                isOpen: false
            }

            this.toggleAccordion = this.toggleAccordion.bind(this);
            this.portfolioClick = this.portfolioClick.bind(this);
            this.accordionOpened = this.accordionOpened.bind(this);
        }

        toggleAccordion() {
            if (!this.state.isOpen) {
                trackLearnMore("social-media-content-marketing", "Social Media & Content Marketing");
            }
            this.setState({
                isOpen: !this.state.isOpen
            });
        }

        portfolioClick() {
            trackPortfolio("social-media-content-marketing");
        }

        accordionOpened() {
            if (!this.scrollRef.current) return;
            let offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(this.scrollRef.current, {
                block: 'center',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }
    }

</script>
