<import name="RMRouterRoot, Route" from="rmlibrary/router" />
<import name="Fragment" from="rmlibrary/comp" />
<import name="FocusDetect," from="rmlibrary/focus-detect" />
<import name="SearchContextProvider" from="../../richideas/search-context.js" />

<import name="Header" from="./header.html" />
<import name="Footer" from="./footer.html" />

<import name="IndexPage" from="../pages/index.html" />
<import name="PortfolioPage" from="../pages/portfolio.html" />
<import name="Privacy" from="../pages/privacy.html" />
<import name="ContactPage" from="../pages/contact.html" />
<import name="HolidayVideo2018" from="../pages/2018-holiday-video.html" />

<import name="PharmaRedirect" from="../pages/pharma-redirect" />

<import name="DebugSiteOrder" from="../pages/debug-site-order.html" />

<import name="Careers" from="../pages/careers.html" />
<import name="AccountManager" from="../pages/accountmanager.html" />
<import name="DesignerCopy" from="../pages/graphicdesignercopy.html" />
<import name="GraphicDesigner" from="../pages/graphicdesigner.html" />
<import name="Internship" from="../pages/internship.html" />
<import name="Programmer" from="../pages/programmer.html" />
<import name="QAAnalyst" from ="../pages/qaanalyst.html" />
<import name="ApplyAnyway" from="../pages/apply-anyway.html" />
<import name="CareersRedirect" from="../pages/careers-redirect" />

<import name="SurveyBase" from="../pages/survey/base.js" />
<import name="SurveyLanding" from="../pages/survey/landing.html" />
<import name="SurveyRating" from="../pages/survey/rating.html" />
<import name="SurveyServices" from="../pages/survey/services.html" />
<import name="SurveyProducts" from="../pages/survey/products.html" />
<import name="SurveyRecommend" from="../pages/survey/recommend.html" />
<import name="SurveyEmail" from="../pages/survey/email.html" />
<import name="SurveyResults" from="../pages/survey/results.html" />
<import name="SliderTest" from="../pages/survey/slider-test.html" />

<import name="RichIdeasSearch" from="../pages/rich-ideas-search.html" />

<import name="CustomizationProvider, CustomizationConsumer" from="./customization" />

<import name="PORTFOLIO,FEATURED,APPS_WEBSITE,INTERACTIVE_TOOLS,ANIMATION,LIVE_ACTION,SOCIAL_GAME" from="../../portfolio-data" />

<!--
 <import name="VideoTestPage" from="../pages/video-test.html" />
<import name="CollapsibleTestPage" from="../pages/collapsible-test.html" />
<import name="AccordionTestPage" from="../pages/accordion-test.html" />
<import name="TabsTestPage" from="../pages/tabs-test.html" />
<import name="MenuTestPage" from="../pages/menu-test.html" />
-->
<!--<import name="LineGraphTestPage" from="../pages/line-graph-test.html" />-->
<!--<import name="Page404" from="../pages/page404.html" /> -->

<link href="http://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">

<style lang="scss">
    @import 'sass/variables';
</style>

<template name="PageTemplate">
    <FocusDetect>
        <CustomizationProvider>
            <PageTemplateInner />
        </CustomizationProvider>
    </FocusDetect>
</template>
<template name="PageTemplateInner_" extends="PageTemplateController">
    <FocusDetect>
        <!--<CustomizationProvider> -->
            <div class.enable-redacted="{this.isRedacted()}">
                <SearchContextProvider>
                    <RMRouterRoot onRouteChange="{this.handleRouteChange}">
                        <Header />
    
                        <Route path="/" component="{IndexPage}" title="Rich Media" />
                        <Route path="/portfolio;/portfolio/:project" component="{PortfolioPage}" title="{this.portfolioPageTitle}" />
                        <Route path="/contact" component="{ContactPage}" title="Contact Us - Rich Media" />
                        <Route path="/privacy" component="{Privacy}" title="Privacy Policy - Rich Media" />
    
                        <Route path="/pharma" component="{PharmaRedirect}" title="Rich Media" />
    
                        <!-- Switch component to CareersRedirect for inactive job posting -->
                        <Route path="/careers" component="{Careers}" title="Careers - Rich Media" />
                        <Route path="/careers/accountmanager" component="{AccountManager}" title="Digital Marketing Sales - Careers - Rich Media" />
                        <Route path="/careers/graphicdesigner" component="{GraphicDesigner}" title="Graphic Designer - Careers - Rich Media" />
                        <Route path="/careers/designercopy" component="{CareersRedirect}" title="Graphic Designer &amp; Copy Writer - Careers - Rich Media" />
                        <Route path="/careers/qaanalyst" component="{CareersRedirect}" title="Junior Digital Quality Assurance Analyst - Careers - Rich Media" />
                        <Route path="/careers/programmer" component="{Programmer}" title="Web & Mobile Developer - Careers - Rich Media" />
                        <Route path="/careers/internship" component="{CareersRedirect}" title="Co-op & Internships - Careers - Rich Media" />

                        <Route path="/careers/apply" component="{ApplyAnyway}" title="No posting? Apply anyway - Rich Media" />
    
                        <!-- Survey Links -->
                        <Route path="/survey" component="{SurveyBase}" title="Survey - Rich Media" />
                        <Route path="/survey/rating" component="{SurveyRating}" title="Survey - Rich Media" />
                        <Route path="/survey/services" component="{SurveyServices}" title="Survey - Rich Media" />
                        <Route path="/survey/products" component="{SurveyProducts}" title="Survey - Rich Media" />
                        <Route path="/survey/recommend" component="{SurveyRecommend}" title="Survey - Rich Media" />
                        <Route path="/survey/email" component="{SurveyEmail}" title="Survey - Rich Media" />
                        <Route path="/survey/results" component="{SurveyResults}" title="Survey - Rich Media" />

                        <If c="{!IS_PRODUCTION}">
                            <Fragment>
                                <Route path="/__banner_order" component="{DebugSiteOrder}" title="BANNER SETUP" />
    
                                <Route path="/survey/slider" component="{SliderTest}" title="Survey - Rich Media" />
                                <Route path="/survey/base" component="{SurveyBase}" title="Survey - Rich Media" />
    
                                <Route path="/new-richideas/search" component="{RichIdeasSearch}" title="Search Results - Rich Media" />
                            </Fragment>
                        </If>
    
                        <Footer />
                    </RMRouterRoot>
                </SearchContextProvider>
            </div>
        <!--</CustomizationProvider> -->
    </FocusDetect>
</template>

<script>
    import { campaignURLCheck } from "./hs-track";

    export function PageTemplateInner(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <PageTemplateInner_ {...props} 
            trackPage={customizationState.trackPage}
            whatBrings={customizationState.whatBrings}
            updateCurrentPage={customizationState.updateCurrentPage}
            theme={customizationState.theme} /> }
        </CustomizationConsumer>)
    }

    function getProjectBySlug(slug) {
        return PORTFOLIO.filter((p) => p.slug === slug)[0];
    }

    const FILTER_NAMES = {
        [FEATURED]: "Featured",
        [APPS_WEBSITE]: "Apps/Websites",
        [INTERACTIVE_TOOLS]: "Interactive Tools",
        [ANIMATION]: "Animation",
        [LIVE_ACTION]: "Live Action",
        [SOCIAL_GAME]: "Social/Games",
    }

    class PageTemplateController extends Component {
        constructor(props) {
            super(props);

            this.handleOrientationChange = this.handleOrientationChange.bind(this);
            this.portfolioPageTitle = this.portfolioPageTitle.bind(this);
            this.handleRouteChange = this.handleRouteChange.bind(this);
        }

        componentDidMount() {
            window.addEventListener("onorientationchange", this.handleOrientationChange);
        }

        componentWillUnmount() {
            window.removeEventListener("onorientationchange", this.handleOrientationChange)
        }

        /* Trigger the 'resize' event when the device orientation changes */
        handleOrientationChange() {
            setTimeout(() => {
                if (typeof Event === 'function') // Everything except IE
                    window.dispatchEvent(new Event('resize'));
                else { // IE
                    let e = window.document.createEvent('UIEvents');
                    e.initUIEvent('resize', true, false, window, 0);
                    window.dispatchEvent(e);
                }
            }, 0);
        }

        portfolioPageTitle({query, variables}) {
            if (variables.project) {
                const project = getProjectBySlug(variables.project);
                if (project) return `Portfolio - ${project.client} - ${project.title} - Rich Media`;
            }
            if (query.f) {
                const filterName = FILTER_NAMES[query.f];
                if (filterName) return `Portfolio - ${filterName} - Rich Media`;
            }

            return `Portfolio - ${FILTER_NAMES[FEATURED]} - Rich Media`;
        }

        handleRouteChange({title, path}) {
            let trackingTitle = title.replace(/ - Rich Media$/i, "");
            if (trackingTitle === 'Rich Media') trackingTitle = "Home";

            //check url, if it contains campaign params return campaign params
            //only intitial site visit will contain campaign url params if any
            let isCampaign = campaignURLCheck();

            if (isCampaign) {
                let campaignTrackingTitle = `Source - ${isCampaign.source} *** Campaign - ${isCampaign.campaign} *** ${trackingTitle}`;
                this.props.trackPage(campaignTrackingTitle);
            } else {
                this.props.trackPage(trackingTitle);
            }
            this.props.updateCurrentPage(trackingTitle, path);
            sessionStorage.setItem('currentPage', trackingTitle);
        }

        isRedacted() {
            // DELETE THE IS_PRODUCTION CHECK TO RE-ENABLE REDACTED FUNCTIONALITY
            if (!IS_PRODUCTION) {
                if (this.props.theme === 'redacted') {
                    return true;
                }
            }
            
            return false;
        }
    }
</script>
