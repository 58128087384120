import { Component, createElement } from "rmlibrary/comp";

const OUTER_STYLES = {
    display: 'none',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    background: "rgba(0,0,0,0.2)",
    zIndex: 10000,
};
const SR_ONLY_STYLES = {
    clip: "rect(1px, 1px, 1px, 1px)",
    clipPath: "inset(50%)",
    height: "1px",
    width: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: 0,
    position: "absolute",

}

export class RMModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.close = this.close.bind(this);
        this._focusFirst = this._focusFirst.bind(this);
        this._focusLast = this._focusLast.bind(this);
    }

    get isOpen() {
        return this.state.open;
    }

    open() {
        if (this.state.open) throw new Error("RMModal: the modal is already open.");

        this.returnFocus = document.activeElement;
        this.setState({
            open: true
        });

        return new Promise((resolve) => {
            this.resolver = resolve;
        });
    }

    close(returnValue) {
        this.setState({
            open: false
        }, () => {
            if (this.resolver) {
                this.resolver(returnValue);
                this.resolver = null;
            }
            try {
                this.returnFocus.focus();
            } catch(e) {
                // Do nothing
            }
        })
    }

    _findFocusable() {
        if (!this.element) return [];
        return Array.from(this.element.querySelectorAll("input, select, textarea, button, object, a[href], [tabindex]"))
            .filter((el) => el.tabIndex >= 0)
            .filter((el) => !el.classList.contains('rm-react-modal-trap'));
    }

    _focusFirst() {
        const focusable = this._findFocusable();
        const first = focusable[0];
        if (!first) return;
        first.focus();
    }

    _focusLast() {
        const focusable = this._findFocusable();
        const last = focusable[focusable.length - 1];
        if (!last) return;
        last.focus();
    }

    render() {
        if (!this.props.content) throw new Error("RMModal: You must provide a component as a 'content' prop.");

        const C = this.props.content;
        const outerStyles = {
            ...OUTER_STYLES,
            ...this.props.style,
            display: this.state.open ? 'flex' : 'none'
        };

        return (<div style={outerStyles} ref={ref => this.element = ref}>
            <button className="rm-react-modal-trap" onFocus={this._focusLast} style={SR_ONLY_STYLES}></button>
            {this.state.open ? <C {...this.props.contentProps} closeModal={this.close} /> : null}
            <button className="rm-react-modal-trap" onFocus={this._focusFirst} style={SR_ONLY_STYLES}></button>
        </div>)
    }
}