export const FEATURED = "featured";
export const APPS_WEBSITE = "apps-website";
export const INTERACTIVE_TOOLS = "interactive";
export const ANIMATION = "animation";
export const LIVE_ACTION = "live-action";
export const SOCIAL_GAME = "social-game";

export const TEMPLATE_1 = "TEMPLATE_1";
export const TEMPLATE_2 = "TEMPLATE_2";
export const TEMPLATE_3 = "TEMPLATE_3";
export const TEMPLATE_4 = "TEMPLATE_4";

export const PORTFOLIO = [
    {
        client: "Edward Jones",
        title: "Edward Jones TV Commercial",
        slug: "edward-jones-whiteboard",
        description: "“Why Edwards Jones” whiteboard animated video and national television commercial.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-edwardjones.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-edwardjones.png",
            tileBg: "site_assets/images/portfolio/edward-jones/edward-tile-2.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/edward-jones/edward-desktop-1.png",
            sideOne: "site_assets/images/portfolio/edward-jones/edward-desktop-2.png",
            sideTwo: "site_assets/images/portfolio/edward-jones/edward-desktop-4.png",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [FEATURED, ANIMATION],
    },
    {
        client: "Sun Life Financial",
        title: "Advisor Match",
        slug: "sun-life-advisor-match",
        description: "Find a financial advisor based on your needs.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/advisor-match/advisor-match-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/advisor-match/advisor-match-desktop.jpg",
            sideOne: "site_assets/images/portfolio/slf/advisor-match/advisor-match-side1.jpg",
            sideTwo: "site_assets/images/portfolio/slf/advisor-match/advisor-match-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [FEATURED, INTERACTIVE_TOOLS, LIVE_ACTION],
    },
    {
        client: "Sun Life Financial",
        title: "My Retirement Café",
        slug: "sun-life-retirement-cafe",
        description: "A 3D retirement resource centre, with video, calculators, social sharing & games.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-desktop.jpg",
            sideOne: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-side1.jpg",
            sideTwo: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [LIVE_ACTION],
    },
    {
        client: "Sun Life Financial",
        title: "Unretirement Index Tool",
        slug: "sun-life-unretirement",
        description: "A tool seeking opinions of retirement with social sharing features.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/unretirement/unretirement-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/slf/unretirement/unretirement-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/slf/unretirement/unretirement-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [FEATURED, INTERACTIVE_TOOLS],
    },
    {
        client: "Sun Life Financial",
        title: "Sesquicentennial Video",
        slug: "sun-life-150",
        description: "A journey through some key moments in Sun Life’s 150 year history.",
        link: "https://www.youtube.com/watch?v=MXeu0jhSreY",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/Sesquicentennial/150-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/Sesquicentennial/150-2.jpg",
            sideOne: "site_assets/images/portfolio/slf/Sesquicentennial/150-3.jpg",
            sideTwo: "site_assets/images/portfolio/slf/Sesquicentennial/150-4.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [ANIMATION, LIVE_ACTION],
    },
    {
        client: "Sun Life Financial",
        title: "Calculator Suite",
        slug: "slf-calculator-suite",
        description: "Tools and calculators designed to guide users in making critical life decisions.",
        link: "https://www.sunlife.ca/ca/Learn+and+Plan/Tools+and+Calculators?vgnLocale=en_CA",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/all-calc/slf-all-calc-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/all-calc/calc-desktop.jpg",
            sideOne: "site_assets/images/portfolio/slf/all-calc/calc-side2.jpg",
            sideTwo: "site_assets/images/portfolio/slf/all-calc/calc-side1.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [INTERACTIVE_TOOLS],
    },
    {
        client: "Scotiabank",
        title: "Scotia Asset Management",
        slug: "scotiabank-sam",
        description: '"Scotia Investing Essentials" Video Series',
        link: "https://www.scotiafunds.com/scotiafunds/en/learning-centre/videos.html",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/sam/sam-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/sam/sam-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/sam/sam-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/sam/sam-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [ANIMATION],
    },
    {
        client: "Scotiabank",
        title: "Dream Home Finder",
        slug: "scotiabank-dream-home-finder",
        description: "An app designed to organize and rank homes of interest, with an animated demo.",
        /*link: "https://dreamhomefinder.scotiabank.com/",*/
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/dream-home/dream-home-tile.png",
            laptop: "site_assets/images/portfolio/final_laptop.png",
            laptopImg: "site_assets/images/portfolio/scotia/dream-home/dream-home-laptop.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/scotia/dream-home/dream-home-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_1,
        categories: [FEATURED, APPS_WEBSITE, INTERACTIVE_TOOLS],
    },
    {
        client: "Scotiabank",
        title: "Better Business Banking",
        slug: "scotiabank-better-business-banking",
        description: "A calculator with video case studies to help with business growth.",
        link: "http://www.scotiabank.com/efficiency/tool/en/index.html",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/better-business/better-business-banking-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/better-business/better-business-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/better-business/better-business-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/better-business/better-business-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [INTERACTIVE_TOOLS, LIVE_ACTION],
    },
    {
        client: "Scotiabank",
        title: "Scotia Plan Writer for business",
        slug: "scotiabank-plan-writer",
        description: "A one stop small business plan generator that exports to Word or Excel.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/plan-writer/plan-writer-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/plan-writer/plan-writer-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/plan-writer/plan-writer-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/plan-writer/plan-writer-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [INTERACTIVE_TOOLS],
    },
    {
        client: "Scotiabank",
        title: "Scotia Total Equity Plan",
        slug: "scotiabank-step",
        description: "How to get ahead in achieving your goals sooner!",
        link: "https://www.youtube.com/watch?v=FGAji5a_Rjc",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/step/step-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/step/step-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/step/step-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/step/step-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [ANIMATION],
    },
    {
        client: "Scotiabank",
        title: "Small Business Credit Card Selector",
        slug: "scotiabank-credit-card-selector",
        description: "A simple selection calculator.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/credit-card/credit-card-selector-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/credit-card/credit-card-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/credit-card/credit-card-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/credit-card/credit-card-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [INTERACTIVE_TOOLS],
    },
    {
        client: "Scotiabank",
        title: "PAC Video",
        slug: "scotiabank-pac-video",
        description:
            "A dynamic video (including the user's inputs) illustrating the benefits of a Scotiabank PAC account.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/pac/pac-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/scotia/pac/pac-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/scotia/pac/pac-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [ANIMATION],
    },
    {
        client: "Scotiabank",
        title: "Tradexpress",
        slug: "scotiabank-tradexpress",
        description: "Videos on the new features  of the Scotiabank TRADEXPRESS elite™ Plus services portal.",
        link: "https://www.youtube.com/watch?v=KiqnY3LlhhU",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/trade-express/trade-express-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/trade-express/trade-express-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/trade-express/trade-express-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/trade-express/trade-express-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [ANIMATION],
    },
    {
        client: "Scotiabank",
        title: "Remote Deposit",
        slug: "scotiabank-remote-deposit",
        description: "A video introducing Scotiabank's remote cheque depositing service for small business.",
        link: "https://www.youtube.com/watch?v=zODlUJzr1ag",
        images: {
            clientLogo: "site_assets/images/logos/logo-scotiabank.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-scotiabank.png",
            tileBg: "site_assets/images/portfolio/scotia/remote-deposit/remote-deposit-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/remote-deposit/remote-deposit-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/remote-deposit/remote-deposit-side1.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/remote-deposit/remote-deposit-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [ANIMATION],
    },
    {
        client: "Scotiabank",
        title: "Scotia iTrade ETF",
        slug: "scotiabank-itrade-etf",
        description: "Find out information on how to invest on ETFs.",
        link: "https://www.youtube.com/watch?v=MzDcaqP_meo",
        images: {
            clientLogo: "site_assets/images/logos/logo-itrade.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-itrade.png",
            tileBg: "site_assets/images/portfolio/scotia/etf-video/etf-video-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/scotia/etf-video/etf-video-desktop.jpg",
            sideOne: "site_assets/images/portfolio/scotia/etf-video/etf-video-side2.jpg",
            sideTwo: "site_assets/images/portfolio/scotia/etf-video/etf-video-side1.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [ANIMATION],
    },
    {
        client: "Samsung",
        title: "The Wayne Gretzky Classic",
        slug: "samsung-wayne-gretzky-classic-golf-game",
        description: "A 3D golf game presented by Samsung print solutions.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-samsung.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-samsung.png",
            tileBg: "site_assets/images/portfolio/samsung/wayne/wayne-gretzky-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/samsung/wayne/gretzky-desktop.jpg",
            sideOne: "site_assets/images/portfolio/samsung/wayne/gretzky-1.jpg",
            sideTwo: "site_assets/images/portfolio/samsung/wayne/gretzky-2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME],
    },
    {
        client: "Samsung",
        title: "Home Appliances App",
        slug: "samsung-kiosk-app",
        description: "A home appliance feature guide for kiosks.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-samsung.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-samsung.png",
            tileBg: "site_assets/images/portfolio/samsung/home-appliance/home-appliance-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/samsung/home-appliance/home-appliance-desktop.jpg",
            sideOne: "site_assets/images/portfolio/samsung/home-appliance/home-appliance-side1.jpg",
            sideTwo: "site_assets/images/portfolio/samsung/home-appliance/home-appliance-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [INTERACTIVE_TOOLS],
    },
    {
        client: "Samsung",
        title: "Samsung Pay",
        slug: "samsung-pay",
        description: "Promotional site introducing Samsung Pay to Canada.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-samsung.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-samsung.png",
            tileBg: "site_assets/images/portfolio/samsung/pay/pay-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/samsung/pay/pay-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/samsung/pay/pay-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [APPS_WEBSITE],
    },
    {
        client: "Samsung",
        title: "Tablet Selector",
        slug: "samsung-tablet-selector",
        description: "A showcase of Samsung tablets, including a personalized product selector.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-samsung.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-samsung.png",
            tileBg: "site_assets/images/portfolio/samsung/tablet/tablet-selector-tile.png",
            laptop: "site_assets/images/portfolio/final_laptop.png",
            laptopImg: "site_assets/images/portfolio/samsung/tablet/tablet-selector-laptop.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/samsung/tablet/tablet-selector-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_1,
        categories: [FEATURED, APPS_WEBSITE, INTERACTIVE_TOOLS],
    },
    {
        client: "Samsung",
        title: "Gear Up",
        slug: "samsung-gear-website",
        description: "A showcase of Samsung wearables.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-samsung.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-samsung.png",
            tileBg: "site_assets/images/portfolio/samsung/gear/gear-site-tile.jpg",
            laptop: "site_assets/images/portfolio/final_laptop.png",
            laptopImg: "site_assets/images/portfolio/samsung/gear/gear-site-laptop.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/samsung/gear/gear-site-mobile-2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_1,
        categories: [FEATURED],
    },
    {
        client: "CAA",
        title: "Life Stages",
        slug: "caa-life-stages",
        description: "Insurance options for every stage of your life.",
        link: "https://www.youtube.com/watch?v=X-cAwb4zcAo",
        images: {
            clientLogo: "site_assets/images/logos/logo-caa.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-caa.png",
            tileBg: "site_assets/images/portfolio/caa/caa-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/caa/caa-desktop.jpg",
            sideOne: "site_assets/images/portfolio/caa/caa-side1.jpg",
            sideTwo: "site_assets/images/portfolio/caa/caa-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [FEATURED, ANIMATION],
    },
    {
        client: "Walmart",
        title: "Credit Card Application",
        slug: "walmart-mastercard",
        description: "Banner ad campaign and credit card application",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-walmart.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-walmart.png",
            tileBg: "site_assets/images/portfolio/walmart/mastercard-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/walmart/mastercard.jpg",
            walmartsideOne: "site_assets/images/portfolio/walmart/walmart_banner_1.gif",
            walmartsideTwo: "site_assets/images/portfolio/walmart/walmart_banner_2.gif",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [FEATURED, ANIMATION],
    },
    {
        client: "Genworth",
        title: "House to Home",
        slug: "genworth-facebook-game",
        description: "An interactive Facebook game linked with a contest.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-genworth.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-genworth.png",
            tileBg: "site_assets/images/portfolio/genworth/facebook-game/facebook-game-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/genworth/facebook-game/facebook-game-desktop.jpg",
            sideOne: "site_assets/images/portfolio/genworth/facebook-game/facebook-game-side1.jpg",
            sideTwo: "site_assets/images/portfolio/genworth/facebook-game/facebook-game-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME],
    },
    {
        client: "Genworth",
        title: "Edge by Genworth Canada",
        slug: "genworth-broker-site",
        description: "A resource centre microsite for mortgage industry professionals.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-genworth.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-genworth.png",
            tileBg: "site_assets/images/portfolio/genworth/broker/broker-site-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/genworth/broker/broker-desktop.jpg",
            sideOne: "site_assets/images/portfolio/genworth/broker/broker-side1.jpg",
            sideTwo: "site_assets/images/portfolio/genworth/broker/broker-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "Genworth",
        title: "The Meaning of Home",
        slug: "genworth-meaning-of-home",
        description: "Microsite promoting Habitat for Humanity featuring an annual writing contest & games.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-genworth.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-genworth.png",
            tileBg: "site_assets/images/portfolio/genworth/meaning-of-home/meaning-of-home-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/genworth/meaning-of-home/meaning-of-home-desktop.jpg",
            sideOne: "site_assets/images/portfolio/genworth/meaning-of-home/meaning-of-home-side2.jpg",
            sideTwo: "site_assets/images/portfolio/genworth/meaning-of-home/meaning-of-home-side1.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE, SOCIAL_GAME],
    },
    {
        client: "Genworth",
        title: "Development Centre Game",
        slug: "genworth-development-centre-game",
        description: "Online tutorials and informative games to enhance advisors experience & knowledge.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-genworth.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-genworth.png",
            tileBg: "site_assets/images/portfolio/genworth/development/development-centre-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/genworth/development/development-desktop1.jpg",
            sideOne: "site_assets/images/portfolio/genworth/development/development-side1-1.jpg",
            sideTwo: "site_assets/images/portfolio/genworth/development/development-side2-1.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME],
    },
    {
        client: "BMO",
        title: "Golf Game",
        slug: "bmo-golf-game",
        description: "Putt it in to Win! An animated and interactive trade show golf game.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-bmo.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-bmo.png",
            tileBg: "site_assets/images/portfolio/bmo/golf-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/bmo/golf-desktop.jpg",
            sideOne: "site_assets/images/portfolio/bmo/golf-side1.jpg",
            sideTwo: "site_assets/images/portfolio/bmo/golf-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME],
    },
    {
        client: "Vera Wang",
        title: "Simply Vera Wang",
        slug: "vera-wang-simplyvera",
        description: "Bridal jewellery product video.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-verawang.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-verawang.png",
            tileBg: "site_assets/images/portfolio/vera-wang/simply-vera-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/vera-wang/simply-vera-desktop.jpg",
            sideOne: "site_assets/images/portfolio/vera-wang/simply-vera-side1.jpg",
            sideTwo: "site_assets/images/portfolio/vera-wang/simply-vera-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [FEATURED, LIVE_ACTION],
    },
    {
        client: "Sun Life Financial",
        title: "Unretirement Index Tool",
        slug: "sun-life-unretirement",
        description: "A tool seeking opinions of retirement with social sharing features.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/unretirement/unretirement-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/slf/unretirement/unretirement-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/slf/unretirement/unretirement-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [SOCIAL_GAME, APPS_WEBSITE],
    },
    {
        client: "Sun Life Financial",
        title: "My Retirement Café",
        slug: "sun-life-retirement-cafe",
        description: "A 3D retirement resource centre, with video, calculators, social sharing & games.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-desktop.jpg",
            sideOne: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-side1.jpg",
            sideTwo: "site_assets/images/portfolio/slf/retirementcafe/retirement-cafe-side2.jpg",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME, APPS_WEBSITE],
    },
    {
        client: "Sun Life Financial",
        title: "Money for Life",
        slug: "sun-life-money-for-life",
        description: "An interactive financial planning microsite.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-sunlife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sunlife.png",
            tileBg: "site_assets/images/portfolio/slf/money-for-life/money_for_life-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/slf/money-for-life/moneyforlife-desktop.jpg",
            sideOne: "site_assets/images/portfolio/slf/money-for-life/moneyforlife-side1.jpg",
            sideTwo: "site_assets/images/portfolio/slf/money-for-life/moneyforlife-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "Penguin Group",
        title: "The Fighter",
        slug: "penguin-group-the-fighter",
        description: "An interactive microsite for the book The Fighter.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-penguin.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-penguin.png",
            tileBg: "site_assets/images/portfolio/penguin/fighter-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/penguin/penguin-desktop.jpg",
            sideOne: "site_assets/images/portfolio/penguin/penguin-side1.jpg",
            sideTwo: "site_assets/images/portfolio/penguin/penguin-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [FEATURED, APPS_WEBSITE],
    },
    {
        client: "Desjardins",
        title: "Discover Campaign",
        slug: "Desjardins-Discover-Campaign",
        description: "A multi-media promotional campaign.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-desjardins.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-desjardins.png",
            tileBg: "site_assets/images/portfolio/desjardins/desjardins-tile2.jpg",
            /*desktop: "site_assets/images/portfolio/final_desktop.png"*/
            desktopImg: "site_assets/images/portfolio/desjardins/desjardins-streetcar2.jpg",
            desktopImg2: "site_assets/images/portfolio/desjardins/desjardins-streetcar-mobile2.jpg",
            gif1: "site_assets/images/portfolio/desjardins/family2.gif",
            gif2: "site_assets/images/portfolio/desjardins/home2.gif",
            gif3: "site_assets/images/portfolio/desjardins/world2.gif",
            gif4: "site_assets/images/portfolio/desjardins/long.gif",
            sideOne: "site_assets/images/portfolio/desjardins/desjardins-newspaper.png",
            /*sideTwo: "site_assets/images/portfolio/penguin/penguin-side2.jpg"*/
        },
        isFlash: false,
        template: TEMPLATE_4,
        categories: [FEATURED, ANIMATION],
    },
    {
        client: "Cayman Islands",
        title: "Cayman Calling",
        slug: "cayman-islands",
        description: "A dynamic and animated microsite for the Cayman Islands.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-caymanislands.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-cayman.png",
            tileBg: "site_assets/images/portfolio/cayman/cayman-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/cayman/cayman-desktop.jpg",
            sideOne: "site_assets/images/portfolio/cayman/cayman-side1.jpg",
            sideTwo: "site_assets/images/portfolio/cayman/cayman-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "CI Investments",
        title: "Professional Development",
        slug: "ci-investments-professional-development",
        description: "Provides access to resources, tools and social sharing.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-ciinvestments.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-ci.png",
            tileBg: "site_assets/images/portfolio/ciinvestments/professional-development-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/ciinvestments/ciinvestments.jpg",
            sideOne: "site_assets/images/portfolio/ciinvestments/ciinvestments-2.jpg",
            sideTwo: "site_assets/images/portfolio/ciinvestments/ciinvestments.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "Walmart",
        title: "Credit Card Application",
        slug: "walmart-mastercard",
        description: "Banner ad campaign and credit card application",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-walmart.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-walmart.png",
            tileBg: "site_assets/images/portfolio/walmart/mastercard-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/walmart/mastercard.jpg",
            walmartsideOne: "site_assets/images/portfolio/walmart/walmart_banner_1.gif",
            walmartsideTwo: "site_assets/images/portfolio/walmart/walmart_banner_2.gif",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "Manulife",
        title: "VRSP Consumer Site",
        slug: "manulife-vrsp",
        description: "Adaptive website for Manulife’s VRSP product",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-manulife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-manulife.png",
            tileBg: "site_assets/images/portfolio/manulife/vrsp/vrsp-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/manulife/vrsp/vrsp-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/manulife/vrsp/vrsp-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [FEATURED, APPS_WEBSITE],
    },
    {
        client: "Manulife",
        title: "Living With Mental Illness",
        slug: "manulife-stigma",
        description: "Educational video about understanding mental illness stigma.",
        link: "https://www.youtube.com/watch?v=hIMxpVXnMDI",
        images: {
            clientLogo: "site_assets/images/logos/logo-manulife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-manulife.png",
            tileBg: "site_assets/images/portfolio/manulife/mentalillness/mental-illness-tile.jpg",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/manulife/mentalillness/mental-illness-desktop.jpg",
            sideOne: "site_assets/images/portfolio/manulife/mentalillness/mental-illness-side1.jpg",
            sideTwo: "site_assets/images/portfolio/manulife/mentalillness/mental-illness-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [FEATURED, ANIMATION],
    },
    {
        client: "Manulife",
        title: "Group Benefits Mobile Demo",
        slug: "manulife-group-benefits",
        description: "Adaptive video and simulation tool for Manulife’s GB mobile application.",
        link: "",
        images: {
            clientLogo: "site_assets/images/logos/logo-manulife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-manulife.png",
            tileBg: "site_assets/images/portfolio/manulife/gb/gb-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/manulife/gb/gb-tablet1.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/manulife/gb/gb-mobile2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [ANIMATION],
    },
    {
        client: "Manulife",
        title: "Contribution Calculator",
        slug: "manulife-contribution-calculator",
        description: "An adaptive online tool and app for calculating group retirement savings contributions.",
        link: "http://events.snwebcastcenter.com/manulife/GBRS/Prod/Media/Calculators/Contribution/en/",
        images: {
            clientLogo: "site_assets/images/logos/logo-manulife.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-manulife.png",
            tileBg: "site_assets/images/portfolio/manulife/contribution/contribution-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/manulife/contribution/contribution-tablet.jpg",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/manulife/contribution/contribution-phone.jpg",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [INTERACTIVE_TOOLS],
    },
    {
        client: "Mawer",
        title: "Retirement Calculator",
        slug: "mawer-retirement-calculator",
        description: "An adaptive online tool to find out whether your current retirement plan is sufficient.",
        link: "https://www.mawer.com/tools-and-resources/retirement-calculator/",
        images: {
            clientLogo: "site_assets/images/logos/logo-mawer.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-mawer.png",
            tileBg: "site_assets/images/portfolio/mawer/retirement-calculator-tile.png",
            laptop: "site_assets/images/portfolio/final_laptop.png",
            laptopImg: "site_assets/images/portfolio/mawer/mawer-desktop.png",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/mawer/mawer-mobile.png",
        },
        isFlash: false,
        template: TEMPLATE_1,
        categories: [FEATURED, INTERACTIVE_TOOLS],
    },
    {
        client: "Sabina Gold",
        title: "Back River Project",
        slug: "sabina-gold-back-river-project",
        description: "Website with social sharing and newsletter content.",
        /*link: "http://backriverproject.com/",*/
        images: {
            clientLogo: "site_assets/images/logos/logo-sabina.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-sabina.png",
            tileBg: "site_assets/images/portfolio/sabina-gold/sabina-gold-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/sabina-gold/sabina-desktop.jpg",
            sideOne: "site_assets/images/portfolio/sabina-gold/sabina-side1.jpg",
            sideTwo: "site_assets/images/portfolio/sabina-gold/sabina-side2.jpg",
        },
        isFlash: false,
        template: TEMPLATE_3,
        categories: [APPS_WEBSITE],
    },
    {
        client: "CLHIA",
        title: "Retirement Guide",
        slug: "clhia",
        description: "A guide to retirement planning from CLHIA.",
        link: "https://www.clhia.ca/rs/en/index.html",
        images: {
            clientLogo: "site_assets/images/logos/logo-clhia.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-clhia.png",
            tileBg: "site_assets/images/portfolio/clhia/clhia-tile.png",
            tablet: "site_assets/images/portfolio/final_tablet.png",
            tabletImg: "site_assets/images/portfolio/clhia/clhia-tablet.JPG",
            phone: "site_assets/images/portfolio/final_phone.png",
            phoneImg: "site_assets/images/portfolio/clhia/clhia-phone.JPG",
        },
        isFlash: false,
        template: TEMPLATE_2,
        categories: [APPS_WEBSITE],
    },
    {
        client: "CDSPI",
        title: "Words of Financial Wisdom Quiz",
        slug: "cdspi-words-of-wisdom",
        description:
            "A fun and interactive quiz asking users to guess which financial planning related quotes were said from a wide range of well-known people.",
        link: "https://www.cdspi.com/words-of-wisdom-quiz/",
        images: {
            clientLogo: "site_assets/images/logos/logo-cdspi.png",
            clientLogoDesktop: "site_assets/images/logos/desktop/logo-cdspi.png",
            tileBg: "site_assets/images/portfolio/cdspi/cdspi-tile.png",
            desktop: "site_assets/images/portfolio/final_desktop.png",
            desktopImg: "site_assets/images/portfolio/cdspi/cdspi-screen1.png",
            sideOne: "site_assets/images/portfolio/cdspi/cdspi-screen2.png",
            sideTwo: "site_assets/images/portfolio/cdspi/cdspi-screen3.png",
        },
        isFlash: true,
        template: TEMPLATE_3,
        categories: [SOCIAL_GAME],
    },
];
