<import name="QuizOne" from="./personalization/quiz-one.html" />
<import name="clients, AMInfo, allClients" from="../../../client-list-AM" />
<import name="createContext" from='../../../../rmlibrary/comp' />
<import name="CustomizationProvider, CustomizationConsumer" from='../../common/customization' />
<import name="trackPersonalizationAll, trackPersonalizationEvent, trackPersonalizationTool" from='../../../tracking' />
<import name="getPersonalization, trackPage" from='../../common/hs-track' />



<template name="PersonalizationQuiz_" extends="PersonalizationQuizController">
    <div class="personalization-main" onKeyDown="{this.onKeyPressed}" tabIndex="0">
        <div class="button-holder" onMouseEnter="{this.reloadCanvas}">
            <div class="pointer" aria-hidden="true">
                <!--Personalize site -->
                <!--What is the code below meant to do?-->

                <div class="canvas-site" width="200px" height="200px">
                    <canvas id="canvas" ref="{this.personalizeCanvas}" width="300" height="175"></canvas>
                </div>

              <div class="IE-arrow"><img src="site_assets/images/icons/IE_arrow.png"></div>
                <!-- reference the component -->
                <svg id="Layer_1" tabIndex="-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.9 103.3" class="point-arrow">
                    <style>
                        .st0 {
                            fill: none;
                            stroke: #fff;
                            stroke-width: 1.5;
                            stroke-linecap: round;
                            stroke-miterlimit: 10
                        }
                    </style>
                    <path class="st0" d="M90.6 48.9l8.6 9.3-11.1 8.5M98.7 58.1s-12.7 7-46.2-12" fill="none"
                        stroke="#fff" strokeWidth="3" />
                </svg>
            </div>
            <button class="customize" onClick="{this.togglePersonalization}" aria-label="Personalize site" ref="personalization">    
                
                <!-- Animated gif for IE only -->
                <div class="IE-customize">
                    <img src="site_assets/images/icons/IE_custom-button.gif">
                </div>

                <svg class="customize-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.91 104.67">
                    <style>
                        .st1 {
                            fill: #fff;
                        }
                    </style>


                    <!---bg-cirlce-->

                    <circle class="black-bg" cx="58.31" cy="52.33" r="45.49" fill="#1a1a1a" opacity=".95" id="black-bg"/>

                    <g id="person">
                        <circle class="person" cx="58.31" cy="39.89" r="7.32" fill="#e5e5e5"/>
                        <path class="person" d="M58.31 51.08a16.48 16.48 0 0 0-16.67 16.28H75a16.47 16.47 0 0 0-16.69-16.28z"  fill="#e5e5e5"/>

                    </g>

    
                    <!--inner circle-->

                    <path class="inner-circle" d="M85.14 26.4a38.88 38.88 0 0 0-53.66 0 36 36 0 0 0-11.12 25.93 2.19 2.19 0 0 0 .69 1.67 2.37 2.37 0 0 0 3.28 0 2.18 2.18 0 0 0 .67-1.68 31.59 31.59 0 0 1 9.76-22.75 34.13 34.13 0 0 1 47.1 0 31.45 31.45 0 0 1 0 45.53 34.13 34.13 0 0 1-47.1 0l-3.28 3.17a38.88 38.88 0 0 0 53.66 0 35.83 35.83 0 0 0 0-51.87z"
                          fill="#e5e5e5"/>
                </svg>

            </button>
        </div>
        
        <div id="box">

        <div class="{`personalization-quiz ${this.state.quizOpen ? 'show' : 'hide'}`}" role="dialog" aria-labelledby="personalizationHed">
            <h2 class="sr-only" id="personalizationHed" tabIndex="0" ref="personalizationHed" onFocus={this.trapFocusTop}>Personalization Window</h2>

            <QuizOne 
                ref="quizOne" 
                handleCompanyBlur="{this.handleCompanyBlur}"
                togglePersonalization="{this.togglePersonalization}"
                openCard="{this.state.openCard}" 
                customization="{this.customization}" 
                handleChange="{this.handleChange}" 
                handleThemeChange="{this.handleThemeChange}" 
                handleIndustryChange="{this.handleIndustryChange}" 
                handleInterestChange="{this.handleInterestChange}" 
                handleCompanyChange="{this.handleCompanyChange}"
                handleNameChange="{this.handleNameChange}"
                handleWhatBringsChange="{this.handleWhatBringsChange}"
                state="{this.state}"
                cat="{this.cat}"
                >
            </QuizOne>
            <span class="sr-only"><button aria-label="close personalization window" onClick="{this.togglePersonalization}" onFocus={this.trapFocus}></button></span>

        </div>
    </div>
    </div>
</template>


<script>
     
import MainBannerAnimation from "./main-banner-animation";

    export function PersonalizationQuiz(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <PersonalizationQuiz_ {...props} 
            updateTheme={customizationState.updateTheme} 
            updateIndustry={customizationState.updateIndustry}
            updateInterest={customizationState.updateInterest}
            updateCompany={customizationState.updateCompany}
            updateName={customizationState.updateName}
            updateWhatBrings={customizationState.updateWhatBrings}
            updateAMContact={customizationState.updateAMContact}
            AMfirstName={customizationState.AMfirstName}
            setRedacted={customizationState.setRedacted}
            setPersonalizationOpen={customizationState.setPersonalizationOpen}
            updateCompanyAutofill={customizationState.updateCompanyAutofill}
        /> }
        </CustomizationConsumer>)
    }

    export class PersonalizationQuizController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                quizOpen: false,
                openCard: 1,
                userName: sessionStorage.getItem('name'),
                whatBrings: sessionStorage.getItem('whatBrings'),
                industry: sessionStorage.getItem('industry'),
                companyInput: false,    
                companyName: sessionStorage.getItem('company'),
                changedCompanyName: false,
                theme: sessionStorage.getItem('theme'),
                lastTheme: 'default',
                interest: sessionStorage.getItem('interest'),
                checkboxArray: [],
                userLastName: '',
                userFirstName: '',
                userEmail: '',
                userPhone: '',
                userTimes: '',
                AMname: '',
                AMfirstName: '',
                AMlastName: '',
                AMimgUrl: '',
                AMemail: '',
                AMphone: '',
                AMext: '',

                value: '',
                suggestions: [],
            }

            this.togglePersonalization = this.togglePersonalization.bind(this);
            this.assignAM = this.assignAM.bind(this);
            this.handleCompanyBlur = this.handleCompanyBlur.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.showCompanyInput = this.showCompanyInput.bind(this);
            this.toggleCheckbox = this.toggleCheckbox.bind(this);
            this.trapFocus = this.trapFocus.bind(this);
            this.trapFocusTop = this.trapFocusTop.bind(this);
            this.onKeyPressed = this.onKeyPressed.bind(this);
            this.skipContact = this.skipContact.bind(this);
            this.dotClick = this.dotClick.bind(this);
            this.handleClickOutsideQuiz = this.handleClickOutsideQuiz.bind(this);

            this.handleNameChange = this.handleNameChange.bind(this);
            this.handleWhatBringsChange = this.handleWhatBringsChange.bind(this);
            this.handleIndustryChange = this.handleIndustryChange.bind(this);
            this.handleCompanyChange = this.handleCompanyChange.bind(this);
            this.handleInterestChange = this.handleInterestChange.bind(this);
            this.handleThemeChange = this.handleThemeChange.bind(this);
            this.onAutofillClick = this.onAutofillClick.bind(this);
            this.clearAutofill = this.clearAutofill.bind(this);
            this.resizeFooter = this.resizeFooter.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            
            this.personalizeCanvas = this.personalizeCanvas.bind(this);
            this.reloadCanvas = this.reloadCanvas.bind(this);
        }
        
        reloadCanvas() {
            var canvas = document.getElementById("canvas");
            var ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.personalizeCanvas(canvas);
        }

        componentDidMount() {
            document.body.addEventListener("click", this.handleClickOutsideQuiz);
            this.resizeFooter();
            /*
            this.moveLabelUp();
            this.moveLabelDown();
            */
        }

        componentWillUnmount() {
            document.body.removeEventListener("click", this.handleClickOutsideQuiz);
        }
        
        personalizeCanvas(ref) {
            if (this.__personalizeRAF) {
                window.cancelAnimationFrame(this.__personalizeRAF)
                this.__personalizeRAF = null;
            }
            
            if (!ref) return;

            var ctx = ref.getContext("2d"),
                dashLen = 220, dashOffset = dashLen, speed = 60, 
                txt = "Personalize \nsite", x = 30, y = 90, i = 0;

            ctx.font = "26px Kalam";
            ctx.lineWidth = 0; ctx.lineJoin = "round";
            ctx.strokeStyle = ctx.fillStyle = "#FFFFFF";  
            
            const loop = function() {
                ctx.setLineDash([dashLen - dashOffset, dashOffset - speed]); 
                dashOffset -= speed;                                             

                if (dashOffset > 0) {
                    this.__personalizeRAF = window.requestAnimationFrame(loop.bind(this));
                } else {
                    if (txt[i] === '\n') {
                        x = 30;
                        y += 30;
                        i++;
                    } else if( Math.round( ctx.measureText(txt).width ) != "179" ) {
                        i = 0;
                    } else {
                        ctx.fillText(txt[i], x, y);
                        dashOffset = dashLen;             
                        x += ctx.measureText(txt[i++]).width-0.625 + ctx.lineWidth;
                    }
                    if (i < txt.length) this.__personalizeRAF = window.requestAnimationFrame(loop.bind(this));
                }
            };
            loop.call(this);
        }

        onAutofillClick(e, eventType, index) {
            var value = null;
            if (eventType == "keydown") {
                value = e.target.nextElementSibling.children[index].textContent;
            } else {
                if (e.target.nextElementSibling) {
                    value = (e.target.nextElementSibling.value || e.target.textContent);
                } else value = e.target.textContent;
            }
            
            this.props.updateCompanyAutofill(value);
            this.setState({
                companyName: value,
            })
            document.querySelector("#companyInput").value = value;
            sessionStorage.setItem("company", value);
            this.clearAutofill(e);
        }

        onInputChange(e) {
            if (this.state.industry == 'financial' || this.state.industry == 'pharma') {
                this.clearAutofill(e);
                var currentFocus = -1;
                var autosuggestNames = []
                var clientNames = [];

                autosuggestNames = ([]).concat(...Object.keys(clients).map((amName) => clients[amName][this.state.industry]))

console.log(autosuggestNames);

                // if (this.state.industry == "financial" || this.state.industry == "pharma") {
                // autosuggestNames = clients["shannon"][this.state.industry]
                //     .concat(clients["kelly"][this.state.industry]);
                // }
                
                /* for (var AM in clients) {
                    for (var industry in clients[AM]) {
                        for (var client in clients[AM][industry]) {
                            clientNames.push(clients[AM][industry][client]);
                        }
                    }
                } */

                const userInput = e.currentTarget.value;
                var filteredClients = filterClients();
                
                function filterClients() {
                    let filteredClientList = [];
                    for (var i = 0; i < autosuggestNames.length; i++) {
                        for (var j = 0; j < autosuggestNames[i].length; j++) {
                            if (!autosuggestNames[i][j].toLowerCase().indexOf(userInput.toLowerCase()) && userInput.length >= 1) {
                                filteredClientList.push(autosuggestNames[i]);
                                j = autosuggestNames[i].length;
                            }
                        }
                    }
                    return filteredClientList;
                }
                
                var results;
                results = document.querySelector("#suggestions");            

                var value = e.target.value
                if (value.length >= 1) {
                    for (var i = 0; i < 4; i++) {
                        if (filteredClients[i]) {
                            var item = document.createElement("DIV");

                            item.classList.add("suggestion");
                            document.querySelector("#suggestions").className += " filled";

                            //item.innerHTML += ("<strong>" + filteredClients[i][0].substr(0, value.length) + "<strong>");
                            item.innerHTML += filteredClients[i][0];
                            //item.innerHTML += "<input type='hidden' value='" + filteredClients[i][0] + "'>";
                            item.setAttribute("aria-labelledby", filteredClients[i][0]);
                            item.setAttribute("role", "option");
                            item.setAttribute("tabIndex", "0");

                            results.appendChild(item);
                            item.addEventListener("mousedown", this.onAutofillClick)
                            item.addEventListener("mouseover", function(e) {
                                var selection = e.target;
                                //if (selection.tagName == "STRONG") selection = selection.parentElement;

                                for (var i = 0; i < results.children.length; i++) {
                                    results.children[i].classList.remove("suggestion-active")
                                }

                                selection.classList.add("suggestion-active");
                                for (var i = 0; i < results.children.length; i++) {
                                    if (results.children[i].classList.contains("suggestion-active")) {
                                        currentFocus = i;
                                    }
                                }
                            })
                        }

                    }
                }

                addEventListener("keydown", (event) => {
                    if (results.children.length > 0) {

                        // up arrow key or shift-tab
                        if (event.keyCode == 38 || (event.shiftKey && event.keyCode == 9)) {
                            event.preventDefault();
                            currentFocus --;
                            activeSuggestion(event);
                        }

                        // down arrow key or tab
                        else if (event.keyCode == 40 || event.keyCode == 9) {
                            event.preventDefault();
                            currentFocus++;
                            activeSuggestion(event);
                        }

                        // enter key
                        else if (event.keyCode == 13) {
                            for (var i = 0; i < results.children.length; i ++) {
                                if (results.children[i].classList.contains("suggestion-active")) {
                                    this.onAutofillClick(event, "keydown", i);
                                    event.preventDefault();
                                }
                            }
                        }

                        else if (event.keyCode == 27) {
                            event.preventDefault();
                            document.querySelector("#suggestions").className = "suggestion-items";
                            var list = document.querySelector("#suggestions");
                            for (var i = 0; i < 4; i++) {
                                if (list.firstChild) {
                                    list.removeChild(list.firstChild);
                                }
                            }
                        }
                    }
                })

                function activeSuggestion(e) {
                    removeActiveSuggestion(results);
                    if (currentFocus >= results.children.length) currentFocus = 0;
                    if (currentFocus < 0) currentFocus = (results.children.length - 1);
                    var activeSuggestion = results.children[currentFocus];
                    activeSuggestion.classList.add("suggestion-active")
                }

                function removeActiveSuggestion(results) {
                    for (var i = 0; i < results.children.length; i++) {
                        results.children[i].classList.remove("suggestion-active")
                    }
                }
            }
        }

        clearAutofill(e) {
            document.querySelector("#suggestions").className = "suggestion-items";
            var list = document.querySelector("#suggestions");
            for (var i = 0; i < 4; i++) {
                if (list.firstChild) {
                    list.removeChild(list.firstChild);
                }
            }
        }        

        handleClickOutsideQuiz(e) {
            if (this.state.quizOpen) {
                if (!this.checkParent(e.target)) {
                    var currentY = window.scrollY;
                    this.togglePersonalization(e);
                    
                    // If not IE, scroll to where page was before clicking
                    if (!(!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g))) {
                        window.scrollTo(0, currentY);
                    }
                }
            }
            this.clearAutofill(e);
        }


        // an IE friendly closest() method
        checkParent(e) {
            if (e.classList.contains("personalization-quiz") || e.classList.contains("customize") || e.classList.contains("suggestion")) {
                return true;
            }
            else if (e.parentElement == null) {
                return false;
            }
            else {
                return this.checkParent(e.parentElement);
            }
        }

        handleNameChange(e) {
            this.handleChange(e);
            this.props.updateName(e);
            sessionStorage.setItem('name', e.target.value);
        }

        handleWhatBringsChange(e) {
            if (sessionStorage.getItem('whatBrings') === 'competitor' && this.state.theme === 'redacted') {
                this.setState({
                    theme: this.state.lastTheme,
                })
                sessionStorage.setItem('theme', this.state.lastTheme);
                this.props.setRedacted(this.state.lastTheme);
            }
            this.handleChange(e);
            this.props.updateWhatBrings(e);
            sessionStorage.setItem('whatBrings', e.target.value);
            trackPersonalizationEvent("Visit Reason", e.target.value)

            
            // Delete the IS_PRODUCTION check to re-enable redacted functionality
            if (!IS_PRODUCTION) {
                if (sessionStorage.getItem('whatBrings') === 'competitor') {
                    if (sessionStorage.getItem('theme') == null) {
                        this.setState({
                            theme: 'redacted',
                        })
                    } else {
                        this.setState({
                            lastTheme: sessionStorage.getItem('theme'),
                            theme: 'redacted',
                        })
                    }
                    
                    this.props.setRedacted();
                    sessionStorage.setItem('theme', 'redacted');
                }
            }       
                 
        }
        handleIndustryChange(e) {
            this.handleChange(e);
            // MainBannerAnimation.vars.industry = e.target.value;
            this.props.updateIndustry(e);
            sessionStorage.setItem('industry', e.target.value);
            trackPersonalizationEvent("Industry", e.target.value)
        }

        handleCompanyChange(e) {
            this.setState({
                changedCompanyName: true
            })
            this.handleChange(e);
            this.props.updateCompany(e);
            this.onInputChange(e);
            sessionStorage.setItem('company', e.target.value);
        }

        
        /*  //For floating label animations, currently under review as of February 7 2019
        moveLabelUp(e) {
            if (document.querySelector("#companyInput").value != "" || e) {
                document.querySelector("#companyLabel").className = "material_field";
            }
            if (document.querySelector("#whatBringsInput").value != "true") {
                document.querySelector("#whatBringsLabel").className = "material_field";
            }
            if (document.querySelector("#industryInput").value != "true") {
                document.querySelector("#industryLabel").className = "material_field";
            }
            if (document.querySelector("#interestInput").value != "true") {
                document.querySelector("#interestLabel").className = "material_field";
            }
        }

        moveLabelDown() {
            if (document.querySelector("#companyInput").value == "") {
                document.querySelector("#companyLabel").className = "material_field_placeholder";
            }
            if (document.querySelector("#whatBringsInput").value == "true") {
                document.querySelector("#whatBringsLabel").className = "material_field_placeholder no-label";
            }
            if (document.querySelector("#industryInput").value == "true") {
                document.querySelector("#industryLabel").className = "material_field_placeholder no-label";
            }
            if (document.querySelector("#interestInput").value == "true") {
                document.querySelector("#interestLabel").className = "material_field_placeholder no-label";
            }
        }
        */

        handleInterestChange(e) {
            this.handleChange(e);
            this.props.updateInterest(e);
            sessionStorage.setItem('interest', e.target.value);
            trackPersonalizationEvent("Interest", e.target.value)
        }

        handleThemeChange(e) {
            if (e.target.value != 'theme') {
                this.handleChange(e);
                this.props.updateTheme(e);
                sessionStorage.setItem('theme', e.target.value);
            }            
            trackPersonalizationEvent("Theme", e.target.value)
        }

        handleChange(e) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }        

        dotClick(e) {
            var openCard = e.target.getAttribute('data-open-card')
            this.setState({
                openCard: Number(openCard)
            })
        }

        onKeyPressed(e) {
            if (document.querySelector("#suggestions").children.length) {
                return;
            }
            if (e.key === 'Escape') {
                this.setState({
                    quizOpen: false
                })
                this.refs.personalization.focus()
            }
        }

        trapFocus(e) {
            this.refs.quizOne.refs.whatBrings.focus()
        }

        trapFocusTop(e) {
            this.refs.quizOne.refs.personalizationBtn.focus() 
        }

        togglePersonalization(e) {
            e.preventDefault()
            if (!this.state.quizOpen) {
                trackPersonalizationTool()
            } else if (this.state.quizOpen) {
                trackPersonalizationAll(this.state.whatBrings, this.state.industry, this.state.interest, this.state.theme, this.assignAM(e))
            }
            
            this.setState({
                quizOpen: !this.state.quizOpen
            }, function() {
                if (this.state.openCard === 1 && this.state.quizOpen === true) {
                    this.refs.quizOne.refs.hello.focus()
                } else if (this.state.quizOpen === false) {
                    this.refs.personalization.focus({preventScroll:true});
                }

                this.props.setPersonalizationOpen(this.state.quizOpen);

                if (this.state.quizOpen) {
                    document.querySelector(".pointer").classList.add("hover-off")
                } else {
                    document.querySelector(".pointer").classList.remove("hover-off");
                }
            })   
        }

        handleCompanyBlur(e) {
            e.preventDefault();

            trackPersonalizationEvent("Company", e.target.value, true);
            this.assignAM(e);
        }

        assignAM(e) {
            e.preventDefault()

            if (this.state.companyName != null && this.state.changedCompanyName) {
                this.setState({
                    changedCompanyName: false
                })
                const companysearch = this.state.companyName.trim().toLowerCase();
                var fullList = true;
                var clientArray = allClients;
                /* if (this.state.industry == undefined || this.state.industry === "true") {
                    var clientArray = allClients;
                    fullList = true;
                } else {
                    var clientArray = clients;
                } */
                let accountManager = Object.keys(clients).map((key) => ({accountManager: key, clientList: clientArray[key]}))
                    .reduce((result, {accountManager, clientList}) => {
                        if (!fullList) {
                            clientList = clientList[this.state.industry]
                        };
                        if (result) return result;
                        if (!clientList) return false;
                        const isFound = clientList.reduce((isFound, clientListItem) => {
                            if (isFound) return true;
                            if (Array.isArray(clientListItem)) {
                                return clientListItem.map((companyName) => companyName.trim().toLowerCase()).indexOf(companysearch) >= 0;
                            } else {
                                return clientListItem === companysearch;
                            }
                        }, false);
                        if (isFound) return accountManager;
                        return false;
                    }, false);

                // if (AM == 0) accountManager = "shannon";
                // else if (AM == 1) accountManager = "kelly";
                // else if (AM == 2) accountManager = "jose";
                // else if (AM == 3) accountManager = "bridget";
                // console.log(accountManager);
                this.props.updateAMContact(AMInfo[accountManager]);
                sessionStorage.setItem("AM", accountManager)
                this.resizeFooter();
                trackPersonalizationEvent("Account Manager", accountManager)
                return accountManager;
            }
            //this.togglePersonalization(e);
            this.resizeFooter();
        }

        resizeFooter() {
            if (sessionStorage.getItem("AM") == "false" || sessionStorage.getItem("AM") == null) {
                //desktop
                document.querySelector(".footer-yellow2").setAttribute("style", "padding-bottom: 32px");
                document.querySelector(".privacy-text").setAttribute("style", "padding-bottom: 30px");

                //mobile
                document.querySelector("#social-media-bar").setAttribute("style", "padding-bottom: 30px");

            } else {
                //desktop
                document.querySelector(".footer-yellow2").setAttribute("style", "padding-bottom: 77px");
                document.querySelector(".privacy-text").setAttribute("style", "padding-bottom: 75px");

                //mobile
                document.querySelector("#social-media-bar").setAttribute("style", "padding-bottom: 75px");
            }
        }

        skipContact(e) {
            e.preventDefault()

            this.setState({
                openCard: 7
            })
        }

        // On card 4, this function shows text input if user selects "yes"
        showCompanyInput() {
            this.setState({
                companyInput: !this.state.companyInput,
                companyName: ''
            })
            this.refs.quizFour.refs.companyInput.focus()
        }

        toggleCheckbox(e) {

            var checkboxArray = this.state.checkboxArray

            //checks if the input value is already in the array. If it is, it will remove from the array, which should keep it in sync with the whether or not the inputs are "checked"
            var newArray = checkboxArray.filter(function(value) {
                return value !== e.target.value;
            })

            //Adds value to the array if box is unchecked
            if (checkboxArray.length === newArray.length) {
                checkboxArray.push(e.target.value)
                this.setState({
                    checkboxArray: checkboxArray
                })
            } else {
                this.setState({
                    checkboxArray: newArray
                })
            }
        }

        render() {
            return (
                null
            );            
        }
    }  


</script>
