import { Component, createElement } from "rmlibrary/comp";

import { CustomizationConsumer } from "../common/customization";

export class PharmaRedirectInner extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.updateIndustry("pharma");
      this.props.redirect("/", { replace: true });
    }, 10);
  }

  render() {
    return <div></div>;
  }
}

export function PharmaRedirect(props) {
  return (
    <CustomizationConsumer>
      {state => (
        <PharmaRedirectInner {...props} updateIndustry={state.updateIndustry} />
      )}
    </CustomizationConsumer>
  );
}
