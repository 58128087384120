import { Component, createElement, Children, cloneElement, findDOMNode } from "rmlibrary/comp";
import RMTween from "rmlibrary/tween";

class HighlightProxy {
    constructor(cr) { this.controller = cr }

    get xPos() { return this.controller.state.x }
    set xPos(v) { this.controller.setState({x: v}) }

    get width() { return this.controller.state.width }
    set width(v) { this.controller.setState({width: v}) }
}

export class ItemSelector extends Component {
    constructor(props) {
        super(props);

        if (props.lookForClass && typeof props.lookForClass != "string")
            throw new Error("Make sure the 'lookForClass' props is a string.");

        this.state = {
            x: null,
            y: null,
            width: null,
            selectedNode: null,
            showHighlight: false
        }

        this.proxy = new HighlightProxy(this);
        this.playground = [];

        this.register = this.register.bind(this);
        this.handlePageEmit = this.handlePageEmit.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);

        this.childProps = {
            ref: this.register,
            onMouseEnter: this.handleEnter,
            onMouseLeave: this.handleLeave
        }
    }

    componentDidMount() {
        addEventListener("resize", this.handleResize);
        if (this.context.router) this.context.router.hookToEmit(this.handlePageEmit);
        else setTimeout(() => this.handlePageEmit(), 100);
    }

    componentWillUnmount() {
        removeEventListener("resize", this.handleResize);
        if (this.context.router) this.context.router.unhookFromEmit(this.handlePageEmit);
    }

    register(elem) {
        if (elem) this.playground.push(elem);
    }

    /* Handlers */

    handleResize() {
        if (this.state.selectedNode) this.popSetTo(this.state.selectedNode);
    }

    handlePageEmit() {
        if (this.props.lookForClass) {
            let selectedNode = this.playground.map(c => findDOMNode(c))
                .filter(c => c.className.split(/\s+/g).indexOf(this.props.lookForClass) > -1)[0];

            this.setState({ selectedNode });

            if (selectedNode) {
                if (this.tween) this.tween.stop();
                let dims = this.calculateDims(selectedNode);
                this.setState({...dims, showHighlight: true});
            }
            else this.setState({ showHighlight: false });
        }
    }

    handleEnter(event) {
        if (this.tween) this.tween.stop();

        if (this.state.selectedNode) this.animateSetTo(event.target);
        else {
            this.popSetTo(event.target);
            this.revealAnimate(event.target);
        }
    }

    handleLeave(event) {
        if (this.state.selectedNode) this.animateSetTo(this.state.selectedNode);
        else this.setState({ showHighlight: false });
    }

    /*  */

    popSetTo(elem) {
        let dims = this.calculateDims(elem);
        this.setState(dims);
    }

    animateSetTo(elem) {
        let dims = this.calculateDims(elem);

        this.tween = RMTween.create(this.proxy)
            .to({ xPos: this.state.x, width: this.state.width })
            .to({ xPos: dims.x, width: dims.width }, 200, 'ease')
            .run();
    }

    revealAnimate(elem) {
        let dims = this.calculateDims(elem);

        this.setState({ showHighlight: true });
        this.tween = RMTween.create(this.proxy, () => {
                this.setState({ x: dims.x + (dims.width / 2) - (this.state.width / 2) })
            })
            .to({ width: 0 })
            .to({ width: dims.width }, 200, 'ease')
            .run();
    }

    buildHighlightStyle() {
        return {
            position: 'absolute',
            height: '1px',
            top: '0',
            left: '0',
            background: this.props.options.color || 'black',
            transform: `translate(${this.state.x}px, ${this.state.y}px)`,
            width: this.state.width,
            pointerEvents: 'none'
        };
    }

    calculateDims(elem) {
        let elemDims = this.getDims(elem);


        let origWidth = elemDims.width;
        let height = elemDims.height;
        if (this.props.ignorePadding) {
            origWidth -= (elemDims.paddingLeft + elemDims.paddingRight);
            height -= (elemDims.paddingTop + elemDims.paddingBottom);
        }

        let x = elemDims.x;
        let y = elemDims.y;
        if (this.props.ignorePadding) {
            x += elemDims.paddingLeft;
            y += elemDims.paddingTop;
        }

        let width = 0;
        if (this.props.options) {
            let { alignX, alignY, shiftX, shiftY, widthPercent } = this.props.options;

            if (widthPercent) width = origWidth * widthPercent;
            if (alignX === "center") x += (origWidth / 2) - (width / 2);
            if (alignY === "bottom") y += height;
            if (shiftX) x += shiftX;
            if (shiftY) y += shiftY;
        }

        return { x, y, width: width || origWidth };
    }

    getDims(elem) {
        let style = window.getComputedStyle(elem, null);

        let bounds = elem.getBoundingClientRect();
        let width = bounds.width;
        let height = bounds.height;
        let paddingTop = parseFloat(style.getPropertyValue("padding-top"));
        let paddingRight = parseFloat(style.getPropertyValue("padding-right"));
        let paddingBottom = parseFloat(style.getPropertyValue("padding-bottom"));
        let paddingLeft = parseFloat(style.getPropertyValue("padding-left"));

        let scopedElement = elem;
        let x = 0, y = 0;
        do {
            x += scopedElement.offsetLeft  || 0;
            y += scopedElement.offsetTop || 0;
            scopedElement = scopedElement.offsetParent;
        } while(scopedElement);

        return { x, y, width, height, paddingTop, paddingRight, paddingBottom, paddingLeft };
    }

    /* Rendering */

    renderHighlight() {
        if (this.state.showHighlight){
            return (
                <div className="highlight" style={this.buildHighlightStyle()}></div>
            );
        }
        else return null;
    }

    render() {
        return (
            <>
            { this.renderHighlight() }
            { Children.map(this.props.children, child => cloneElement(child, this.childProps)) }
            </>
        );
    }
}
ItemSelector.contextTypes = {
    router: PropTypes.object
};
