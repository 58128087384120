/*globals window, document */
import {PropTypes} from "prop-types";
import {Macciato, log} from "./macciato"
import {Component, createElement} from "rmlibrary/comp";

/*
 * RMRouterRoot - Should wrap around the whole app
*/
export class RMRouterRoot extends Component{
    constructor(props) {
        super(props);
        this.router = new Macciato({ hashed: props.hashed });

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.router.hookToEmit(this.handleRouteChange);

        this.validateURL();
    }

    // Gets rid of trailing backslashes and redirects the page
    validateURL() {
        let currURL = this.router.getPathname();
        if (currURL != "/" && currURL.match(/\/$/)) {
            let redirectURL = currURL.replace(/\/$/, "") + this.router.getQuery();
            window.history.replaceState("", "", this.router.addBaseToPath(redirectURL));
        }
    }

    getChildContext() {
        return {router: this.router};
    }

    render() {
        return this.props.children;
    }

    handleRouteChange() {
        if (typeof (this.props.onRouteChange) === 'function') {
            this.props.onRouteChange({
                path: this.router.getPathname(),
                query: this.router.getQueryObj(),
                variables: this.router.getExtractedVariables(),
                title: document.title
            });
        }
    }
}
RMRouterRoot.childContextTypes = {
    router: PropTypes.object
};

/*
 * Route - Manages one view
 * Props
 *      path: string|array - Referenced by the RouterLink
 *      component: react-component - The component to be rendered by the Route
*/
export class Route extends Component{
    constructor(props) {
        super(props);
        this.state = {};

        this.rerender = this.rerender.bind(this);
    }

    componentWillMount() {
        this.fullPaths = this.props.path.split(";").map(part => this.context.router.addBaseToPath(part));
        this.routeControls = this.fullPaths.map(p => this.context.router.registerRoute(p, this, this.props.title));
    }

    componentWillUnmount() {
        this.routeControls.forEach(rc => this.context.router.unregisterRoute(rc));
    }

    rerender() {
        this.setState(this.state);
    }

    render() {
        let match = this.fullPaths.reduce((acc, fullPath) => acc || this.context.router.isCurrentPath(fullPath), false);
        log(`[ROUTE] show: ${match} - ${this.fullPaths.join(";")}`);

        if (match) {
            log('[URL VARS]', this.context.router.getExtractedVariables());
            log('[QUERY]', this.context.router.getQueryObj());

            let pageProps = {
                path: this.props.path,
                query: this.context.router.getQueryObj(),
                variables: this.context.router.getExtractedVariables(),
                redirect: this.context.router.redirect
            }

            return createElement(this.props.component, pageProps);
        }

        return null;
    }
}
Route.contextTypes = {
    router: PropTypes.object
};

/*
 * RouterLink - Handles switching views
 * Props
 *      to: string - Should match the path of the Route to view
 *      query: object - Query variables
 *      classes: array<string> - Gets passed down
*/
export class RouterLink extends Component{
    constructor(props) {
        super(props);
        this.state = {};

        this.composeLink = this.composeLink.bind(this);
        this.rerender = this.rerender.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    // Defines and initializes a query string if a query prop is passed in
    checkQuery() {
        if (this.props.query)
            return this.props.query;
        return null
    }

    componentWillMount() {
        this.query = this.checkQuery()
        this.fullPath = this.composeLink(this.props.to);
        this.linkControls = this.context.router.registerLink(this);
    }

    componentWillUnmount() {
        this.context.router.unregisterLink(this.linkControls);
    }

    componentWillReceiveProps(nextProps) {
        this.fullPath = this.composeLink(nextProps.to);
    }

    // Returns a built path from the current prop
    composeLink(to) {
        if (typeof to === "string")
            return this.context.router.addBaseToPath(to);
        else if (Array.isArray(to))
            return this.context.router.addBaseToPath(`/${to.join('/')}`);

        return null;
    }

    rerender() {
        this.setState(this.state)
    }

    isSelected() {
        return this.context.router.isCurrentPath(this.fullPath);
    }

    onClick(e) {
        if (typeof (this.props.onClick) === 'function') {
            this.props.onClick(e);
            if (e.defaultPrevented) return;
        }
        e.preventDefault();
        this.context.router.redirect(this.fullPath, {
            replace: false,
            query: this.query,
            scrollTop: true,
            newTab: e.nativeEvent.ctrlKey,
            hasBase: true
        });
    }

    getHref() {
        let strQuery = "";
        if (this.query) strQuery = this.context.router.encodeQuery(this.query);
        let hash = this.context.router.isHashed() ? '#' : '';
        return `${hash}${this.fullPath}${strQuery}`;
    }

    selectedClasses(classIfActive) {
        let match = this.context.router.isCurrentPath(this.fullPath);

        if (classIfActive) {
            if (typeof classIfActive === "string" && match) return classIfActive;
            else if (typeof classIfActive === "object") {
                let appliedClasses = [];
                Object.keys(classIfActive).forEach(classKey => {
                    let regex = classIfActive[classKey];
                    if (this.context.router.getPathname().match(regex)) appliedClasses.push(classKey);
                })
                return appliedClasses.join(" ");
            }
        }
        return null;
    }

    render() {
        const {to, query, classIfActive, className, ...props} = this.props; // eslint-disable-line no-unused-vars

        let appliedClasses = "";
        if (className) appliedClasses += `${className} `;
        appliedClasses += this.selectedClasses(classIfActive) || "";

        return <a {...props} className={appliedClasses} href={this.getHref()} onClick={this.onClick}>{this.props.children}</a>;
    }
}
RouterLink.contextTypes = {
    router: PropTypes.object
};
