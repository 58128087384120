// define(["rmlibrary/util"], function (rmUtil) {

import rmUtil from './util'

function EventEmitter() {
}

EventEmitter.prototype.trigger = function (evtName, args) {
    if (!this._handlers) this._handlers = {};

    var handlers = this._handlers[evtName];
    if (!Array.isArray(handlers)) return;
    for (var i = 0; i < handlers.length; i++) {
        if (typeof (handlers[i]) === 'function') handlers[i].apply(this, args);
    }
    return this;
};

EventEmitter.prototype.on = function (evtName, handler) {
    if (!this._handlers) this._handlers = {};

    if (!this._handlers[evtName]) this._handlers[evtName] = [];
    this._handlers[evtName].push(handler);
    return this;
};

EventEmitter.prototype.off = function (evtName, handler) {
    if (!this._handlers) this._handlers = {};

    if (!this._handlers[evtName]) return;
    if (typeof (handler) === 'undefined') {
        this._handlers[evtName] = [];
    } else {
        var handlers = this._handlers[evtName];
        for (var i = handlers.length - 1; i >= 0; i--) {
            if (handlers[i] === handler) handlers.splice(i, 1);
        }
    }
    return this;
};

export default EventEmitter;
