/*globals window, document, setTimeout, clearTimeout */

// define(['jquery'], function ($) {

function debounce(fn, t, immediate) {
	var timer;
	return function () {
		var thisArg = this, args = arguments;
		var noTimer = !timer;

		clearTimeout(timer);
		timer = setTimeout(function () {
			timer = null;
			if (!immediate && typeof (fn) === 'function') fn.apply(thisArg, args);
		}, t);

		if (immediate && noTimer && typeof (fn) === 'function') fn.apply(thisArg, args);
	};
}

function throttle(fn, t, immediate) {
    var lastArgs, timer;

    return function () {
        var thisArg = this,
            args = Array.prototype.slice.call(arguments, 0),
            noTimer = !timer;

        function timeoutFn() {
            if (lastArgs) {
                if (typeof (fn) === 'function') fn.apply(thisArg, lastArgs);
                lastArgs = null;
                timer = setTimeout(timeoutFn, t);
            } else {
                timer = null;
            }
        }

        if (!noTimer || !immediate) {
            lastArgs = args;
        }
        if (!timer) timer = setTimeout(timeoutFn, t);
        if (immediate && noTimer && typeof (fn) === 'function') fn.apply(thisArg, args);
    };
}

function _extend(deep, obj, obj2) {
    if (typeof (obj) !== 'object' || typeof (obj2) !== 'object') return;

    for (var prop in obj2) {
        if (obj2.hasOwnProperty(prop)) {
            if (deep && typeof (obj2[prop]) === 'object') {
                if (Array.isArray(obj2[prop])) obj[prop] = [];
                else obj[prop] = {};

                _extend(deep, obj[prop], obj2[prop]);
            } else {
                obj[prop] = obj2[prop];
            }
        }
    }
}

function extend(deep, obj) {
    var args = Array.prototype.slice.call(arguments, 0);

    if (typeof (deep) === 'object') {
        obj = deep;
        deep = false;
        args.splice(0, 1);
    } else {
        deep = !!deep;
        args.splice(0, 2);
    }

    if (typeof (obj) !== 'object') throw new Error("Object that you are extending, must be an object");
    for (var i = 0; i < args.length; i++) {
        _extend(deep, obj, args[i]);
    }

    return obj;
}

function urlParams() {
    var ret = {};
    var params = window.location.search;
    if (params.length > 0) {
        params = params.slice(1).split("&");
        params.forEach(function (p) {
            try {
                p = p.split("=");
                ret[p[0]] = p[1];
            } catch(e) {
                // Do nothing
            }
        });
    }
    return ret;
}

function formatStr(template, vars) {
    if(template){
        return template.replace(/\{(\w+)\}/gi, function (pattern, varName) {
            return (vars && vars[varName]) || "";
        });
    }
}

function inherits(A, B) {
    var A_Proto = function () {};
    A_Proto.prototype = B.prototype;
    A.prototype = new A_Proto();
    A.prototype.constructor = A;
}

function cloneObj(obj) {
    var ret = {};

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) ret[prop] = obj[prop];
    }

    return ret;
}

function bind(fn, thisObj, args) {
    return function () {
        return fn.apply(thisObj, args || arguments);
    };
}

function objectCreate(p) {
    var A = function () {};
    A.prototype = p;
    var ret = new A();
    A.prototype = null;
    return ret;
}

function onCapture(elem, evt, handler) {
    // $(elem).each(function (i, elem) {
    //     elem.addEventListener(evt, handler, true);
    // });

    elem.forEach(function (i, elem) {
        elem.addEventListener(evt, handler, true);
    })
}

export default {
    debounce: debounce,
    extend: extend,
    urlParams: urlParams,
    formatStr: formatStr,
    inherits: inherits,
    clone: cloneObj,
    bind: bind,
    objectCreate: objectCreate,
    throttle: throttle,
};
