<import name="RouterLink" from="rmlibrary/router" />

<style lang="scss">
    @import 'sass/variables';

    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        line-height:1.2;
    }

    .mobile {
        @media #{$xs-up} {
            display: block;
        }
        @media #{$md-up} {
            display: none;
        }
        /*@media #{$height} { display: block; }*/
        a {
            color: $black;
            text-decoration: none;
            cursor: pointer;
        }


        .download {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            height: 80px;
            background-color: $black;
            color: $white;
            padding: 10px 0px;
            @media #{$height} {
                height: 120px;
                padding-top: 30px;
            }
            .download-icon {
                width: 30px;
                margin: 0 auto;
                text-align: center;
            }

            .download-flex {
                display: flex;
                width: 20%;
                align-items: center;
                justify-content: space-around;
                margin: 10px auto;

            }

        }


        .contact-us {
            width: 100%;
            background-color: $yellow;
            height: 230px;
            text-align: left;
            color: $black;
            padding: 20px 0px;
            transition: 0.5s ease-in-out;
            @media #{$xs-only, $sm-only} {
                height: 210px;
                transition: 0.5s ease-in-out;
            }
            @media #{$height} {
                height: 300px;
                padding: 40px 0px;
                padding-top: 60px
            }
            .contactus-flex {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 80%;
                margin: 15px auto;
            }

            .text-title {
                font-size: 20pt;
                text-align: left;
                margin: 0 auto;
                width: 80%;
                font-weight: bolder;

            }

            .footer-icon {
                width: 27px;
                margin-right: 20px;
                margin-top: -5px;
                margin-left:0;
                @media #{$xs-only} {
                    min-width: 27px;
                }
            }
        }
        
        .arrow_down {
            position: absolute;
            margin-top: -15px;
            right: 10%;
        }
        
        .social-media {
            width: 100%;
            background-color: $black;
            color: $white;
            padding-bottom: 30px;
            min-height: calc(100vh - 1001px);
            .socialmedia-flex {
                width: 100%;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-around;
                padding: 20px 0px;
            }

            img {
                height: 25px;
            }
            .instagram {
                background-image: url(./images/icons/SVG/instagram-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .twitter {
                background-image: url(./images/icons/SVG/twitter-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .facebook {
                background-image: url(./images/icons/SVG/facebook-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .linkedin {
                background-image: url(./images/icons/SVG/linkedin-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
        }
        .footer-legal {
            color: $white;
            font-size: 10pt;
            text-align: center;
            margin-top: 5px;

        .footer-icon {
            display: none;
        }
            
            .privacy {
                color: $white;
                cursor: pointer;
                font-size: 10pt;
                text-decoration: none;

            }
        }
    }

</style>
<template name="FooterMobile">
    <div class="mobile">
        <div class="download">
            <p class="download-text">Download the Rich Media Ideas App</p>
            <span class="download-flex">
                <a href="https://play.google.com/store/apps/details?id=com.richmedia.richideas&hl=en" target="_blank">  <img src="./site_assets/images/android.svg" width="30px" height="30px" alt="Android"/></a>
                <a href="https://itunes.apple.com/us/app/rich-media-ideas/id948015528?mt=8" target="_blank"><img src="./site_assets/images/apple.svg" width="30px" height="30px" alt="Apple"/></a>
            </span>
        </div>

        <div class="contact-us">
            <p class="text-title">Contact Us</p>
            <a href="tel:4164066545"><span class="contactus-flex"><img class="footer-icon" src="./site_assets/images/phone.svg" alt="Contact Number" width="30px" height="30px">416 406 6545</span></a>
            <a href="mailto:contactus@richmedia.com"><span class="contactus-flex"><img class="footer-icon email" src="./site_assets/images/email.svg" alt="Contact Number" width="30px" height="30px">contactus@richmedia.com</span></a>
            <a href="https://goo.gl/maps/XRPtoj5Rim72" target="_blank"><span class="contactus-flex"><img class="footer-icon location" src="./site_assets/images/location-b.svg" alt="Contact Number" width="30px" height="30px">1090 Don Mills Rd. Suite 501 Toronto, ON M3C 3R6</span></a>
        </div>
        <div class="social-media" id="social-media-bar">
            <div class="socialmedia-flex">
                <a aria-label="rich media instagram" href="https://www.instagram.com/richmedia.social/" target="_blank"><div class="instagram"></div></a>
                <a aria-label="rich media x" href="https://x.com/RichMediaSocial/" target="_blank"><div class="twitter"></div></a>
                <a aria-label="rich media facebook" href="https://www.facebook.com/Rich-Media-251114581758676/" target="_blank"><div class="facebook"></div></a>
                <a aria-label="rich media linkedin" href="https://www.linkedin.com/company/rich-media" target="_blank"><div class="linkedin"></div></a>
            </div>
            <p class="footer-legal">©2021 Rich Media |<span>&nbsp;</span>
                <If c="{!this.props.isRichIdeas}"><RouterLink className="privacy" to="/privacy">Privacy</RouterLink></If>
                <If c="{this.props.isRichIdeas}"><a className="privacy" href="{`${RICH_IDEAS_BASE}/privacy`}">Privacy</a></If>
            </p>
        </div>
    </div>
</template>
