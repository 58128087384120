/*globals window */

// define('rmlibrary/tween', ['rmlibrary/util', 'rmlibrary/event-emitter'], function (rmUtil, EventEmitter) {
import rmUtil from 'rmlibrary/util';
import EventEmitter from 'rmlibrary/event-emitter';
import {CubicBezier} from './bezier';

var requestAnimationFrame = window.requestAnimationFrame || function (cb) { return window.setTimeout(cb(new Date().getTime()), 16); };
var cancelAnimationFrame = window.cancelAnimationFrame || function (id) { window.clearTimeout(id); };
function getTime() {
	if (window.performance) return window.performance.now();
	return new Date().getTime();
}

var defaultEasings = {
	"linear": function (t) {
		return t;
	},
	"easeIn": function (t) {
		return Math.pow(t, 2);
	},
	"easeOut": function (t) {
		return 1 - Math.pow(1 - t, 2);
	},
	"easeInOut": function (t) {
		if (t <= 0.5) return 0.5 * Math.pow(t * 2, 2);
		else return 1 - 0.5 * Math.pow(2 - (t * 2), 2);
	},
	"cubicIn": function (t) {
		return Math.pow(t, 3);
	},
	"cubicOut": function (t) {
		return 1 - Math.pow(1 - t, 3);
	},
	"cubicInOut": function (t) {
		if (t <= 0.5) return 0.5 * Math.pow(t * 2, 3);
		else return 1 - 0.5 * Math.pow(2 - (t * 2), 3);
	}
};

function Timer() {
    EventEmitter.call(this);
}
rmUtil.inherits(Timer, EventEmitter);
Timer.get = function () {
    return new Timer();
};

Timer.prototype.start = function () {
    this._time = getTime();
    this._timer = requestAnimationFrame(Timer.prototype._tick.bind(this));
    this.ticking = true;
};
Timer.prototype._tick = function () {
    if (!this.ticking) return;

    var now = getTime();
    this.trigger("tick", now - this._time);
    this._time = now;

    if (this.ticking) this._timer = requestAnimationFrame(Timer.prototype._tick.bind(this));
};
Timer.prototype.stop = function () {
    if (this._timer) {
        cancelAnimationFrame(this._timer);
        this._timer = null;
        this.ticking = false;
    }
};

function parseTransformLength(v, def) {
    return v ? parseCSSLength(v) : { value: def };
}

function parseTransform(v) {
    v = v || {};
    var scale = parseTransformLength(v.scale, 1);

    var ret = {
        type: 'transform',
        x: parseTransformLength(v.x, 0),
        y: parseTransformLength(v.y, 0),
        scaleX: parseTransformLength(v.scaleX, scale.value),
        scaleY: parseTransformLength(v.scaleY, scale.value),
        rotate :parseCSSAngle(v.rotate)
    };
    return ret;
}

function interpolateLength(v1, v2, pct) {

    var unit = v1.unit;

    if (v1.unit !== v2.unit) {
        if (!v1.value) {
            unit = v2.unit;
        } else if (!v2.value) {
            unit = v1.unit;
        } else {
            throw new TypeError("Cannot interpolate between the values \"" + lengthToValue(v1) + "\" and \"" + lengthToValue(v2) + "\"");
        }
    }

    var ret = lengthToValue({
        value: ((v2.value - v1.value) * pct) + v1.value,
        unit: unit
    });
    return ret;
}

function interpolateTransform(v1, v2, pct) {
    var ret = ([
        'translate(' + interpolateLength(v1.x, v2.x, pct) + ',' + interpolateLength(v1.y, v2.y, pct) + ')',
        'scale(' + interpolateLength(v1.scaleX, v2.scaleX, pct) + ',' + interpolateLength(v1.scaleY, v2.scaleY, pct) + ')',
        'rotate(' + interpolateLength(v1.rotate, v2.rotate, pct) + ')'
    ]).join(" ");
    return ret;
}

function parseCSSLength(value, propName) {
    if (propName === 'transform') {
        return parseTransform(value);
    }

	if (typeof (value) === 'number') return {value: value};
	if (typeof (value) === 'string') {
		var match = value.match(/^([+-]?\d+(?:\.\d+)?)(em|ex|ch|rem|vh|vw|vmin|vmax|px|mm|q|cm|in|pt|pc|%)?$/);

		if (match) {
			return {
				value: Number(match[1]),
				unit: match[2]
			};
		}
	}
}

function parseCSSAngle(value) {
    if (typeof (value) === 'number' || !value) {
        return {
            value: value || 0,
            unit: 'deg'
        };
    }
	if (typeof (value) === 'string') {
		var match = value.match(/^([+-]?\d+(?:\.\d+)?)(deg|grad|red|turn)?$/);

		if (match) {
			return {
				value: Number(match[1]),
				unit: match[2]
			};
		}
	}
}

function lengthToValue(obj) {
	if (!obj.unit) return obj.value;
	return obj.value + obj.unit;
}

function processProps(props) {
	Object.keys(props).forEach(function (key) {
		var processed = parseCSSLength(props[key], key);
		if (!processed) throw new TypeError("Cannot animate the value \"" + props[key] + "\" on property '" + key + "'");

		props[key] = processed;
	});
}

function Tween(obj, tickFn) {
	this.obj = obj;
    this.instance = obj;
	this.tickCb = tickFn;
	this.queue = [];
	this.duration = 0;
	this._endProps = {};
}

Tween.prototype.to = function (props, duration, ease) {
	duration = duration || 0;

	processProps(props);
	var oldDuration = this.duration;
	this.duration += duration;
	var endProps = this._endProps;
	var obj = this.obj;
	var easeFn = defaultEasings.linear;
	if (typeof (ease) === 'function') easeFn = ease;
	if (typeof (ease) === 'string' && defaultEasings[ease]) easeFn = defaultEasings[ease];
	this.queue.push({
		startTime: oldDuration,
		endTime: oldDuration + duration,
		duration: duration,
		easeFn: easeFn,
		props: Object.keys(props).map(function (key) {
			var startValue = endProps[key];
            var endValue = props[key];

            if (duration <= 0) {
                startValue = endValue;
            } else if (!startValue) {
				var processed = parseCSSLength(obj[key], key);
				if (!processed) throw new TypeError("Cannot animate the value \"" + props[key] + "\" on property '" + key + "'");
				startValue = processed;
			}

            if (key !== 'transform' && startValue.unit !== endValue.unit && startValue.value && endValue.value) {
                throw new TypeError("Cannot animate the property '" + key + "' from \"" + lengthToValue(startValue.unit) + "\" to \"" + lengthToValue(endValue.unit) + "\"");
            }

			return {
				prop: key,
				startValue: startValue,
				endValue: endValue
			};
		})
	});
	this._endProps = this._endProps = rmUtil.extend(this._endProps, props);

    return this;
};

Tween.prototype.wait = function (duration) {
	return this.to({}, duration);
};

Tween.prototype.reset = function () {
    this._position = 0;
    return this;
};

Tween.prototype.run = function (loop, cb) {
	if (typeof (loop) === 'function') {
		cb = loop;
		loop = false;
	}

	this._loop = loop;
	this._cb = cb;
	this._position = 0;

	this._timer = Timer.get().on('tick', Tween.prototype._tick.bind(this));
    this._timer.start();

	return this;
};

Tween.prototype._completed = function () {
	if (typeof (this._cb) === 'function') this._cb();
	if (this._loop) this.run(true, this._cb);
};

Tween.prototype._tick = function (delta) {
	var oldPosition = this._position || 0;
	var tweenPosition = oldPosition + delta;
	var obj = this.obj;

	this.queue.forEach(function (q) {
		var itemOldPosition = oldPosition - q.startTime;
		var itemPosition = tweenPosition - q.startTime;

		if (itemPosition > q.duration && itemOldPosition <= q.duration) itemPosition = q.duration;

		if (itemPosition >= 0 && itemPosition <= q.duration) {
			var itemPct = q.duration === 0 ? 0 : q.easeFn(itemPosition / q.duration);
			q.props.forEach(function (p) {
                var newVal;
                var prefixes = false;
                if (p.prop === 'transform') {
                    prefixes = true;
                    newVal = interpolateTransform(p.startValue, p.endValue, itemPct);
                } else {
                    newVal = interpolateLength(p.startValue, p.endValue, itemPct);
                }
				obj[p.prop] = newVal;
                if (prefixes) {
                    obj['-webkit-' + p.prop] = newVal;
                    obj['-moz-' + p.prop] = newVal;
                    obj['-ms-' + p.prop] = newVal;
                }
			});
		}
	});

	this._position = tweenPosition;

	if (typeof (this.tickCb) === 'function') this.tickCb(this.obj);

	if (tweenPosition > this.duration) {
        if (this._timer) {
            this._timer.stop();
            this._timer = null;
        }
        requestAnimationFrame(Tween.prototype._completed.bind(this));
    }
	//else this._timer = requestAnimationFrame(Tween.prototype._tick.bind(this));
};

Tween.prototype.stop = function () {
	if (this._timer) {
		this._timer.stop();
		this._timer = null;
	}
};

Tween.prototype.skipToEnd = function () {
    if (this._timer) {
        this._tick(this.duration - this._position);
        this.stop();
		requestAnimationFrame(Tween.prototype._completed.bind(this));
    }
};

function ParallelTween(children) {
    this.children = children;
    this.duration = children.reduce(function (duration, child) {
        return Math.max(duration, child.duration);
    }, 0);
}

ParallelTween.prototype.reset = function () {
    this._position = 0;

    this.children.forEach(function (c) {
        c.reset();
    });

    return this;
};

ParallelTween.prototype._tick = function (delta) {
    var oldPosition = this._position;
	var tweenPosition = oldPosition + delta;

    this.children.forEach(function (c) {
        c._tick(delta);
    });

    this._position = tweenPosition;

    if (tweenPosition > this.duration) {
        if (this._timer) {
            this._timer.stop();
            this._timer = null;
        }
        requestAnimationFrame(Tween.prototype._completed.bind(this));
    }
};

ParallelTween.prototype.stop = function () {
	if (this._timer) {
		this._timer.stop();
		this._timer = null;
	}
};

ParallelTween.prototype.skipToEnd = function () {
    if (this._timer) {
        this._tick(this.duration - this._position);
        this.stop();
    }
};

function SeriesTween() {
    this.queue = [];
	this.duration = 0;
}

SeriesTween.prototype.reset = function () {
    this._position = 0;
    return this;
};

SeriesTween.prototype.run = function (loop, cb) {
	if (typeof (loop) === 'function') {
		cb = loop;
		loop = false;
	}

    this.queue.forEach(function (q) {
        q.tween.reset();
    });

	this._loop = loop;
	this._cb = cb;
	this._position = 0;

	this._timer = Timer.get().on('tick', SeriesTween.prototype._tick.bind(this));
    this._timer.start();

	return this;
};

SeriesTween.prototype._completed = function () {
	if (typeof (this._cb) === 'function') this._cb();

	if (this._loop) this.run(true, this._cb);
};

SeriesTween.prototype._tick = function (delta) {
    var oldPosition = this._position;
	var tweenPosition = oldPosition + delta;

    this.queue.forEach(function (q) {
        var itemOldPosition = oldPosition - q.startTime;
		var itemPosition = tweenPosition - q.startTime;

		if (itemPosition > q.duration && itemOldPosition <= q.duration) q.tween._tick(delta);
		if (itemPosition >= 0 && itemPosition <= q.duration) q.tween._tick(delta);
	});

    this._position = tweenPosition;

    if (typeof (this.tickCb) === 'function') this.tickCb(this.obj);

    if (tweenPosition > this.duration) {
        if (this._timer) {
            this._timer.stop();
            this._timer = null;
        }
        requestAnimationFrame(SeriesTween.prototype._completed.bind(this));
    }
};

SeriesTween.prototype.then = function () {
    var children = Array.prototype.slice.call(arguments, 0);
    var oldDuration = this.duration;

    if (!children.length) return;

    if (typeof (children[0]) === 'function') {
        this._cb = children[0];
        return;
    }
    if (Array.isArray(children[0])) children = children[0];

    var child;
    if (children.length === 1) child = children[0];
    else child = new ParallelTween(children);

    this.queue.push({
        startTime: oldDuration,
		endTime: oldDuration + child.duration,
		duration: child.duration,
        tween: child
    });

    this.duration += child.duration;

    return this;
};

SeriesTween.prototype.stop = function () {
	if (this._timer) {
		this._timer.stop();
		this._timer = null;
	}
};

SeriesTween.prototype.skipToEnd = function () {
    if (this._timer) {
        this._tick(this.duration - this._position);
        this.stop();
    }
};

export default {
	create: function (obj, tickFn) {
		return new Tween(obj, tickFn);
	},
    parallel: function () {
        var children = Array.prototype.slice.call(arguments, 0);
        if (Array.isArray(children[0])) children = children[0];

        return (new SeriesTween()).then(children);
    },
    cubicBezier: function (x1, y1, x2, y2) {
        const cubicBezier = new CubicBezier(x1, y1, x2, y2);
        return (x) => cubicBezier.y(x);
    }
};
