<import name="RouterLink" from="rmlibrary/router" />
<import name="RMModal" from="rmlibrary/react-modal" />
<import name="TransparentVideo" from="../../common/transparent-video" />
<import name="Collapsible" from="rmlibrary/collapsible" />
<import name="LearnMoreButton" from="../../common/learn-more-button.html" />

<style lang="scss">
    @import 'sass/variables';

    .banner-animation {
        .two-columns {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @media #{$md-only} {
                padding-top: 60px;
            }
            .column {
                width: 50%;
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                }
            }
            .column1 {
                @media #{$md-only} {
                    width: 52%;
                }
            }
            .column2 {
                @media #{$sm-only, $xs-only} {
                    text-align: center;
/*                    margin-top:0px;*/
                }
                
/*
                @media #{$lg-only, $xl-only} {
                    margin-top:35px;
                   
                }
*/
                
                
                
                @media #{$md-only} {
                    width: 48%;
                }
                img {
                    width: 100%;
                    max-width: 820px;
                    @media #{$sm-only, $xs-only} {
                        width: 51%;
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$xs-only} {
                        width: 72%;
                    }
                }
                
                .transcript {
                    width: 30px;
                    position: relative;
                    display: block;
                    left: 250px;
                    img {
                        width: 100%;
                    }
                    @media #{$sm-only} {
                        left: 0px;
                        bottom: 10px;
                    }
                    @media #{$xs-only} {
                        left: 0px;
                        bottom: 10px;
                    }
                    @media #{$md-only} {
                        left: 50%;
                    }

                    >* {
                        vertical-align: top;
                    }
                }

                .video-el {
                    width: 100%;
                    max-width: 620px;
                    min-height: 100px;
                    overflow: hidden;        
                    
                    @media #{$sm-only, $xs-only} {
                        width: 100%;
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                        max-width: 300px;
                     
                        
                    }
                    
                    @media #{$sm-only} {
                        max-width: 350px;
                        
                    }
                    
                    @media #{$md-only} {
                        max-width: 420px;
                        margin-top:0px;
                        
                    }
                    
                    @media #{$lg-only} {
                        max-width: 500px;
                        margin-top:50px;
                    }
                    
                      @media #{$xl-only} {
                        max-width: 500px;
                        margin-top:50px;
        
                    }
                }
            }
        }
        .text {
            color: white;
            max-width: 500px;
            @media #{$md-only} {
                max-width: 368px;
            }
            @media #{$xs-only, $sm-only} {
                max-width: none;
                padding-top: 45px;
            }
        }
    }

    .banner .yellow-banner {
        background-color: $yellow;
        background-image: radial-gradient(circle, #FCCB5E, $yellow, $yellow, $yellow, $yellow, $yellow);
    }

    .animation {
        overflow: hidden;
    }

    .banner {
        position: relative;

        .buttons {
            .black-button {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                &:hover {
                    background-color: $yellow;
                    border: 1px solid $black;
                    border-style: inset;
                    color: $black;
                    transition: background-color 0.5s ease;
                }
            }

            .black-button:hover a {
                color: $yellow;
            }
            .black-button:hover .chevron {
                color: $black;
            }
            .right-button {
                margin-left: 20px;
                @media #{$xs-only} {
                    margin-left: 5px;
                }
            }
        }
    }


    .learn-more {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        @media #{$sm-only, $xs-only} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            @media #{$sm-only, $xs-only} {
                align-items: inherit;
            }
            .item {
                width: 31%;
                text-align: center;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 50%;
                    font-size: 14px;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        width: 60px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    border-bottom: 1px solid $black90;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $black90;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .second-row {
                padding-top: 50px;
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
            .line2 {
                margin-top: 50px;
            }
            .no-line {
                @media #{$sm-only, $xs-only} {
                    border: none;
                }
            }
            .lines {
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
                .line-horizontal {
                    width: 33%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 50px;
                    max-width: 280px;
                    margin-right: 30px;
                    margin-left: 30px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="VideosAnimation__" extends="VideosAnimationController">
<div role="group" aria-labelledby="lbl-animation" class="chevron-scroll-anchor" >
    <div class="banner animation">
        <div class="banner-animation" class.black-banner="{this.props.theme === 'black'}" class.yellow-banner="{this.props.theme === 'yellow'}">
            <div class="content">
                <div class="two-columns">
                    <If condition="{!this.props.specialIndustry}">
                        <div class="column column1 text">
                            <h2 id="lbl-animation" class="title">Video &amp; Animation</h2>
                            <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Tell your story with live action and animated</span> videos that stand out in any environment.</p>
                            <div class="buttons">
                                <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                                <RouterLink
                                    role="button"
                                    to="/portfolio"
                                    query="{{f: 'video-animation'}}"
                                    className="yellow-button right-button"
                                    onClick="{this.portfolioClick}"
                                >Portfolio <span class="chevron right"></span></RouterLink>
                            </div>
                        </div>
                    </If>
                    <If condition="{this.props.industry == 'financial'}">
                        <div class="column column1 text">
                            <h2 id="lbl-animation" class="title">Video and Animation</h2>
                            <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Tell your story with live action and animated</span> videos that make personal finance seem easy.</p>
                            <div class="buttons">
                                <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                                <RouterLink
                                    role="button"
                                    to="/portfolio"
                                    query="{{f: 'video-animation'}}"
                                    className="yellow-button right-button"
                                    onClick="{this.portfolioClick}"
                                >Portfolio <span class="chevron right"></span></RouterLink>
                            </div>
                        </div>
                    </If>
                    <If condition="{this.props.industry == 'pharma'}">
                        <div class="column column1 text">
                            <h2 id="lbl-animation" class="title">Videos and Animation</h2>
                            <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Promote your products </span> and get attention with live action and animated videos and e-detailing that stand out in any environment.</p>
                            <div class="buttons">
                                <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                                <RouterLink
                                    role="button"
                                    to="/portfolio"
                                    query="{{f: 'video-animation'}}"
                                    className="yellow-button right-button"
                                    onClick="{this.portfolioClick}"
                                >Portfolio <span class="chevron right"></span></RouterLink>
                            </div>
                        </div>
                    </If>
                    <div class="column column2">
                        <!-- <video preload="true" autoPlay loop muted>
                            <source src="site_assets/images/sRGB.mp4" type="video/mp4"/>
                        </video> -->
                        <!-- <img src="site_assets/images/animation.gif"/> -->
                        <div onMouseEnter="{this.showTranscriptButton}" onMouseLeave="{this.hideTranscriptButton}">
                            <TransparentVideo
                                tabIndex="-1"
                                class="video-el"
                                src="{`site_assets/videos/video_animation_${this.props.theme === 'yellow' ? 'light' : 'dark'}.mp4`}" controlColor="#000000"
                            />
                        </div>
                        
                        <If c="{!IS_PRODUCTION}">
                            <button
                                class="transcript sr-only-with-focus"
                                class.show="{this.state.showTranscriptButton}"
                                aria-label="Video description"
                                title="Video description"
                                onClick="{this.showTranscriptDialog}"
                            ><img src="./site_assets/images/transcript/transparent-transcript.svg"/></button>
                        </If>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Collapsible open={this.state.isOpen} duration="250" ease="easeInOut" onOpened={this.accordionOpened}>
        <div class="learn-more" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
            <If condition="{!this.props.specialIndustry}">
                <div class="learn-more-content">
                    <div class="item item-line">
                        <img src="{`site_assets/images/icons/SVG/promo-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-promo-icon"/>
                        <p id="video-promo-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Promotional videos</span></p>
                    </div>
                    <div class="line"></div>
                    <div class="item item-line">
                        <img src="{`site_assets/images/icons/SVG/explainer-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-explainer-icon"/>
                        <p id="video-explainer-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Animated explainers</span></p>
                        
                    </div>
                    <div class="line"></div>
                    <div class="item item3">
                        <img src="{`site_assets/images/icons/SVG/steps-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-guides-icon"/>
                        <p id="video-guides-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Step-by-step guides</span></p>
                    </div>

                    <div class="lines">
                        <div class="line-horizontal"></div>
                        <div class="line-horizontal"></div>
                        <div class="line-horizontal"></div>
                    </div>

                    <div class="item item-line second-row">
                        <img src="{`site_assets/images/icons/SVG/testimonials-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-caseStudies-icon"/>
                        <p id="video-caseStudies-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Case studies &amp; testimonials</span></p>
                    </div>
                    <div class="line line2"></div>
                    <div class="item item-line second-row no-line">
                        <img src="{`site_assets/images/icons/SVG/stop-motion-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-stopMotion-icon"/>
                        <p id="video-stopMotion-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Stop-motion and time-lapse video</span></p>
                    </div>
                    <div class="line line2"></div>
                    <div class="item item3 second-row no-line">
                        <img src="{`site_assets/images/icons/SVG/illustration-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-photos-icon"/>
                        <p id="video-photos-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Illustration and photography</span></p>
                    </div>
                </div>
            </If>
            <If condition="{this.props.specialIndustry}">
                <div>
                    <If condition="{this.props.industry == 'financial'}">
                        <div class="learn-more-content">
                            <div class="item item-line">
                                <img src="{`site_assets/images/icons/SVG/promo-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-promo-icon"/>
                                <p id="video-promo-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Promotional videos</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item-line">
                                <img src="{`site_assets/images/icons/SVG/explainer-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-explainer-icon"/>
                                <p id="video-explainer-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Animated explainers</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item3">
                                <img src="{`site_assets/images/icons/SVG/steps-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-guides-icon"/>
                                <p id="video-guides-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Step-by-step guides</span></p>
                            </div>
        
                            <div class="lines">
                                <div class="line-horizontal"></div>
                                <div class="line-horizontal"></div>
                                <div class="line-horizontal"></div>
                            </div>
        
                            <div class="item item-line second-row">
        <!--                        <img src="{`site_assets/images/icons/SVG/testimonials-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-caseStudies-icon"/>-->
                                <img src="{`site_assets/images/icons/SVG/illustration-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-photos-icon"/>
        <!--                        <p id="video-caseStudies-icon">Case studies &amp; testimonials</p>-->
                                <p id="video-photos-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Customized onboarding videos for new clients</span></p>
                            </div>
                            <div class="line line2"></div>
                            <div class="item item-line second-row no-line">
                                <img src="{`site_assets/images/icons/SVG/testimonials-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-caseStudies-icon"/>
        <!--                        <img src="{`site_assets/images/icons/SVG/stop-motion-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-stopMotion-icon"/>-->
        <!--                        <p id="video-stopMotion-icon">Stop-motion and time-lapse video</p>-->
                                <p id="video-caseStudies-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Case studies &amp; testimonials</span></p>
                            </div>
                            <div class="line line2"></div>
                            <div class="item item3 second-row no-line">
                                <img src="{`site_assets/images/icons/SVG/stop-motion-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-stopMotion-icon"/>
        <!--                        <img src="{`site_assets/images/icons/SVG/illustration-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-photos-icon"/>-->
        <!--                        <p id="video-photos-icon">Illustration and photography</p>-->
                                <p id="video-stopMotion-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Stop-motion and time-lapse video</span></p>
                            </div>
                        </div>
                    </If>
                    <If condition="{this.props.industry == 'pharma'}">
                        <div class="learn-more-content">
                            <div class="item item-line">
                                <img src="{`site_assets/images/icons/SVG/promo-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-promo-icon"/>
                                <p id="video-promo-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Promotional videos</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item-line">
                                <img src="{`site_assets/images/icons/SVG/explainer-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-explainer-icon"/>
                                <p id="video-explainer-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Animated explainers</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item3">
                                <img src="{`site_assets/images/icons/SVG/steps-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-guides-icon"/>
                                <p id="video-guides-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Step-by-step educational guides</span></p>
                            </div>
        
                            <div class="lines">
                                <div class="line-horizontal"></div>
                                <div class="line-horizontal"></div>
                                <div class="line-horizontal"></div>
                            </div>
        
                            <div class="item item-line second-row">
        <!--                        <img src="{`site_assets/images/icons/SVG/testimonials-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-caseStudies-icon"/>-->
                                <img src="{`site_assets/images/icons/SVG/illustration-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-photos-icon"/>
        <!--                        <p id="video-caseStudies-icon">Case studies &amp; testimonials</p>-->
                                <p id="video-photos-icon"><span class.redact-white3="{this.props.theme !== 'yellow'}" class.redact3="{this.props.theme === 'yellow'}">Illustration and photography</span></p>
                            </div>
                            <div class="line line2"></div>
                            <div class="item item-line second-row no-line">
                                <img src="{`site_assets/images/icons/SVG/testimonials-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-caseStudies-icon"/>
        <!--                        <img src="{`site_assets/images/icons/SVG/stop-motion-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-stopMotion-icon"/>-->
        <!--                        <p id="video-stopMotion-icon">Stop-motion and time-lapse video</p>-->
                                <p id="video-caseStudies-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Case studies &amp; testimonials</span></p>
                            </div>
                            <div class="line line2"></div>
                            <div class="item item3 second-row no-line">
                                <img src="{`site_assets/images/icons/SVG/stop-motion-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-stopMotion-icon"/>
        <!--                        <img src="{`site_assets/images/icons/SVG/illustration-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="video-photos-icon"/>-->
        <!--                        <p id="video-photos-icon">Illustration and photography</p>-->
                                <p id="video-stopMotion-icon"><span class.redact-white2="{this.props.theme !== 'yellow'}" class.redact2="{this.props.theme === 'yellow'}">Stop-motion and time-lapse video</span></p>
                            </div>
                        </div>
                    </If>
                </div>
            </If>
        </div>
    </Collapsible>
    <RMModal content="{Dialog}" ref="{(ref) => (this.dialog = ref)}" />
</div>
</template>
<template name="Dialog">
    <div class="transcript-dialog">
        <h1 tabIndex="-1" ref="{ref => ref && ref.focus()}" style.outline="0">Video & Animation - Video Description</h1>
        <div class="body">
            <p>A woman drawing on a tablet while a sketch of the Eiffel tower and the Arc de Triomphe appear behind her and
                luggage
                bags appear by her side.</p>
    
            <p>A student swipes on a tablet while a sketch of a university appears behind him, with him standing on the
                pathway,
                surrounded by grass, a backpack and a couple of books.</p>
    
            <p>A man swipes on a tablet while a sketch of a house appears behind him. The house starts to change, with added
                windows,
                and a garage and an additional room as he modifies on his tablet.</p>
        </div>
        <button class="btn-close" aria-label="Close" onClick="{() => this.props.closeModal()}">&times;</button>
    </div>
</template>
<script>
    import {
        createRef
    } from 'rmlibrary/comp';
    import {
        scrollIntoView
    } from "rmlibrary/react-scroll-into-view";

    import {CustomizationConsumer} from "../../common/customization";
    import {trackLearnMore, trackPortfolio} from '../../../tracking';

    export function VideosAnimation(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <VideosAnimation__ {...props} 
            specialIndustry={customizationState.specialIndustry}
            industry={customizationState.industry} /> }
        </CustomizationConsumer>)
    }
    
    class VideosAnimationController extends Component {
        constructor(props) {
            super(props);

            this.scrollRef = createRef();

            this.state = {
                isOpen: false,
                showTranscriptButton: false
            }

            this.toggleAccordion = this.toggleAccordion.bind(this);
            this.portfolioClick = this.portfolioClick.bind(this);
            this.accordionOpened = this.accordionOpened.bind(this);
            this.showTranscriptButton = this.showTranscriptButton.bind(this);
            this.hideTranscriptButton = this.hideTranscriptButton.bind(this);
            this.showTranscriptDialog = this.showTranscriptDialog.bind(this);
        }

        showTranscriptButton() {
            if (this.showTranscriptTimer) {
                clearTimeout(this.showTranscriptTimer);
                this.showTranscriptTimer = null;
            }
            this.setState({
                showTranscriptButton: true
            });
        }
        hideTranscriptButton() {
            this.showTranscriptTimer = setTimeout(() => {
                this.showTranscriptTimer = null;
                this.setState({
                    showTranscriptButton: false
                });
            }, 1000);
        }

        showTranscriptDialog() {
            if (this.dialog) this.dialog.open();
        }

        toggleAccordion() {
            if (!this.state.isOpen) {
                trackLearnMore("video-animation", "Video & Animation");
            }
            this.setState({
                isOpen: !this.state.isOpen
            });
        }

        portfolioClick() {
            trackPortfolio("video-animation");
        }

        accordionOpened() {
            if (!this.scrollRef.current) return;
            let offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(this.scrollRef.current, {
                block: 'center',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }
    }

</script>
