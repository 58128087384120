<style lang="scss">
    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: $yellow;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only} {
            font-size: 36px;
        }
    }

    h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 40px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    .main-container {
        overflow-x:hidden;
        position:relative;
    }

    .text-container {
        text-align: center;
        padding: 40px 20px;
        width:100%;
        margin-bottom:80px;
    }

    .rating-btns {
        display: flex;
        justify-content: center;
        
        .btn {
            background-color: $yellow;
            color: $black90;
            font-size: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: 0.5s ease;
            margin: 0 30px;
        }
    }


    .thumbs {
        width: 29px;
        padding-top: 7px;
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:active {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    .btn:focus {
        background-color: #fff;
        outline: none;
        color: #1a1a1a;
        border: 1px solid $yellow;
    }

    .btn.selected {
        background: $white;
        color: #1a1a1a;
        outline: none;  
    }
    
    .rating-desc {
        padding-top: 15px;
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }    

    .action-btn-wrapper {
        @media #{$xs-only, $sm-only, $md-only} {
            @media screen and (max-height: 800px) {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, -50%);
                padding-bottom:0;
            }
            @media screen and (max-height: 450px) {
                bottom: -10px;
            }
        }
    }
    

    
</style>

<template name="SurveyRecommend" extends="SurveyRecommendController">
    <main class="main-container">
        <div class="text-container">
            <h1 tabIndex="-1" style="outline: none;" ref="{this.focusElement}">{this.props.title}</h1>
            
            <div>
                <h2>{this.props.question}</h2>
            </div>
            
            
           
            <div class="rating-btns">
                <button class="{'btn ' + (this.props.answer.value === 'skip' ? 'selected' : '')}" onClick="{() => this.props.onSubmit({name: this.props.questionName, value: 'skip'})}"> 
                    <img class="thumbs" src="./site_assets/images/survey/thumbs-down.svg" alt="thumbs down icon" />
                </button>
                
                <button class="{'btn ' + (this.props.answer.value === 'email' ? 'selected' : '')}" onClick="{() => this.props.onSubmit({name: this.props.questionName, value: 'email'})}"> 
                    <img class="thumbs" src="./site_assets/images/survey/thumbs-up.svg" alt="thumbs up icon" />
                </button>
            </div>
        </div>

       <!-- Buttons back/next -->
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">
                    Back
                </button>
            </div>
            <!-- <div class="next-button-container">
                <button
                    aria-label="next"
                    class="next-button btn"
                    onClick="{() => this.props.onSubmit('next')}"
                >
                    Next
                </button>
            </div>
        </div> -->
    </main>
</template>

<script>
    class SurveyRecommendController extends Component {
        constructor(props) {
            super(props);
            
            this.focusElement = React.createRef();
        }

        componentDidMount() {
            this.focusElement.current.focus();
            window.scrollTo(0, 0);
        }
    }

    SurveyRecommendController.defaultProps = {
        title: "Recommend Us",
        question: "Would you recommend us to a friend?",
        onSubmit: () => {
            console.log("submit")
        }
    };
</script>