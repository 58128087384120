/*globals ga */

import { trackEvent, trackPage } from "./components/common/hs-track";

export function trackLearnMore(section, sectionFull) {
    console.log("TRACK LEARN MORE", `/learn-more/${section}`); // eslint-disable-line
    ga("send", "pageview", `/learn-more/${section}`);
    trackPage(`Learn more - ${sectionFull || section}`);
}

export function trackPersonalizationTool() {
    console.log("TRACK PERSONALIZATION TOOL");
    ga("send", "pageview", `/personalization`);
    trackPage(`Personalization Tool`);
}

export function trackPersonalizationEvent(eventType, selection, preventGoogle) {
    selection = selection == "true" ? "default" : selection;
    selection = !selection ? "N/A" : selection;
    console.log(`TRACK ${eventType.toUpperCase()} - ${selection.toUpperCase()}`);
    trackEvent(`${eventType} - ${selection}`);
    if (preventGoogle) {
        ga("send", "event", "Personalization", eventType, selection);
    }
}

export function trackPersonalizationAll(visitReason, industry, interest, theme, AM) {
    AM = !AM ? "N/A" : AM;
    console.log("TRACK PERSONALIZATION ALL");
    ga("send", "pageview", `/personalization/${visitReason} ${industry} ${interest} ${theme} ${AM}`);
    // trackPage(`Personalization - ${visitReason} ${industry} ${interest} ${theme} ${AM}`)
}

export function trackPortfolio(section) {
    // console.log("TRACK CLICK", 'Portfolio', section); // eslint-disable-line
    // ga('send', 'event', 'Portfolio', 'Click', section);
}

export function trackSurvey(section) {
    console.log("TRACK Survey", `/survey/${section}`); // eslint-disable-line
    ga("send", "pageview", `/survey/${section}`);
    trackPage(`Survey - ${section}`);
}
