
const NUM_SEGMENTS = 10;

export class CubicBezier {
    constructor(x1, y1, x2, y2)  {
        this.x1 = x1;
        this.x2 = x2;
        this.y1 = y1;
        this.y2 = y2;

        this.Ax = 3.0 * x1 - 3.0 * x2 + 1;
        this.Bx = 3.0 * x2 - 6.0 * x1;
        this.Cx = 3.0 * x1;
        this.Ay = 3.0 * y1 - 3.0 * y2 + 1;
        this.By = 3.0 * y2 - 6.0 * y1;
        this.Cy = 3.0 * y1;

        this._cache = {};
    }

    x(t) {
        return (((this.Ax * t + this.Bx) * t) + this.Cx) * t;
    }
    dx(t) {
        return (3.0 * this.Ax * t + 2 * this.Bx) * t + this.Cx;
    }

    yForT(t) {
        return (((this.Ay * t + this.By) * t) + this.Cy) * t;
    }

    findT(x) {
        let t = x;
        for (var i = 0; i < 5; i++) {
            let dx = this.dx(t);
            if (dx === 0) return t;
            let _x = this.x(t) - x;
            t = t - _x / dx;
        }
        return t;
    }

    y(x) {
        if (this._cache[x]) return this._cache[x];

        const t = this.findT(x);
        const y = this.yForT(t);
        this._cache[x] = y;
        return y;
    }
}
