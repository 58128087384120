<style lang="scss">
    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #1a1a1a;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        width:100%;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        width: 80%;
        color: #1a1a1a;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    h3 {
        font-size: 23px;
        font-weight: 600;
        vertical-align: super;
        display: inline;

        @media #{$xs-only, $sm-only} {
            font-size: 18px;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    .text-container-products {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 40px 20px 0 20px;
        position:inherit;
        max-width:1400px;
        margin:0 auto;
        @media #{$sm-only, $xs-only, $md-only} {
            padding: 40px 10px 0 10px;
        }

    }

    .validation {
        margin-top: 10px;

        .exclamation {
            width: 20px;
            height: 20px;
        }

        p {
            display: inline;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: text-top;
        }
    }

    .products-page {
        display: flex;
        justify-content: center;
        width: 100%;
        @media #{$md-up} {
            .products-column {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        .products-row {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 1200px;

            @media #{$md-up} {
                margin-left: 36px;
            }
            @media #{$lg-only} {
                margin-bottom: 70px;
            }
            @media #{$xs-only, $sm-only} {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
            }
            @media #{$lg-up} {
                @media (min-height: 700px) and (max-height: 820px) {
                    margin-bottom: 120px;
                }
            }    
        }


        .products-column {
            width: 306px;
            margin-bottom: 40px;

            @media #{$xs-only, $sm-only} {
                width: 250px;
                margin-bottom: 15px;
                margin-left: 25px;
            }

            .products-icon {
                width: 40px;
                height: 40px;
                margin-right: 15px;
            }

            .title {
                margin-bottom: 20px;
                text-align: left;
            }

            .item {
                margin-bottom: 12px;
                text-align: left;
                margin-left: 10px;
            }

            input[type="checkbox"] {
                width: 22px;
                height: 22px;
                border: 1px solid #1a1a1a;
                margin-right: 15px;
                -webkit-appearance: none;
                transition: 0.2s ease;
                background-position: center;
                background-size: 0%;
                float: left;
            }
            
            input[type="checkbox"]::-ms-check {
                color: transparent;
                background-color: transparent;
                border:1px solid #1a1a1a;
            }

            input[type="checkbox"]:checked {
                background-color: #1a1a1a;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(./images/survey/checkmark-white.png);
                background-size: 75%;
            }

            input[type="checkbox"]:hover,
            input[type="checkbox"]:focus {
                cursor: pointer;
                background-color: #1a1a1a;
            }

            input[type="checkbox"]:focus {
                outline: none;
                border:1px solid white;
            }

            .text input[type="text"] {
                width: 185px;
                height: 40px;
                padding: 0 10px;
                border-bottom: 1px solid #1a1a1a;
                background-color: rgba(255, 255, 255, 0);
                margin-top: -20px;
            }

            .text input[type="text"]::placeholder {
                color: #1a1a1a;
            }


            label {
                font-size: 20px;
                vertical-align: top;
                display: block;
                margin-left: 35px;
            }
        }
    }

    .back-button-container, .next-button-container {
        bottom:70px;
        @media #{$xs-only} {
            bottom:20px;
        }
    }
    
    .action-btn-wrapper {
        @media #{$lg-up} {
            @media screen and (max-height: 699px) {
                padding-top:100px;
            }
        }    
        @media (min-height: 821px) and (max-height: 900px) {
            padding-top:100px;
        }
        @media (min-height: 700px) and (max-height: 820px) {
            margin-top: 0px;
        }
    }
    
</style>

<template name="SurveyProducts" extends="SurveyProductsController">
    <!-- background colour determined by parent component "renderCurrentQuestion()" -->
    <main class="main-container yellow-container">
        <div class="text-container-products">
            <h1 tabIndex="-1" style="outline: none;" ref="{this.headerElement}">{this.props.title}</h1>

            <h2>{this.props.question}</h2>

            <div class="products-page">

                <div class="products-row">
                    <!-- generate list of categories and checkbox options -->
                    <For var="category" of="{this.props.products}" index="index">
                        <div key={index} class="products-column">
                            <div class="title">
                                <span class="products-icon">
                                    <img src="{category.icon}" alt="{category.alt}" />
                                </span>
                                <h3>{category.category}</h3>
                            </div>
                            <For var="option" of="{category.options}">
                                <div key={category.options.indexOf(option)} class="item">
                                    <input type="checkbox" id="{option.id}" name="{option.name}"
                                        onClick="{this.productChecked}">
                                    <label htmlFor="{option.id}">{option.name}</label>
                                </div>
                            </For>
                        </div>
                    </For>

                    <!-- other category and options -->
                    <div class="products-column">
                        <div class="title">
                            <span class="products-icon"><img src="{this.props.other.icon}"
                                    alt="{this.props.other.alt}" /></span>
                            <h3>{this.props.other.category}</h3>
                        </div>
                        <div class="item">
                            <input type="checkbox" id="{this.props.other.options.name}" name="{this.props.other.options.name}"
                                onClick="{this.otherChecked}">
                            <label htmlFor="{this.props.other.options.name}">{this.props.other.options.name}</label>
                        </div>
                        <div class="text" style="{{display: this.state.otherSelected ? 'block' : 'none'}}">
                            <input ref="{this.textInput}" type="text" id="this.props.other.text"
                                placeholder="Please specify" autoComplete="off" onBlur="{this.validateTextInput}"
                                aria-label="specify other product" />
                        </div>
                        <div class="validation" style="{{display: this.state.passValidation ? 'none' : 'block'}}">
                            <span><img class="exclamation"
                                    src="./site_assets/images/survey/validation-black.svg" /></span>
                            <p role="alert">Please fill in the text above.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Buttons back/next -->
       <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">Back</button>
            </div>
            <div class="next-button-container">
                <button aria-label="next" class="next-button btn next-outline" onClick="{this.handleSubmission}">Next</button>
            </div>
        </div>   
    </main>
</template>

<script>
    class SurveyProductsController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                productSelected: false,
                selectedProductCount: 0,
                selectedOptions: [],
                otherSelected: false,
                passValidation: true
            };

            this.validateTextInput = this.validateTextInput.bind(this);
            this.handleSubmission = this.handleSubmission.bind(this);
            this.otherChecked = this.otherChecked.bind(this);
            this.productChecked = this.productChecked.bind(this);
            this.submit = this.submit.bind(this);
            this.textInput = React.createRef();
            this.headerElement = React.createRef();
        }

        componentDidMount() {
            this.headerElement.current.focus();

            if (this.props.answer) {
                this.props.answer.value.map((answer) => {
                    let checkbox = document.getElementById(answer);
                    if (checkbox !== null) {
                        checkbox.checked = true;
                    } else {
                        checkbox = document.getElementById("Other");
                        let otherTxt = answer.substr(7);
                        this.textInput.current.value = otherTxt;
                        checkbox.checked = true;
                        this.setState({
                            otherSelected: true
                        });
                    }
                });
                this.setState({
                    productSelected: true
                });
                
            }

            let nav = document.getElementsByClassName('page-header')[0];
            let x = nav.offsetWidth / 2;
            window.scrollTo(0, 0);
        }

        componentDidUpdate() {
            if (!this.state.passValidation) {
                this.textInput.current.focus();
            }
        }

        // otherChecked & productChecked: allows user to proceed with survey once product or other checkboxes are checked
        otherChecked() {
            if (!this.state.otherSelected) {
                this.setState({
                    otherSelected: true,
                    productSelected: true
                }, () => {
                    this.productChecked();
                });
            } else {
                this.setState({
                    otherSelected: false,
                    passValidation: true
                }, () => {
                    this.productChecked();
                });
            }
        }

        productChecked() {
            let counter;

            if (this.otherSelected) {
                counter = 1;
            } else {
                counter = 0;
            }

            // checks if options are selected & increments count
            this.props.products.forEach(product => {
                product.options.forEach(option => {
                    let inputElement = document.getElementsByName(option.name)[0];
                    if (inputElement.checked) {
                        counter++;
                        // set ProductSelected to true to immediately show next arrow
                        if (this.productSelected == false) {
                            this.setState({
                                productSelected: true
                            });
                        }
                    }
                });
            });

            // set ProductSelected flag once final count of checkboxes identified (including other)
            if (counter === 0 && !this.state.otherSelected) {
                this.setState({
                    productSelected: false
                });
            } else {
                this.setState({
                    productSelected: true
                });
            }

            // reset count
            if (this.state.otherSelected) {
                this.setState({
                    selectedProductCount: 1
                });
            } else {
                this.setState({
                    selectedProductCount: 0
                });
            }
        }

        handleSubmission() {
            if (this.state.otherSelected) {
                if (this.validateTextInput()) {
                    this.submit();
                }
            } else {
                this.submit();
            }
        }

        // validates text input is not empty
        validateTextInput() {
            let validity = false;
            if (this.textInput.current.value.length > 0) {
                this.setState({
                    passValidation: true
                });
                validity = true;
            } else {
                this.setState({
                    passValidation: false
                });
            }
            return validity;
        }

        // updates state selectedOptions array and submits  
        submit() {
            if (this.state.otherSelected && this.state.passValidation) {
                this.state.selectedOptions.push("Other: " + this.textInput.current.value);
            }
            this.props.products.forEach(product => {
                let numOfOptions = product.options.length;
                let currentOption = 0;
                while (currentOption < numOfOptions) {
                    let element = document.getElementById(product.options[currentOption].id);
                    if (element.checked) {
                        this.state.selectedOptions.push(element.id);
                    }
                    currentOption++;
                }
            });
            this.props.onSubmit({name: this.props.questionName, value: this.state.selectedOptions});
        }
    }

    SurveyProductsController.defaultProps = {
        title: "Our Products",
        question: "Which of the following types of difital marketing do you expect to be needing in the next 6-12 months? (Select all that apply)"
    };
</script>