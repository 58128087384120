<!-- Import other components used here -->


<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';
    .wrapper {
        background-color:$black90;
    }
    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top:100px;
        padding-bottom:100px;
        @media #{$xs-only, $sm-only} {
            padding-top:20px;
        }
        .two-columns {
            display:flex;
            width:100%;
            flex-wrap:wrap;
            .column {
                width:50%;
                @media #{$xs-only, $sm-only} {
                    width:100%;
                }
                h1 {
                    font-size:50px;
                    font-weight:bold;
                    color:$yellow;
                    max-width:350px;
                    margin-bottom:20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    @media #{$sm-only} {
                        font-size:29px;
                    }
                    @media #{$md-only} {
                        font-size:45px;
                    }
                    @media #{$xs-only, $sm-only} {
                        max-width:300px;
                        font-size:35px;
                        max-width:none;
                    }
                }
                h2 {
                    margin-bottom:10px;
                }
                .job-details {
                    display:flex;
                    width:100%;
                    .icon {
                        width:20%;
                        animation-name: fadeIn;
                        animation-duration: 1s;
                        @media #{$xs-only, $sm-only} {
                            width:12%;
                        }
                    }
                    .details {
                        width:80%;
                        @media #{$xs-only, $sm-only, $md-only} {
                            margin-left:10px;
                        }
                    }
                }
            }
            .column1 {
                width:40%;
                max-width:400px;
                margin-right:90px;
                @media #{$sm-only} {
                    margin-right:20px;
                }
                @media #{$md-only} {
                    margin-right:55px;
                }
                @media #{$xs-only, $sm-only} {
                    width:100%;
                    max-width:none;
                    margin-right:0;
                }
            }
            .column2 p {
                margin-bottom:10px;
            }
            .column2 img {
                @media #{$sm-only, $xs-only} {
                    max-width:50px;
                    width:100%;
                }
            }
            .subcategory {
                margin-top:25px;
            }
        }
    }

    .desktop {
        display:none;
        @media #{$md-up} {
            display:inline-block;
        }
    }
    h2 {
        color:$yellow;
        font-size:20px;
        font-weight:bold;
        margin-bottom:15px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }
    p {
        color:white;
        font-size:16px;
        line-height:21px;
        animation-name: fadeIn;
        animation-duration: 1s;
        margin: 10px 0;
    }
    .yellow-button {
        margin-top:30px;
        margin-left:0;
        animation-name: fadeIn;
        animation-duration: 1s;
    }
    .divider {
        height:1px;
        background-color:$yellow;
        width:90%;
        margin-top:25px;
        margin-bottom:25px;
    }
    .apply-mobile {
        display:none;
        @media #{$xs-only, $sm-only} {
            display:contents;
        }
    }
    .mobile {
        display:none;
        @media #{$xs-only, $sm-only} {
            display:table;
        }
    }
</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->

<template name="ApplyAnyway">
    <main>
        <div class="wrapper">
        <div class="content">
            <div class="two-columns">
                <div class="column column1">
                    <h1>No posting?<br />Apply anyway</h1>
                    <p>Rich Media is always looking for exceptional people with a passion for solving problems in creative ways.</p>
                    <div class="divider"></div>
                    <h2 class="desktop">For interested applicants:</h2>
                    <p class="apply desktop">Please provide a copy of your resume and university or college transcript.</p>
                    <a href="mailto:careers@richmedia.com?subject=Applying anyways&body=Please provide a copy of your resume and university or college transcript. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button desktop">Apply<span class="chevron right"></span></a>
                </div>
                <div class="column column2">
                    <div class="job-details">
                        <div class="icon">
                            <img src="site_assets/images/icons/SVG/responsibilities-icon-yellow.svg" alt="Responsibilities" width="74" height="74"/>
                        </div>
                        <div class="details">

                            <h2>Designers & developers wanted</h2>
                            <p>We thrive on creativity, problem solving, and teamwork. If you do too, we’d love to get to know more about you. Even if we’re not looking now, we may be soon.</p>
                            <h2 class="subcategory">Wear many hats</h2>
                            <p>We’re a nimble, agile team. You won’t just work on one thing day-in, day-out – every day will bring with it a new task, a new challenge, a new learning opportunity.</p>
                            <h2 class="subcategory">Work with major clients</h2>
                            <p>Many of Rich Media’s clients are financial institutions, tech giants, and other large, international corporations. With Rich Media, you’ll work with the best, for the best.</p>
                        </div>
                    </div>

                </div>

                <div class="divider mobile"></div>

                <div class="mobile apply-mobile">
                    <h2 class="mobile">For interested applicants:</h2>
                        <p class="apply mobile">Please provide a copy of your resume and university or college transcript.</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying anyways&body=Please provide a copy of your resume and university or college transcript. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button mobile">Apply<span class="chevron right"></span></a>
                </div>
            </div>
        </div>
        </div>
    </main>

</template>
