<import name="RouterLink" from="rmlibrary/router" />
<import name="RMModal" from="rmlibrary/react-modal" />
<import name="TransparentVideo" from="../../common/transparent-video" />
<import name="Collapsible" from="rmlibrary/collapsible" />
<import name="Scroll" from="rmlibrary/scroll" />
<import name="LearnMoreButton" from="../../common/learn-more-button.html" />

<style lang="scss">
    @import 'sass/variables';

    .banner-video {
        @media #{$md-only} {
            max-height: 530px;
        }
        .text {
            color: white;
            text-align: center;
            .title {
                padding-top: 60px;
                @media #{$md-only} {
                    padding-top: 30px;
                }
            }
            h2.black-banner {
                color: #FFC425;
            }
            h2.yellow-banner {
                color: #000000;
            }
            .copy {
                /*color: white;*/
                margin-bottom: 35px;
                margin-top: 50px;
                @media #{$sm-only} {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
                @media #{$xs-only} {
                    margin-bottom: 30px;
                    margin-top: 30px;
                }
                @media #{$md-only} {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }

        }
        .device {
            text-align: center;
            overflow: hidden;
            padding-bottom: 35px;
            @media #{$md-only, $sm-only, $xs-only} {
                padding-bottom: 0px;
            }
        }
        .buttons {
            padding-bottom: 0;
            @media #{$sm-only, $xs-only} {
                padding-bottom: 40px;
            }
            @media #{$md-only} {
                padding-bottom: 30px;
            }
            .yellow-button {
                margin: 10px;
                @media #{$xs-only} {
                    margin: 3px;
                }
            }
            .yellow-button .selected {
                border: 1px solid $yellow;
                transition: 0.5s ease;
                margin: 10px;
                .chevron {
                    color: $black;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid white;
                    border-style: inset;
                    color: $white;
                    transition: background-color 0.5s ease;
                }
            }
            .black-button {
                background-color: $black90;
                color: white;
                border: 1px solid white;
                .chevron {
                    color: $white;
                }
            }
            .selected {
                background-color: $yellow;
                color: $black;
                .chevron {
                    color: $black;
                }
            }
        }
        &.yellow-banner {
            .black-button {
                background-color: $yellow;
                color: black;
                border: 1px solid black;
                .chevron {
                    color: black;
                }
            }
            .black-button:hover {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                .chevron {
                    color: $yellow;
                }
            }
        }
    }
    
    .transcript {
        width: 30px;
        position: relative;
        bottom: 171px;
        left: 310px;
        @media #{$sm-only} {
            bottom: 10px;
            left: 20px;
            position: absolute;
        }
        @media #{$xs-only} {
            bottom: 10px;
            left: 20px;
            position: absolute;
        }
        @media #{$md-only} {
            bottom: 125px;
            left: 235px;
        }

        >* {
            vertical-align: top;
        }
    }

    .video-el {
        width: 100%;
        max-width: 590px;
        position: relative;
        min-height: 100px;
        @media #{$md-up} {
            bottom: 60px;
            padding-top: 40px;
        }
        @media #{$md-only} {
            max-width: 435px;
        }
        @media #{$sm-only, $xs-only} {
            bottom: 0px;
        }
        @media #{$xs-only} {
            max-width: 420px;
        }
    }

    .mobile {
        display: none;
        @media #{$xs-only, $sm-only} {
            display: block;
            padding-bottom: 40px;
        }
    }

    .desktop {
        display: none;
        @media #{$md-up} {
            display: block;
        }
    }


    .learn-more {
        background-color: $black90;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 90px;
        .learn-more-content {
            display: flex;
            justify-content: Center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            padding-bottom: 90px;
            .column {
                width: 50%;
                max-width: 450px;
                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                }
                .item {
                    display: flex;
                    align-items: center;
                    color: white;
                    img {
                        width: 70px;
                        max-width: 70px;
                        margin-right: 20px;
                        flex-shrink: 0;
                        -webkit-animation: pop-in 0.5s 0.2s both;
                        -moz-animation: pop-in 0.5s 0.2s both;
                        -ms-animation: pop-in 0.5s 0.2s both;
                        animation-delay: 0.7s;
                    }
                    p {
                        -webkit-animation: fadeIn 1s 0.2s both;
                        -moz-animation: fadeIn 1s 0.2s both;
                        -ms-animation: fadeIn 1s 0.2s both;
                        animation-delay: 0.8s;
                    }
                }
                .line {
                    width: 100%;
                    height: 1px;
                    background-color: $white;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
                .line-mobile {
                    @media #{$xs-only, $sm-only} {
                        width: 100%;
                        height: 1px;
                        background-color: $white;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        animation: grow-width2 0.5s 0.2s both;
                        animation-delay: 0.6s;
                        @media #{$md-up} {
                            opacity: 0;
                        }
                    }
                }
                .hide {
                    @media #{$xs-only, $sm-only} {
                        display: none;
                    }
                }
            }
            .column1 {
                padding-right: 60px;
                @media #{$md-only} {
                    padding-right: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-right: 0;
                }
            }
            .column2 {
                padding-left: 60px;
                @media #{$md-only} {
                    padding-left: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-left: 0;
                }
            }
        }
        &.yellow {
            background-color: $yellow;
            .learn-more-content {
                .column {
                    .line,
                    .line-mobile {
                        background-color: $black;
                    }
                    p {
                        color: $black;
                    }
                }
            }
        }
        .fDesc {
            font-size: 20px;
        }
    }

    .personalized-finance {
        color: #FFFFFF;
        
        &.black {
            color: #000000;
        }
    }
    
</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="PersonalizedVideo__" extends="PersonalizedVideoController">
    <div class="chevron-scroll-anchor">
    <Scroll classInView="rm-timeline-banner-personalized-video">
    <div class="banner">
        <div class="banner-video" class.black-banner="{this.props.theme === 'black'}" class.yellow-banner="{this.props.theme === 'yellow'}">
            <div class="content">
                <div role="group" aria-labelledby="lbl-personalized-video" class="text">
                    <h2 id="lbl-personalized-video" class="title" class.black-banner="{this.props.theme === 'black'}" class.yellow-banner="{this.props.theme === 'yellow'}">Personalized Video</h2>
                    <If condition="{!this.props.specialIndustry}">
                        <p class="copy"> <span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach your customers like never before with</span> dynamic,<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> individually-customized video. Let them see their own</span> data illustrated in a video<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">, with dynamic graphs and live-feed information –</span> all of it mobile-friendly.</span></p>
                    </If>
                    <If condition="{this.props.specialIndustry}">
                        <div>
                            <If condition="{this.props.industry == 'financial'}">
                                <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach your customers like never before with </span> dynamic,<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> individually-customized video. Let them see their own finances </span>illustrated with dynamic graphs<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> and live-feed information –</span> all of it mobile-friendly.</p>
                            </If>
                            <If condition="{this.props.industry == 'pharma'}">
                                    <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Reach viewers like never before with </span> dynamic,<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> individually-customized video. Tailor a video to the </span>specific viewer, so <span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"> they see what's</span> most important to them.</p>
                                </If>
                        </div>
                    </If>
                    <div class="buttons desktop buttons-yellow">
                        <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                        <RouterLink
                            role="button"
                            to="/portfolio"
                            query="{{f: 'video-animation'}}"
                            className="yellow-button black-button"
                            onClick="{this.portfolioClick}"
                        >Portfolio <span class="chevron right"></span></RouterLink>
                    </div>
                    <div class="device">
                        <!-- <img src="site_assets/images/banner-bgs/personalized-video.png"/> -->
                        <div style.display="inline-block" onMouseEnter="{this.showTranscriptButton}" onMouseLeave="{this.hideTranscriptButton}">
                            <TransparentVideo tabIndex="-1" class="video-el" src="site_assets/videos/personalized-new.mp4" />
                        </div>
                    </div>
                    <If c="{!IS_PRODUCTION}">
                        <button class="transcript sr-only-with-focus"
                            class.show="{this.state.showTranscriptButton}"
                            aria-label="Video description"
                            title="Video description"
                            onClick="{this.showTranscriptDialog}"
                        ><img src="./site_assets/images/transcript/yellow-transcript.svg" /></button>
                    </If>
                    <div class="buttons mobile buttons-yellow">
                        <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                        <RouterLink
                            role="button"
                            to="/portfolio"
                            query="{{f: 'video-animation'}}"
                            className="yellow-button black-button"
                            onClick="{this.portfolioClick}"
                        >Portfolio <span class="chevron right"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Scroll>    
    <Collapsible open={this.state.isOpen} duration="250" ease="easeInOut" onOpened={this.accordionOpened}>
        <div class="learn-more" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
            <div class="content">
                <If condition="{this.props.specialIndustry}">
                    <div class="learn-more-content fDesc title-2">
                        <p class="personalized-finance" class.black="{this.props.theme === 'yellow'}">Personalized videos are like regular videos, but:</p>
                    </div>
                </If>
                <If condition="{!this.props.specialIndustry}">
                    <div class="learn-more-content">
                        <div class="column column1">
                            <div class="item item1">
                                    <img src="{`site_assets/images/icons/SVG/tailored-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-tailored-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-tailored-icon">Tailored:</b> only viewer-relevant sections need to be shown</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/customized-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-customized-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-customized-icon">Customized:</b> instead of hypothetical data, animate your user’s data</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2 item3">
                                <img src="{`site_assets/images/icons/SVG/uptodate-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-upToDate-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-upToDate-icon">Always up to date:</b> data (like interest rates) is pulled from external sources</span></p>
                            </div>
                            <div class="line-mobile"></div>
                        </div>
                        <div class="column column2">
                            <div class="item item1">
                                <img src="{`site_assets/images/icons/SVG/lightweight-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-lightweight-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-lightweight-icon">Lightweight:</b> HTML and JS files take up less space than video</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/analytics-compatible-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-analytics-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-analytics-icon">Compatible with analytics:</b> know how users interact with your video</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/interactive-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-interactive-icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-interactive-icon">Interactive:</b> clickable links (and more) can be placed directly in the video</span></p>

                            </div>
                        </div>
                    </div>
                
                </If>
                <If condition="{this.props.specialIndustry}">
                    <div>
                        <If condition="{this.props.industry == 'financial'}"> 
                            <div class="learn-more-content">
                                <div class="column column1">
                                    <div class="item item1">
                                            <img src="{`site_assets/images/icons/SVG/tailored-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-tailored-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-tailored-icon">Tailored</b><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"></span> speak to each viewer’s specific wants and needs</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/customized-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-customized-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-customized-icon">Customized</b> instead of hypothetical data, animate your user’s data</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2 item3">
                                        <img src="{`site_assets/images/icons/SVG/uptodate-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-upToDate-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-upToDate-icon">Always up to date</b> data (like interest rates) is pulled from external sources</span></p>
                                    </div>
                                    <div class="line-mobile"></div>
                                </div>
                                <div class="column column2">
                                    <div class="item item1">
                                        <img src="{`site_assets/images/icons/SVG/lightweight-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-lightweight-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-lightweight-icon">Lightweight</b> HTML and JS files take up less space than video</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/analytics-compatible-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-analytics-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-analytics-icon">Compatible with analytics</b> know how users interact with your video</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/interactive-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-interactive-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-interactive-icon">Interactive:</b> clickable links (and more) can be placed directly in the video</span></p>
                                    </div>
                                </div>
                            </div>
                        </If>
                        <If condition="{this.props.industry == 'pharma'}"> 
                            <div class="learn-more-content">
                                <div class="column column1">
                                    <div class="item item1">
                                            <img src="{`site_assets/images/icons/SVG/tailored-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-tailored-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-tailored-icon">Tailored:</b><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"></span> only viewer-relevant sections need to be shown, in any order you want</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/customized-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-customized-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-customized-icon">Customized:</b> dynamic variables let you show your user's name (and more) in the video</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2 item3">
                                        <img src="{`site_assets/images/icons/SVG/uptodate-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-upToDate-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-upToDate-icon">Always up to date:</b> data and text can be pulled from external sources</span></p>
                                    </div>
                                    <div class="line-mobile"></div>
                                </div>
                                <div class="column column2">
                                    <div class="item item1">
                                        <img src="{`site_assets/images/icons/SVG/lightweight-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-lightweight-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-lightweight-icon">Lightweight:</b> HTML and JS files take up less space than video</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/analytics-compatible-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-analytics-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-analytics-icon">Compatible with analytics:</b> know how users interact with your video</span></p>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item item2">
                                        <img src="{`site_assets/images/icons/SVG/interactive-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-labelledby="personalized-interactive-icon"/>
                                        <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}"><b id="personalized-interactive-icon">Interactive:</b> clickable links can be placed directly in the video</span></p>
                                    </div>
                                </div>
                            </div>
                        </If>
                    </div>
                </If>
                
            </div>
        </div>
    </Collapsible>
    <RMModal content="{Dialog}" ref="{(ref) => (this.dialog = ref)}" />
</div>
</template>
<template name="Dialog">
    <div class="transcript-dialog">
        <h1 tabIndex="-1" ref="{ref => ref && ref.focus()}" style.outline="0">Personalized Video - Video Description</h1>
        <div class="body">
            <p>Social Media animated video of the seasons changing, the screen reading “Hello, Jane”.</p>

            <p>An animated video of a personalized savings calculation.</p>

            <p>An animated video of a rewards message saying, “Congratulations, Samantha! You are now a gold member!”.</p>

            <p>An animated video of a personalized mortgage report, showing a bar graph comparison.</p>
        </div>
        <button class="btn-close" aria-label="Close" onClick="{() => this.props.closeModal()}">&times;</button>
    </div>
</template>
<script>
    import {
        createRef
    } from 'rmlibrary/comp';
    import {
        scrollIntoView
    } from "rmlibrary/react-scroll-into-view";

    import {CustomizationConsumer} from "../../common/customization";
    import {trackLearnMore, trackPortfolio} from '../../../tracking';

    export function PersonalizedVideo(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <PersonalizedVideo__ {...props} 
            specialIndustry={customizationState.specialIndustry}
            industry={customizationState.industry} /> }
        </CustomizationConsumer>)
    }

    class PersonalizedVideoController extends Component {
        constructor(props) {
            super(props);

            this.scrollRef = createRef();

            this.state = {
                isOpen: false,
                showTranscriptButton: false
            }

            this.toggleAccordion = this.toggleAccordion.bind(this);
            this.portfolioClick = this.portfolioClick.bind(this);
            this.accordionOpened = this.accordionOpened.bind(this);
            this.showTranscriptButton = this.showTranscriptButton.bind(this);
            this.hideTranscriptButton = this.hideTranscriptButton.bind(this);
            this.showTranscriptDialog = this.showTranscriptDialog.bind(this);
        }

        showTranscriptButton() {
            if (this.showTranscriptTimer) {
                clearTimeout(this.showTranscriptTimer);
                this.showTranscriptTimer = null;
            }
            this.setState({
                showTranscriptButton: true
            });
        }
        hideTranscriptButton() {
            this.showTranscriptTimer = setTimeout(() => {
                this.showTranscriptTimer = null;
                this.setState({
                    showTranscriptButton: false
                });
            }, 1000);
        }

        showTranscriptDialog() {
            if (this.dialog) this.dialog.open();
        }

        toggleAccordion() {
            if (!this.state.isOpen) {
                trackLearnMore("personalized-video", "Personalized Video");
            }
            this.setState({
                isOpen: !this.state.isOpen
            });
        }

        portfolioClick() {
            trackPortfolio("personalized-video");
        }

        accordionOpened() {
            if (!this.scrollRef.current) return;
            let offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(this.scrollRef.current, {
                block: 'center',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }
    }

</script>
