<style lang="scss">
    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: $black90;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only} {
            font-size: 36px;
        }
    }

    h2 {
        color: $black90;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 50px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
            margin-top: 25px;
        }
    }

    .main-container {
        background-color: $yellow;
        overflow: hidden;
        min-height: auto;
    }

    .text-container {
        padding: 20px 20px;
        transform: none;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        display: block;
        margin: 0 auto;
        @media #{$lg-up} {
            margin-bottom: 105px;
        }
    }

    label {
        color: $black90;
        font-size: 16px;
        font-weight: 600;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        font-size: 14px;
    }

    .form-row {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        margin-left: 10px;

        @media #{$xs-only, $sm-only} {
            flex-direction: column;
            align-items: center;
        }
    }

    .form-row .first-row {
        margin-left: -50px;
        margin-right: -7.5px;
        padding-right: 0px;

        @media #{$xs-only, $sm-only} {
            margin-left: -45px;
            margin-right: 0px;
        }
    }

    .no-label-lastname {
        margin-left: 55px;
        margin-right: -7.5px;

        @media #{$xs-only, $sm-only} {
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    #ccInput {
        @media #{$xs-only, $sm-only} {
            margin-top: 10px;
            margin-left: -40px;
            margin-bottom: 10px;
        }
    }

    .text label {
        display: inline;
        padding-right: 20px;
    }

    .text input {
        height: 50px;
        border-radius: 10px;
        width: 270px;
        padding-left: 20px;
        padding-right: 20px;

        @media #{$xs-only, $sm-only} {
            width: auto;
            font-size: 14px;
        }
        &:focus {
            border: 1px solid $black90;
            outline: none;
        }
    }
    .text .cc-mail {
        &:focus {
            border: 1px solid white;
            outline: none;
        }
    }

    .mail-text label {
        padding-top: 0px;
    }

    .mail-text textarea {
        height: 375px;
        max-width: 691px;
        width: 100%;
        border: 1px solid white;
        border-radius: 10px;
        padding: 15px;
        transition: 0.2s ease-in-out;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }

        @media #{$xs-only} {
            width: 100%;
            transition: 0.2s ease-in-out;
        }
        &:focus {
            border: 1px solid $black90;
            outline: none;
        }
    }

    .mail-text {
        max-width: 610px;
        padding-left: 15px;
        margin: 0 auto;
        @media #{$xs-only, $sm-only} {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 20px;
        }
        @media #{$xs-only} {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
        }
    }

    .cc-mail {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #1a1a1a;
    }

    .validation {
        margin-top: 10px;
        .exclamation {
            width: 20px;
            height: 20px;
        }
        p {
            display: inline;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: text-top;
        }
    }

    a.btn {
        padding-top: 10px;
        cursor: pointer;
    }
    .btn {
        background-color: $black90;
        color: $white;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        width: 160px;
        height: 40px;
        border-radius: 10px;
        transition: 0.5s ease;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        display: block;
        &:focus {
            outline: none;
            border: 1px solid white;
            border-radius: 10px;
        }
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {
            background-color: $white;
            color: $black90;
            border: none;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:hover {
            background-color: $white;
            color: $black90;
            border: none;
        }
    }

    .back-button {
        background-color: white;
        color: #1a1a1a;
        &:hover {
            background-color: #1a1a1a;
            color: white;
            border: 1px solid white;
        }
    }

    .next-button {
        background-color: $yellow;
        border: 1px solid #1a1a1a;
        color: #1a1a1a;
        &:hover {
            background-color: white;
            border: 1px solid white;
        }
    }

    .skip {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid $black90;
        color: $black90;
        margin-bottom: 20px;
        &:focus {
            outline: none;
            border: 1px solid $black90;
            background-color: white;
        }
    }

    #sendBtn {
        &:focus {
            border: 1px solid $black90;
        }
    }
</style>

<template name="SurveyEmail" extends="SurveyEmailController">
    <main class="main-container">
        <div class="text-container">
            <h1 tabIndex="-1" style="outline: none" ref="{this.headerRef}">{this.props.title}</h1>
            <div>
                <h2>{this.props.text}</h2>
            </div>

            <form id="emailForm" noValidate>
                <div class="form-row">
                    <div class="text first-row">
                        <label htmlFor="firstName">To:</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value="{this.state.firstName}"
                            placeholder="First Name"
                            ref="{this.firstNameRef}"
                            onChange="{this.handleChange}"
                            onBlur="{() => this.inputsValidation('firstName')}"
                            autoComplete="off"
                        />
                        <div class="validation" style="{{display: this.state.firstNameValid ? 'none' : 'block'}}">
                            <span><img class="exclamation" src="./site_assets/images/survey/validation.svg" /></span>
                            <p role="alert">Please enter a first name.</p>
                        </div>
                    </div>
                    <div class="text no-label-lastname">
                        <label htmlFor="lastName" class="sr-only">last name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value="{this.state.lastName}"
                            placeholder="Last Name"
                            ref="{this.lastNameRef}"
                            onChange="{this.handleChange}"
                            onBlur="{() => this.inputsValidation('lastName')}"
                            autoComplete="off"
                        />
                        <div class="validation" style="{{display: this.state.lastNameValid ? 'none' : 'block'}}">
                            <span><img class="exclamation" src="./site_assets/images/survey/validation.svg" /></span>
                            <p role="alert">Please enter a last name</p>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="text no-label">
                        <label htmlFor="mail" class="sr-only">email</label>
                        <input
                            type="email"
                            id="mail"
                            name="mail"
                            value="{this.state.mail}"
                            placeholder="example@email.com"
                            ref="{this.emailRef}"
                            onChange="{this.handleChange}"
                            onBlur="{() => this.inputsValidation('mail')}"
                            autoComplete="off"
                        />
                        <div
                            class="validation"
                            style="{{display: (!this.state.emailPresent && this.state.emailValid) ? 'block' : 'none'}}"
                        >
                            <span><img class="exclamation" src="./site_assets/images/survey/validation.svg" /></span>
                            <p role="alert">Please enter an email address</p>
                        </div>
                        <div class="validation" style="{{display: this.state.emailValid ? 'none' : 'block'}}">
                            <span><img class="exclamation" src="./site_assets/images/survey/validation.svg" /></span>
                            <p role="alert">Please enter a valid email address</p>
                        </div>
                    </div>
                    <div class="text" id="ccInput">
                        <label htmlFor="cc">CC:</label>
                        <input class="cc-mail" type="email" id="cc" value="{this.state.cc}" readOnly="readonly" />
                    </div>
                </div>
                <div class="mail-text">
                    <label htmlFor="mail-text" class="sr-only">email body</label>
                    <textarea
                        id="mail-text"
                        rows="4"
                        cols="80"
                        value="{this.state.body}"
                        aria-describedby="emailBody"
                        readOnly
                    ></textarea>
                    <p id="emailBody" class="sr-only">{this.state.body}</p>
                </div>
            </form>

            <a id="sendBtn" class="btn" role="button" onClick="{this.handleSubmission}">
                <p>{this.props.btn1}</p>
            </a>
            <!-- <button class="btn skip" onClick="{this.handleSubmission}">
                <p>{this.props.btn2}</p>
            </button> -->
        </div>
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">
                    Back
                </button>
            </div>
            <!-- <div class="next-button-container">
                <button aria-label="next" class="next-button btn next-outline" onClick="{this.handleSubmission}">Next</button>
            </div> -->
        </div>
    </main>
</template>

<script>
    class SurveyEmailController extends Component {
        constructor(props) {
            super(props);

            const hasAnswer = this.props.answer.value;

            this.state = {
                firstName: hasAnswer ? this.props.answer.value.firstName : "",
                lastName: hasAnswer ? this.props.answer.value.lastName : "",
                mail: hasAnswer ? this.props.answer.value.email : "",
                cc: this.props.cc,
                subject: "Rich Media Recommendation",
                body:
                    this.props.valueIntro +
                    (hasAnswer ? this.props.answer.value.firstName : "") +
                    ", \n\n" +
                    this.props.valueBody1 +
                    "\n\n" +
                    this.props.valueBody2 +
                    "\n\n" +
                    this.props.valueBody3 +
                    "\n\n" +
                    this.props.valueThanks +
                    "\n" +
                    this.props.valueSender,
                response: {
                    firstName: hasAnswer ? this.props.answer.value.firstName : "",
                    lastName: hasAnswer ? this.props.answer.value.lastName : "",
                    email: hasAnswer ? this.props.answer.value.email : "",
                    emailSent: true,
                },
                firstNameValid: true,
                lastNameValid: true,
                emailPresent: true,
                emailValid: true,
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleSubmission = this.handleSubmission.bind(this);
            this.inputsValidation = this.inputsValidation.bind(this);
            this.validEmailAddress = this.validEmailAddress.bind(this);
            this.allInputsValidation = this.allInputsValidation.bind(this);
            this.headerRef = React.createRef();
            this.firstNameRef = React.createRef();
            this.lastNameRef = React.createRef();
            this.emailRef = React.createRef();
        }

        componentDidMount() {
            const hasAnswer = this.props.answer.value;

            this.setState({
                firstName: hasAnswer ? this.props.answer.value.firstName : "",
                lastName: hasAnswer ? this.props.answer.value.lastName : "",
                mail: hasAnswer ? this.props.answer.value.email : "",
                body:
                    this.props.valueIntro +
                    (hasAnswer ? this.props.answer.value.firstName : "") +
                    ", \n\n" +
                    this.props.valueBody1 +
                    "\n\n" +
                    this.props.valueBody2 +
                    "\n\n" +
                    this.props.valueBody3 +
                    "\n\n" +
                    this.props.valueThanks +
                    "\n" +
                    this.props.valueSender,
                response: {
                    firstName: hasAnswer ? this.props.answer.value.firstName : "",
                    lastName: hasAnswer ? this.props.answer.value.lastName : "",
                    email: hasAnswer ? this.props.answer.value.email : "",
                    emailSent: true,
                },
            });

            this.headerRef.current.focus();
            window.scrollTo(0, 0);
        }

        componentDidUpdate() {
            if (document.activeElement.id === "sendBtn") {
                if (!this.state.firstNameValid) {
                    this.firstNameRef.current.focus();
                } else if (!this.state.lastNameValid) {
                    this.lastNameRef.current.focus();
                } else if (!this.state.emailPresent || !this.state.emailValid) {
                    this.emailRef.current.focus();
                }
            }
        }

        /**
         * handles dynamic content for email body and mailto with any change of input fields by
         * updating the state
         * */
        handleChange(event) {
            let property = event.target.id;
            this.setState(
                {
                    [property]: event.target.value,
                },
                () => {
                    if (property === "firstName") {
                        this.setState({
                            body:
                                this.props.valueIntro +
                                this.state.firstName +
                                ", \n\n" +
                                this.props.valueBody1 +
                                "\n\n" +
                                this.props.valueBody2 +
                                "\n\n" +
                                this.props.valueBody3 +
                                "\n\n" +
                                this.props.valueThanks +
                                "\n" +
                                this.props.valueSender,
                            response: {
                                firstName: this.state.firstName,
                                lastName: this.state.lastName,
                                email: this.state.mail,
                            },
                        });
                    }
                    if (property === "lastName" || property === "mail") {
                        this.setState({
                            response: {
                                firstName: this.state.firstName,
                                lastName: this.state.lastName,
                                email: this.state.mail,
                            },
                        });
                    }
                },
            );
        }

        /**
         * if triggered by sendBtn: this function sends email recommendation and passes the state response
         * object containing first/last names and email to parent component (response object is updated
         * in handleChange function)
         * if triggered by btn skip: this function updates state response object with user input for
         * first/last names and email, and adds a boolean property with value of false before proceeding but
         * does not send email
         * if there is no user input, the response object is not updated and a string is returned instead
         */
        handleSubmission(event) {
            if (event.target.innerText === "SKIP") {
                if (Object.keys(this.state.response).length > 0) {
                    this.setState(
                        {
                            response: {
                                ...this.state.response,
                                emailSent: false,
                            },
                        },
                        () => {
                            this.props.onSubmit({ name: this.props.questionName, value: this.state.response });
                        },
                    );
                } else {
                    this.props.onSubmit("email skipped with no values entered");
                }
            } else if (this.allInputsValidation()) {
                document
                    .getElementById("sendBtn")
                    .setAttribute(
                        "href",
                        "mailto:" +
                            encodeURIComponent(this.state.mail) +
                            "?cc=" +
                            encodeURIComponent(this.state.cc) +
                            "&subject=" +
                            encodeURIComponent(this.state.subject) +
                            "&body=" +
                            encodeURIComponent(this.state.body),
                    );
                this.props.onSubmit({ name: this.props.questionName, value: this.state.response });
            }
        }

        //-------------------- FORM VALIDATION --------------------//

        // checks all input fields for empty or invalid inputs
        allInputsValidation() {
            let validity = true;
            let inputs = document.getElementById("emailForm").elements;

            if (inputs["firstName"].value === "") {
                this.setState({
                    firstNameValid: false,
                });
                validity = false;
            } else {
                this.setState({
                    firstNameValid: true,
                });
            }
            if (inputs["lastName"].value === "") {
                this.setState({
                    lastNameValid: false,
                });
                validity = false;
            } else {
                this.setState({
                    lastNameValid: true,
                });
            }
            if (inputs["mail"].value === "") {
                this.setState({
                    emailPresent: false,
                });
                validity = false;
            } else {
                this.setState({
                    emailPresent: true,
                });
            }

            if (!this.validEmailAddress()) {
                validity = false;
            }

            return validity;
        }

        /**
         * checks individual input fields for empty or invalid inputs after attempt to submit & validation
         * failed note: if "...Valid" state properties are already false, the state is not updated to
         * avoid rendering and resetting the focus
         */
        inputsValidation(name) {
            let validity = true;
            let inputs = document.getElementById("emailForm").elements;
            switch (name) {
                case "firstName":
                    if (!this.state.firstNameValid) {
                        if (inputs["firstName"].value !== "") {
                            this.setState({
                                firstNameValid: true,
                            });
                        } else {
                            validity = false;
                        }
                    }
                    break;
                case "lastName":
                    if (!this.state.lastNameValid) {
                        if (inputs["lastName"].value !== "") {
                            this.setState({
                                lastNameValid: true,
                            });
                        }
                    } else {
                        validity = false;
                    }
                    break;
                case "mail":
                    if (!this.state.emailPresent) {
                        if (inputs["mail"].value !== "") {
                            this.setState({
                                emailPresent: true,
                            });
                        }
                    } else {
                        validity = false;
                    }
                    break;
            }

            if (!this.state.emailValid) {
                if (this.validEmailAddress() && validity === true) {
                    validity = true;
                } else {
                    validity = false;
                }
            }

            return validity;
        }

        // checks email address format is correct
        validEmailAddress() {
            let validity = true;
            let inputs = document.getElementById("emailForm").elements;
            let email = inputs["mail"].value;
            let atPos = email.indexOf("@");
            let dotPos = email.lastIndexOf(".");

            if (!this.state.emailValid && email.length > 0) {
                if (atPos >= 1 || dotPos - atPos >= 2) {
                    this.setState({
                        emailValid: true,
                    });
                } else {
                    validity = false;
                }
            } else {
                if (email.length > 0) {
                    if (atPos < 1 || dotPos - atPos < 2) {
                        validity = false;
                        this.setState({
                            emailValid: false,
                        });
                    } else {
                        this.setState({
                            emailValid: true,
                        });
                    }
                }
            }
            return validity;
        }

        //-------------------- END OF FORM VALIDATION --------------------//
    }

    SurveyEmailController.defaultProps = {
        title: "Recommend Us",
        text: "If you have someone in mind, send them this e-mail!",
        body:
            "Hi {First Name}, I recently had a great experience creating an interactive digital project with Rich Media. Let me introduce you to {Kelly Stepien}, my account manager throughout this process. Let me introduce you to {Kelly Stepien}, my account manager throughout this process. Feel free to reach out to {Kelly} for more information, or check out their website here: www.richmedia.com. Thanks, {John}",
        cc: "kellystepien@richmedia.com",
        btn1: "START",
        btn2: "SKIP",
    };
</script>
