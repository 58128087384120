import {createElement, joinClassNames} from "rmlibrary/comp";

export function PauseButton(props) {
    const {show, size, color, ...otherProps} = props;
    const _size = size || 50;
    const _color = color || "rgba(255,196,37, 0.3)";

    return (
        <div {...otherProps}
            className={joinClassNames("rm-play-btn", show ? 'show' : undefined, props.className)}
        >
            <svg width={_size} height={_size} viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" strokeWidth="7" stroke={_color} fill="none" />
                <rect x="30" y="25" width="15" height="50" strokeWidth="0" stroke="#000" fill={_color} />
                <rect x="55" y="25" width="15" height="50" strokeWidth="0" stroke="#000" fill={_color} />
            </svg>
        </div>
    );
}
