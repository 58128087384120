import {Component, createElement, createRef} from 'rmlibrary/comp';
import {TransparentVideo as RMTransparentVideo} from 'rmlibrary/transparent-video';

import {Loader} from "./loader";
import {PlayButton} from "./play-btn";

export class TransparentVideo extends Component {

    constructor(props) {
        super(props);

        this.transparentVideo = createRef();

        this.state = {
            videoLoading: true,
            videoPaused: true
        }

        this.handlePlaying = this.handlePlaying.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleCanPlayThrough = this.handleCanPlayThrough.bind(this);
        this.handleWaiting = this.handleWaiting.bind(this);
        this.handlePlayCLick = this.handlePlayCLick.bind(this);
    }

    handlePlaying() {
        this.setState({
            videoLoading: false,
            videoPaused: false,
        });
    }
    handlePause() {
        this.setState({
            videoPaused: true,
        });
    }

    handleCanPlayThrough() {
        this.setState({
            videoLoading: false,
        });
    }
    handleWaiting() {
        this.setState({
            videoLoading: true,
        })
    }

    handlePlayCLick() {
        if (this.transparentVideo.current && this.transparentVideo.current.video.current) {
            this.transparentVideo.current.video.current.play();
        }
    }

    render() {
        const {controlColor, ...props} = this.props;

        return (<RMTransparentVideo
            {...props}
            autoPlay
            ref={this.transparentVideo}
            loop muted webkit-playsinline="true" playsInline
            pauseWhenOffscreen={true}
            onPlaying={this.handlePlaying}
            onPause={this.handlePause}
            onWaiting={this.handleWaiting}
            onCanPlayThrough={this.handleCanPlayThrough}
        >
            <Loader class="preloader full" size="50" show={this.state.videoLoading} color={controlColor} />
            <PlayButton class="play-btn full" size="50" show={(!this.state.videoLoading && this.state.videoPaused)} onClick={this.handlePlayCLick} color={controlColor} />
        </RMTransparentVideo>)
    }
}
