
import { Component, createElement, forwardRef } from "rmlibrary/comp";
import {Slider as RMSlider} from 'rmlibrary/slider';


class SliderInner extends Component {

    constructor(props) {
        super(props);

        this.handleRef = this.handleRef.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.slider) return;
        if (prevProps.value !== this.props.value && this.slider.value !== this.props.value) {
            this.slider.value = this.props.value;
        }
        if (prevProps.options !== this.props.options) {
            const options = {
                ...this.props.options,
                ...this.props.value || {}
            };
            this.slider.setOptions(options);
        }
    }

    handleRef(element) {
        this.element = element;

        if (element) {
            const options = {
                ...this.props.options,
                ...this.props.value || {}
            };
            this.slider = new RMSlider(element, options);
            this.slider.on('up', (v) => {
                if (typeof (this.props.onUp) === 'function') this.props.onUp(v);
            })
            this.slider.on('down', () => {
                if (typeof (this.props.onDown) === 'function') this.props.onDown();
            })
            this.slider.on('change', (v) => {
                if (typeof (this.props.onChange) === 'function') this.props.onChange(v);
            })
        } else if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }

        if (typeof (this.props.forwardRef) === 'function') this.props.forwardRef(element);
    }

    render() {
        const {value, options, onUp, onDown, onChange, forwardRef, ...otherProps} = this.props; // eslint-disable-line no-unused-vars
        return (<div {...otherProps} ref={this.handleRef}></div>)
    }
}

export const Slider = forwardRef((props, ref) => (
    <SliderInner {...props} forwardRef={ref} />
));

export default Slider;