<import name="RouterLink" from="rmlibrary/router" />
<import name="Collapsible" from="rmlibrary/collapsible" />
<import name="LearnMoreButton" from="../../common/learn-more-button.html" />

<style lang="scss">
    @import 'sass/variables';

    .banner-apps {
        .two-columns {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @media #{$md-only} {
                padding-top: 60px;
            }
            .title {
                @media #{$md-only} {
                    padding-top: 40px;
                }
            }
            .column1 {
                width: 60%;
                @media #{$sm-only, $xs-only} {
                    text-align: center;
                }
                img {
                    width: 100%;
                    max-width: 820px;
                    padding-top: 60px;
                    @media #{$lg-up} {
                        margin-left: -60px;
                        width: 105%;
                    }
                    @media #{$sm-only, $xs-only} {
                        width: 70%;
                        text-align: center;
                        padding-top: 50px;
                    }
                    @media #{$xs-only} {
                        width: 100%;
                    }
                    @media #{$md-only} {
                        padding-top: 0;
                    }
                }
            }
            .column2 {
                width: 40%;
                h2.black-background {
                    color: #FFC425;
                    /* margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: inherit;
                    vertical-align: baseline;
                    background-image: transparent;
                    line-height:1.2em; */
                }
            }
            .column {
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                }
                @media #{$md-only} {
                    width: 50%;
                }
            }
        }

    }


    .banner {
        .buttons {
            .selected {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button {
                background-color: $yellow;
                color: black;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;
                padding: 10px 25px;
                border: 1px solid $black;
                border-radius: 10px;
                text-align: center;
                transition: background-color 0.5s ease;
                display: inline-block;
                margin-left: 20px;
                @media #{$xs-only} {
                    padding: 5px 10px 5px 10px;
                    font-size: 14px;
                }
                @media #{$xs-only} {
                    margin-left: 5px;
                }
                @media #{$md-only} {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid $yellow;
                    border-style: inset;
                    color: $yellow;
                    transition: background-color 0.5s ease;
                }
                .chevron {
                    color: $black;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button:hover .chevron {
                color: $yellow;
            }
        }
    }


    .learn-more {
        background-color: $yellow;
        padding-right: 20px;
        padding-left: 20px;
        .learn-more-content {
            display: flex;
            justify-content: Center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            padding-bottom: 90px;
            .column {
                width: 50%;
                max-width: 450px;
                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                }
                .item {
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                        max-width: 70px;
                        margin-right: 20px;
                        flex-shrink: 0;
                        -webkit-animation: pop-in 0.5s 0.2s both;
                        -moz-animation: pop-in 0.5s 0.2s both;
                        -ms-animation: pop-in 0.5s 0.2s both;
                        animation-delay: 0.7s;
                    }
                    p {
                        -webkit-animation: fadeIn 1s 0.2s both;
                        -moz-animation: fadeIn 1s 0.2s both;
                        -ms-animation: fadeIn 1s 0.2s both;
                        animation-delay: 0.8s;
                    }
                }
                .line {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
                .line-mobile {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                    @media #{$md-up} {
                        opacity: 0;
                    }
                }
                .hide {
                    @media #{$xs-only, $sm-only} {
                        display: none;
                    }
                }
            }
            .column1 {
                padding-right: 60px;
                @media #{$md-only} {
                    padding-right: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-right: 0;
                }
            }
            .column2 {
                padding-left: 60px;
                @media #{$md-only} {
                    padding-left: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-left: 0;
                }
            }
        }
        &.black {
            background-color: $black90;
            .column {
                .line {
                    background-color: $white;
                }
                .line-mobile {
                    background-color: $white;
                }
                p {
                    color: $white;
                }

            }
        }
    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="AppsWebsites__" extends="AppsWebsitesController">
    <div class="chevron-scroll-anchor">
        <div class="banner">
            <div class="banner-apps" class.black-banner="{this.props.theme === 'black'}" class.yellow-banner="{this.props.theme === 'yellow'}">
                <div class="content">
                    <div class="two-columns">
                        <div class="column column1">
                            <img src="site_assets/images/banner-bgs/apps-websites2.png" alt="Apps & Websites " title="Apps & Websites"/>
                        </div>
                        <div role="group" aria-labelledby="lbl-apps-web" class="column column2 text">
                            <If condition="{!this.props.specialIndustry}">
                                <div>
                                    <h2 id="lbl-apps-web" class="title" class.black-background="{this.props.theme === 'black'}">Apps &amp; Websites</h2>
                                    <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Make your presence felt with simple,</span> easy-to-use<span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">, cleanly-designed sites and apps that impress </span> on any device.</p>
                                </div>
                            </If>
                            <If condition="{this.props.specialIndustry}">
                                <div>
                                    <If condition="{this.props.industry == 'financial'}">
                                        <div>
                                            <h2 id="lbl-apps-web" class="title" class.black-background="{this.props.theme === 'black'}">Websites and Apps</h2>
                                            <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Provide financial guidance with simple,</span> easy-to-use <span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">sites and apps that impress</span> on any device.</p>
                                        </div>
                                    </If>
                                    <If condition="{this.props.industry == 'pharma'}">
                                        <div>
                                            <h2 id="lbl-apps-web" class="title" class.black-background="{this.props.theme === 'black'}">Websites and Apps</h2>
                                            <p class="copy"><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Connect with health care providers and consumers with simple,</span> easy-to-use, cleanly-designed <span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">sites and apps that impress</span> on any device.</p>
                                        </div>
                                    </If>
                                </div>
                            </If>
                            <div class="buttons">
                                <LearnMoreButton selected="{this.state.isOpen}" onClick={this.toggleAccordion} />
                                <RouterLink
                                    role="button"
                                    to="/portfolio"
                                    query="{{f: 'apps-website'}}"
                                    className="yellow-button right-button"
                                    onClick="{this.portfolioClick}"
                                >Portfolio <span class="chevron right"></span></RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Collapsible open={this.state.isOpen} duration="250" ease="easeInOut" onOpened={this.accordionOpened}>
            <div class="learn-more" ref={this.scrollRef} class.black="{this.props.theme === 'black'}" class.yellow="{this.props.theme === 'yellow'}">
                <div class="content">
                    <div class="learn-more-content">
                        <div class="column column1">
                            <div class="item item1">
                                <img src="{`site_assets/images/icons/SVG/responsive-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="desktop tablet smart phone icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Responsive, accessible designs that work on all devices</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/apps-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="smart phone icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">iOS and Android apps</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2 item3">
                                <img src="{`site_assets/images/icons/SVG/analytics-reports-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="report icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Statistics-rich analytics reports</span></p>
                            </div>
                            <div class="line-mobile"></div>
                        </div>
                        <div class="column column2">
                            <div class="item item1">
                                <img src="{`site_assets/images/icons/SVG/CMS-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="CMS icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Sites that can work within CMSes and larger frameworks</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/APIs-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="code icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">Front-end sites that hook into APIs</span></p>
                            </div>
                            <div class="line"></div>
                            <div class="item item2">
                                <img src="{`site_assets/images/icons/SVG/html-icon-${this.props.theme === 'yellow' ? 'black' : 'yellow'}.svg`}" width="70px" height="70px" aria-label="web page icon"/>
                                <p><span class.redact-white="{this.props.theme !== 'yellow'}" class.redact="{this.props.theme === 'yellow'}">HTML, CSS and JS web pages and sites</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Collapsible>

    </div>
</template>
<script>
    import { createRef } from 'rmlibrary/comp';
    import { scrollIntoView } from "rmlibrary/react-scroll-into-view";

    import {CustomizationConsumer} from "../../common/customization";
    import { trackLearnMore, trackPortfolio } from '../../../tracking';

    export function AppsWebsites(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <AppsWebsites__ { ...props } 
            specialIndustry = { customizationState.specialIndustry }
            industry = { customizationState.industry } /> }
        </CustomizationConsumer>);
    }

    class AppsWebsitesController extends Component {
        constructor(props) {
            super(props);

            this.scrollRef = createRef();

            this.state = {
                isOpen: false
            }

            this.toggleAccordion = this.toggleAccordion.bind(this);
            this.portfolioClick = this.portfolioClick.bind(this);
            this.accordionOpened = this.accordionOpened.bind(this);
        }

        toggleAccordion() {
            if (!this.state.isOpen) {
                trackLearnMore("apps-websites", "Apps & Websites");
            }
            this.setState({
                isOpen: !this.state.isOpen
            });
        }

        portfolioClick() {
            trackPortfolio("apps-websites");
        }

        accordionOpened() {
            if (!this.scrollRef.current) return;
            let offsetTop = document.querySelector(".nav-wrapper").offsetHeight;
            scrollIntoView(this.scrollRef.current, {
                block: 'center',
                behavior: 'smooth',
                viewportBox: {
                    top: offsetTop
                }
            });
        }
    }

</script>
