/*globals document, window */

import {findDOMNode} from 'rmlibrary/comp';
import RMTween from 'rmlibrary/tween';

const DEFAULT_VIEWPORT_BOX = {left: 0, right: 0, top: 0, bottom: 0};

class ScrollProxy {
    get scroll() {
        return window.pageYOffset;
    }

    set scroll(y) {
        window.scroll(0, y);
    }
}

const scrollProxy = new ScrollProxy()

export function scrollIntoView(ref, options) {
    let el = ref;
    if (!ref.scrollIntoView) el = findDOMNode(ref); // eslint-disable-line react/no-find-dom-node

    // el.scrollIntoView(options);
    const viewportBox = options.viewportBox ? {...DEFAULT_VIEWPORT_BOX, ...options.viewportBox} : DEFAULT_VIEWPORT_BOX;
    const elRect = el.getBoundingClientRect();
    const viewportHeight = document.documentElement.clientHeight;
    const effectiveViewportHeight = viewportHeight - viewportBox.top - viewportBox.bottom;
    const scrollOffset = scrollProxy.scroll;
    let scrollPosition = options.block || 'start';
    const elTop = elRect.top + scrollOffset;

    if (scrollPosition === 'center' && elRect.height > effectiveViewportHeight) scrollPosition = 'start';

    let newScrollOffset = elTop - viewportBox.top;
    if (scrollPosition === 'end') newScrollOffset = elTop - viewportHeight - viewportBox.bottom + elRect.height;
    if (scrollPosition === 'center') newScrollOffset = elTop - viewportBox.top - ((effectiveViewportHeight - elRect.height) / 2);

    if (options.behavior === 'instant') {
        scrollProxy.scroll = newScrollOffset;
    } else {
        const duration = options.duration || 500;
        const easeFn = options.ease || "easeInOut";
        RMTween.create(scrollProxy).to({scroll: scrollOffset}, 0).to({scroll: newScrollOffset}, duration, easeFn).run();
    }
}
