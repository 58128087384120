/*globals window, document */
import {Component, createElement, createRef} from "rmlibrary/comp";

const requestAnimationFrame = window.requestAnimationFrame || function (cb) { return window.setTimeout(cb(new Date().getTime()), 16); };
const cancelAnimationFrame = window.cancelAnimationFrame || function (id) { window.clearTimeout(id); };

export class TransparentVideo2D extends Component {

    constructor(props) {
        super(props);
        this.video = createRef();
        this.buffer = createRef();
        this.canvas = createRef();

        this.state = {
            videoWidth: 0,
            videoHeight: 0
        }

        this.hasPlayed = false;

        this.handleLoadedMetadata = this.handleLoadedMetadata.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.processFrame = this.processFrame.bind(this);
        this.checkPositioning = this.checkPositioning.bind(this);
        this.videoRef = this.videoRef.bind(this);
    }

    componentDidMount() {
        if (this.props.pauseWhenOffscreen) {
            window.addEventListener('scroll', this.checkPositioning);
            window.addEventListener('resize', this.checkPositioning);
            setTimeout(this.checkPositioning);
        }
    }

    checkPositioning() {
        if (!this.canvas.current) return;
        const canvas = this.canvas.current
        const video = this.video.current
        const rect = canvas.getBoundingClientRect();
        const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const isOffScreen = (rect.top + rect.height) < 0 || rect.top > windowHeight;
        if (isOffScreen) {
            if (!video.paused) video.pause();
            video.autoplay = false;
        } else {
            if (video.paused && (this.props.autoPlay || this.hasPlayed)) video.play();
            if (this.props.autoPlay) video.autoplay = true;
        }
    }

    handleLoadedMetadata(...args) {
        this.setState({
            videoWidth: this.video.current.videoWidth,
            videoHeight: this.video.current.videoHeight / 2,
            fullVideoHeight: this.video.current.videoHeight
        });
        if (typeof (this.props.onLoadedMetadata) === 'function') this.props.onLoadedMetadata(...args);
    }

    processFrame() {
        if (!this.bufferCtx) this.bufferCtx = this.buffer.current.getContext('2d');
        if (!this.canvasCtx) this.canvasCtx = this.canvas.current.getContext('2d');

        if (this.buffer.current.width) {
            this.bufferCtx.drawImage(this.video.current, 0, 0);

            const topImg = this.bufferCtx.getImageData(0, 0, this.state.videoWidth, this.state.videoHeight);
            const topData = topImg.data;
            const bottomImg = this.bufferCtx.getImageData(0, this.state.videoHeight, this.state.videoWidth, this.state.videoHeight);
            const bottomData = bottomImg.data;
            const len = topData.length;

            for (let i = 3; i < len; i += 4) {
				let alpha = bottomData[i - 1];
				if (alpha < 20) alpha = 0;
                topData[i] = alpha;
            }

            this.canvasCtx.putImageData(topImg, 0, 0, 0, 0, this.state.videoWidth, this.state.videoHeight);
        }


        this.rafId = requestAnimationFrame(this.processFrame);
    }

    handlePlay(...args) {
        this.hasPlayed = true;
        this.processFrame();
        if (typeof (this.props.onPlay) === 'function') this.props.onPlay(...args);
    }
    handlePause(...args) {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
        if (typeof (this.props.onPause) === 'function') this.props.onPause(...args);
    }

    componentWillUnmount() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
        window.removeEventListener('scroll', this.checkPositioning);
        window.removeEventListener('resize', this.checkPositioning);
    }

    videoRef(video) {
        if (video) {
            this.video = {current: video};
            this.checkPositioning()
        } else {
            this.video = {};
        }
    }

    render() {
        const {pauseWhenOffscreen, className, style, width, height, 'rm-comp': rmComp, ...props} = this.props; // eslint-disable-line no-unused-vars

        const widthAttr = width || this.state.videoWidth;
        const heightAttr = height || this.state.videoHeight;

        return (<div style={{position:'relative', display:'inline-block', width: `${widthAttr}px`, height: `${heightAttr}px`, ...style}}
            rm-comp={this.props['rm-comp']}
            className={className}
        >
            <video ref={this.videoRef}
                {...props}
                style={{position: 'absolute', top:0, left: 0, opacity: 0,pointerEvents:'none',width:'1px',height:'1px'}}
                width={this.state.videoWidth}
                height={this.state.fullVideoHeight}
                onLoadedMetadata={this.handleLoadedMetadata}
                onPlay={this.handlePlay}
                onPause={this.handlePause}
            ></video>
            <canvas ref={this.buffer}
                style="display:none;"
                width={this.state.videoWidth}
                height={this.state.fullVideoHeight}
            ></canvas>
            <canvas ref={this.canvas}
                style={{maxWidth: '100%'}}
                width={width || this.state.videoWidth}
                height={height || this.state.videoHeight}
            ></canvas>
            {this.props.children}
        </div>)
    }
}
