/* globals window, btoa, atob, IS_PRODUCTION */

import {URL_PARAMS} from 'rmlibrary/util';

import {createElement, Fragment} from 'rmlibrary/comp';

import {MainVideoBanner} from "../pages/banners/main-video-banner.html";
import {ClientsCarousel} from "../pages/index-clients-carousel.html";
import {AppsWebsites} from "../pages/banners/apps-websites.html";
import {InteractiveTools} from "../pages/banners/interactive-tools.html";
import {PersonalizedVideo} from "../pages/banners/personalized-video.html";
import {VideosAnimation} from "../pages/banners/videos-animation.html";
import {SocialMedia} from "../pages/banners/social-media.html";
import {FutureTech} from "../pages/banners/future-tech.html";

const BANNER_IDS = [
    MainVideoBanner,
    ClientsCarousel,
    AppsWebsites,
    InteractiveTools,
    PersonalizedVideo,
    VideosAnimation,
    SocialMedia,
    FutureTech
]

// default banners encoded parameter is MDEyMzQ1Njc=
const DEFAULT_BANNERS = [
    MainVideoBanner,
    ClientsCarousel,
    AppsWebsites,
    InteractiveTools,
    PersonalizedVideo,
    VideosAnimation,
    SocialMedia,
    FutureTech
];

const BANNER_NAMES = [
    "Intro Video",
    "Client Carousel",
    "Apps/Websites",
    "Interactive Tools",
    "Personalized Video",
    "Videos/Animation",
    "Social Media",
    "Future Tech"
];
export const BANNER_OPTIONS = BANNER_NAMES.map((name, i) => ({value: i, text: name, c: BANNER_IDS[i]}));

export function encodeParam(arr) {
    return encodeURIComponent(btoa(arr.map((n) => n.toString(16)).join("")).replace(/=+$/, ""));
}

function decodeParam(str) {
    str = atob(decodeURIComponent(str));
    const arr = [];
    for (var i = 0; i < str.length; i++) arr.push(parseInt(str.charAt(i), 16));
    return arr;
}

export function getBannerIndexes() {
    return BANNER_IDS.map((c) => BANNER_IDS.indexOf(c)).filter((n) => n >= 0);
}

export function getOriginalBanners() {
    return (
        <Fragment>
            <MainVideoBanner />
            <ClientsCarousel />
            <AppsWebsites theme="yellow" />
            <InteractiveTools theme="yellow" />
            <PersonalizedVideo theme="black" />
            <VideosAnimation theme="yellow" />
            <SocialMedia theme="black" />
            <FutureTech theme="yellow" />
        </Fragment>
    );
}

export function getBannerArray() {
    let savedOrder = window.sessionStorage.getItem("com.richmedia.banner-order");
    if (URL_PARAMS.b) {
        savedOrder = URL_PARAMS.b;
        window.sessionStorage.setItem("com.richmedia.banner-order", savedOrder);
    }

    let banners;
    if (savedOrder) {
        try {
            const order = decodeParam(savedOrder);
            banners = order.map((i) => BANNER_IDS[i]);
        } catch(err) {
            banners = DEFAULT_BANNERS;
        }
    } else {
        banners = DEFAULT_BANNERS;
    }

    if (banners.indexOf(MainVideoBanner) < 0) banners = [MainVideoBanner, ...banners];

    return banners;
}
