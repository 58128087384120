<import name="RouterLink" from="rmlibrary/router" />
<import name="Fragment" from="rmlibrary/comp" />
<import name="RichIdeasSearch,RichIdeasSearchIcon" from="./richideas-search.html" />

<style lang="scss">
    @import 'sass/variables';

    $page-header-height: 90px;
    $mobile-header-height: 67px;

    * {
        line-height: 1.2em;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif !important;
    }

    .nav-space {
        height: $mobile-header-height;

        @media #{$md-up} {
            height: $page-header-height;
        }
    }

    .nav-wrapper{
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 1002;
    }

    header {
        background-color: $yellow;
        z-index: 1003;
        height: $mobile-header-height;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

        @media #{$md-up} {
            height: $page-header-height;
        }

        .page-header {
            height: $page-header-height;
            padding:20px;
            padding-top:10px;
            max-width:1100px;
            margin:0 auto;
            
            @media #{$xs-only, $sm-only} {
                display:none;
            }
            @media #{$md-only} {
                padding-top:20px;
                padding-left:10px;
                padding-right:10px;
            }
            nav {
                max-width: 1100px;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin:0 auto;
                margin-top:10px;
                .header-logo {
                    height: 38.83px;
                    max-width:200px;
                    width:100%;
                    margin-right:30px;
                    margin-top:-10px;
                    margin-left:30px; /*added for doodle space*/
                    @media #{$md-only} {
                        margin-right:0;
                        margin-left:33px; /*added for doodle space*/
                    }
                    a {
                        padding-left:0;
                    }
                }
                .nav-links {
                    flex-grow: 1;
                    display: inline-block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                a {
                    text-transform: uppercase;
                    text-decoration: none;
                    color:$dark-gray;
                    font-size:18px;
                    letter-spacing:1px;
                    font-weight:600;
                    /*padding:20px 40px 20px 40px;*/
                    padding-bottom:5px;
                    @media #{$md-only} {
                        padding:7px 13px 7px 13px;
                        font-size:15px;
                        letter-spacing:0px;
                    }
                    @media #{$lg-up} {
                        padding:20px 25px 20px 25px;
                    }
                    @media #{$lg-only} {
                        padding:20px 20px 20px 20px;
                    }
                }

                .search-container {
                    position:relative;
                    height:100%;
                }
            }
        }

        .mobile-header {
            background-color:$yellow;
            height: $mobile-header-height;
            @media #{$md-up} {
                display:none;
            }
            .header-logo {
                text-align:center;
                width:100%;
                display:block;
                margin:0 auto;
                padding-top:12px;
                padding-bottom:12px;
                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
                img {
                    @media #{$xs-only} {
                        max-width:150px;
                    }
                }
            }
        }
    }

    .click-container{
        position: absolute;
        z-index: 1001;
        width: 100vw;
        height: 100vh;
    }
    
    .mobile-nav{
        @media #{$md-up} {
            display:none;
        }
        transition: transform 0.5s ease;
        transform: translateX(-200px);
        &.showNav{
            transform: translateX(0);
        }
        background: #2f2f2f;
        position: absolute;
        width: 200px;
        z-index: 1004;
        height: 100vh;

        ul{
            list-style: none;
            width: 100%;
            li{
                /* &:not(:first-child){ */
                    border-top: 1px solid #1d1d1d;
                /* } */
                a {
                    text-transform: uppercase;
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    padding: 10px;
                    text-decoration: none;
                    color: #fff;
                    /* transition: background 0.25s ease; */
                    &:hover{
                        /* background: $yellow; */
                        background-position: 20px 0;
                        color: #2f2f2f !important;
                    }
                    &.selected{
                        color: $yellow;
                    }

                    background-image: linear-gradient(to left, transparent, transparent 50%, $yellow 50%, $yellow);
                    background-size: 200% 100%;
                    background-position: 100% 0;
                    transition: background 0.25s ease;
                }
            }
        }
    }

    .morph-icon {
        $primary-color: $yellow;
        $secondary-color: $black;
        $size: 3;
        $animation-speed: 0.5s;
        $animation-timing: ease;

        position: absolute;
        width: 15px * $size;
        height: 15px * $size;
        background: $primary-color;
        cursor: pointer;
        transition: all $animation-speed $animation-timing;
        padding-left:20px;
        margin-top:10px;
        .morph-icon-line {
            position: absolute;
            width: 9px * $size;
            height: 1px * $size;
            left: 3px * $size;
            background: $secondary-color;
            transition: transform $animation-speed $animation-timing,
                        opacity $animation-speed $animation-timing,
                        top $animation-speed $animation-timing,
                        bottom $animation-speed $animation-timing;
        }
        .morph-icon-line-top {
            top: 5px * $size;
        }
        .morph-icon-line-mdl {
            top: 7px * $size;
        }
        .morph-icon-line-btm {
            top: 9px * $size;
        }
        &.morphed {
            background: $primary-color;
            .morph-icon-line {
                background: $secondary-color;
            }
            .morph-icon-line-top {
                top: 7px * $size;
                transform: rotate(135deg);
            }
            .morph-icon-line-mdl {
                opacity: 0;
                transform: rotate(-135deg);
            }
            .morph-icon-line-btm {
                top: 7px * $size;
                transform: rotate(-135deg);
            }
        }
    }
    
    .doodle {
        display:none;
/*        display:inline;*/
        z-index:10;
        position:absolute;
        margin-left:-49px;

    }
    
    .reason_container {
        position: relative;
    }
    
    .reason_arrow, .reason_arrow2, .reason_arrow3 {
        position: absolute;
        top: -20px;
        width: 300px;
        left: -300px;
            @media #{$md-up} {
            left: 0px;
        }
    }
    
    .reason_text, .reason_text2, .reason_text3 {
        font-family: 'Kalam', cursive !important;
        font-size: 20px;
        color: #FFFFFF;
        margin-top: -60px;
        width: 100%;
        text-shadow: 0px 0px 30px #000000;
        pointer-events: none;
    }
    
    .reason_text {
        margin-top: 36px;
        margin-left: -88px;
    }
    
    .reason_text2 {
        margin-left: -160px;
        margin-top: 35px;
    }
    
    .reason_text3 {
        margin-left: -80px;
        margin-top: 36px;
    }
    
     .reason_text_mobile {
        font-family: 'Kalam', cursive !important;
        font-size: 15px;
        color: #FFFFFF;
        margin-top: 48px;
        margin-left: 72px;
        width: 100%;
        transition: 0.2s ease-in-out;
        text-shadow: 0px 0px 20px #000000;
         @media #{$xs-only} {
             font-size: 12px;
             transition: 0.25s ease-in-out;
         }
    }
    
    .IE_reason_arrow {
            display: none;


        } /* IE ONLY */
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            .IE_reason_arrow {
                display: block;
                position: absolute;
                left: 0px;
                top: -15px;
                transform: scale(-0.5, 0.5);
            }
        }
    
    .IE_reason_mobile {
            display: none;


        } /* IE ONLY */
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            .IE_reason_mobile {
                display: block;
                position: absolute;
                left: 16px;
                top: -3px;
                transform: scale(0.25, 0.25);
                transition: 0.25s ease-in-out;
                    @media #{$sm-up} {
                    transform: scale(0.4, 0.4);
                    left: 8px;
                    top: -5px;
                    transition: 0.25s ease-in-out;
                }
            }
        }
    
    .cls-1, .cls-2, cls-3 {
        transform: scale(-0.9, 0.9);
        position: absolute;
        top: 0px;
        left: -14px;
    }
    
    .cls-mobile {
        transform: scale(0.5);
        position: absolute;
        top: 10px;
        left: 24px;
        transition: 0.25s ease-in-out;
        
        @media #{$sm-up}{
            transform: scale(0.9);
            top: 10px;
            left: 24px;
            transition: 0.25s ease-in-out;
        }
    }
    
    path {
            stroke-dasharray: 70;
            stroke-dashoffset: -70;
            animation: draw 3s forwards;
            animation-delay: 1s;
    }
                
    .reason_arrow {
        position: absolute;
        top: 20px;
        left: 180px;
            @media #{$lg-up} {
            left: 280px;
            }
    }
    
    .reason_arrow2 {
        top: 20px;
        left: 77px;
           @media #{$lg-up} {
            top: 20px;
            left: 133px;
        }
    }
    
    .reason_arrow3 {
        top: 20px;
        left: 85px;
            
        @media #{$md-up} {
            left: 277px;
        }
        
           @media #{$lg-up} {
            left: 417px;
        }
    }
    
    .arrow_flip{
        transform: scaleX(-1);
        stroke-dasharray: 50;
        stroke-dashoffset: -50;
        animation: draw 3s forwards;
        display: none;
    }
    
    .arrow_flip2{
        transform: scaleX(-1);
        margin-left: 190px;
        width: 50px;
        height: 50px;
        transition: 0.5s ease-in-out;
        
        @media #{$lg-up} {
        width: 80px;
        height: 80px;
        transition: 0.5s ease-in-out;
        }
    }
    
    .reason_mobile {
        position: absolute;
        left: -20px;
        top: 25px;
        transition: 0.25s ease-in-out;
        pointer-events: none;
            
        @media #{$sm-up} {
            top: 38px;
            left: -10px;
            transition: 0.25s ease-in-out;
        }
        
        @media #{$md-up} {
            opacity: 0;
            transition: 0.1s ease-in-out;
        }
    }
    
    .reason_arrow_mobile {
        width: 70px;
        transform: scale(0.5);
        margin-left: 6px;
        margin-top: -10px;
    }
    
    .mobile_arrow, .mobile_arrow2, .mobile_arrow3 {
        position: absolute;
        transform: scale(0.22);
        left: 84px;
    }
    
    .mobile_arrow {
        top: -24px;
    }
    
    .mobile_arrow2 {
        top: 16px;
    }
    
     .mobile_arrow3 {
        top: 56px;
    }
    
    /*
    .reason_container:hover, .reason_arrow:hover, .reason_arrow2:hover, .reason_arrow3:hover, .arrow_flip:hover, .arrow_flip2:hover, .reason_text:hover, .reason_text2:hover, .reason_text3:hover {
        opacity: 0;
        transition: 0.5s ease-in-out;
    }
    */

    .fade {
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    .appear {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
    header .page-header nav .header-logo a {
        padding:0;
    }

    
</style>

<template name="HeaderLogo">
    <Fragment>
        <If c="{this.props.theme === 'xmas' || this.props.theme === 'new-year'}">
            <h1 class="rm-logo"><img src="{`${this.props.base}/site_assets/images/xmas-logo.svg`}" alt="Rich Media" width="200px" height="38.83px"/></h1>
        </If>
        <If c="{this.props.theme === 'canadaday' || this.props.theme === 'canadaday'}">
            <h1 class="rm-logo"><img src="{`${this.props.base}/site_assets/images/logo-canada-day.svg`}" alt="Rich Media" width="200px" style="height: 100%;"/></h1>
        </If>
        <If c="{this.props.theme === 'halloween'}">
            <h1 class="rm-logo"><img src="{`${this.props.base}/site_assets/images/halloween-logo.svg`}" alt="Rich Media" width="200px" height="38.83px"/></h1>
        </If>
        <If c="{this.props.isThanksgiving}">
            <h1 class="rm-logo"><img class="doodle" src="{`${this.props.base}/site_assets/images/icons/doodles/thanksgiving-test.png`}" alt="Happy Thanksgiving!"/></h1>                   
        </If>
        <If c="{this.props.theme === 'pride'}">
            <h1 class="rm-logo"><img src="{`${this.props.base}/site_assets/images/pride-logo.svg`}" alt="Rich Media" width="200px" height="38.83px"/></h1>
        </If>
        <If c="{this.props.theme === 'default' || this.props.theme === 'redacted'}">
            <h1 class="rm-logo"><img src="{`${this.props.base}/site_assets/images/logo.svg`}" alt="Rich Media" width="200px" height="38.83px"/></h1>
        </If>
    </Fragment>
</template>


<template name="Header_" extends="HeaderController">
    <div>

        <div class="nav-space">
            <div class="nav-wrapper">
                <header>
                    <div class="page-header">
                        <nav>
                            <div class="header-logo">
                                <If c="{!this.props.isRichIdeas}">
                                    <RouterLink to="/">
                                        <HeaderLogo theme="{this.props.theme}" base="." />
                                    </RouterLink>
                                </If>
                                <If c="{this.props.isRichIdeas}">
                                    <a href="./">
                                        <HeaderLogo theme="{this.props.theme}" base="." />
                                    </a>
                                </If>
                            </div>
                            <div class="nav-links">
                                <If c="{this.props.whatBrings === 'job-seeker' && this.props.currentPage == 'Home'}">
                                    <div class="reason_container">
                                        <span class="reason_arrow reason">
                                            
                                            <div class="reason_text">Apply Here!</div>
                                                <div class="IE_reason_arrow">
                                                    <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                                                </div>
                                            <svg class="cls-1" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                                            </svg>
                                        </span>
                                    </div>
                                </If>
                                <If c="{(this.props.whatBrings === 'looking-for-agency' || this.props.whatBrings === 'other') && this.props.currentPage == 'Home'}">
                                    <div class="reason_container">
                                        <span class="reason_arrow2 reason">
                                            <div class="reason_text2">Check out our work!</div>
                                                <div class="IE_reason_arrow">
                                                    <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                                                </div>
                                            <svg class="cls-2" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                                            </svg>
                                        </span>
                                    </div>
                                </If>
                                <If c="{this.props.whatBrings === 'client' && this.props.currentPage == 'Home'}">
                                    <div class="reason_container">
                                    <span class="reason_arrow3 reason">
                                        <div class="reason_text3">Talk to us!</div>
                                            <div class="IE_reason_arrow">
                                                <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                                            </div>
                                            <svg class="cls-1" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                                            </svg>
                                    </span>
                                    </div>
                                </If>
                                <If c="{!this.props.isRichIdeas}">
                                    <ItemSelector lookForClass="selected" ignorePadding options="{this.itemSelectorOptions}">
                                        <RouterLink classIfActive="selected" to="/">Home</RouterLink>
                                        <RouterLink classIfActive="{{ selected: /^\/portfolio/ }}" to="/portfolio"><span class="redact-header">Portfolio</span></RouterLink>
                                        <RouterLink classIfActive="{{ selected: /^\/careers/ }}" to="/careers"><span class="redact-header">Careers</span>
                                        </RouterLink>
                                        <RouterLink classIfActive="selected" to="/contact"><span class="redact-header">Contact</span></RouterLink>
                                        <a href="./richideas/"><span class="redact-header">Rich Ideas</span></a>
                                    </ItemSelector>
                                </If>
                                <If c="{this.props.isRichIdeas}">
                                    <ItemSelector lookForClass="selected" ignorePadding options="{this.itemSelectorOptions}">
                                        <a href="{`${RICH_IDEAS_BASE}/`}">Home</a>
                                        <a href="{`${RICH_IDEAS_BASE}/portfolio`}"><span class="redact-header">Portfolio</span></a>
                                        <a href="{`${RICH_IDEAS_BASE}/careers`}"><span class="redact-header">Careers</span></a>
                                        <a href="{`${RICH_IDEAS_BASE}/contact`}"><span class="redact-header">Contact</span></a>
                                        <a class="selected" href="{`${RICH_IDEAS_BASE}/richideas`}"><span class="redact-header">Rich Ideas</span></a>
                                    </ItemSelector>
                                </If>
                            </div>
                            <If c="{!IS_PRODUCTION && this.state.enableSearch}">
                                <div class="search-container">
                                    <RichIdeasSearchIcon onClick={this.toggleSearch} />
                                </div>
                            </If>
                        </nav>
                    </div>
    
                    <div class="mobile-header">
                        <a href='#nav' class="morph-icon" onClick="{this.toggleNav}" class.morphed="{this.state.showNav}" aria-label="hamburger icon">
                            <div class="morph-icon-line morph-icon-line-top"></div>
                            <div class="morph-icon-line morph-icon-line-mdl"></div>
                            <div class="morph-icon-line morph-icon-line-btm"></div>
                        </a>
                            
                        <div class="header-logo" onClick="{this.closeNav}">
                                
                            <If c="{!this.props.isRichIdeas}">
                                <RouterLink to="/">
                                    <HeaderLogo theme="{this.props.theme}" base="." />
                                </RouterLink>
                            </If>
                            <If c="{this.props.isRichIdeas}">
                                <a href="{`${RICH_IDEAS_BASE}/`}"><HeaderLogo theme="{this.props.theme}" base="." /></a>
                            </If>
                        </div>
                        
                        <If c="{!IS_PRODUCTION && this.state.enableSearch}">
                            <div class="search-container">
                                <RichIdeasSearchIcon onClick={this.toggleSearch} /> 
                            </div>
                        </If>
                    </div>
                </header>
                
                <div class.click-container="{this.state.showNav}" onClick="{this.closeNav}"></div>
                <nav class="mobile-nav" class.showNav="{this.state.showNav}">
                    <If c="{this.props.whatBrings === 'looking-for-agency' || this.props.whatBrings === 'other'}">
                        <img class="mobile_arrow" src="./site_assets/images/icons/mobile_arrow.png"/>
                    </If>
                    <If c="{this.props.whatBrings === 'job-seeker'}">
                        <img class="mobile_arrow2" src="./site_assets/images/icons/mobile_arrow.png"/>
                    </If>
                    <If c="{this.props.whatBrings === 'client'}">
                        <img class="mobile_arrow3" src="./site_assets/images/icons/mobile_arrow.png"/>
                    </If>
                    <ul>
                        <If c="{!this.props.isRichIdeas}">
                            <Fragment>
                                <li onClick="{this.closeNav}"><RouterLink classIfActive="selected" to="/">Home</RouterLink></li>
                                <li onClick="{this.closeNav}"><RouterLink classIfActive="{{ selected: /^\/portfolio/ }}" to="/portfolio">Portfolio</RouterLink></li>
                                <li onClick="{this.closeNav}"><RouterLink classIfActive="{{ selected: /^\/careers/ }}" to="/careers">Careers</RouterLink></li>
                                <li onClick="{this.closeNav}"><RouterLink classIfActive="selected" to="/contact">Contact</RouterLink></li>
                                <li><a href="./richideas/">Rich Ideas</a></li>
                            </Fragment>
                        </If>
                        <If c="{this.props.isRichIdeas}">
                            <Fragment>
                                <li><a href="{`${RICH_IDEAS_BASE}/`}">Home</a></li>
                                <li><a href="{`${RICH_IDEAS_BASE}/portfolio`}">Portfolio</a></li>
                                <li><a href="{`${RICH_IDEAS_BASE}/careers`}">Careers</a></li>
                                <li><a href="{`${RICH_IDEAS_BASE}/contact`}">Contact</a></li>
                                <li><a class="selected" href="{`${RICH_IDEAS_BASE}/richideas`}">Rich Ideas</a></li>
                            </Fragment>
                        </If>
                    </ul>
                </nav>
                <If c="{this.props.whatBrings === 'client' && !this.state.showNav && this.props.currentPage == 'Home'}">
                    <span class="reason_mobile">
                        <div class="reason_text_mobile">Talk to us!</div>
                            <div class="IE_reason_mobile">
                                <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                            </div>
                            <svg class="cls-mobile" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                            </svg>
                    </span>
                </If>
                <If c="{(this.props.whatBrings === 'looking-for-agency' || this.props.whatBrings === 'other') && !this.state.showNav && this.props.currentPage == 'Home'}">
                    <span class="reason_mobile">
                        <div class="reason_text_mobile">Check out our work!</div>
                            <div class="IE_reason_mobile">
                                <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                            </div>
                            <svg class="cls-mobile" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                            </svg>
                    </span>
                </If>
                <If c="{this.props.whatBrings === 'job-seeker' && !this.state.showNav && this.props.currentPage == 'Home'}">
                    <span class="reason_mobile">
                        <div class="reason_text_mobile">Apply here!</div>
                            <div class="IE_reason_mobile">
                                <img src="site_assets/images/icons/IE_reason_arrow.png" width="80">
                            </div>
                            <svg class="cls-mobile" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 90 80">
                                <path class="path" d="M 12, 0 S12,48,48,48 m-36 -50 l-9 13 m 10 -12 l9 10 " fill="none" stroke="#fff" strokeWidth="3"/>
                            </svg>
                    </span>
                </If>
            </div>
        </div>
        <If c="{this.state.enableSearch}">
            <RichIdeasSearch show={this.state.showSearch} onClose={this.toggleSearch} />
        </If>
    </div>
</template>

<script>
import {ItemSelector} from './item-selector'
import {CustomizationConsumer} from './customization';

export function Header(props) {
    return (<CustomizationConsumer>
        {(customizationState) => <Header_ {...props} 
        theme={customizationState.theme}
        whatBrings={customizationState.whatBrings}
        currentPage={customizationState.currentPage}
        currentPath={customizationState.currentPath} /> }
    </CustomizationConsumer>)
}
            
class HeaderController extends Component {

    static getDerivedStateFromProps(props, state) {
        const enableSearch = props.isRichIdeas || (/^\/(new-)?richideas\//).test(props.currentPath)
        return {
            enableSearch,
            showSearch: enableSearch ? state.showSearch : false
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            showNav: false,
            showSearch: false
        }

        this.itemSelectorOptions = {
            alignX: 'center',
            alignY: 'bottom',
            widthPercent: 0.8,
            shiftX: 0,
            shiftY: 4
        }


        this.toggleNav = this.toggleNav.bind(this)
        this.toggleSearch = this.toggleSearch.bind(this)
        this.closeNav = this.closeNav.bind(this)
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount(){
        window.addEventListener("popstate", this.closeNav);
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.closeNav);
        window.addEventListener("scroll", this.handleScroll)
    }

    handleResize(){
        if (this.state.showNav && window.innerWidth > 768)
            this.closeNav()
    }

    toggleNav(e){
        // debugger;
        if (e){
             e.preventDefault();
             e.stopPropagation();
        }

        this.setState({showNav: !this.state.showNav})
    }

    toggleSearch() {
        this.setState({showSearch: !this.state.showSearch});
    }

    closeNav(e){
        if (e) e.preventDefault();
        if (this.state.showNav) this.setState({showNav: false})
    }

    scrollToContact(e){
        e.preventDefault();
        document.querySelector("#contact").scrollIntoView({behavior: 'smooth'})
    }

    handleScroll(e) {
        e.preventDefault();
        if (sessionStorage.currentPage == 'Home' && document.querySelector(".reason") != null) {
            var scrollElement = (document.scrollingElement || document.documentElement);
            scrollElement = scrollElement.scrollTop;
            if (scrollElement > 35 || document.body.scrollTop > 35) {
                document.querySelector(".reason").classList.add("fade"); 
                document.querySelector(".reason").classList.remove("appear");
                document.querySelector(".reason_text_mobile").classList.add("fade"); 
                document.querySelector(".reason_text_mobile").classList.remove("appear");
                document.querySelector(".IE_reason_mobile").classList.add("fade");
                document.querySelector(".IE_reason_mobile").classList.remove("appear");
                if (!(!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g))) {
                    document.querySelector(".cls-mobile").classList.add("fade");
                    document.querySelector(".cls-mobile").classList.remove("appear");
                }
            } else {
                document.querySelector(".reason").classList.add("appear");
                document.querySelector(".reason").classList.remove("fade");
                document.querySelector(".reason_text_mobile").classList.add("appear");
                document.querySelector(".reason_text_mobile").classList.remove("fade");
                document.querySelector(".IE_reason_mobile").classList.add("appear");
                document.querySelector(".IE_reason_mobile").classList.remove("fade");
                if (!(!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g))) {
                    document.querySelector(".cls-mobile").classList.add("appear");
                    document.querySelector(".cls-mobile").classList.remove("fade");
                }
            }
        }  
    }
}

</script>

