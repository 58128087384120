<template name="HolidayVideo2018" extends="HolidayVideo2018Controller">
    <div>
        2018 Holiday Video - {this.state.urlName}
    </div>
</template>
<script>

class HolidayVideo2018Controller extends Component {
    constructor(props){
        super(props);

        this.state = {};
    }
    static getDerivedStateFromProps(props, state) {
        return {
            isLoading: true, // TODO - set to false when the animation preloads and the API call is complete
            urlName: props.variables.name
        }
    }
}

</script>