<import name="RouterLink" from="rmlibrary/router" />
<import name="FloatingCard" from="./banners/floating-card.html" />

<style lang="scss">
    @import 'sass/variables';
    .signedUp {
        position: absolute;
        top: 67%;
        left: 50%;
        transform: translate(-50%);
        padding: 5px;
        user-select: none;
/*        opacity: 0;*/
        animation: singupBox 1s forwards ease;
        font-size: 14px;
    }

    .submitBtn {
        margin-top: 40px;
    }

    .social-media {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: Center;
        -webkit-animation: pop-in 0.5s;
        -moz-animation: pop-in 0.5s;
        -ms-animation: pop-in 0.5s;
        img {
            max-width: 30px;
            margin: 10px;
        }
        .social {
            width: 27px;
            height: 25px;
            background-size: cover;
            margin: 10px;
            margin-right: 20px;
            margin-left: 20px;
            transition: background-image 0.5s ease-in-out;
        }
        .instagram {
            background-image: url(images/icons/SVG/instagram-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/instagram-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .twitter {
            background-image: url(images/icons/SVG/twitter-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/twitter-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .facebook {
            background-image: url(images/icons/SVG/facebook-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/facebook-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .linkedin {
            background-image: url(images/icons/SVG/linkedin-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/linkedin-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
    }

    .contact {
        background-color: $black90;
        width: 100%;
        .two-columns {
            display: flex;
            padding-top: 60px;
            max-width: 1100px;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            @media #{$xs-only, $sm-only} {
                flex-wrap: wrap;
                padding-top: 30px;
            }
            width:100%;
            .column {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 50px;
                @media #{$md-up} {
                    width: 50%;
                }
                @media #{$xs-only, $sm-only} {
                    padding-bottom: 20px;
                }
            }
            .heading {
                color: white;
                margin-right: 60px;
                @media #{$xs-only, $sm-only} {
                    margin-right: 0px;
                }
                h1 {
                    font-size: 50px;
                    margin-bottom: 5px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-fill-mode: forwards;
                    @media #{$xs-only, $sm-only} {
                        font-size: 30px;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
                p {
                    font-size: 20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    @media #{$xs-only, $sm-only} {
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .contact-item {
                    display: flex;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .icon {
                        width: 20%;
                        max-width: 70px;
                        margin-right: 20px;
                        -webkit-animation: pop-in 0.5s;
                        -moz-animation: pop-in 0.5s;
                        -ms-animation: pop-in 0.5s;
                        @media #{$xs-only} {
                            width: 18%;
                            margin-top: -10px;
                            margin-right: 25px;
                        }
                        img {
                            @media #{$xs-only} {
                                width: 50px;
                            }
                            @media #{$sm-only} {
                                width: 60px;
                            }
                        }
                    }
                    .copy {
                        width: 80%;
                        @media #{$xs-only, $sm-only} {
                            width: 112%;
                        }
                        h3, h2 {
                            color: $yellow;
                            font-size: 20px;
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 16px;
                            @media #{$xs-only, $sm-only} {
                                text-align: left;
                            }
                            span {
                                @media #{$xs-only} {
                                    display: block;
                                }
                                @media #{$xs-up} {
                                    display: inline-block;
                                }
                            }
                        }
                        a {
                            color: white;
                            text-decoration: none;
                            animation-name: fadeIn;
                            animation-duration: 1s;
                            &:hover {
                                color: $yellow;
                            }
                        }
                    }
                }
            }
            .sign-up {
                text-align: center;
                color: white;
                img {
                    max-width: 105px;
                    -webkit-animation: pop-in 0.5s;
                    -moz-animation: pop-in 0.5s;
                    -ms-animation: pop-in 0.5s;
                }
                h2,
                h3 {
                    text-transform: uppercase;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                }
                h2 {
                    color: $yellow;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
                h3 {
                    margin-top: 50px;
                }
                p {
                    animation-name: fadeIn;
                    animation-duration: 1s;
                }
                input[type="text"] {
                    display: block;
                    margin: 0 auto;
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 10px;
                    width:242px;
                    margin-bottom: 5px;
                    color: $black;
                    border-bottom: 4px solid white;
                    transition: border-bottom 0.25s ease;
                    -webkit-animation: pop-in 0.5s;
                    -moz-animation: pop-in 0.5s;
                    -ms-animation: pop-in 0.5s;
                    &.invalid {
                        border-bottom: 4px solid tomato;
                    }
                }
            }
        }
        .bottom {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .map {
            display: block;
            width: 100%;
            height: 380px;
            animation-name: fadeIn;
            animation-duration: 1s;
            /* background-image: url(images/contact-map.jpg);
            background-size:cover;
            background-repeat:no-repeat;
            background-position:center;*/
        }
        .mobile {
            display: none;
            @media #{$xs-only, $sm-only} {
                display: block;
                padding-bottom: 30px;
            }
        }
        .desktop {
            display: none;
            @media #{$md-up} {
                display: block;
            }
        }
    }

    .careers {
        background-color: $black90;
        text-align: center;
        .looking {
            color: white;
            font-size: 18px;
            .yellow {
                color: $yellow;
            }
            .bold {
                font-weight: bold;
            }
            a {
                text-decoration: none;
                color: $yellow;
                &:hover {
                    color: white;
                }
            }
        }
    }
</style>

<template name="ContactPage" extends="ContactPageController">
    <main>
        <div class="contact">
            <div class="two-columns">
                <div class="column heading">
                    <h1>Contact Us</h1>
                    <p>Interested in finding out more about Rich Media and the solutions we offer? Reach out and we’ll be happy to get back to you!</p>

                    <div class="contact-item">
                        <div class="icon"><img src="site_assets/images/icons/SVG/phone-icon-yellow.svg" width="70px" height="70px" aria-labelledby="phone-icon" />
                        </div>
                        <div class="copy">
                            <h2 id="phone-icon">Phone</h2>
                            <a href="tel:14164066545">416 406 6545</a>
                        </div>
                    </div>

                    <div class="contact-item">
                        <div class="icon"><img src="site_assets/images/icons/SVG/email-icon-yellow.svg" width="70px" height="70px" aria-labelledby="email-icon" />
                        </div>
                        <div class="copy">
                            <h2 id="email-icon">Email</h2>
                            <a href="mailto:contactus@richmedia.com">contactus@richmedia.com</a>
                        </div>
                    </div>

                    <div class="contact-item">
                        <div class="icon"><img src="site_assets/images/icons/SVG/map-icon-yellow.svg" width="70px" height="70px" aria-labelledby="map-icon" />
                        </div>
                        <div class="copy">
                            <h2 id="map-icon">Address</h2>
                            <a href="https://goo.gl/maps/ZAXUiMQgnkGBgFYV6" target="_blank">
                                <p>Rich Media</p>
                                <p>1090 Don Mills Rd. <span>Suite 501</span></p>
                                <p>Toronto, Ontario <span>M3C 3R6</span></p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="map mobile">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2882.7498133355843!2d-79.34423794417593!3d43.73652395409241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda26d90bde316d6e!2sRich%20Media!5e0!3m2!1sen!2sca!4v1649351718907!5m2!1sen!2sca" width="100%" height="100%" frameBorder="0" style="{{border:0}}" allowFullScreen></iframe>
                </div>
                <div class="column sign-up">
                    <img src="site_assets/images/icons/SVG/newsletter-icon-yellow.svg" aria-label="newsletter"/>
                    <h2>Sign up for our newsletter</h2>
                    <p>Join the hundreds of industry professionals who stay up to date with our latest tech and innovation news</p>

                    <form onSubmit="{this.submitEmail}" style="{{position: 'relative'}}">

                        <input type="text" name="name" onChange="{this.firstNameChange}" placeholder="Enter your first name here" value="{this.state.firstName}" class.invalid="{this.state.submitted && !this.state.validFirstName}">
                        <If c="{this.state.submitted && !this.state.validFirstName}">
                            <div class="error-msg">
                                Please enter a first name.
                            </div>
                        </If>

                        <input type="text" name="name" onChange="{this.lastNameChange}" placeholder="Enter your last name here" value="{this.state.lastName}" class.invalid="{this.state.submitted && !this.state.validLastName}">
                        <If c="{this.state.submitted && !this.state.validLastName}">
                            <div class="error-msg">
                                Please enter a last name.
                            </div>
                        </If>

                        <input type="text" name="name" onChange="{this.emailChange}" placeholder="Enter your email here" value="{this.state.emailValue}" class.invalid="{this.state.submitted && !this.state.validEmail}">
                        <If c="{this.state.submitted && !this.state.validEmail}">
                            <div class="error-msg">
                                Please enter a valid email.
                            </div>
                        </If>

                        <button type="submit" class="submitBtn yellow-button">Submit</button>
                        <If c="{this.state.emailSubmitted}">
                            <div class="signedUp">You've been signed up!</div>
                        </If>
                    </form>

                    <h3>Follow us</h3>
                    <div class="social-media">
                        <a href="https://www.instagram.com/richmedia.social/" target="_blank" aria-label="Rich Media on Instagram">
                            <div class="instagram social"></div>
                        </a>
                        <a href="https://x.com/RichMediaSocial/" target="_blank" aria-label="Rich Media on X">
                            <div class="twitter social"></div>
                        </a>
                        <a href="https://www.facebook.com/Rich-Media-251114581758676/" target="_blank" aria-label="Rich Media on Facebook">
                            <div class="facebook social"></div>
                        </a>
                        <a href="https://www.linkedin.com/company/rich-media" target="_blank" aria-label="Rich Media on LinkedIn">
                            <div class="linkedin social"></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="map desktop">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2882.7498133355843!2d-79.34423794417593!3d43.73652395409241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda26d90bde316d6e!2sRich%20Media!5e0!3m2!1sen!2sca!4v1649351718907!5m2!1sen!2sca" width="100%" height="100%" frameBorder="0" style="{{border:0}}" allowFullScreen title="map"></iframe>
            </div>
            <div class="content bottom">
                <div class="careers">
                    <p class="looking"><span class="yellow bold">Looking for a job?</span> Visit our <span class="yellow"><RouterLink to="/careers">careers</RouterLink></span> page or contact <span class="yellow"><a href="mailto:careers@richmedia.com">careers@richmedia.com</span></a>
                    </p>
                </div>
            </div>
        </div>
    </main>

</template>

<script>
    class ContactPageController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                emailValue: '',
                validEmail: false,
                firstName: '',
                validFirstName: false,
                lastName: '',
                validLastName: false,
                submitted: false
            };

            this.submitEmail = this.submitEmail.bind(this);
            this.emailChange = this.emailChange.bind(this);
            this.firstNameChange = this.firstNameChange.bind(this);
            this.lastNameChange = this.lastNameChange.bind(this);
        }

        emailChange(e) {
            const email = e.target.value;
            this.setState({
                emailValue: email,
                validEmail: !!email && this.validEmail(email)
            });
        }
        firstNameChange(e) {
            const firstName = e.target.value;
            this.setState({
                firstName: firstName,
                validFirstName: !!firstName
            });
        }
        lastNameChange(e) {
            const lastName = e.target.value;
            this.setState({
                lastName: lastName,
                validLastName: !!lastName
            });
        }

        isValid() {
            return (
                this.state.validEmail &&
                this.state.validFirstName &&
                this.state.validLastName
            )
        }

        submitEmail(e) {
            e.preventDefault();

            const email = this.state.emailValue;

            this.setState({submitted: true})
            if (this.isValid()) {
                this.postEmail();
            }
        }

        // postEmail(email) {
        //     let http = new XMLHttpRequest();
        //     let endpoint = '/php/api/email.php';
        //     http.open('POST', endpoint, true);
        //
        //     http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        //
        //     http.onreadystatechange = () => {
        //         if (http.status == 500) console.error("Something went wrong while adding the email.");
        //         else {
        //             this.setState({
        //                 emailValue: '',
        //                 emailSubmitted: true
        //             });
        //             setTimeout(() => this.setState({
        //                 emailSubmitted: false
        //             }), 2000);
        //         }
        //     }
        //     let data = `user_email=${email}`
        //     http.send(data);
        // }
        postEmail() {
            let http = new XMLHttpRequest();
            let endpoint = '/r/subscribeAMemail.php';
            http.open('POST', endpoint, true);

            http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

            http.onreadystatechange = () => {
                if (http.status == 500) console.error("Something went wrong while adding the email.");
                else {
                    this.setState({
                        emailValue: '',
                        firstName: '',
                        lastName: '',
                        emailSubmitted: true
                    });
                    setTimeout(() => this.setState({
                        emailSubmitted: false
                    }), 2000);
                }
            }
            let email = this.state.emailValue;
            let firstName = this.state.firstName;
            let lastName = this.state.lastName;
            let data = `email=${encodeURIComponent(email)}&firstname=${encodeURIComponent(firstName)}&lastname=${encodeURIComponent(lastName)}&time=${(new Date()).getTime()}`;
            http.send(data);
        }

        validEmail(email) {
            return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        }
    }
</script>
