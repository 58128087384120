export const CLIENT_LOGOS = [
    {
        client: "Desjardins",
        clientLogo: "site_assets/images/carousel-logos/desjardins.svg",
    },
    {
        client: "Scotiabank",
        clientLogo: "site_assets/images/carousel-logos/scotiabank.svg",
    },
    {
        client: "Samsung",
        clientLogo: "site_assets/images/carousel-logos/samsung.svg",
    },
    {
        client: "Manulife",
        clientLogo: "site_assets/images/carousel-logos/manulife.svg",
    },
    {
        client: "Genworth",
        clientLogo: "site_assets/images/carousel-logos/genworth.svg",
    },
    {
        client: "Walmart",
        clientLogo: "site_assets/images/carousel-logos/walmart.svg",
    },
    {
        client: "Mawer",
        clientLogo: "site_assets/images/carousel-logos/mawer.svg",
    },
    {
        client: "CiInvestments",
        clientLogo: "site_assets/images/carousel-logos/ci.svg",
    },
    {
        client: "Penguin",
        clientLogo: "site_assets/images/carousel-logos/penguin.png",
    },
    {
        client: "BMO",
        clientLogo: "site_assets/images/carousel-logos/bmo.svg",
    },
    {
        client: "CIBC",
        clientLogo: "site_assets/images/carousel-logos/cibc.svg",
    },
    {
        client: "Sunlife",
        clientLogo: "site_assets/images/carousel-logos/sunlife.svg",
    },
    {
        client: "Sabina",
        clientLogo: "site_assets/images/carousel-logos/sabina.svg",
    },
    {
        client: "SimplyVera",
        clientLogo: "site_assets/images/carousel-logos/simplyvera.png",
    },
];
