<!-- Import other components used here -->
<import name="FloatingCard" from="./banners/floating-card.html" />

<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .wrapper {
        background-color: $black90;
    }

    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
        padding-bottom: 100px;

        @media #{$xs-only, $sm-only} {
            padding-top: 20px;
        }

        .two-columns {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .column {
                width: 50%;

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                }

                h1 {
                    font-size: 60px;
                    font-weight: bold;
                    color: $yellow;
                    max-width: 300px;
                    margin-bottom: 20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;

                    @media #{$sm-only} {
                        font-size: 29px;
                    }

                    @media #{$md-only} {
                        font-size: 45px;
                    }

                    @media #{$xs-only, $sm-only} {
                        font-size: 35px;
                        max-width: none;
                    }
                }

                h2 {
                    margin-bottom: 10px;
                }

                .job-details {
                    display: flex;
                    width: 100%;

                    .icon {
                        width: 20%;
                        animation-name: fadeIn;
                        animation-duration: 1s;

                        @media #{$xs-only, $sm-only} {
                            width: 12%;
                        }
                    }

                    .details {
                        width: 80%;

                        @media #{$xs-only, $sm-only, $md-only} {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .column1 {
                width: 40%;
                max-width: 400px;
                margin-right: 90px;

                @media #{$sm-only} {
                    margin-right: 20px;
                }

                @media #{$md-only} {
                    margin-right: 55px;
                }

                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                    margin-right: 0;
                }
            }

            .column2 p {
                margin-bottom: 10px;
            }

            .column2 img {
                @media #{$sm-only, $xs-only} {
                    max-width: 50px;
                    width: 100%;
                }
            }

            .subcategory {
                margin-top: 25px;
            }
        }
    }

    .desktop {
        display: none;

        @media #{$md-up} {
            display: inline-block;
        }
    }

    h2 {
        color: $yellow;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    p {
        color: white;
        font-size: 16px;
        line-height: 21px;
        animation-name: fadeIn;
        animation-duration: 1s;
        margin: 10px 0;
    }

    .yellow-button {
        margin-top: 30px;
        margin-left: 0;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .divider {
        height: 1px;
        background-color: $yellow;
        width: 90%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .apply-mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: contents;
        }
    }

    .mobile {
        display: none;

        @media #{$xs-only, $sm-only} {
            display: table;
        }
    }
</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->

<template name="Internship" extends="InternshipPageController">
    <main>
        <div class=" wrapper">
            <div class="content">
                <div class="two-columns">
                    <div class="column column1">
                        <h1>Co-op &amp; Internships</h1>
                        <For var="description" of="{this.state.position}" index="index">
                            <p key={index}>{description}</p>
                        </For>
                        <div class="divider"></div>

                        <h2 class="desktop">For interested applicants:</h2>
                        <p class="apply desktop">Please provide a copy of your resume, university or college transcript and a link to your online portfolio.</p>
                        <a href="mailto:careers@richmedia.com?subject=&body=Please provide a copy of your resume, university or college transcript and a link to your online portfolio. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button desktop">Apply<span class="chevron right"></span></a>
                    </div>
                    <div class="column column2">
                        <div class="job-details">
                            <div class="icon">
                                <img src="site_assets/images/icons/SVG/responsibilities-icon-yellow.svg" alt="Responsibilities" width="74" height="74" />
                            </div>
                            <div class="details">
                                <For var="detail" of="{this.state.details}" index="index">
                                    <React.Fragment key={index}>
                                        <h2 class="subcategory">{detail.title}</h2>
                                        <p>{detail.description}</p>
                                    </React.Fragment>
                                </For>
                            </div>
                        </div>

                    </div>

                    <div class="divider mobile"></div>

                    <div class="mobile apply-mobile">
                        <h2 class="mobile">For interested applicants:</h2>
                        <p class="apply mobile">Please provide a copy of your resume, university or college transcript and a link to your online portfolio.</p>
                        <a href="mailto:careers@richmedia.com?subject=Applying for Co-op %26 Internships&body=Please provide a copy of your resume, university or college transcript and a link to your online portfolio. %0ANote that this is a full-time in-office position at 1090 Don Mills Road in Toronto." class="yellow-button mobile">Apply<span class="chevron right"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    class InternshipPageController extends Component {
        constructor(props) {
            super(props);

            this.state = {
                "position": [
                    "Rich Media is looking for energetic students eager to learn, experiment, and discover how digital media is made.",
                    "Rich Media has coop and intern positions for those looking to break into the world of web development. Whether you're a graphic designer or a web programmer, you can expect:"
                ],
                "company": "Rich Media is an award winning Toronto based digital agency producing many of North America's leading interactive projects. Our services include websites, apps, tools, social media, print, animation, and video. Some of our clients include Samsung, Scotiabank, CIBC, Manulife and Sun Life Financial. Rich Media has garnered a reputation for taking leading edge technology, combining it with strong creative talents and developing awesome digital projects.",
                "details": [
                    {
                        "title": "Hands-on experience",
                        "description": "You won't be stuck doing menial chores or passively observing – you'll gain actual experience, work on actual projects, and learn to work and create as part of a team."
                    },
                    {
                        "title": "A welcoming environment",
                        "description": "We're not a giant, hierarchical corporation – we're a nimble, flexible team. Everyone, even the CEO, is always reachable for questions, help, or good knock-knock jokes."
                    },
                    {
                        "title": "A chance to try everything",
                        "description": "As part of the Rich Media team, you'll get to try your hand at a wide range of activities. You won't be dedicated on one narrow aspect of design or development – you'll experience it all."
                    },
                    {
                        "title": "Working with major clients",
                        "description": "Many of Rich Media's clients are financial institutions, tech giants, and other large, international corporations. With Rich Media, you'll learn to work with the best, for the best."
                    }
                ]
            };
        }

        componentDidMount() {
            const { hostname } = location;
            const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

            let baseURL = "";
            switch (hostname) {
                case "localhost":
                case "127.0.0.1": {
                    break;
                }

                case "richmediawireless.com": {
                    baseURL = "www.richmediawireless.com/dev/rm/job-posting";
                    break;
                }

                default: {
                    baseURL = "www.richmedia.com";
                    break;
                }
            }

            if (!isLocalhost && hostname !== "richmediawireless.com") {
                fetch(`https://${baseURL}/api/jobpostings.php?position_title=internship`)
                .then(result => result.json())
                .then(result => this.setState({ ...result }));
            }
        }
    }
</script>