export const AMInfo = {
    shannon: {
        firstName: "Shannon",
        lastName: "Sloan",
        email: "shannonsloan@richmedia.com",
        imgUrl: "site_assets/images/icons/shannon_dp-big.png",
        ext: "208",
    },
    kelly: {
        firstName: "Kelly",
        lastName: "Stepien",
        email: "kellystepien@richmedia.com",
        imgUrl: "site_assets/images/icons/am-icon.png",
        ext: "206",
    },
    vikas: {
        firstName: "Vikas",
        lastName: "Christian",
        email: "vikaschristian@richmedia.com",
        ext: "216",
    },
    kim: {
        firstName: "Kim",
        lastName: "Cruz",
        email: "kimcruz@richmedia.com",
        imgUrl: "site_assets/images/icons/kim_dp-big.png",
        ext: "217",
    },
    teeyum: {
        firstName: "Teeyum",
        lastName: "Amin",
        email: "teeyumamin@richmedia.com",
        imgUrl: "site_assets/images/icons/Teeyum_dp-big.png",
        ext: "207",
    },
    allison: {
        firstName: "Allison",
        lastName: "Thompson",
        email: "allisonthompson@richmedia.com",
        imgUrl: "site_assets/images/icons/allison_dp-big.png",
        ext: "217",
    },
    ryleigh: {
        firstName: "Ryleigh",
        lastName: "Carson",
        email: "ryleighcarson@richmedia.com",
        imgUrl: "site_assets/images/icons/ryleigh_dp-big.png",
        ext: "206",
    },
    rida: {
        firstName: "Rida",
        lastName: "Khurram",
        email: "ridakhurram@richmedia.com",
        imgUrl: "site_assets/images/icons/rida_dp-big.png",
        ext: "217",
    },
    javed: {
        firstName: "Javed",
        lastName: "Hydaralli",
        email: "javedhydaralli@richmedia.com",
        imgUrl: "site_assets/images/icons/javed_dp-big.png",
        ext: "207",
    },
};

export const AM_BY_EMAIL = {
    "shannonsloan@richmedia.com": AMInfo.shannon,
    "kellystepien@richmedia.com": AMInfo.kelly,
    "vikaschristian@richmedia.com": AMInfo.vikas,
    "kimcruz@richmedia.com": AMInfo.kim,
    "teeyumamin@richmedia.com": AMInfo.teeyum,
    "allisonthompson@richmedia.com": AMInfo.allison,
    "ryleighcarson@richmedia.com": AMInfo.ryleigh,
    "ridakhurram@richmedia.com": AMInfo.rida,
    "javedhydaralli@richmedia.com": AMInfo.javed,
};

export const clients = {
    shannon: {
        financial: [
            ["Scotiabank", "The Bank of Nova Scotia", "Bank of Nova Scotia"],
            ["Sonnet Insurance Company", "Sonnet Insurance", "Sonnet"],
            ["Sun Life Financial", "Sun Life", "Sun", "SLF", "SLGI", "SunLife"],
            ["Tangerine", "Tangerine Bank"],
            ["Walmart Financial", "Walmart"],
            ["MD Financial", "MD", "MD Financial Management"],
            ["ATB Financial", "ATB", "Alberta Treasury Branches"],
            ["Genworth", "Genworth Financial"],
            ["Bank of Montreal", "BMO", "BMO Bank"],
            ["CAA", "Canadian Automobile Association", "The Canadian Automobile Association"],
            ["Travelers", "Travelers Finance", "Travelers Finance Ltd", "Travelers Ltd"],
            ["CIBC", "Canadian Imperial Bank of Commerce", "CIBC Bank", "Bank CIBC"],
        ],
        pharma: [["Apotex"], ["Merck & Co", "Merck"], ["Gilead"], ["Sanofi Pasteur", "Sanofi"], ["Amgen"], ["Bayer"]],
    },

    kelly: {
        financial: [
            ["CMHC", "Canada Mortgage and Housing Corporation"],
            ["Capital One", "Capital One Financial Corporation"],
            ["CI Investments", "CI"],
            ["Citi", "Citi Bank", "Citigroup Inc", "Citigroup", "Citi Inc"],
            ["Desjardins", "Desjardins Insurance", "The Desjardins Group", "Desjardins Group"],
            ["Fidelity", "Fidelity Investments", "Fidelity Investments Inc"],
            ["Green Shield Canada", "Green Shield"],
            ["HSBC", "HSBC Canada", "HSBC Bank Canada"],
            ["Manulife", "Manulife Financial", "Manulife Insurance", "Manulife Financial Corporation"],
            ["Royal Bank of Canada", "RBC", "RBC Financial Group", "RBC Bank", "Bank RBC"],
            [
                "OSC/Investors Education Fund",
                "IEF",
                "OSC Investors Education Fund",
                "OSC Education Fund",
                "Investors Education Fund",
                "OSC/Investors",
                "OSC",
                "OSC Investors",
            ],
            ["BlackRock", "BlackRock Inc"],
            ["John Hancock", "John Hancock Life Insurance Company", "John Hancock Insurance"],
            ["Morneau Shepell"],
            ["Allstate", "Allstate Corporation", "AllState Insurance", "The AllState Corporation"],
            ["AGF", "AGF Investments", "AGF Investments Inc", "AGF Management Ltd", "AGF Management"],
            ["iA Clarington", "iA Clarington Investments", "iA Clarington Investments Inc"],
            ["Aviva", "Aviva Canada", "Aviva Inc", "Aviva Canada Inc"],
            [
                "Equitable Life",
                "Equitable Life Insurance",
                "Equitable Life Insurance Company",
                "Equitable Life Insurance Company of Canada",
                "Equitable Life Canada",
            ],
            [
                "Sabina",
                "Sabina Gold and Silver",
                "Sabina Gold and Silver Corporation",
                "Sabina Gold and Silver Corp",
                "Sabina Gold & Silver",
                "Sabina Gold & Silver Corporation",
                "Sabina Gold & Silver Corp",
            ],
            ["Ivari", "Ivari Insurance", "Transamerica Life Canada", "Transamerica Life", "Transamerica"],
            ["London Life Insurance Company", "London Life", "London Life Insurance"],
            ["Meridian Credit Union", "Meridian", "Meridian Credit Union Ltd"],
            [
                "Great-West Life",
                "GWL",
                "Great West Life",
                "The Great-West Life Assurance Company",
                "Great-West Life Assurance Company",
                "The Great West Life Assurance Company",
                "Great West Life Assurance Company",
            ],
            ["Mawer", "Mawer Investment", "Mawer Investment Management", "Mawer Investment Management LTD"],
            ["Canada Life", "Canada Life Financial Corporation"],
        ],
        pharma: [
            ["Johnson & Johnson", "J&J", "J & J"],
            ["Teva"],
            ["Roche"],
            ["Bristol-Myers Squibb", "BMS"],
            ["Lilly"],
            ["GE Healthcare", "GE"],
            ["Novartis"],
            ["Pfizer"],
            ["Astrazenica"],
            ["Abbvie"],
            ["GlaxoSmithKline", "GSK"],
            ["Astellas"],
        ],
    },
    kim: {
        financial: [],
        pharma: [],
    },
    teeyum: {
        financial: [],
        pharma: [],
    },
    allison: {
        financial: [],
        pharma: [],
    },
    ryleigh: {
        financial: [],
        pharma: [],
    },
    rida: {
        financial: [],
        pharma: [],
    },
    javed: {
        financial: [],
        pharma: [],
    },
};

export const allClients = {
    shannon: [
        ["Scotiabank", "The Bank of Nova Scotia", "Bank of Nova Scotia"],
        ["Sonnet Insurance Company", "Sonnet Insurance", "Sonnet"],
        ["Sun Life Financial", "Sun Life", "Sun", "SLF", "SLGI", "SunLife"],
        ["Tangerine", "Tangerine Bank"],
        ["Walmart Financial", "Walmart"],
        ["MD Financial", "MD", "MD Financial Management"],
        ["ATB Financial", "ATB", "Alberta Treasury Branches"],
        ["Genworth", "Genworth Financial"],
        ["Bank of Montreal", "BMO", "BMO Bank"],
        ["CAA", "Canadian Automobile Association", "The Canadian Automobile Association"],
        ["Travelers", "Travelers Finance", "Travelers Finance Ltd", "Travelers Ltd"],
        ["CIBC", "Canadian Imperial Bank of Commerce", "CIBC Bank", "Bank CIBC"],
        ["Apotex"],
        ["Merck & Co", "Merck"],
        ["Gilead"],
        ["Sanofi Pasteur", "Sanofi"],
        ["Amgen"],
        ["Bayer"],
    ],

    kelly: [
        ["CMHC", "Canada Mortgage and Housing Corporation"],
        ["Capital One", "Capital One Financial Corporation"],
        ["CI Investments", "CI"],
        ["Citi", "Citi Bank", "Citigroup Inc", "Citigroup", "Citi Inc"],
        ["Desjardins", "Desjardins Insurance", "The Desjardins Group", "Desjardins Group"],
        ["Fidelity", "Fidelity Investments", "Fidelity Investments Inc"],
        ["Green Shield Canada", "Green Shield"],
        ["HSBC", "HSBC Canada", "HSBC Bank Canada"],
        ["Manulife", "Manulife Financial", "Manulife Insurance", "Manulife Financial Corporation"],
        ["Royal Bank of Canada", "RBC", "RBC Financial Group", "RBC Bank", "Bank RBC"],
        [
            "OSC/Investors Education Fund",
            "IEF",
            "OSC Investors Education Fund",
            "OSC Education Fund",
            "Investors Education Fund",
            "OSC/Investors",
            "OSC",
            "OSC Investors",
        ],
        ["BlackRock", "BlackRock Inc"],
        ["John Hancock", "John Hancock Life Insurance Company", "John Hancock Insurance"],
        ["Morneau Shepell"],
        ["Allstate", "Allstate Corporation", "AllState Insurance", "The AllState Corporation"],
        ["AGF", "AGF Investments", "AGF Investments Inc", "AGF Management Ltd", "AGF Management"],
        ["iA Clarington", "iA Clarington Investments", "iA Clarington Investments Inc"],
        ["Aviva", "Aviva Canada", "Aviva Inc", "Aviva Canada Inc"],
        [
            "Equitable Life",
            "Equitable Life Insurance",
            "Equitable Life Insurance Company",
            "Equitable Life Insurance Company of Canada",
            "Equitable Life Canada",
        ],
        [
            "Sabina",
            "Sabina Gold and Silver",
            "Sabina Gold and Silver Corporation",
            "Sabina Gold and Silver Corp",
            "Sabina Gold & Silver",
            "Sabina Gold & Silver Corporation",
            "Sabina Gold & Silver Corp",
        ],
        ["Johnson & Johnson", "J&J", "J & J"],
        ["Teva"],
        ["Roche"],
        ["Bristol-Myers Squibb", "BMS"],
        ["Lilly"],
        ["GE Healthcare", "GE"],
        ["Ivari", "Ivari Insurance", "Transamerica Life Canada", "Transamerica Life", "Transamerica"],
        ["London Life Insurance Company", "London Life", "London Life Insurance"],
        ["Meridian Credit Union", "Meridian", "Meridian Credit Union Ltd"],
        [
            "Great-West Life",
            "GWL",
            "Great West Life",
            "The Great-West Life Assurance Company",
            "Great-West Life Assurance Company",
            "The Great West Life Assurance Company",
            "Great West Life Assurance Company",
        ],
        ["Mawer", "Mawer Investment", "Mawer Investment Management", "Mawer Investment Management LTD"],
        ["Canada Life", "Canada Life Financial Corporation"],
        ["Novartis"],
        ["Pfizer"],
        ["Astrazenica"],
        ["Abbvie"],
        ["GlaxoSmithKline", "GSK"],
        ["Astellas"],
    ],
    kim: [],
    teeyum: [],
    allison: [],
    ryleigh: [],
    rida: [],
    javed: [],
};
