<import name="FloatingCard" from="./banners/floating-card.html" />
<import name="Fragment" from="rmlibrary/comp" />

<style lang="scss">
    @import 'sass/variables';
</style>

<template name="IndexPage_" extends="IndexPageController">
    <div role="main">
        {
        this.getBanners()
        }
    </div>

</template>
<script>

    import { getBannerArray, getOriginalBanners, encodeParam } from "../common/banner-order";
    import { CustomizationConsumer } from '../common/customization';

    export function IndexPage(props) {
        return (<CustomizationConsumer>
            {(customizationState) => <IndexPage_ {...props}
                AMfirstName={customizationState.AMfirstName}
                AMlastName={customizationState.AMlastName}
                AMemail={customizationState.AMemail}
                interest={customizationState.interest} />}
        </CustomizationConsumer>)
    }

    class IndexPageController extends Component {
        constructor(props) {
            super(props);

            this.currentInterest = null;
            this.banners;
            this.bannerOrder = [0, 1, 2, 3, 4, 5, 6, 7];
            this.interestMap = {
                'websites': 2,
                'interactive-tools': 3,
                'personalized-video': 4,
                'animation': 5,
                'social-media': 6,
                'content-marketing': 6,
                'apps': 2,
                'future-tech': 7
            }
        }

        getBanners() {

            if (this.currentInterest != this.props.interest && this.props.interest != 'true') {
                this.currentInterest = this.props.interest;
                this.bannerOrder = [0, 1, 2, 3, 4, 5, 6, 7];
                var index = this.bannerOrder.indexOf(this.interestMap[this.props.interest]);
                if (index > -1) {
                    this.bannerOrder.splice(index, 1);
                }
                this.bannerOrder.splice(2, 0, this.interestMap[this.props.interest]);
                sessionStorage.setItem("com.richmedia.banner-order", encodeParam(this.bannerOrder));
            }

            this.banners = getBannerArray();

            let nextColour = 'yellow';

            return this.banners.map((C, i) => {
                let thisColour = nextColour;
                if (!C.PASS_COLOUR || (C.name == "FutureTech" && i == 7)) {
                    nextColour = nextColour === "yellow" ? "black" : "yellow";
                } else {
                    thisColour = nextColour === "yellow" ? "black" : "yellow";
                }
                return (<C theme={thisColour} key={C.name} />)
            });
        }
    }

</script>