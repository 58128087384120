/*globals window, document */

const DEBUG = false;

export function log(...args) {
    if (DEBUG) console.log(...args); // eslint-disable-line no-console
}

export class Macciato{

    constructor(options) {
        const { hashed } = options || {};

        // Retrieve the baseURL as defined in the head
        let base = document.querySelector("base");
        try {
             this.baseURL = base.getAttribute("href").replace(/\/$/, '');
         } catch(err) {
             this.baseURL = "";
         }

        this.routes = []; // Registered routes
        this.links = []; // Registered links

        this.emitHooks = []; // Callbacks for every emit

        this.extractedVariables = {}; // Variables from the Route path

        this.hashed = hashed;

        this.currentPath = null;
        this.ROUTE_404 = null;

        this.emit = this.emit.bind(this);
        this.redirect = this.redirect.bind(this);

        this.originalTitle = document.title;

        if (this.hashed) window.addEventListener("hashchange", this.emit);
        else window.addEventListener("popstate", this.emit);
    }

    /*
        Params:
            pathname: string - relative URL without the baseURL
            options: {
                query: string - will be encoded and added to the url
                replace: boolean - uses replaceState instead of pushState if true
                scrollTop: boolean - scrolls the user to the top if true
                newTab: boolean - opens the page in a new tab if true
            }
    */
    redirect(pathname, options) {
        const { query, replace, scrollTop, newTab, hasBase } = options || {};

        let url = pathname + (query ? this.encodeQuery(query) : '');

        if (!hasBase) url = this.addBaseToPath(url);

        let leadingSlash = url.charAt(0) != '/' ? '/' : '';
        let regex = new RegExp(`^${this.baseURL}`);
        if (this.hashed) url = `#${leadingSlash}${url.replace(regex, '')}`;

        if (newTab) window.open(url, "_blank")
        else if (replace) window.history.replaceState('', '', url);
        else  window.history.pushState('', '', url);

        this.emit(scrollTop);
    }

    // Updates the current route to be displayed and rerenders all
    // registered routes and links
    emit(scrollTop) {
        log('== EMIT ==');
        this.currentPath = this.findCurrentPath();
        const title = this.findCurrentTitle();
        document.title = title;
        this.routes.forEach(route => route.rerender());
        this.links.forEach(link => link.rerender());
        setTimeout(() => {this.emitHooks.forEach(callback => callback())}, 0);

        if (scrollTop) window.scrollTo(0, 0);
    }

    findCurrentTitle() {
        this.extractedVariables = {};
        const getRoute = () => {
            for (let i = 0; i < this.routes.length; i++) {
                let currRoute = this.routes[i];

                if (this.matchURL(currRoute.path, this.getPathname())) {
                    return currRoute;
                }
            }
            if (this.ROUTE_404) return this.ROUTE_404;
            else return this.routes[0];
        }

        const route = getRoute();
        if (!route.title) return this.originalTitle;
        if (typeof (route.title) === 'function') return route.title({variables: this.extractedVariables, query: this.getQueryObj()});
        else return route.title + "";
    }

    // Returns the pathname of the route that should be displayed based on
    // the current URL and extracts URL variables if applicable
    findCurrentPath() {
        this.extractedVariables = {};

        for (let i = 0; i < this.routes.length; i++) {
            let currRoute = this.routes[i];

            if (this.matchURL(currRoute.path, this.getPathname())) {
                return `${this.baseURL}${currRoute.path}`;
            }
        }

        if (this.ROUTE_404) return this.ROUTE_404.path;
        else{
            log('updateCurretnRoute() ->  404 not defined, redirecting to the first registered page');
            this.redirect(this.routes[0].path, {replace: true});
            return this.routes[0].path;
        }
    }

    // Returns true if the paths match part by part, ignoring variable URL parts
    matchURL(path, windowPath) {
        let paths = path.split("/");
        let windowPaths = windowPath.split("/");
        if (paths.length != windowPaths.length) return false;

        for (let i = 0; i < paths.length; i++) {
            let path = paths[i];
            if (path.match(/^:/)) this.extractedVariables[path.replace(":", '')] = windowPaths[i];
            else if (path != windowPaths[i]) return false;
        }

        return true;
    }

    isCurrentPath(path) {
        return path == this.currentPath;
    }

    // Returns the pathname of the current URL, excluding the baseURL
    getPathname() {
        if (this.hashed) {
            let path = window.location.hash.slice(1);
            let queryPos = path.lastIndexOf('?');

            let pathname = path;
            if (queryPos != -1) pathname = path.slice(0, queryPos);

            return pathname;
        }
        else {
            let regex = new RegExp(`^${this.baseURL}`);
            return window.location.pathname.replace(regex, '');
        }
    }

    // Returns the current extracted variables object,
    // as per the lastest findCurrentPath() call
    getExtractedVariables() {
        return this.extractedVariables;
    }

    /* Query manipulation */

    getQuery() {
        if (!this.hashed) return window.location.search;
        else {
            let queryPos = window.location.hash.lastIndexOf('?');
            if (queryPos == -1) return '';
            return window.location.hash.slice(queryPos);
        }
    }

    // Converts an object of query parameters and returns an encoded string
    encodeQuery(queryObj) {
        let strQuery = '';
        Object.keys(queryObj).forEach((key, i) => {
            strQuery += (i == 0) ? "?" : "&";
            strQuery += `${key}=${encodeURIComponent(queryObj[key])}`;
        })

        return strQuery;
    }

    // Returns an object of the query parameters from the current URL
    getQueryObj() {
        let str = this.getQuery().substring(1);
        let obj = str.split(/&/g);

        return obj.reduce((acc, strPair) => {
            let pair = strPair.split("=");
            if (pair[1]) acc[pair[0]] = decodeURIComponent(pair[1]);
            return acc;
        }, {})
    }

    isHashed() {
        return this.hashed;
    }

    /* Managing Routes */

    registerRoute(path, route, title = null) {
        let regex = new RegExp(`^${this.baseURL}`);
        path = path.replace(regex, '');

        let routeControl = { path, rerender: route.rerender, title };
        this.routes.push(routeControl);

        if (routeControl.path.match(/\/404$/)) this.ROUTE_404 = routeControl;

        // Timeout for initial render of all Routes
        if (!this.timeout) this.timeout = setTimeout(this.emit, 0);
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.emit, 0);
        }

        return routeControl;
    }

    unregisterRoute(route) {
        this.routes = this.routes.slice(this.routes.indexOf(route), 1);
    }

    /* Managing RouterLinks */

    registerLink(link) {
        let linkControls = { rerender: link.rerender };
        this.links.push(linkControls);

        return linkControls;
    }

    unregisterLink(link) {
        this.links.splice(this.links.indexOf(link), 1);
    }

    /* API */

    hookToEmit(callback) {
        if (typeof callback === "function") this.emitHooks.push(callback);
    }

    unhookFromEmit(callback) {
        this.emitHooks = this.emitHooks.filter(hook => hook != callback);
    }

    addBaseToPath(path) {
        return `${this.baseURL}${path}`;
    }
}
