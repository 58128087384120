<!-- Import other components used here -->


<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .template {
        background-color: $black90;
        .project-imgs {
            overflow: hidden;
            .portfolio-project {
                width: 100%;
                .devices {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    left: 21%;
                    margin-top: -30px;

                    @media #{$md-only, $sm-only, $xs-only} {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin: 0 auto;
                        left: 0;
                    }
                    @media #{$lg-only} {
                        width: 90%;
                        left: 25%;
                        margin-top: 0;
                    }
                    @media #{$xs-only} {
                        width: 140%;
                        max-width: 545px;
                        margin: auto;
                        left: -5%;
                    }
                    @media only screen and (min-width: 420px) and (max-width: 575px) {
                        width: 110%;
                        left: 5%;
                    }
                    @media #{$md-only} {
                        width: 100%;
                        max-width: 690px;
                    }
                    @media #{$sm-only} {
                        max-width: 640px;
                    }
                    .device {
                        display: inline-block;
                        width: 50%;
                        .laptop {
                            position: relative;
                            display: inline-block;
                            top: 0;
                            width: 120%;
                            z-index: 10000;
                            @media #{$md-only} {
                                left: 20%;
                            }
                            @media #{$sm-only} {
                                left: 18%;
                            }
                            @media #{$xs-only} {
                                left: 12%;
                            }
                        }
                        .desktop-img {
                            position: absolute;
                            top: 27%;
                            width: 44%;
                            left: 8%;
                            @media #{$md-only} {
                                width: 44%;
                                left: 18%;
                                top: 28%;
                            }
                            @media #{$sm-only} {
                                left: 16%;
                            }
                            @media #{$xs-only} {
                                left: 14%;
                                width: 44%;
                            }
                        }
                        .phone {
                            position: relative;
                            display: inline-block;
                            width: 50%;
                            margin-top: 1%;
                            right: 20%;
                            z-index: 20000;
                            @media #{$md-only} {
                                right: 6%;
                            }
                            @media #{$sm-only} {
                                right: 13%;
                            }
                            @media #{$xs-only} {
                                right: 30%;
                            }
                        }
                        .phone-img {
                            position: absolute;
                            width: 19.5%;
                            left: 55.2%;
                            top: 13%;
                            z-index: 15000;
                            @media #{$md-only} {
                                width: 20%;
                                left: 61.7%;
                            }
                            @media #{$sm-only} {
                                width: 19%;
                                left: 58.9%;
                            }
                            @media #{$xs-only} {
                                left: 50.4%
                            }
                        }
                    }
                }
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

</style>

<!--
HTML for the component.
Notes:
   - There should only be one element inside of the <template> tag.
     If you need multiple elements, enclose them in a div.
   - Give the template a name that represents the component.
     This is the name that you'll use to import it into other components.
     The name needs to start with a capital letter and cannot contain spaces or dashes.
 -->
<template name="PortfolioTemplate1">
    <main>

        <div class="template">
            <div class="content" style="{{position: 'relative', zIndex: '1'}}">

                <div class="project">
                        <h1 class="project-title">{ this.props.project.title }</h1>
                        <p class="project-description">{ this.props.project.description }</p>
                        <If c="{this.props.project.link}">
                        <a href="{ this.props.project.link }" class="yellow-button" target="_blank">View Live <span class="chevron right"></span></a>
                        </If>
                    </div>
                 <div class="project-imgs">
                    <div class="portfolio-project">
                        <div class="devices">
                            <div class="device">
                                <img draggable="false" src="{this.props.project.images.laptop}" alt="{ this.props.project.title }" class="laptop"/>
                                <img draggable="false" src="{this.props.project.images.laptopImg}" alt="{ this.props.project.title }" class="desktop-img"/>
                            </div> 
                            <div class="device">
                                <img draggable="false" src="{this.props.project.images.phone}" alt="{ this.props.project.title }" class="phone"/>
                                <img draggable="false" src="{this.props.project.images.phoneImg}" alt="{ this.props.project.title }" class="phone-img"/>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="gradient" style="{{position: 'absolute', bottom: '0', zIndex: '0'}}">
                <div class="content">
                    <div class="client-logo">
                        <img draggable="false" class="center" src="{this.props.project.images.clientLogo}" />
                        <img draggable="false" class="left" src="{this.props.project.images.clientLogoDesktop}" />
                    </div>
                </div>
            </div>
        </div>

    </main>

</template>
