<style lang="scss">
    @import "sass/variables";
    @import "sass/survey-common";

    /* Before/after default styles */
    #before-after {
        display: block;
        position: relative;
        height: 550px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        top: 50%;
        /*        margin-top: 50px;*/
        @media #{$xs-only} {
            margin-top: 0px;
        }
        @media #{$md-only} {
            margin-top: 20px;
        }
        @media #{$xs-only, $sm-only, $md-only} {
            padding-bottom: 56.5%;
            height: auto;
        }
    }

    .rm-ba-left,
    .rm-ba-right {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        @media #{$xs-only} {
            width: 120%;
            height: 120%;
            left: -10%;
        }
    }

    .rm-ba-left *,
    .rm-ba-right * {
        display: block;
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media #{$lg-up} {
            top: 42%;
        }
    }

    .rm-ba-right * {
        z-index: 1;
    }

    .rm-ba-left * {
        z-index: 2;
    }

    .number {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .slider-outer {
        z-index: 4;
        position: inherit;
        @media #{$lg-up} {
            position: absolute;
        }
        @media (max-height: 400px) {
            margin-top: -80px;
        }
    }

    .main-container {
        overflow: hidden;
    }

    @keyframes sliderAnim {
        from {
            left: 0;
        }

        75% {
            left: 100%;
        }

        to {
            left: 0;
        }
    }
</style>

<template name="SurveyRatingVidImg" extends="SurveyRatingVidImgController">
    <main class="main-container" class.black-banner="{this.props.theme === 'black'}">
        <h1 tabIndex="-1" style="outline: none;">{this.props.title}</h1>

        <!-- Before/After element -->
        <rm-before-after id="before-after" progress="{this.props.answer ? (this.props.answer.value - 1) * 20 : 0}">
            <div class="rm-ba-left">
                <video src="{this.props.videoSrc}" autoPlay muted loop playsInline preload="auto"></video>
            </div>
            <div class="rm-ba-right">
                <img src="{this.props.imageSrc}" alt="Test Image" />
            </div>
        </rm-before-after>

        <!-- Slider to control before/after -->
        <div class="slider-outer">
            <p>{this.props.desc}</p>
            <div class="slider-container">
                <div class="sign minus" role="button">&#8722;</div>
                <div class="sign plus" role="button">+</div>
                <div id="rm-slider"></div>
                <p class="number">{this.props.answer ? Math.round(this.props.answer.value) : 1}</p>
            </div>
        </div>

        <!-- Buttons back/next -->
        <div class="action-btn-wrapper">
            <div class="back-button-container">
                <button aria-label="back" class="back-button btn" onClick="{() => this.props.onSubmit('back')}">
                    Back
                </button>
            </div>
            <div class="next-button-container">
                <button
                    aria-label="next"
                    class="next-button btn"
                    onClick="{() => this.props.onSubmit({name: this.props.questionName, value: (this.slider.value / 20) + 1})}"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- </SurveyAnimation> -->
    </main>
</template>

<script>
    import "@rmlibrary/before-after";
    // import "@rmlibrary/slider/src/custom-element.js";
    import { Slider } from "@rmlibrary/slider";

    class SurveyRatingVidImgController extends Component {
        constructor(props) {
            super(props);
        }

        componentDidMount() {
            const sliderEl = document.getElementById("rm-slider");

            this.slider = new Slider(sliderEl, {
                min: 0,
                max: 100,
                step: 1,
                value: 0,
            });

            this.beforeAfterElement = document.querySelector("#before-after");

            this.sliderNumber = document.querySelector(".number");
            this.sliderHandle = document.querySelector(".rm-slider-handle");
            this.sliderHandle.appendChild(this.sliderNumber);

            this.slider.on("change", value => {
                this.beforeAfterElement.progress = value.toString();
                this.sliderNumber.innerText = Math.round(value / 20) + 1;
            });

            this.updateValues(true);
            window.scrollTo(0, 0);

            this.nextBtn = document.querySelector(".next-button");
            this.backBtn = document.querySelector(".back-button");
            this.plus = document.querySelector(".plus");
            this.minus = document.querySelector(".minus");

            this.plus.addEventListener("click", evt => {
                let num = Number(this.sliderNumber.innerText);
                if (num < 6) {
                    num++;
                    this.sliderNumber.innerText = num;
                    this.beforeAfterElement.progress = (num - 1) * 20;
                    this.slider.value = (num - 1) * 20;
                }
            });
            this.minus.addEventListener("click", evt => {
                let num = Number(this.sliderNumber.innerText);
                if (num > 1) {
                    num--;
                    this.sliderNumber.innerText = num;
                    this.beforeAfterElement.progress = (num - 1) * 20;
                    this.slider.value = (num - 1) * 20;
                }
            });

            if (this.props.answer === "") {
                this.nextBtn.setAttribute("disabled", "true");
                this.backBtn.setAttribute("disabled", "true");
            }  else {
                this.nextBtn.removeAttribute("disabled");
                this.backBtn.removeAttribute("disabled");
            }
            
            const video = this.beforeAfterElement.querySelector("video");
            const videoLoadedHandler = () => {
                if (this.props.answer === "") {
                    this.animateSliderHandle(this.slider)
                }

                video.removeEventListener("loadeddata", videoLoadedHandler);
            };
            video.addEventListener("loadeddata", videoLoadedHandler);
        }

        componentDidUpdate() {
            this.updateValues(false);
            
            if (this.props.answer === "") {
                this.nextBtn.setAttribute("disabled", "true");
                this.backBtn.setAttribute("disabled", "true");
            }  else {
                this.nextBtn.removeAttribute("disabled");
                this.backBtn.removeAttribute("disabled");
            }

            if (document.activeElement) {
                document.activeElement.blur();
            }
            window.scrollTo(0, 0);

            const video = this.beforeAfterElement.querySelector("video");

            const videoLoadedHandler = () => {
                if (this.props.answer === "") {
                    this.animateSliderHandle(this.slider)
                }
                video.removeEventListener("loadeddata", videoLoadedHandler);
            };
            video.addEventListener("loadeddata", videoLoadedHandler);
        }

        updateValues(firstMount) {
            const sliderNumber = this.props.answer ? this.props.answer.value : 1;
            const sliderValue = this.props.answer ? (this.props.answer.value - 1) * 20 : 0;

            if (!firstMount) {
                this.beforeAfterElement.progress = `${sliderValue}`;
            }
            this.slider.value = sliderValue;
            this.sliderNumber.innerText = Math.round(sliderNumber);
        }

        animateSliderHandle() {
            let start;
            let rafID;

            /* const easeInOut = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t); */
            const easeInOutCubic = t => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);

            let animateSlider = timestamp => {
                if (start === undefined) start = timestamp;
                const elapsed = timestamp - start;
                let value =
                    elapsed <= 1500
                        ? Math.min(easeInOutCubic(elapsed * 0.00075), 1) * 100
                        : Math.max(1 - easeInOutCubic(Math.abs(elapsed - 1500) * 0.001), 0) * 50 + 50;

                if (elapsed <= 2000 || value > 50) {
                    this.slider.value = value;
                    this.beforeAfterElement.progress = value;
                    this.sliderNumber.innerText = Math.round(value / 20) + 1;
                    requestAnimationFrame(animateSlider);
                } else {
                    cancelAnimationFrame(rafID);
                    this.nextBtn.removeAttribute("disabled");
                    this.backBtn.removeAttribute("disabled");
                }
            };

            rafID = requestAnimationFrame(animateSlider);
        }
    }

    SurveyRatingVidImgController.defaultProps = {
        title: "Customer Care",
        question: "We are attentive to your needs and proactively communicating with you.",
        onSubmit: () => console.log("submit"),
    };
</script>
