/*globals document, window */
import {
    cloneElement as clone,
    Children,
    Component,
    createElement
} from "rmlibrary/comp";
import RMTween from "rmlibrary/tween";
import { Draggable } from "rmlibrary/draggable";

class CarouselProxy {
    constructor(cr) {
        this.controller = cr;
    }

    get xShift() {
        return this.controller.state.x;
    }
    set xShift(v) {
        this.controller.setState({ x: v });
    }
}

export class Carousel extends Component {
    constructor(props) {
        super(props);

        this.validateProps(props);

        this.state = {
            viewState: null, // The card to show. <0|1|2>, in order left to right
            x: null,
            xUnits: null,
            busy: false,
            beingDragged: false,
            isHover: false,
            dragRangeX: null,
            reveal: props.reveal
        };

        this.carouselProxy = new CarouselProxy(this);
        this.animateDurationDefault = 1000;

        this.setView = this.setView.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.getViewWidth = this.getViewWidth.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if (this.props.autoScroll) this.setScrollInterval();

        let state = { viewState: 1, dragRangeX: this.getDragRange() };
        if (this.state.reveal) this.setState({ ...state, x: 0, xUnits: "px" });
        else this.setState({ ...state, x: -(100 / 3), xUnits: "%" });
    }

    componentWillReceiveProps(nextProps) {
        this.validateProps(nextProps);
        this.setState({
            dragRangeX: this.getDragRange(),
            reveal: nextProps.reveal
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        if (this.scrollInterval) clearInterval(this.scrollInterval);
        if (this.currentTween) this.currentTween.stop();
    }

    /* Handlers */

    handleResize() {
        if (this.currentTween) this.currentTween.skipToEnd();
        else this.setToDefaultView();

        this.setState({ dragRangeX: this.getDragRange() });
    }

    handleMouseEnter() {
        // Pause the autoScroll
        if (this.scrollInterval)
            this.scrollInterval = clearInterval(this.scrollInterval);
        this.setState({ isHover: true });
    }

    handleMouseLeave() {
        // Continue autoScroll if it's setup
        if (
            !this.scrollInterval &&
            this.props.autoScroll &&
            !this.state.beingDragged
        )
            this.setScrollInterval();

        this.setState({ isHover: false });
    }

    handleDragStart() {
        // Convert to px xShift for Draggable
        let state = { beingDragged: true };
        if (this.state.reveal) this.setState(state);
        else
            this.setState({
                ...state,
                x: -this.getViewWidth(),
                xUnits: "px" // Changed to px for Draggable
            });

        if (this.scrollInterval) clearInterval(this.scrollInterval);
    }

    handleDragEnd(dx, ddx) {
        this.setState({ beingDragged: false });

        if (dx < 0 && this.state.x > this.state.dragRangeX[0]) {
            if (ddx > 0) this.setView(0, this.state.x + dx, ddx);
            else this.setView(1, this.state.x + dx, ddx);
        } else if (dx > 0 && this.state.x < this.state.dragRangeX[1]) {
            if (ddx < 0) this.setView(0, this.state.x + dx, ddx);
            else this.setView(-1, this.state.x + dx, ddx);
        }
    }

    /* Deck and cards manipulation */

    /* Prepare for the deck to get updated */
    deckWillUpdate() {
        this.setToDefaultView();
        if (this.props.autoScroll && !this.state.isHover)
            this.setScrollInterval();
    }

    /* Used by draggable to change the current card */
    setView(increment, dx, ddx) {
        const viewWidth = this.getViewWidth();
        let dir = increment == 1 ? "right" : increment == -1 ? "left" : "both";

        let animateTo;

        // These are the defaults for xShifts of viewState = 1
        if (this.state.reveal) {
            if (increment == 1) animateTo = -viewWidth;
            else if (increment == -1) animateTo = viewWidth;
            else animateTo = 0;
        } else {
            if (increment == 1) animateTo = -2 * viewWidth;
            else if (increment == -1) animateTo = 0;
            else animateTo = -viewWidth;
        }

        let duration = this.map(
            dx,
            ddx,
            increment,
            this.state.reveal,
            viewWidth
        );

        this.animateTween({
            scrollDir: dir,
            tweenFrom: { xShift: dx },
            tweenTo: { xShift: animateTo },
            duration
        });
    }

    // Linear mapping of dx left to animate and duration
    map(dx, ddx, increment, reveal, viewWidth) {
        let from1, from2, to1, to2;

        from2 = 0;
        to2 = this.props.animateDuration || this.animateDurationDefault;

        if (this.state.reveal) {
            if (increment == 1) {
                from1 = -viewWidth;
                to1 = 0;
            } else if (increment == -1) {
                from1 = viewWidth;
                to1 = 0;
            } else if (increment == 0) {
                if (dx < 0 && ddx > 0) {
                    from1 = 0;
                    to1 = -viewWidth;
                } else if (dx > 0 && ddx < 0) {
                    from1 = 0;
                    to1 = -viewWidth;
                }
            }
        } else {
            if (increment == 1) {
                from1 = -2 * viewWidth;
                to1 = -viewWidth;
            } else if (increment == -1) {
                from1 = 0;
                to1 = -viewWidth;
            } else if (increment == 0) {
                if (dx < 0 && ddx > 0) {
                    from1 = -viewWidth;
                    to1 = -2 * viewWidth;
                } else if (dx > 0 && ddx < 0) {
                    from1 = 0;
                    to1 = -viewWidth;
                }
            }
        }

        return ((dx - from1) / (to1 - from1)) * (to2 - from2) + from2;
    }

    prev() {
        const viewWidth = this.getViewWidth();
        if (this.state.reveal)
            this.animateTween({
                scrollDir: "left",
                tweenFrom: { xShift: 0 },
                tweenTo: { xShift: viewWidth }
            });
        else
            this.animateTween({
                scrollDir: "left",
                tweenFrom: { xShift: -viewWidth },
                tweenTo: { xShift: 0 }
            });
    }

    next() {
        const viewWidth = this.getViewWidth();
        if (this.state.reveal)
            this.animateTween({
                scrollDir: "right",
                tweenFrom: { xShift: 0 },
                tweenTo: { xShift: -viewWidth }
            });
        else
            this.animateTween({
                scrollDir: "right",
                tweenFrom: { xShift: -viewWidth },
                tweenTo: { xShift: -2 * viewWidth }
            });
    }

    animateTween({ scrollDir, tweenFrom, tweenTo, duration }) {
        let indexBound =
            scrollDir === "right" ? 2 : scrollDir === "left" ? 0 : Infinity;
        let increment =
            scrollDir === "right" ? 1 : scrollDir === "left" ? -1 : 0;

        let allow = this.isAllowedScroll(scrollDir);

        let withinBounds = true;
        if (increment === -1) withinBounds = this.state.viewState > indexBound;
        else if (increment === 1)
            withinBounds = this.state.viewState < indexBound;

        if (!this.state.busy && withinBounds && allow) {
            this.setState({
                viewState: this.state.viewState + increment,
                busy: true,
                xUnits: "px"
            });

            if (this.props.callbefore) this.props.callbefore(increment);

            const animateDuration =
                this.props.animateDuration || this.animateDurationDefault;
            this.carouselProxy.xShift = -this.getViewWidth();
            this.currentTween = RMTween.create(this.carouselProxy)
                .to(tweenFrom)
                .to(tweenTo, duration || animateDuration, "easeOut")
                .run(() => {
                    this.setState({ busy: false });

                    if (increment != 0) {
                        this.deckWillUpdate();
                        this.props.callback(increment);
                    }
                });
        }
    }

    /* Internal */

    isAllowedScroll(direction) {
        if (
            this.props.allowScroll &&
            this.props.allowScroll !== "both" &&
            this.props.allowScroll !== direction
        )
            return false;

        return true;
    }

    getDragRange() {
        const viewWidth = this.getViewWidth();
        let dragRanges;

        if (this.state.reveal)
            dragRanges = {
                left: [0, viewWidth],
                right: [0, -viewWidth],
                both: [-viewWidth, viewWidth]
            };
        else
            dragRanges = {
                left: [-1 * viewWidth, 0],
                right: [-2 * viewWidth, -1 * viewWidth],
                both: [-2 * viewWidth, 0]
            };

        if (this.props.allowScroll) return dragRanges[this.props.allowScroll];

        return dragRanges.both;
    }

    validateProps(props) {
        if (this.getNumChildren(props) != 3)
            throw new Error("Carousel must take in exactly three children.");
        if (!props.callback)
            throw new Error(
                "Carousel must take in a callback prop to update the deck after an animation."
            );
        if (typeof props.callback !== "function")
            throw new Error(
                "Make sure the callback prop on Carousel is a function."
            );
        if (!props.width)
            throw new Error(
                "Carousel must take in a width prop with a css width value to set the dimensions."
            );
        if (!/\d+(px|%)$/.exec(props.width))
            throw new Error(
                "Make sure the width prop is eitehr a pixel value or a percentage."
            );
        if (props.autoScroll && typeof props.autoScroll !== "number")
            throw new Error(
                "Make sure the autoScroll value is a number representing millisecond intervals."
            );
        if (props.animateDuration && typeof props.animateDuration !== "number")
            throw new Error(
                "Make sure the animateDuration value is a number representing millisecond durations."
            );
        if (
            props.allowScroll &&
            ["left", "right", "both"].indexOf(props.allowScroll) == -1
        )
            throw new Error(
                "Make sure the allowScroll value is one of ['left', 'right', 'both']."
            );
    }

    setScrollInterval() {
        if (this.scrollInterval) clearInterval(this.scrollInterval);
        this.scrollInterval = setInterval(() => {
            if (!this.viewElem) return;
            const rect = this.viewElem.getBoundingClientRect();
            const windowHeight = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );
            const isOffScreen =
                rect.top + rect.height < 0 || rect.top > windowHeight;
            if (isOffScreen) return;
            this.next();
        }, this.props.autoScroll + (this.props.animateDuration || this.animateDurationDefault));
    }

    /* (Re)sets the position of the cards to a base state */
    setToDefaultView() {
        if (this.state.reveal)
            this.setState({
                viewState: 1,
                x: 0,
                xUnits: "px"
            });
        else
            this.setState({
                viewState: 1,
                x: -(100 / 3),
                xUnits: "%"
            });
    }

    getNumChildren(props) {
        if (Array.isArray(props.children)) return props.children.length;
        else if (this.props.children) return 1;
        else return 0;
    }

    getViewWidth() {
        return this.viewElem.getBoundingClientRect().width;
    }

    /* API */

    isBusy() {
        return this.state.busy;
    }

    /* Rendering */

    renderCarouselViewDefault() {
        let deckStyle = {
            width: "300%",
            display: "flex",
            height: "100%"
        };

        if (this.props.draggable) {
            if (!this.state.beingDragged) {
                deckStyle = {
                    ...deckStyle,
                    transform: `translate3d(${this.state.x}${
                        this.state.xUnits
                    }, 0, 0)`
                };
            }

            let dx = this.state.beingDragged ? this.state.x : "NaN";
            return (
                <Draggable
                    dx={dx}
                    dragRangeX={this.state.dragRangeX}
                    onDragEnd={this.handleDragEnd}
                    onDragStart={this.handleDragStart}
                >
                    <div className="deck" style={deckStyle}>
                        {Children.map(this.props.children, child =>
                            clone(child, {
                                className: [
                                    ...(child.props.className || "").split(" "),
                                    "card-selected"
                                ].join(" "),
                                style: {
                                    ...child.props.style,
                                    flex: "1 0 0",
                                    width: "100%"
                                }
                            })
                        )}
                    </div>
                </Draggable>
            );
        } else {
            deckStyle = {
                ...deckStyle,
                transform: `translate3d(${this.state.x}${
                    this.state.xUnits
                }, 0, 0)`
            };

            return (
                <div style={deckStyle}>
                    {Children.map(this.props.children, child =>
                        clone(child, {
                            className: [
                                ...(child.props.className || "").split(" "),
                                "card-selected"
                            ].join(" "),
                            style: {
                                ...child.props.style,
                                flex: `0 0 ${100 / 3}%`,
                                width: "100%"
                            }
                        })
                    )}
                </div>
            );
        }
    }

    renderCarouselViewReveal() {
        let deckStyle = { width: "100%", height: "100%", position: "relative" };
        let cloneStyle = { width: "100%", height: "100%" };

        let divStyle = {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0"
        };

        let dx = this.state.beingDragged
            ? this.draggable.getDx()
            : this.state.x;
        let topOuter = {
            ...divStyle,
            overflow: "hidden",
            transform: `translate3d(${dx}px, 0, 0)`
        };

        let topInner = {
            ...divStyle,
            transform: `translate3d(${-dx}px, 0, 0)`
        };

        let leftStyle = { ...divStyle, display: dx > 0 ? "block" : "none" };

        let rightStyle = { ...divStyle, display: dx < 0 ? "block" : "none" };

        let cards = Children.map(this.props.children, child =>
            clone(child, { style: { ...child.props.style, ...cloneStyle } })
        );

        if (this.props.draggable) {
            let schmuck = () => this.setState({});
            return (
                <div style={deckStyle}>
                    <div style={leftStyle}> {cards[0]} </div>
                    <div style={rightStyle}> {cards[2]} </div>

                    <Draggable
                        dx={dx}
                        dragRangeX={this.state.dragRangeX}
                        onDragEnd={this.handleDragEnd}
                        onDragStart={this.handleDragStart}
                        ref={draggable => (this.draggable = draggable)}
                        tick={schmuck}
                    >
                        <div
                            style={topOuter}
                            ref={elem => (this.topOuterRef = elem)}
                        >
                            <div className="card-selected" style={topInner}>
                                {cards[1]}
                            </div>
                        </div>
                    </Draggable>
                </div>
            );
        } else {
            return (
                <div style={deckStyle}>
                    <div style={leftStyle}> {cards[0]} </div>
                    <div style={rightStyle}> {cards[2]} </div>

                    <div
                        style={topOuter}
                        ref={elem => (this.topOuterRef = elem)}
                    >
                        <div className="card-selected" style={topInner}>
                            {cards[1]}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderCarouselControls() {
        let buttonStyle = {
            background: "black",
            color: "white",
            padding: "5px",
            margin: "5px"
        };

        if (this.props.controls)
            return (
                <div>
                    <button style={buttonStyle} onClick={this.prev}>
                        prev
                    </button>
                    <button style={buttonStyle} onClick={this.next}>
                        next
                    </button>
                </div>
            );
        else return null;
    }

    render() {
        //do not allow keyboard access to the hidden slider elements
        let noTabToHidden = document.querySelectorAll(".hidden-slider");

        for (var i = 0; i < noTabToHidden.length; i++) {
            let noTabTo = noTabToHidden[i].querySelectorAll(
                "a, input, button, textarea, select, object"
            );
            for (var x = 0; x < noTabTo.length; x++) {
                noTabTo[x].setAttribute("tabindex", "-1");
            }
        }

        let viewStyle = {
            overflow: "hidden",
            width: this.props.width,
            height: "100%"
        };

        return (
            <div style="height: 100%">
                {this.renderCarouselControls()}

                <div
                    style={viewStyle}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    ref={elem => (this.viewElem = elem)}
                >
                    {this.state.reveal
                        ? this.renderCarouselViewReveal()
                        : this.renderCarouselViewDefault()}
                </div>
            </div>
        );
    }
}
