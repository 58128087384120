import { Component, createElement, Fragment } from "rmlibrary/comp";

import { SurveyLanding } from "./landing.html";
import { SurveyRating } from "./rating.html";
import { SurveyRatingVidVid } from "./rating-vid-vid.html";
import { SurveyRatingVidImg } from "./rating-vid-img.html";
import { SurveyRatingVidImgWebgl } from "./rating-vid-img-webgl.html";
import { SurveyServices } from "./services.html";
import { SurveyProducts } from "./products.html";
import { SurveyRecommend } from "./recommend.html";
import { SurveyEmail } from "./email.html";
import { SurveyResults } from "./results.html";

import { trackSurvey } from "../../../tracking";
// import { SurveyAnimation } from '../../common/survey-animation';

const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
const isIE = !!window.MSInputMethodContext && !!document.documentMode;

function getQueryStringFromURL(name) {
    const queryStrings = location.search.substring(1).split("&");

    for (let i = 0; i < queryStrings.length; i++) {
        const [key, value] = queryStrings[i].split("=");

        if (decodeURIComponent(key) === name) {
            return decodeURIComponent(value);
        }
    }

    return null;
}

// parent component which handles rendering of all survey pages
export class SurveyBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: null,
            questionAnswers: [],
            currentQuestion: -1,
            feedback: "",
            answer: "",
            engagementId: null,
            notes: null,
        };

        this.handleQuestionSubmit = this.handleQuestionSubmit.bind(this);
        this.updateEngagementId = this.updateEngagementId.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
    }

    /**
     * Load survey json files (array of questions/content/event dates) into state
     * and set current question at starting element.
     */
    componentDidMount() {
        let questions;

        const vid = window.localStorage.getItem("com.richmedia.hsid") || getQueryStringFromURL("vid");

        const searchParams = `query=getcontactdata&param=${vid}`;
        fetch("site_assets/surveys/basic.json")
            .then((res) => res.json())
            .then((data) => {
                questions = data.questions;

                if (vid !== undefined || vid !== null) {
                    fetch("https://www.richmedia.com/api/getcontact.php", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: searchParams,
                    })
                        .then((res) => res.json())
                        .then((hubspotData) => {
                            this.setState({
                                questions: questions,
                                currentQuestion: 0,
                                userData: {
                                    vid: vid,
                                    userName: hubspotData.firstname,
                                    firstName: hubspotData.firstname,
                                    lastName: hubspotData.lastname,
                                    email: hubspotData.email,
                                    accountMgrFirstName: hubspotData.owner.firstName,
                                    accountMgrLastName: hubspotData.owner.lastName,
                                    accountMgrEmail: hubspotData.owner.email,
                                    senderName: hubspotData.firstname,
                                    company: hubspotData.company,
                                    // clientIndustry: hubspotData.industry,
                                    // completedProjType: "website", // used to determine resultsText2
                                    // completedProject: "usability testing", // used to determine cross sell product
                                },
                                error: false,
                            });
                            // this.forceUpdate();
                        })
                        .catch((err) => {
                            this.state = this.setState({ ...this.state, error: true });
                            console.error(err);
                        });
                } else {
                    this.setState({
                        questions: questions,
                        currentQuestion: 0,
                    });
                }
            })
            .catch((err) => {
                this.state = this.setState({ ...this.state, error: true });
                console.error(err);
            });
    }

    /**
     * Store all user input in state (questionAnswers, feedback, answer), and advance
     * to next question or retreat to previous question if back button is selected.
     * NOTE: "answer" holds the current questions previously selected answer, regardless if the
     * current question is the next question or the previous question.
     * @param {number | string | array} answer
     * @param {string} feedback
     */
    handleQuestionSubmit(answer, feedback) {
        let currentQuestion = this.state.currentQuestion;
        let prevAnswer = null;

        if (currentQuestion !== 0) {
            // if current question is 0 move to next question (0 is the landing page)
            if (answer !== "back") {
                // proceed to next question
                /**
                 * Remove the previous selected slider value
                 */
                const slider = document.getElementById("rm-slider");
                const beforeAfter = document.getElementById("before-after");
                // const sliderNumber = document.querySelector(".number");
                if (slider) {
                    slider.value = 0;
                    beforeAfter.progress = "0";
                }

                // store answer in new questionAnswers array
                let newQstAnswers = this.state.questionAnswers;
                newQstAnswers[currentQuestion] = answer;

                // keep track of previously selected answer if next question had already been answered
                let nextQstAnswer = "";
                if (newQstAnswers[currentQuestion + 1]) {
                    nextQstAnswer = newQstAnswers[currentQuestion + 1];
                }
                // update state questionAnswers array, this will replace the previously selected answer for the current question if one exists
                this.setState({
                    questionAnswers: newQstAnswers,
                    answer: nextQstAnswer,
                });

                // advance to next question
                if (currentQuestion + 1 < this.state.questions.length) {
                    this.setState({
                        currentQuestion: currentQuestion + 1,
                    });
                }
            } else {
                // track question answer and retreat to previous question
                if (currentQuestion === 1) {
                    prevAnswer = this.state.questionAnswers[currentQuestion];
                    --currentQuestion;
                    this.setState({
                        currentQuestion: currentQuestion,
                        answer: prevAnswer,
                    });
                } else {
                    --currentQuestion;
                    prevAnswer = this.state.questionAnswers[currentQuestion];
                    this.setState({
                        currentQuestion: currentQuestion,
                        answer: prevAnswer,
                    });
                }
            }
        } else {
            this.setState({
                currentQuestion: currentQuestion + 1,
            });
        }
    }

    /**
     * If param === true, this function returns a sorted array of all products from basic.json
     * else, returns a sorted array of the product page answers.
     * @param {boolean} sortProductsFromJsonFile
     * @returns {array}
     */
    sortSelectedProducts(sortProductsFromJsonFile) {
        let answers = [];
        const productsFromJsonFile = this.state.questions[8].products;
        let idx = [];
        let optionIndex = -1;
        let productName = "";
        let sortedAnswers = [];

        if (!sortProductsFromJsonFile) {
            answers = Array.from(this.state.questionAnswers[9]);
        } else {
            productsFromJsonFile.forEach((product) => {
                product.options.forEach((option) => {
                    answers.push(option.id);
                });
            });
        }
        // find priority ranked index of answers
        answers.forEach((answer) => {
            for (let i = 0; i < productsFromJsonFile.length; i++) {
                let product = productsFromJsonFile[i];

                optionIndex = product.options.findIndex((productFromOptions) => {
                    return productFromOptions.id.toLowerCase() === answer.toLowerCase();
                });

                if (optionIndex !== -1) {
                    idx.push(productsFromJsonFile[i].options[optionIndex].rank);
                    optionIndex = -1;
                    break;
                }
            }
        });

        // sort indexes by highest rank (0-16)
        idx.sort((a, b) => a - b);

        // push answers into sortedAnswers by rank
        idx.forEach((index) => {
            for (let i = 0; i < productsFromJsonFile.length; i++) {
                let products = productsFromJsonFile[i];

                optionIndex = products.options.findIndex((productFromOptions) => {
                    return productFromOptions.rank === index;
                });

                if (optionIndex > -1) {
                    productName = products.options[optionIndex].name.toLowerCase();
                    sortedAnswers.push(productName);
                    optionIndex = -1;
                    break;
                }
            }
        });
        return sortedAnswers;
    }

    updateEngagementId(id, notes) {
        this.setState((prevState) => ({
            ...prevState,
            engagementId: id,
            notes: notes
        }));
    }

    updateNotes(notes) {
        this.setState((prevState) => ({
            ...prevState,
            notes: notes,
        }));
    }

    // Update all dynamic json data (through userData object & updateJsonData method) and render next question
    renderCurrentQuestion() {
        // render current question if the questions are loaded
        if (this.state.questions) {
            const questions = this.state.questions[this.state.currentQuestion];
            const prevQuestion = this.state.currentQuestion - 1;
            const prevSelectedAnswer = this.state.answer;
            const nextQuestion = this.state.questions[this.state.currentQuestion + 1];
            let themeColour = "yellow";
            const finalQuestion = this.state.questions.length - 2; // -2 to exclude index 0 and final results page

            // update user name on landing page
            if (this.state.currentQuestion === 0) {
                questions.title = questions.title.replace("dynamicText", this.state.userData.userName);
            }

            // survey page background colour
            if (this.state.currentQuestion % 2 === 1) {
                themeColour = "black";
            }

            // render appropriate survey page based on current question
            trackSurvey(questions.section || "Results");

            const questionType =
                questions.type === "ranking-vid-img" && !isFirefox && !isIE
                    ? `${questions.type}-webgl`
                    : questions.type;

            switch (questionType) {
                case "landing":
                    return (
                        <SurveyLanding
                            error={false}
                            title={questions.title}
                            intro={questions.text1}
                            startMsg={questions.text2}
                            btnTxt={questions.buttonText}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "ranking":
                    return (
                        <SurveyRating
                            title={questions.title}
                            question={questions.text}
                            theme={themeColour}
                            desc={questions.desc}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "ranking-vid-vid":
                    return (
                        <SurveyRatingVidVid
                            title={questions.title}
                            question={questions.text}
                            videoSrc={questions.videoSrc}
                            theme={themeColour}
                            desc={questions.desc}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "ranking-vid-img-webgl":
                    return (
                        <SurveyRatingVidImgWebgl
                            title={questions.title}
                            question={questions.text}
                            videoSrc={questions.videoSrc}
                            imageSrc={questions.imageSrc}
                            theme={themeColour}
                            desc={questions.desc}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "ranking-vid-img":
                    return (
                        <SurveyRatingVidImg
                            title={questions.title}
                            question={questions.text}
                            videoSrc={questions.videoSrc}
                            imageSrc={questions.imageSrc}
                            theme={themeColour}
                            desc={questions.desc}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "slider-textbox":
                    return (
                        <SurveyServices
                            title={questions.title}
                            question={questions.text1}
                            subQuestion={questions.text}
                            theme={themeColour}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            feedback={this.state.feedback}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "products":
                    return (
                        <SurveyProducts
                            title={questions.title}
                            question={questions.text}
                            theme={themeColour}
                            products={questions.products}
                            other={questions.other}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "recommend":
                    return (
                        <SurveyRecommend
                            title={questions.title}
                            question={questions.text}
                            theme={themeColour}
                            questionName={questions.questionName}
                            answer={prevSelectedAnswer}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
                case "email":
                    // if user selects yes in "recommend" page, load email page
                    if (this.state.questionAnswers[prevQuestion].value === "email") {
                        const cc = questions.cc.replace("cc", this.state.userData.accountMgrEmail);
                        const emailBody2 = questions.emailBody2
                            .replace("accountMgrFirstName", this.state.userData.accountMgrFirstName)
                            .replace("accountMgrLastName", this.state.userData.accountMgrLastName);
                        const emailBody3 = questions.emailBody3.replace(
                            "accountMgrFirstName",
                            this.state.userData.accountMgrFirstName,
                        );
                        const emailSender = questions.emailSender.replace(
                            "dynamicText",
                            this.state.userData.firstName + " " + this.state.userData.lastName,
                        );
                        return (
                            <SurveyEmail
                                title={questions.title}
                                text={questions.text}
                                theme={themeColour}
                                cc={cc}
                                valueIntro={questions.emailIntro}
                                valueBody1={questions.emailBody1}
                                valueBody2={emailBody2}
                                valueBody3={emailBody3}
                                valueThanks={questions.emailThanks}
                                valueSender={emailSender}
                                btn1={questions.buttonText1}
                                btn2={questions.buttonText2}
                                questionName={questions.questionName}
                                answer={prevSelectedAnswer}
                                onSubmit={this.handleQuestionSubmit}
                            />
                        );
                    } else {
                        // user selected no in "recommend" page, load results page
                        return (
                            <SurveyResults
                                title={nextQuestion.title}
                                vid={this.state.userData.vid}
                                question={questions.text}
                                firstName={this.state.userData.firstName}
                                lastName={this.state.userData.lastName}
                                accountMgrEmail={this.state.userData.accountMgrEmail}
                                company={this.state.userData.company}
                                answers={this.state.questionAnswers}
                                answer={prevSelectedAnswer}
                                questionName={questions.questionName}
                                updateEngagementId={this.updateEngagementId}
                                engagementId={this.state.engagementId}
                                updateNotes={this.updateNotes}
                                notes={this.state.notes}
                                onSubmit={this.handleQuestionSubmit}
                            />
                        );
                    }
                case "results":
                    return (
                        <SurveyResults
                            title={questions.title}
                            vid={this.state.userData.vid}
                            question={questions.text}
                            firstName={this.state.userData.firstName}
                            lastName={this.state.userData.lastName}
                            accountMgrEmail={this.state.userData.accountMgrEmail}
                            company={this.state.userData.company}
                            answers={this.state.questionAnswers}
                            answer={prevSelectedAnswer}
                            questionName={questions.questionName}
                            updateEngagementId={this.updateEngagementId}
                            engagementId={this.state.engagementId}
                            updateNotes={this.updateNotes}
                            notes={this.state.notes}
                            onSubmit={this.handleQuestionSubmit}
                        />
                    );
            }
        }

        if (this.state.error) {
            trackSurvey("error");
            return <SurveyLanding error={true} />;
        }
    }

    render() {
        return <div>{this.renderCurrentQuestion()}</div>;
    }
}
